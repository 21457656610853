// 第n个红包对应的所需连对数
export const comboList = [
  {
    num: 1,
    combo: 3,
  },
  {
    num: 2,
    combo: 5,
  },
  {
    num: 3,
    combo: 3,
  },
  {
    num: 4,
    combo: 5,
  },
  {
    num: 5,
    combo: 3,
  },
  {
    num: 6,
    combo: 5,
  },
];

// 总生命
export const TOTAL_HP = 3;
