<template>
  <CommonModal
    :buttonText="amount == 0 ? '知道了' : '看视频解锁更多搜索机会'"
    :height="status === 1 ? '1.96rem' : '1.76rem'"
    @click="onClick"
    @close="onClose"
  >
    <img class="chance_modal_img" src="@/assets/images/tree/steal/chance_modal_img.png" alt="chance_modal_img" />
    <div class="content">
      <p class="tips">今日剩余搜索机会{{ amount }}次</p>
      <p v-if="status == 1" class="tips">升级到摇钱树解锁更多机会</p>
    </div>
  </CommonModal>
</template>

<script>
  import CommonModal from '../CommonModal/CommonModal';
  export default {
    components: {
      CommonModal,
    },
    props: {
      status: {
        type: Number,
        default: 0,
      },
      onClick: {
        type: Function,
        default: () => {},
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      amount: {
        type: Number,
        default: 0,
      },
    },
  };
</script>

<style lang="less" scoped>
  .chance_modal_img {
    position: absolute;
    top: -0.75rem;
    width: 1.7rem;
    height: 1.7rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.34rem;
    box-sizing: border-box;
  }
  .tips {
    position: relative;
    z-index: 200;
    width: 2.2rem;
    height: 0.24rem;
    font-size: 0.15rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2538c0;
    line-height: 0.24rem;
    text-align: center;
  }
</style>
