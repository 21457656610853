<template>
  <div ref="modal" class="modal-warpper">
    <div class="modal-container">
      <div class="rule-modal-warpper">
        <div class="rule-modal-close" @click="close"></div>
        <div class="rule-modal-container">
          <div v-if="ruleType === 'default'" class="rule-modal-content">
            <h5 class="rule-modal-content-title">活动规则</h5>
            <p class="rule-modal-content-section">
              1）喝水打卡赚金币，保持健康好生活，每天6:00-9:00、9:00-11:00、11:00-13:00、13:00-14:00、14:00-15:00、15:00-16:00、16:00-17:00、17:00-24:00都可以打卡；
            </p>
            <p class="rule-modal-content-section">
              2）超过规定打卡时间，您可看视频进行补打卡，完整看完视频即可完成补打卡；
            </p>
            <p class="rule-modal-content-section">
              3） 每次打卡，可以选择看视频获得超多翻倍金币的奖励，千万不要错过哦；
            </p>
            <p class="rule-modal-content-section">4） 连续7天打卡，即可获得连续宝箱开启机会1次；</p>
            <p class="rule-modal-content-section">5） 连续7天全勤（即每天打满8次卡），可获得全勤大奖1份；</p>
            <p class="rule-modal-content-section">
              6）您有任何问题咨询，欢迎点击加入官方讨论QQ群：
              <a class="link-active" @click="copyAndNavigate(728704491)">728704491</a>；
            </p>
          </div>

          <div v-else class="rule-modal-content">
            <h5 class="rule-modal-content-title">活动规则</h5>
            <p class="rule-modal-content-section">
              1）喝水打卡赚金币，保持健康好生活，每天6:00-9:00、9:00-11:00、11:00-13:00、13:00-14:00、14:00-15:00、15:00-16:00、16:00-17:00、17:00-24:00正点打卡的次数越多，单次打卡可获得更多金币哦；
            </p>
            <p class="rule-modal-content-section">2）超过规定打卡时间，您可以在对应时间内补打卡获得少量金币；</p>
            <p class="rule-modal-content-section">
              3）
              每次打卡或不打卡，您都可以选择看视频获得超多翻倍金币的奖励，您就算当次加倍领取失败或未点击加倍领取，之后也可以在对应时间内找到【可翻倍】按钮，点击即可领取翻倍奖励；
            </p>
            <p class="rule-modal-content-section">
              4） 每日累计打卡或补打卡2、4、6、8次均可以获得开启红包的机会，累计打卡或补打卡次数越多，红包金额越大哦；
            </p>
            <p class="rule-modal-content-section">
              5）
              您可以通过每日正点打卡累计当天的健康值，次日即可领取昨日健康值结算的金币，小提示：健康值越高，第二日的金币发放金额就会越多哦，您可以通过增加按时打卡的次数增加健康值；
            </p>
            <p class="rule-modal-content-section">6）连续7天坚持打卡，即可获得连续宝箱开启机会1次；</p>
            <p class="rule-modal-content-section">
              7）您有任何问题咨询，欢迎点击加入官方讨论QQ群：
              <a class="link-active" @click="copyAndNavigate(728704491)">728704491</a>；
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'drinking-rule-modal',
    props: {
      // 调用客户端 Copy 方法并拉起qq群
      copyAndNavigate: {
        type: Function,
        required: true,
      },
      ruleType: {
        type: String,
        default: 'default',
      },
    },
    methods: {
      /**
       * @description: 关闭弹窗
       * @param {type}
       * @return:viod
       */
      close() {},
    },
  };
</script>

<style scoped>
  .modal-container {
    position: relative;
    width: 3.1rem;
    height: auto;
    border-radius: 0.18rem;
    box-sizing: border-box;
  }
  .rule-modal-warpper {
    width: 2.9rem;
    height: auto;
    margin: 0 auto;
    background: linear-gradient(180deg, rgba(134, 180, 255, 1) 0%, rgba(88, 150, 250, 1) 100%);
    border-radius: 0.12rem;
    border: 0.005rem solid rgba(151, 151, 151, 1);
  }
  .rule-modal-container {
    box-sizing: border-box;
    padding: 0.15rem;
  }
  .rule-modal-content {
    box-sizing: border-box;
    padding: 0.15rem;
    background: rgba(82, 144, 255, 1);
    box-shadow: 0 0.02rem 0.03rem 0 rgba(50, 109, 215, 1);
    border-radius: 0.06rem;
    color: #fff;
    text-align: center;
  }
  .rule-modal-content {
    text-align: left;
    overflow: scroll;
    height: 4rem;
  }

  .rule-modal-content-title {
    color: #ffffff;
    font-size: 0.16rem;
    line-height: 0.16rem;
    margin-bottom: 0.2rem;
  }

  .rule-modal-content-section {
    color: #ffffff;
    font-size: 0.14rem;
    line-height: 0.17rem;
    margin-bottom: 0.14rem;
  }
</style>
