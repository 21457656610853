<template>
  <div class="sign-modal">
    <div class="sign-modal-coin">{{ pageModalInfo.today_reward_amount }}</div>
    <div class="sign-modal-day">已签到{{ pageModalInfo.today_sign_in_day }}天</div>
    <div class="sign-modal-content">
      <div class="sign-modal-content-title">{{ pageModalInfo.data_list.length }}天签到必得巨额奖励</div>
      <div class="sign-modal-content-list">
        <template v-for="(item, k) in pageModalInfo.data_list">
          <div v-if="item.day !== 7" :key="k" class="sign-modal-content-list-item">
            <div class="sign-modal-content-list-item-day">
              {{ pageModalInfo.today_sign_in_day === item.day ? '今天' : `第${item.day}天` }}
            </div>
            <div class="sign-modal-content-list-item-warp">
              <img
                class="sign-modal-content-list-item-icon"
                :src="
                  pageModalInfo.today_sign_in_day < item.day
                    ? require('@/assets/images/eatingV2/sign-icon.png')
                    : require('@/assets/images/eatingV2/sign-in.png')
                "
                alt="icon"
              />
            </div>
            <div class="sign-modal-content-list-item-num">{{ item.reward_amount }}金币</div>
          </div>
          <div v-else :key="k" class="sign-modal-content-list-last-item">
            <div class="sign-modal-content-list-last-item-day">第{{ item.day }}天</div>
            <div class="sign-modal-content-list-last-item-warp">
              <div class="warp-item">
                <div class="warp-item-icon-cont">
                  <img
                    class="warp-item-icon"
                    :src="
                      pageModalInfo.today_sign_in_day === 7
                        ? require('@/assets/images/eatingV2/sign-in.png')
                        : require('@/assets/images/eatingV2/sign-icon.png')
                    "
                    alt="icon"
                  />
                </div>
                <div class="warp-item-num">{{ item.reward_amount }}金币</div>
              </div>
              <div class="warp-item">
                <div class="warp-item-icon-cont">
                  <img
                    class="warp-item-icon"
                    :src="
                      pageModalInfo.today_sign_in_day === 7
                        ? require('@/assets/images/eatingV2/sign-in.png')
                        : require('@/assets/images/eatingV2/sign-icon.png')
                    "
                    alt="icon"
                  />
                </div>
                <div class="warp-item-num">{{ item.continuous_reward_amount }}金币</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <button @click="handleExtendTask" v-for="(item, k) in pageModalInfo.extend_tasks" :key="k" class="sign-modal-btn">
      {{ item.title }}
    </button>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { trackClick } from '@/utils/track';
  import { closeModal } from '@/utils/modal';

  export default {
    name: 'SignModalV2',
    props: {
      handleBtn: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('eatingV2', ['pageModalInfo']),
    },
    mounted() {
      trackClick({
        page_title: '吃饭补贴',
        element_name: '签到弹窗弹窗',
        remarks: '曝光',
      });
    },
    methods: {
      ...mapMutations('eatingV2', ['setVideoCbInfo']),
      handleExtendTask() {
        let { task_id, task_type, position, ad_group_id: groupId } = this.pageModalInfo.extend_tasks[0];
        let remarks = '明日再来';
        if (task_type === 'dining_sign_in_video') {
          remarks = '看视频领取';
          this.setVideoCbInfo({ type: 'SignModal', task: this.pageModalInfo.extend_tasks[0] });
          this.handleBtn(task_id, task_type, position, groupId);
        }
        trackClick({
          page_title: '吃饭补贴',
          element_name: '签到弹窗弹窗',
          remarks,
        });
        closeModal('eating-sign-modal-v2');
      },
    },
  };
</script>

<style scoped lang="less">
  .sign-modal {
    background: url('../../../assets/images/eatingV2/signmodalbg-v2.png') no-repeat no-repeat;
    background-size: 100% 100%;
    width: 3.49rem;
    height: 4.45rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-coin {
      font-size: 0.29rem;
      font-weight: 800;
      color: #ffde7b;
      position: relative;
      margin-top: 0.58rem;
      right: 0.68rem;
      text-shadow: 0.02rem 0.02rem 0.01rem rgba(97, 203, 242, 0.7), 0px -0.01rem 0.02rem #ffc478;
    }
    &-day {
      background: #ffffff;
      height: 0.23rem;
      padding: 0 0.1rem;
      border-radius: 0.12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      color: #4f98ff;
      font-size: 0.12rem;
      margin-top: 0.06rem;
      position: relative;
      right: 1rem;
    }
    &-content {
      margin-top: 0.13rem;
      width: 2.76rem;
      height: 2.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.14rem 0.06rem;
      box-sizing: border-box;
      border: 0.02rem solid #ffffff;
      background: #ffd36b;
      border-radius: 0.1rem;
      &-title {
        width: 1.85rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.03rem;
        background: rgba(255, 255, 255, 0.6);
        font-size: 0.12rem;
        color: #4f98ff;
        font-weight: bold;
        padding: 0.02rem 0;
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.16rem;
        &-item {
          &:nth-child(4) {
            margin-right: 0;
          }
          overflow: hidden;
          width: 0.55rem;
          height: 0.75rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff;
          border-radius: 0.06rem;
          margin-right: 0.13rem;
          margin-bottom: 0.1rem;
          &-day {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #4f98ff;
            padding: 0.02rem 0;
            font-weight: bold;
            font-size: 0.12rem;
            background: #cfe3ff;
          }
          &-warp {
            flex: 1;
            justify-content: center;
            display: flex;
            align-items: center;
          }
          &-icon {
            width: 0.26rem;
            height: 0.26rem;
          }
          &-num {
            font-weight: bold;
            color: #4f98ff;
            font-size: 0.12rem;
            margin-bottom: 0.02rem;
          }
        }
        &-last-item {
          width: 1.22rem;
          height: 0.75rem;
          background: #fff;
          border-radius: 0.06rem;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          &-day {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #4f98ff;
            padding: 0.02rem 0;
            font-weight: bold;
            font-size: 0.12rem;
            background: #cfe3ff;
          }
          &-warp {
            flex: 1;
            display: flex;
            justify-content: space-around;
            .warp-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              &-icon-cont {
                flex: 1;
                justify-content: center;
                display: flex;
                align-items: center;
              }
              &-icon {
                width: 0.26rem;
                height: 0.26rem;
              }
              &-num {
                font-weight: bold;
                color: #4f98ff;
                font-size: 0.12rem;
                margin-bottom: 0.02rem;
              }
            }
          }
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      width: 2.5rem;
      height: 0.44rem;
      border-radius: 0.22rem;
      background: #4f98ff;
      font-size: 0.15rem;
      font-weight: bold;
      margin-top: 0.14rem;
    }
  }
</style>
