<template>
  <div class="fortune-box">
    <img class="head" src="@/assets/images/fortune-wheel/head.png" />
    <img class="base" src="@/assets/images/fortune-wheel/base.png" />
    <!-- 转盘奖品区 -->
    <Lottery
      ref="lottery"
      :radius="148"
      :prizes="turntable.prizes"
      :text-y="40"
      :img-y="43"
      :text-size="12"
      :img-size="48"
      @lotteryEnd="showReward"
    />
    <!-- 转盘边框 -->
    <img class="turntable" src="@/assets/images/fortune-wheel/turntable.png" />
    <div class="turntable">
      <lottie :options="marqueeOptions" />
    </div>
    <!-- 转盘中间抽奖按钮 -->

    <div v-show="lotteryType == 'again'" class="lottery-btn" @click="handleLottery">
      <lottie :options="againOptions" />
    </div>
    <!-- 转盘中间抽奖按钮 -->
    <div v-show="lotteryType == 'double'" class="lottery-btn" @click="handleLottery">
      <lottie :options="doubleOptions" />
    </div>
    <!-- 转盘中间抽奖按钮 -->
    <div v-show="lotteryType !== 'again' && lotteryType !== 'double'" class="lottery-btn" @click="handleLottery">
      <lottie :options="goOptions" />
    </div>
    <!-- 转盘底部抽奖按钮 -->
    <button class="lottery-tip" :class="{ 'lottery-disabled': lotteryType === 'disabled' }" @click="handleLottery">
      <img
        class="video-36-icon"
        v-if="lotteryType === 'video'"
        src="@/assets/images/fortune-wheel/video-36.png"
        width="18"
      />
      {{ lotteryTip }}
    </button>
    <!-- 宝箱区 -->
    <!-- <template v-if="!chest.chestStatus">
      <img class="chest" src="@/assets/images/fortune-wheel/chest.png" @click="chestThrottle" />
      <div class="outer">
        <div class="inner" :style="{ width: width }"></div>
        <div class="ratio">{{ chest.chestProgress }} / {{ chest.chestSum }}</div>
      </div>
    </template>
    <div v-if="chest.chestStatus === 1" class="chest-active">
      <Tag text="可领取" />
      <img src="@/assets/images/fortune-wheel/chest-active.png" @click="chestThrottle" />
    </div> -->
    <!-- 宝箱点击提示文案 -->
    <!-- <Message v-if="show" :text="chestTip" />
    <img
      v-if="chest.chestStatus === 2"
      class="chest"
      src="@/assets/images/fortune-wheel/chest.png"
      @click="chestThrottle"
    /> -->
    <!-- 转盘抽奖恭喜获得弹窗 -->
    <!-- v-if="rewardShow" -->
    <Modal
      v-if="rewardShow"
      type="fortune-reward"
      :close-fn="handleCloseBtn"
      :handle-again-btn="handleAgainBtn"
      :btn-fn="handleLotteryVideoClick"
      :child-type="rewardType"
      :close-btn-pos="closeBtnPos"
      origin="lottery"
    ></Modal>
    <!-- 宝箱开启恭喜获得弹窗 -->
    <Modal
      v-if="chestRewardShow"
      type="fortune-reward"
      :close-fn="handleChestClose"
      :btn-fn="handleChestVideoClick"
      :child-type="chestRewardType"
      :close-btn-pos="closeBtnPos"
      origin="chest"
    ></Modal>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { trackClick } from '@/utils/track';
  import { countDownFormat2, getUrlParams } from '@/utils/common';
  import sdk from '@/utils/sdk';
  import lottie from 'vue-lottie';
  import Marquee from '@/assets/lottie/marquee.json';
  import go from '@/assets/lottie/go.json';
  import again from '@/assets/lottie/again.json';
  import double from '@/assets/lottie/double.json';
  import Lottery from '@/components/Lottery/index';

  export default {
    components: {
      lottie,
      Lottery,
    },
    data() {
      return {
        show: false, // 宝箱提示文案显示控制
        rewardShow: false, // 奖励弹窗显示控制
        chestRewardShow: false,
        rewardType: 2, // 抽奖奖励模块布局；1：不翻倍布局；2：翻倍布局
        chestRewardType: 2, // 宝箱奖励模块布局；3：不翻倍布局；2：翻倍布局
        lotteryStatus: false, // 抽奖状态，true 为抽奖中， false 为未抽奖
        startCountDown: false, // 抽奖倒计时
        /* // 抽奖类型；
        // 'free': 免费抽奖
        // 'video': 视频抽奖
        // 'double': 翻倍抽奖
        // 'again': 再来一次抽奖
        // 'disabled': 不能抽奖
           'countdown': 倒计时中*/
        lotteryType: 'free',
        marqueeOptions: {
          animationData: Marquee,
        },
        goOptions: {
          animationData: go,
        },
        againOptions: {
          animationData: again,
        },
        doubleOptions: {
          animationData: double,
        },
      };
    },
    created() {
      console.log('wheelCreated');
      sdk.register(sdk.listenMethod.ON_REWARD_VIDEO_PLAYEND, this.videoEndCallback);
    },
    beforeDestroy() {
      console.log('wheelDestroy');
    },
    methods: {
      ...mapMutations(['updateTurnTable', 'updateTimeLimit', 'setHasVirtualItem']),

      /**
       * @description: 抽奖按钮点击事件处理方法
       */

      handleLottery() {
        const { accessNum, useNum, limitNum, videoCan, timeLimit, doubleCard, againCard } = this.turntable;
        if (this.lotteryStatus) return;

        if (useNum === limitNum || useNum > limitNum) {
          // 抽奖次数已达上限
          this.$toast({
            message: '今日抽奖次数已达上限！',
            duration: 3000,
          });
          return;
        }

        console.log('转盘冷却时间, timeLimit', timeLimit);

        if (timeLimit) {
          this.$toast({
            message: '转盘正在冷却中，稍后再来吧',
            duration: 3000,
          });
          return;
        }

        if (accessNum) {
          // 有抽奖次数，直接抽奖
          this.lotteryStatus = true;
          this.lotteryThrottle();

          // 抽奖埋点
          this.trackClick('免费抽奖');

          if (againCard) {
            this.trackClick('再来一次_抽奖');
            return;
          }

          if (doubleCard) {
            this.trackClick('翻倍卡_抽奖');
          }
          return;
        }

        if (useNum < limitNum && videoCan) {
          // 没有可用抽奖次数，但是抽奖次数未达今日上限，则观看视频，获取抽奖次数
          this.$store.commit('setVideoPos', 'fwLotteryVideo');
          this.playVideo(1);

          // 抽奖埋点
          this.trackClick('观看视频抽奖');

          if (doubleCard) {
            this.trackClick('翻倍卡_抽奖');
          }
          return;
        }

        if (!videoCan) {
          // 没有免费抽奖次数，且可观看视频次数已达上限
          this.$toast({
            message: '今日抽奖次数已达上限！',
            duration: 3000,
          });
        }
      },

      /**
       * @description: 激励视频播放完毕回调方法
       */

      videoEndCallback() {
        const { videoPos } = this.$store.getters;

        console.log(`videoPos => ${videoPos}`);
        if ('fwLotteryVideo' === videoPos) {
          console.log(`fwLotteryVideo`);
          // 刷新可用抽奖次数
          this.$post(this.$api.INIT_TURNTABLE_API, { id: 5 }).then((res) => {
            this.$store.commit('setTurnTable', res);

            if (this.turntable.accessNum) {
              this.handleLottery();
              this.trackClick('免费抽奖观看视频成功');
            } else {
              // 中间状态，导致原因可能为：视频回调延时，回调失败等。
              // 加入埋点代码
              this.trackClick('激励视频播放失败');
              console.log('视频播放失败！');
            }
          });
        } else if ('fwLotteryRewardVideo' === videoPos) {
          console.log(`fwLotteryRewardVideo`);
          // 展示抽奖结算弹窗
          let { reward } = this.$store.state.fortune_wheel;
          Object.assign(reward, { reward_num: reward.reward_num * 2 });
          this.rewardType = 1;
          this.rewardShow = true;
          this.trackClick('翻倍领取观看视频成功');
        } else if ('fwChestRewardVideo' === videoPos) {
          console.log(`fwChestRewardVideo`);
          // 展示宝箱结算弹窗
          let { chestReward } = this.$store.state.fortune_wheel;
          console.log(`chestRewardBefore => ${JSON.stringify(chestReward)}`);

          chestReward = Object.assign(chestReward, { reward_num: chestReward.reward_num * 2 });

          console.log(`chestReward => ${JSON.stringify(chestReward)}`);
          this.chestRewardType = 3;
          this.chestRewardShow = true;
          this.trackClick('翻倍领取观看视频成功');
        }
      },

      /**
       * @description: 宝箱点击事件回调函数
       */

      handleChest() {
        if (this.turntable.chestStatus === 1) {
          // 请求宝箱接口, 打开宝箱
          this.$post(this.$api.OPEN_FORTUNE_CHEST_API, { id: 5 }).then((res) => {
            this.$store.commit('setChestReward', res);
            this.showReward('chest');
          });
          this.trackClick('宝箱领取');
        } else {
          this.show = true;

          setTimeout(() => {
            this.show = false;
          }, 5000);
        }
      },

      /**
       * @description: 抽奖方法
       */

      startLottery() {
        this.$post(this.$api.GET_TURNTABLE_REWARD_API, { id: 5 }).then((res) => {
          this.$store.commit('setReward', res);
          this.$refs.lottery.handleLottery(this.reward.id);
        });
      },

      /**
       * @description: 抽奖恭喜获得模块翻倍视频点击事件回调
       */

      handleLotteryVideoClick() {
        this.$store.commit('setVideoPos', 'fwLotteryRewardVideo');
        this.trackClick('翻倍领取观看视频');
        this.playVideo(2);
      },

      /**
       * @description: 宝箱恭喜获得模块翻倍视频点击事件回调
       */

      handleChestVideoClick() {
        this.$store.commit('setVideoPos', 'fwChestRewardVideo');
        this.trackClick('翻倍领取观看视频');
        this.playVideo(2);
      },

      /**
       * @description: 抽奖恭喜获得模块翻倍视频点击事件回调
       */

      handleAgainBtn() {
        this.handleCloseBtn();
        this.handleLottery();

        // 结算弹窗 '再抽一次' 按钮点击埋点
        const { doubleCard, againCard } = this.reward;

        if (againCard) {
          this.trackClick('再来一次_立即使用');
          return;
        }

        if (doubleCard) {
          this.trackClick('翻倍卡_立即使用');
          return;
        }

        this.trackClick('再抽一次');
      },

      /**
       * @description: 抽奖恭喜获得模块关闭点击事件回调
       */

      handleCloseBtn() {
        const reward = this.$store.getters.reward;
        this.rewardShow = false;
        if (reward && reward.reward_type === 'sleep_game_prop') {
          this.trackClick('道具卡_立即使用', reward.reward_text);
        }
        // 更新抽奖状态
        this.updateTurnTable(this.reward);
        // 更新宝箱状态
        this.updateChest();
      },

      /**
       * @description: 宝箱恭喜获得模块关闭点击事件回调
       */

      handleChestClose() {
        this.chestRewardType = 2;
        this.chestRewardShow = false;
        // 更新宝箱状态
        this.updateChest();
      },

      /**
       * @description: 播放激励视频
       * @param { Number } source 播放位置 1：抽奖播放激励视频 2：转盘获奖播放激励视频/宝箱获奖播放激励视频
       */

      playVideo(source) {
        // 验证播放视频逻辑
        let video,
          app_position = '';
        const activityPosition = getUrlParams('position') || '';
        switch (source) {
          case 1:
            video = this.video;
            app_position = `${activityPosition};睡觉赚钱;;转盘获取使用次数;`;
            // 通知后端用户开始视频抽奖
            this.$post(this.$api.MARK_PULL_VIDEO_API, { id: 5 });
            break;
          case 2:
            video = this.rewardVideo;
            app_position = `${activityPosition};睡觉赚钱;;转盘翻倍领取;`;
            break;
        }
        sdk.register(sdk.listenMethod.ON_REWARD_VIDEO_PLAYEND, this.videoEndCallback);
        const { task_id, task_type, ad_group_id } = video;

        const mediationParams = {
          task_type,
          tid: task_id,
          uid: this.$store.getters.client.uid,
          app_position,
        };

        console.log(`mediationParams`, mediationParams);

        sdk.appCall(sdk.callMethod.LOAD_MEDIATION_AD, ad_group_id, JSON.stringify(mediationParams));
      },

      /**
       * @description: 抽奖结束展示恭喜获得弹窗和改变宝箱状态
       */

      showReward(type) {
        if (this.reward.reward_type === 'sleep_game_prop') {
          this.trackClick('道具卡_曝光', this.reward.reward_text);
          this.setHasVirtualItem(true);
        }
        // 接入恭喜获得模块; 恭喜模块暂时凉了，先用自己写的获奖模块
        if (this.reward.double_status) {
          this.rewardType = 2;
        } else {
          this.rewardType = 1;
        }

        if ('chest' === type) {
          this.chestRewardShow = true;
        } else {
          this.lotteryStatus = false;
          this.rewardShow = true;

          if (this.rewardType === 1) {
            // 结算弹窗 '再来一次' 按钮曝光埋点
            const { doubleCard, againCard } = this.reward;

            if (againCard) {
              this.trackClick('再来一次_曝光');
              return;
            }

            if (doubleCard) {
              this.trackClick('翻倍卡_曝光');
            }
          }
        }
      },

      /**
       * @description: 更新宝箱状态
       */

      updateChest() {
        const { chestStatus, chestProgress, chestSum } = this.turntable;
        this.$store.commit('setChest', { chestStatus, chestProgress, chestSum });
      },

      /**
       * @description: 节流函数
       * @param { Function} fn , { Array } params, { Number } wait
       */

      throttle(fn, wait, params) {
        let pre = null;
        const context = this;

        return function () {
          let now = Date.now();

          if (!pre || now - pre > wait) {
            fn.apply(context, params);
            pre = Date.now();
          }
        };
      },

      /**
       * @description: 埋点方法
       * @param { String} element_name 点击元素名 , { Object } remarks
       */

      trackClick(element_name, remarks) {
        trackClick({
          page_title: '睡觉转盘',
          element_name,
          remarks,
        });
      },

      /**
       * @description: 设置type
       * @param {string} type
       * @return {void}
       */
      setType(type) {
        this.lotteryType = type;
      },

      setStartCountDown(flag) {
        this.startCountDown = flag;
      },
    },
    computed: {
      ...mapGetters({
        reward: 'reward',
        turntable: 'turntable',
        video: 'video',
        progress: 'progress',
        rewardVideo: 'rewardVideo',
        chest: 'chest',
      }),
      width() {
        return `${this.progress * 0.575}rem`;
      },
      lotteryTip() {
        const { accessNum, useNum, limitNum, doubleCard, againCard, videoCan } = this.turntable;

        let { timeLimit } = this.turntable;

        // if 判断语句顺序不可随意改变
        if (useNum === limitNum || useNum > limitNum) {
          this.setType('disabled');
          return '明日再来';
        }

        if (timeLimit) {
          this.setType('countdown');
          this.setStartCountDown(true);
          return countDownFormat2(timeLimit);
        }

        if (accessNum) {
          if (againCard) {
            this.setType('again');
            this.trackClick('再来一次_抽奖_曝光');
            return '再来一次卡：免费抽奖';
          }

          if (doubleCard) {
            this.setType('double');
            this.trackClick('翻倍卡_抽奖_曝光');
            return '本次抽奖奖励翻倍';
          }
          this.setType('free');
          return `免费抽奖`;
        }

        if (useNum < limitNum && videoCan) {
          if (againCard) {
            this.setType('again');
            this.trackClick('再来一次_抽奖_曝光');
            return '再来一次卡：免费抽奖';
          }

          if (doubleCard) {
            this.setType('double');
            this.trackClick('翻倍卡_抽奖_曝光');
            return '本次抽奖奖励翻倍';
          }
          this.setType('video');
          return `免费抽奖`;
        }

        if (!videoCan) {
          this.setType('disabled');
          return '明日再来';
        }
        return '';
      },
      /* isShowVideoIcon() {
        const { accessNum, useNum, limitNum, timeLimit } = this.turntable;

        if (!accessNum && (useNum < limitNum) && !timeLimit) {
          return true;
        }

        return false;
      },
      isBtnDisabled() {
        const { useNum, limitNum, videoCan } = this.turntable;

        if (!videoCan || useNum > limitNum || useNum === limitNum) {
          return true;
        }

        return false;
      }, */
      chestTip() {
        if (this.chest.chestStatus === 0) {
          return `距离宝箱开启还差${this.chest.chestSum - this.chest.chestProgress}次`;
        } else if (this.chest.chestStatus === 2) {
          return `今日宝箱开启次数已达上限`;
        } else {
          return '';
        }
      },
      closeBtnPos() {
        if (this.rewardType === 1) {
          return { top: 1.5, right: 0.215 };
        } else if (this.rewardType === 2) {
          return { top: 1.5, right: 0.215 };
        }
        return { top: 0, right: 0 };
      },
      lotteryThrottle() {
        return this.throttle(this.startLottery, 5000);
      },

      chestThrottle() {
        return this.throttle(this.handleChest, 5000);
      },
    },
    watch: {
      startCountDown(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal) {
          let timer = setInterval(() => {
            let { timeLimit } = this.turntable;

            if (timeLimit === 0 || timeLimit < 0) {
              clearInterval(timer);
              timer = null;
              this.startCountDown = false;
              return;
            }

            this.updateTimeLimit({ timeLimit: timeLimit - 1 });
          }, 1000);
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .fortune-box {
    width: 3rem;
    height: 5rem;
  }
  .turntable,
  .lottery-btn,
  .lottery-tip,
  .head,
  .base,
  .chest,
  .chest-active,
  .outer,
  .ratio,
  .global-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .turntable {
    width: 3.75rem;
    height: 3.75rem;
  }
  .lottery-btn {
    width: 1.04rem;
    height: 1.04rem;
  }
  .base {
    width: 3rem;
    transform: translate(-50%, 0.005rem);
  }
  .head {
    width: 3.26rem;
    transform: translate(-50%, -2.6rem);
  }
  .chest {
    transform: translate(0.73rem, 1.05rem);
    width: 0.685rem;
  }
  .chest-active {
    transform: translate(0.4rem, 0.83rem);
    img {
      width: 1.01rem;
    }
    .global-tag {
      position: absolute;
      right: 0.2rem;
      top: 0.17rem;
      height: 0.24rem;
      padding: 0 0.07rem;
      border: none;
      border-radius: 0.24rem;
      background: #ff184d;
      font-size: 0.16rem;
      line-height: 0.22rem;
    }
  }
  .outer {
    transform: translate(0.78rem, 1.65rem);
    width: 0.575rem;
    height: 0.04rem;
    background: #c6c6c6;
    border: 0.01rem solid #a721bf;
    border-right: 0.02rem solid #a721bf;
    border-left: 0.02rem solid #a721bf;
    border-radius: 0.07rem;
  }
  .inner {
    height: 100%;
    background: #7321bf;
    border-radius: 0 0.07rem 0.07rem 0;
  }
  .ratio {
    transform: translate(0.13rem, -50%);
    width: 0.2rem;
    font-size: 0.06rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  .lottery-tip {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0.08rem 0.18rem;
    border-radius: 0.21rem;
    box-shadow: 0 4px 0 #8200e8;
    background-color: #be41ff;
    text-align: center;
    color: #fff;
    transform: translate(-50%, 1.805rem);
    font-size: 0.22rem;
    font-weight: 600;
    line-height: 0.2rem;
    text-shadow: 0 2px 4px rgba(163, 0, 193, 0.93);
  }
  .video-36-icon {
    margin-right: 0.06rem;
  }
  .global-message {
    box-sizing: border-box;
    transform: translate(0.63rem, 0.85rem);
  }
  .lottery-disabled {
    background-color: #c5c5c5;
    box-shadow: 0 4px 0 #979797;
    text-shadow: 0px 2px 4px rgba(80, 80, 80, 0.93);
  }
</style>
