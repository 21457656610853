<template>
  <div class="modal-warpper">
    <div class="modal-container">
      <div class="modal-content">
        <!-- 顶部开始 -->
        <div class="modal-content-top">
          <p class="modal-content-top-title">活动规则</p>
          <img @click="handleClose" src="../../../assets/images/common-component/close.png" alt="关闭" />
        </div>
        <!-- 顶部结束 -->
        <!-- 规则内容开始 -->
        <div class="modal-content-detail">
          <p>
            1、睡觉就能赚金币，每晚{{ this.taskStartTime }}至次日{{
              this.taskEndTime
            }}，您可以点击“我要睡了”开启睡眠模式，次日{{ this.settleStartTime }}至{{
              this.settleEndTime
            }}记得回来这里点击“我睡醒了，领金币”就能获得金币奖励哦
          </p>
          <p>2、在睡觉打卡的时间外，您可以在快音听音乐、玩游戏、刷短视频，海量金币等您领取哦；</p>
          <p>3、每晚的收益跟您打卡的时间有关，早睡早起按时打卡对身体健康有益哦；</p>
          <p>4、早上领取金币奖励后还可以看视频或者分享给好友获得额外的金币奖励哦；</p>
          <p>
            5、您有任何问题咨询，欢迎点击加入官方讨论QQ群：<a
              @click="copy(728704491)"
              style="color: #007aff; text-decoration: underline"
              >728704491</a
            >；
          </p>
          <p>6、如果您有其他问题，请点击加入咨询QQ群。</p>
        </div>
        <!-- 规则内容结束 -->
        <!-- 关闭开始 -->
        <div @click="handleClose" class="modal-content-ok_button">
          <div class="ok-button">好的</div>
        </div>
        <!-- 关闭结束 -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      handleClose: {
        type: Function,
      },
      taskStartTime: {
        required: true,
      },
      taskEndTime: {
        required: true,
      },
      settleStartTime: {
        required: true,
      },
      settleEndTime: {
        required: true,
      },
      copy: {
        type: Function,
        required: true,
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal-container {
    width: 2.6rem;
    height: 2.85rem;
    background: #ffffff;
    box-shadow: 0px 1rem 0.3rem 0px rgba(0, 0, 0, 0.28);
    border-radius: 0.12rem;
  }
  .modal-content {
    box-sizing: border-box;
    padding: 0.15rem;
    width: 100%;
    height: 100%;
  }
  .modal-content-top {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 0.1rem;
  }
  .modal-content-top img {
    width: 0.16rem;
    height: 0.16rem;
  }
  .modal-content-top-title {
    height: 0.16rem;
    font-size: 0.16rem;
    font-weight: bold;
    color: rgba(34, 34, 34, 1);
    line-height: 0.16rem;
    letter-spacing: 0.005rem;
  }
  .modal-content-detail {
    box-sizing: border-box;
    width: 100%;
    height: 1.77rem;
    text-align: left;
    padding: 0.1rem;
    border-radius: 0.06rem;
    border: 0.005rem solid rgba(151, 151, 151, 0.75);
    color: rgba(0, 0, 0, 0.3);
    letter-spacing: 0.005rem;
    overflow-y: scroll;
  }
  .modal-content-ok_button {
    margin-top: 0.15rem;
    width: 100%;
  }
  .ok-button {
    width: 2.03rem;
    height: 0.37rem;
    background: rgba(240, 61, 91, 1);
    border-radius: 0.22rem;
    text-align: center;
    line-height: 0.37rem;
    font-size: 0.14rem;
    color: #fff;
    font-weight: bold;
    margin: 0 auto;
  }
</style>
