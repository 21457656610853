export default {
  // 新睡觉页面信息
  GET_SLEEP_PAGE_INFO_API: '/SleepGame/GetTaskInfoV2',
  // 新睡觉转盘信息
  GET_SLEEP_TURNTABLE_INFO_API: '/Turntable/GetTurntableInfo',
  // 获取转盘奖励
  GET_SLEEP_TURNTABLE_REWARD_API: '/Turntable/GetTurntableReward',
  // 新睡觉主动弹窗
  GET_SLEEP_WINDOW: '/SleepGame/GetSleepWindow',
  // 获取签到信息
  GET_SLEEP_SIGN_INFO: '/SignIn/GetSignInfo',
  // 手动签到
  SIGN_IN_SLEEP: '/SignIn/SignIn',
  // 瓜分开始
  CARVE_UP_START: '/SleepGame/CarveUpStart',
  // 领取瓜分奖励
  GET_CARVE_UP_REWARD: '/SleepGame/CarveUpReward',
  // 主动领取气泡
  GET_BUBBLE_REWARD: '/SleepGame/RewardOfBubble',
  // 非瓜分起床
  SETTLE_SLEEP_REWARD_V2: '/sleep_game/SettleRewardV2',
  // 分享根据类型发放金币
  GET_SLEEP_SHARE_REWARD: '/SleepGame/shareReward',
  // 睡觉赚钱分享拿回瓜分本金
  GET_SHARE_TAKE_BACK_CARVE: '/SleepGame/ShareTakeBackCarve',
  // 睡觉赚钱 - 领取时段奖励
  GET_SLEEP_TIME_REWARD: '/SleepGame/GetTimeReward',
  // 睡觉赚钱-记录时段奖励点击
  MARK_SLEEP_TIME_REWARD_CLICK: '/SleepGame/MarkTimeRewardClick',
};
