export default [
  {
    path: '/game/eating',
    name: 'Eating',
    component: () => import('@/views/Eat/V1'),
  },
  {
    path: '/game/new-eating',
    name: 'NewEating',
    component: () => import('@/views/Eat/V2'),
  },
];
