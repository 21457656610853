import { Toast } from 'vant';
Toast.allowMultiple();

let loadingToast = null;
const show = (message, duration) => {
  loadingToast = Toast.loading({
    message: message || '加载中...',
    forbidClick: true,
    duration: duration || 1500,
  });
};

const stop = () => {
  if (loadingToast) {
    loadingToast.clear();
  }
};

const loading = {
  show,
  stop,
};

export default loading;
