import Vue from 'vue';
import '@/assets/style/base.less';
import '@/assets/style/common.less';
import '@/utils/layout';
import '@/assets/style/modal.less';
import * as Http from '@/http';
import CommonComponents from '@/components';
import { showModal, closeModal } from '@/utils/modal/index';
import VConsole from 'vconsole';
import { isPromiseLike } from 'is-promise-like';

// 错误上报
// import errorNotify from '@/utils/error-handler/index';
// Vue.use(() => errorNotify.init(Vue));

import { Swipe, SwipeItem } from 'vant';
import { Icon } from 'vant';
import { List } from 'vant';
import { PullRefresh } from 'vant';
import { Progress } from 'vant';
import { Switch } from 'vant';
import { Loading } from 'vant';
import { CountDown } from 'vant';
import { Toast } from 'vant';
import { Circle } from 'vant';
import { Popup } from 'vant';
import { Cascader } from 'vant';

Vue.use(Toast);
Vue.use(Loading);
Vue.use(Switch);
Vue.use(Progress);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Icon);
Vue.use(Swipe).use(SwipeItem);
Vue.use(CountDown);
Vue.use(Circle);
Vue.use(Popup);
Vue.use(Cascader);

if (process.env.VUE_APP_BUILD_ENV !== 'production') {
  new VConsole();
}
Toast.setDefaultOptions({ duration: 3000 });
Vue.use(CommonComponents);
Vue.prototype.$http = Http;
Vue.prototype.$post = Http.TaskPost;
Vue.prototype.$api = Http.Api;
Vue.prototype.$showModal = showModal;
Vue.prototype.$closeModal = closeModal;
Vue.prototype.$toast = Toast;
Vue.prototype.$asyncEmit = function (event) {
  const vm = this;
  let cbs = vm._events[event];
  if (cbs) {
    cbs = cbs.length > 1 ? [...cbs] : cbs;
    const args = [...arguments].slice(1);
    return cbs.map((cb) => {
      const res = args ? cb.apply(vm, args) : cb.call(vm);
      if (isPromiseLike(res)) {
        return res;
      } else {
        return new Promise((resolve) => {
          resolve(res);
        });
      }
    });
  }
  return vm;
};
