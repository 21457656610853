import { GamePost, Api, TaskPost } from '@/http/index';
import { trackClick } from '@/utils/track';
export default {
  namespaced: true,
  state: () => ({
    stageInfo: {},
    stageMap: new Map([
      [
        'breakfast-forbid',
        {
          id: 1,
          title: require('@/assets/images/eatingV2/breakfast_title--forbid.png'),
          background: require('@/assets/images/eatingV2/breakfast_forbid.png'),
        },
      ],
      [
        'breakfast-available',
        {
          id: 1,
          title: require('@/assets/images/eating/breakfast_title--available.png'),
          background: require('@/assets/images/eatingV2/breakfast_available.png'),
          nextStage: 'breakfast-extra',
        },
      ],
      [
        'breakfast-extra',
        {
          id: 1,
          title: require('@/assets/images/eatingV2/breakfast_title--extra.png'),
          background: require('@/assets/images/eatingV2/breakfast_available.png'),
          nextStage: 'breakfast-success',
        },
      ],
      [
        'breakfast-success',
        {
          id: 2,
          title: require('@/assets/images/eatingV2/lunch_title--forbid.png'),
          background: require('@/assets/images/eatingV2/lunch_forbid.png'),
          titleTop: '0',
        },
      ],
      [
        'lunch-forbid',
        {
          id: 2,
          title: require('@/assets/images/eatingV2/lunch_title--forbid.png'),
          background: require('@/assets/images/eatingV2/lunch_forbid.png'),
        },
      ],
      [
        'lunch-available',
        {
          id: 2,
          title: require('@/assets/images/eating/lunch_title--available.png'),
          background: require('@/assets/images/eatingV2/lunch_available.png'),
          nextStage: 'lunch-extra',
        },
      ],
      [
        'lunch-extra',
        {
          id: 2,
          title: require('@/assets/images/eatingV2/lunch_title--extra.png'),
          background: require('@/assets/images/eatingV2/lunch_available.png'),
          nextStage: 'lunch-success',
        },
      ],
      [
        'lunch-success',
        {
          id: 3,
          title: require('@/assets/images/eatingV2/tea_title--forbid.png'),
          background: require('@/assets/images/eatingV2/tea_forbid.png'),
          titleTop: '0',
        },
      ],
      [
        'tea-forbid',
        {
          id: 3,
          title: require('@/assets/images/eatingV2/tea_title--forbid.png'),
          background: require('@/assets/images/eatingV2/tea_forbid.png'),
        },
      ],
      [
        'tea-available',
        {
          id: 3,
          title: require('@/assets/images/eatingV2/tea_title--available.png'),
          background: require('@/assets/images/eatingV2/tea_available.png'),
          nextStage: 'tea-extra',
        },
      ],
      [
        'tea-extra',
        {
          id: 3,
          title: require('@/assets/images/eatingV2/tea_title--extra.png'),
          background: require('@/assets/images/eatingV2/tea_available.png'),
          nextStage: 'tea-success',
        },
      ],
      [
        'tea-success',
        {
          id: 4,
          title: require('@/assets/images/eatingV2/dinner_title--forbid.png'),
          background: require('@/assets/images/eatingV2/dinner_forbid.png'),
          titleTop: '0',
        },
      ],
      [
        'dinner-forbid',
        {
          id: 4,
          title: require('@/assets/images/eatingV2/dinner_title--forbid.png'),
          background: require('@/assets/images/eatingV2/dinner_forbid.png'),
        },
      ],
      [
        'dinner-available',
        {
          id: 4,
          title: require('@/assets/images/eating/dinner_title--available.png'),
          background: require('@/assets/images/eatingV2/dinner_available.png'),
          nextStage: 'dinner-extra',
        },
      ],
      [
        'dinner-extra',
        {
          id: 4,
          title: require('@/assets/images/eatingV2/dinner_title--extra.png'),
          background: require('@/assets/images/eatingV2/dinner_available.png'),
          nextStage: 'dinner-success',
        },
      ],
      [
        'dinner-success',
        {
          id: 5,
          title: require('@/assets/images/eatingV2/nightingale_title--forbid.png'),
          background: require('@/assets/images/eatingV2/nightingale_forbid.png'),
          titleTop: '0',
        },
      ],
      [
        'nightingale-forbid',
        {
          id: 5,
          title: require('@/assets/images/eatingV2/nightingale_title--forbid.png'),
          background: require('@/assets/images/eatingV2/nightingale_forbid.png'),
        },
      ],
      [
        'nightingale-available',
        {
          id: 5,
          title: require('@/assets/images/eating/nightingale_title--available.png'),
          background: require('@/assets/images/eatingV2/nightingale_available.png'),
          nextStage: 'nightingale-extra',
        },
      ],
      [
        'nightingale-extra',
        {
          id: 5,
          title: require('@/assets/images/eatingV2/nightingale_title--extra.png'),
          background: require('@/assets/images/eatingV2/nightingale_available.png'),
          nextStage: 'nightingale-success',
        },
      ],
      [
        'nightingale-success',
        {
          id: 1,
          title: require('@/assets/images/eatingV2/breakfast_title--forbid.png'),
          background: require('@/assets/images/eatingV2/breakfast_forbid.png'),
          titleTop: '0',
        },
      ],
    ]),
    taskList: [],
    pageModalInfo: {},
    videoCbInfo: '',
    doubleGetTaskId: '',
    businessNameMap: null,
    currentTask: null,
    serverTime: Date.now(),
    doubleVideoGroup: null,
    advancedTime: 0,
    diningLeadVideoGroup: {},
    diningFillVideoGroup: {},
    isNeedLeadDining: false,
  }),
  mutations: {
    setStageInfo(state, stage) {
      state.stageInfo = state.stageMap.get(stage);
    },
    setBusinessNameMap(state, data) {
      state.businessNameMap = data;
    },
    setTaskList(state, data) {
      state.taskList = data;
    },
    setCurrentTask(state, current_task) {
      state.currentTask = current_task;
    },
    setServerTime(state, server_time) {
      state.serverTime = server_time;
    },
    setPageModalInfo(state, data) {
      state.pageModalInfo = data;
    },
    setDoubleVideoGroup(state, data) {
      state.doubleVideoGroup = data;
    },
    setVideoCbInfo(state, data) {
      state.videoCbInfo = data;
    },
    setDoubleGetTaskId(state, data) {
      state.doubleGetTaskId = data;
    },
    setAdvancedTime(state, data) {
      state.advancedTime = data;
    },
    setDiningLeadVideoGroup(state, data) {
      state.diningLeadVideoGroup = data;
    },
    setDiningFillVideoGroup(state, data) {
      state.diningFillVideoGroup = data;
    },
    setIsNeedLeadDining(state, data) {
      state.isNeedLeadDining = data;
    },
  },
  actions: {
    // 从服务端获取页面信息
    async getEatingPageInfo({ commit }, playLoad) {
      try {
        const data = await GamePost(Api.GET_EATING_PAGE_INFO_V3, {});

        const {
          server_timestamp,
          business_name,
          task_list,
          current_task,
          advanced_time,
          business_name_over,
          dining_lead_video_group,
          dining_fill_video_group,
        } = data;

        const { id, status } = current_task;
        let stage;

        if (id === 1) {
          if (status === 0) {
            stage = 'breakfast-forbid';
          } else if (status === 6) {
            stage = 'breakfast-extra';
          } else {
            stage = 'breakfast-available';
          }
        } else if (id === 2) {
          if (status === 0) {
            stage = 'lunch-forbid';
          } else if (status === 6) {
            stage = 'lunch-extra';
          } else {
            stage = 'lunch-available';
          }
        } else if (id === 3) {
          if (status === 0) {
            stage = 'tea-forbid';
          } else if (status === 6) {
            stage = 'tea-extra';
          } else {
            stage = 'tea-available';
          }
        } else if (id === 4) {
          if (status === 0) {
            stage = 'dinner-forbid';
          } else if (status === 6) {
            stage = 'dinner-extra';
          } else {
            stage = 'dinner-available';
          }
        } else if (id === 5) {
          if (status === 0) {
            stage = 'nightingale-forbid';
          } else if (status === 6) {
            stage = 'nightingale-extra';
          } else {
            stage = 'nightingale-available';
          }
        }
        let isNeedLeadDining = false;
        let nowTime = Math.floor(Date.now() / 1000);
        if (current_task.start_time - nowTime <= advanced_time && current_task.status === 0) {
          isNeedLeadDining = true;
        }
        if (playLoad === 'init') {
          trackClick({ page_title: '吃饭补贴', element_name: '页面曝光', remarks: current_task.title });
        }

        commit('setStageInfo', stage);
        commit('setServerTime', server_timestamp);
        commit('setCurrentTask', current_task);
        commit('setBusinessNameMap', { business_name, business_name_over });
        commit('setTaskList', task_list);
        commit('setAdvancedTime', advanced_time);
        commit('setDiningLeadVideoGroup', dining_lead_video_group);
        commit('setDiningFillVideoGroup', dining_fill_video_group);
        commit('setIsNeedLeadDining', isNeedLeadDining);
      } catch (error) {}
    },
    async getSignListInfo({ commit }) {
      let data = await TaskPost(Api.GET_SIGN_LIST, { page: 'dining' }).catch((e) => {});
      commit('setPageModalInfo', data);
    },
  },
  getters: {
    StageInfo(state) {
      return state.stageInfo;
    },
    TaskList(state) {
      return state.taskList;
    },
    BusinessNameMap(state) {
      return state.businessNameMap;
    },
    CurrentTask(state) {
      return state.currentTask;
    },
    ServerTime(state) {
      return state.serverTime;
    },
    pageModalInfo(state) {
      return state.pageModalInfo;
    },
    doubleVideoGroup(state) {
      return state.doubleVideoGroup;
    },
    videoCbInfo(state) {
      return state.videoCbInfo;
    },
    doubleGetTaskId(state) {
      return state.doubleGetTaskId;
    },
    advancedTime(state) {
      return state.advancedTime;
    },
    diningLeadVideoGroup(state) {
      return state.diningLeadVideoGroup;
    },
    diningFillVideoGroup(state) {
      return state.diningFillVideoGroup;
    },
    isNeedLeadDining(state) {
      return state.isNeedLeadDining;
    },
  },
};
