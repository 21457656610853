export default {
  functional: true,
  name: 'CoinTag',
  props: {
    text: {
      type: String,
      require: true,
    },
  },
  render(h, context) {
    return h(
      'div',
      {
        class: 'g-el-tag g-coin-tag',
      },
      [
        h('img', {
          class: 'g-coin-tag__icon',
          domProps: {
            src: require('@/assets/images/eating/icon_coin.png'),
          },
        }),
        h(
          'span',
          {
            class: 'g-coin-tag__text',
          },
          context.props.text
        ),
      ]
    );
  },
};
