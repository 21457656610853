export default {
  GET_EATING_PAGE_INFO: '/dining/get_page_info',
  GET_EATING_REWARD: '/dining/receive_reward',
  GET_EATING_REWARD_V3: '/dining/receive_reward_v3',
  RECEIVE_EXTRA_REWARD: '/dining/receive_extra_reward',

  SET_DOUBLE_REWARD: '/dining/mark_double_reward',
  GET_SIGN_LIST: '/Task/get_sign_in_list',
  GET_EATING_PAGE_INFO_V2: '/dining/get_page_info_V2',
  GET_EATING_PAGE_INFO_V3: '/dining/get_page_info_V3',
};
