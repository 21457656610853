<template>
  <div class="modal_wrapper">
    <div class="modal_box">
      <img @click="handleDialogClose" class="close_btn" src="@/assets/images/newSleep/close.png" alt="关闭" />

      <img
        v-if="modalType === 'carve_fail' || modalType === 'carve_fail_share'"
        class="leaf"
        src="@/assets/images/newSleep/carve_fail.png"
        alt="落叶装饰"
      />
      <img v-else class="top_img" src="@/assets/images/newSleep/coin_top.png" alt="金币装饰" />

      <div v-if="modalType === 'carve_success'" class="carve_success_title">
        <p>恭喜你，瓜分成功</p>
      </div>
      <p
        v-if="modalType !== 'carve_fail' && modalType !== 'carve_fail_share'"
        class="title"
        v-html="modalInfo.title"
      ></p>
      <p
        v-if="modalType === 'carve_fail' || modalType === 'carve_fail_share'"
        class="fail_title"
        v-html="modalInfo.title"
      ></p>

      <p v-if="modalType !== 'carve_fail' && modalType !== 'carve_fail_share'" class="reward">
        <span class="reward_number">{{ reward_coins }}</span>
        <span class="reward_unit">金币</span>
      </p>

      <div @click="handleClickButton" class="sign_button">
        <span>{{ modalInfo.buttonText }}</span>
      </div>

      <p
        v-if="modalType === 'carve_fail' || modalType === 'carve_fail_share'"
        @click="handleDialogClose"
        class="no_need"
      >
        我不需要
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // normal 普通早起打卡 carve_fail 瓜分失败 carve_success 瓜分成功
      modalType: {
        type: String,
        default: 'normal',
      },
      reward_coins: {
        type: Number,
        default: 0,
      },
      adRewardAmount: {
        type: Number,
        default: 0,
      },
      carveReturnAmount: {
        type: Number,
        default: 100,
      },
      handleClickButton: {
        type: Function,
      },
      handleDialogClose: {
        type: Function,
      },
      shareText: {
        type: String,
        default: '',
      },
    },
    computed: {
      modalInfo() {
        const type = this.modalType;
        return type === 'normal'
          ? {
              buttonText: `看视频领取${this.adRewardAmount}金币`,
              name: '早起',
              title: `本次早起打卡获得`,
            }
          : type === 'carve_success'
          ? {
              buttonText: `点击领取早起打卡金币`,
              name: '瓜分',
              title: `本次瓜分打卡获得`,
            }
          : type === 'carve_fail_share'
          ? {
              buttonText: this.shareText,
              name: '',
              title: `很遗憾</br>您已错过瓜分打卡的时间`,
            }
          : {
              buttonText: `看视频拿回${this.carveReturnAmount}金币`,
              name: '',
              title: `很遗憾</br>您已错过瓜分打卡的时间`,
            };
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_box {
    position: relative;
    width: 2.68rem;
    height: auto;
    background: #e1efff;
    border-radius: 0.12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.58rem 0 0.2rem;
    box-sizing: border-box;
    font-family: PingFangSC-Regular, PingFang SC;
    .close_btn {
      position: absolute;
      top: 0.14rem;
      right: 0.12rem;
      width: 0.18rem;
      height: 0.18rem;
    }
    .top_img {
      position: absolute;
      top: -0.38rem;
      left: 0.7rem;
      width: 1.28rem;
      height: 0.83rem;
    }
    .leaf {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: -0.47rem;
    }
    .carve_success_title {
      width: 1.62rem;
      height: 0.19rem;
      background-image: url(../../../assets/images/newSleep/success_title.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.18rem;
      p {
        font-size: 0.15rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #2538c0;
      }
    }
    .title {
      font-size: 0.15rem;
      font-weight: 400;
      color: #2538c0;
      line-height: 0.15rem;
    }
    .fail_title {
      width: 1.65rem;
      height: 0.5rem;
      font-size: 0.15rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2538c0;
      line-height: 0.25rem;
      text-align: center;
      margin-bottom: 0.2rem;
    }
    .reward {
      text-align: center;
      display: flex;
      align-items: baseline;
      margin-top: 0.06rem;
      margin-bottom: 0.14rem;
      .reward_number {
        position: relative;
        top: 0.01rem;
        font-size: 0.34rem;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #2538c0;
        margin-right: 0.11rem;
      }
      .reward_unit {
        font-size: 0.18rem;
        font-weight: 400;
        color: #2538c0;
      }
    }
    .sign_button {
      width: 1.88rem;
      height: 0.5rem;
      background-image: url(../../../assets/images/newSleep/check-button.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 0.12rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bolder;
        color: #8c3213;
      }
    }
    .no_need {
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: rgba(37, 56, 192, 0.3);
      line-height: 0.12rem;
      margin-top: 0.12rem;
    }
  }
</style>
