import { GamePost, Api } from '@/http/index';
import {
  TreeStatus,
  saplingRedpacketPointList,
  smallTreeRedpacketPointList,
  getNextRedpacketWaterTimes,
  getRedpacketStyle,
} from './constance';
import sign from './sign/index';
import steal from './steal/index';

export default {
  namespaced: true,
  state: () => {
    return {
      // 加载资源中
      loading: true,
      // 服务器时间 s
      serverTime: 0,
      // 自服务器时间经过了多少秒 s
      pastTime: 0,
      // 水滴值
      waterValue: 0,
      // 水滴生成速率 s/滴
      waterRate: 600,
      // 下次生成水滴的时间
      nextCreateWaterTime: 500,
      // 活动获取的总金币数
      totalCoins: 0,
      // 0-树苗 1-小树 2-大树 3-死树
      status: 0,
      // 树苗升级所需水滴
      saplingUpdateWaterValue: 30,
      // 小树升级所需水滴
      smallTreeUpdateWaterValue: 90,
      // 当前已经浇的水
      wateredValue: 0,
      // 今日是否有机会看视频加水
      canAddWaterByVideo: true,
      // 多少水滴能浇水一次
      singleTimeWaterValue: 10,
      // 水壶tips
      kettleTips: {
        show: false,
        text: '',
      },

      // 小树红包节点
      wateringRedpackagePoints: smallTreeRedpacketPointList,

      // 摇钱树红包列表
      redpacketList: [
        {
          id: 1,
          coins: 0,
        },
        {
          id: 2,
          coins: 50,
        },
        {
          id: 3,
          coins: 0,
        },
        {
          id: 4,
          coins: 0,
        },
        {
          id: 5,
          coins: 0,
        },
      ],
      selectedRedpacketId: 0,

      // 金币动画目标坐标
      targetPosition: {
        x: 0,
        y: 0,
      },
      // 单个红包限额
      redpacketCoinLimit: 50,
      // 金币生成速率(无虫) s/金币
      coinRate: 5,
      // 金币生成速率(有虫) s/金币
      coinRateWithBug: 7,

      // 蛀虫信息
      bugList: [
        {
          create_time: 0, // 蛀虫的出生时间点
          status: 0, // 1-已激活，0-未激活
          id: 1, // ID标识
        },
        {
          create_time: 0,
          status: 0,
          id: 2,
        },
      ],

      deadTime: 0,

      // 加水的视频信息
      waterVideoGroup: {
        ad_group_id: 0,
        game_type: 'game_sleep_bubble',
      },

      // 除虫的视频信息
      bugVideoGroup: {
        ad_group_id: 0,
        game_type: 'game_sleep_carve',
      },

      searchVideoGroup: {
        ad_group_id: 0,
        game_type: 'game_money_tree_search_time',
      },

      businessName: '',

      showMoneyTreeGuide: false,

      bannerTitle: [
        '成年摇钱树每天</br>最高产生18888金币',
        '累计浇水达9次即可升级为成年摇钱树坐享收益',
        '成年摇钱树离线时间收益照样有，记得及时上线收取',
      ],

      packageExpireWindow: '',

      // 插屏广告id
      screenAdGroupId: 0,
    };
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },

    setServerTime(state, payload) {
      state.serverTime = payload;
    },

    setPastTime(state, payload) {
      state.pastTime = payload;
    },
    addPastTime(state) {
      state.pastTime = state.pastTime + 0.5;
    },

    setWaterValue(state, payload) {
      state.waterValue = payload;
    },
    addWaterValue(state) {
      state.waterValue = state.waterValue + 1;
    },

    setWateredValue(state, payload) {
      state.wateredValue = payload;
    },
    addWateredValue(state) {
      state.wateredValue = state.wateredValue + 10;
    },

    setNextCreateWaterTime(state, payload) {
      state.nextCreateWaterTime = payload;
    },

    setTotalCoins(state, payload) {
      state.totalCoins = payload;
    },

    setStatus(state, payload) {
      state.status = payload;
    },

    setCanAddWarterByVideo(state, payload) {
      state.canAddWaterByVideo = payload;
    },

    setRedpacketList(state, list) {
      state.redpacketList = [...list];
    },

    setBugList(state, list) {
      state.bugList = [...list];
    },

    setKettleTips(state, data) {
      state.kettleTips = { ...data };
    },

    setTargetPosition(state, data) {
      state.targetPosition = { ...data };
    },

    setSelectedRedpacketId(state, id) {
      state.selectedRedpacketId = id;
    },

    setRedpacketCoinLimit(state, payload) {
      state.redpacketCoinLimit = payload;
    },

    setCoinRate(state, payload) {
      state.coinRate = payload;
    },
    setCoinRateWithBug(state, payload) {
      state.coinRateWithBug = payload;
    },

    setWaterRate(state, payload) {
      state.waterRate = payload;
    },

    setDeadTime(state, payload) {
      state.deadTime = payload;
    },

    setWaterVideoGroup(state, data) {
      state.waterVideoGroup = { ...data };
    },
    setBugVideoGroup(state, data) {
      state.bugVideoGroup = { ...data };
    },

    setSmallTreeUpdateWaterValue(state, payload) {
      state.smallTreeUpdateWaterValue = payload;
    },

    setBusinessName(state, payload) {
      state.businessName = payload;
    },

    setShowMoneyTreeGuide(state, payload) {
      console.log(`payload`, payload);
      const hasTreeUserGuide = localStorage.getItem('tree_user_guide');
      if (payload) {
        if (!hasTreeUserGuide) {
          localStorage.setItem('tree_user_guide', '0');
        }
        state.showMoneyTreeGuide = payload;
      } else {
        if (hasTreeUserGuide) {
          state.showMoneyTreeGuide = true;
          return;
        }
        state.showMoneyTreeGuide = payload;
      }
    },

    setBannerTitle(state, list) {
      state.bannerTitle = list;
    },

    setPackageExpireWindow(state, payload) {
      state.packageExpireWindow = payload;
    },

    setSearchVideoGroup(state, data) {
      state.searchVideoGroup = { ...data };
    },

    setScreenAdGroupId(state, payload) {
      state.screenAdGroupId = payload;
    },
  },
  actions: {
    // 获取游戏信息
    async getPageInfo({ commit, state }) {
      const data = await GamePost(
        Api.GET_TREE_PAGE_INFO,
        {},
        {
          showLoading: false,
        }
      ).catch((e) => {});
      let {
        server_timestamp,
        stage,
        watering_can_info,
        watering_time,
        water_rate,
        all_watering_time,
        total_reward_coins,
        next_load_water_time,
        can_add_warter_by_video,
        redpackage_coin_limit,
        coin_rate_normal,
        coin_rate_bug,
        bugs,
        redpackages,
        dead_at,
        watering_video_group,
        bug_video_group,
        business_name,
        show_money_tree_guide,
        steal_coin_search_time,
        banner_title,
        gold_package_expire_window,
        search_time_video_group,
        screen_ad_group_id,
      } = data;
      const { singleTimeWaterValue } = state;
      // 状态
      commit('setStatus', Number(stage) - 1);
      // 服务器时间
      commit('setServerTime', Number(server_timestamp));
      // 总金币
      commit('setTotalCoins', Number(total_reward_coins));
      // 浇水值
      if (watering_can_info) {
        const { loaded } = watering_can_info;
        commit('setWaterValue', Number(loaded));
      }
      // 已浇水值
      commit('setWateredValue', Number(watering_time) * singleTimeWaterValue);
      // 下次加水时间
      commit('setNextCreateWaterTime', Number(next_load_water_time));
      // 是否能看视频加水
      commit('setCanAddWarterByVideo', can_add_warter_by_video);
      // 水生成速率
      commit('setWaterRate', Number(water_rate));
      commit('setSmallTreeUpdateWaterValue', Number(all_watering_time) * singleTimeWaterValue);

      // 红包上限
      commit('setRedpacketCoinLimit', redpackage_coin_limit);
      commit('setCoinRate', Number(coin_rate_normal));
      commit('setCoinRateWithBug', Number(coin_rate_bug));

      if (redpackages && redpackages.length) {
        commit('setRedpacketList', redpackages);
      }
      if (bugs && bugs.length) {
        commit('setBugList', [...bugs]);
      }
      // 死亡时间
      commit('setDeadTime', Number(dead_at));
      // 视频参数
      commit('setWaterVideoGroup', watering_video_group);
      commit('setBugVideoGroup', bug_video_group);
      commit('setBusinessName', business_name);

      if (+stage === 1) {
        commit('setKettleTips', {
          show: true,
          text: '点击水壶浇水可使小树<br/>成长',
        });
      }
      commit('setShowMoneyTreeGuide', show_money_tree_guide);
      commit('steal/setChance', steal_coin_search_time);
      banner_title && commit('setBannerTitle', banner_title);
      commit('setPackageExpireWindow', gold_package_expire_window);
      commit('setSearchVideoGroup', search_time_video_group);
      commit('setScreenAdGroupId', screen_ad_group_id);
    },

    // 增加摇钱树金币
    addRedpacketCoins({ commit, state }, rate) {
      let { redpacketList } = state;
      let list = [...redpacketList].sort((a, b) => a.id - b.id);
      // 优先填充有金币但未满的
      let currentIndex = list.findIndex((item) => item.coins != 0 && item.coins < item.max_coin);

      if (currentIndex < 0) {
        // 其次填充全空的
        currentIndex = list.findIndex((item) => item.coins < item.max_coin);
        if (currentIndex < 0) {
          // 全满
          return;
        }
      }

      const newCoins = Number(list[currentIndex].coins) + rate;
      list[currentIndex].coins = newCoins;
      commit('setRedpacketList', list);
    },

    // 除虫
    async disinsect({ commit, state }, id) {
      try {
        await GamePost(Api.KILL_BUG, { bug_id: id });
        const { bugList } = state;
        let list = [...bugList];
        const index = list.findIndex((item) => item.id == id);
        if (index > -1) {
          list[index].status = 0;
        }
        commit('setBugList', list);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    // 当前服务器时间
    serverTimeNow(state) {
      const { serverTime, pastTime } = state;
      return Number(serverTime) + Number(pastTime);
    },

    // 金币生成速率
    coinCreateRate(state) {
      const { coinRate, coinRateWithBug, bugList } = state;
      const bugCount = bugList ? bugList.filter((item) => +item.status === 1).length : 0;

      return bugList && bugCount ? coinRateWithBug : coinRate;
    },

    // 领取红包的水滴节点信息
    redpacketPointInfo(state) {
      const { status, saplingUpdateWaterValue, smallTreeUpdateWaterValue, wateringRedpackagePoints } = state;
      return +status === TreeStatus.SAPLING
        ? {
            pointList: saplingRedpacketPointList,
            updateWaterValue: +saplingUpdateWaterValue,
            last: +saplingUpdateWaterValue,
          }
        : {
            pointList: wateringRedpackagePoints,
            updateWaterValue: +smallTreeUpdateWaterValue,
            last: smallTreeUpdateWaterValue,
          };
    },

    // 浇水进度文案
    waterProgressText(state) {
      const {
        status,
        wateredValue,
        saplingUpdateWaterValue,
        smallTreeUpdateWaterValue,
        wateringRedpackagePoints,
      } = state;
      const nextLevelWaterValue =
        +status === TreeStatus.SAPLING
          ? saplingUpdateWaterValue - wateredValue
          : smallTreeUpdateWaterValue - wateredValue;
      const pointlist = +status === TreeStatus.SAPLING ? saplingRedpacketPointList : wateringRedpackagePoints;
      const nextName = +status === TreeStatus.SAPLING ? '小树' : '摇钱树';
      if (nextLevelWaterValue == 10) {
        return `再浇水一次可升级为${nextName}`;
      }
      const next = getNextRedpacketWaterTimes(pointlist, wateredValue);

      return next ? `再浇水${next}次可得红包` : '';
    },

    // 摇钱树 - 可领取金币数
    getableCoins(state) {
      const { redpacketList } = state;
      return redpacketList.reduce((total, current) => {
        return total + current.coins;
      }, 0);
    },

    // 摇钱树 - 带有位置和类型信息的红包列表
    displayRedpacketList(state) {
      const { redpacketList } = state;
      const list = redpacketList.map((item) => {
        return getRedpacketStyle(item);
      });
      return list;
    },

    // 摇钱树 - 下次生成蛀虫时间
    nextBugCreateTime(state) {
      const { bugList } = state;
      let time = 0;
      bugList.forEach((item) => {
        const { create_time, status } = item;
        // 找到status为零且生成时间靠前的蛀虫
        if (Number(status) === 0) {
          if (time === 0 || Number(create_time) < time) {
            time = create_time;
          }
        }
      });

      return time;
    },

    // 下一个红包过期时间
    nextBagExpireTime(state) {
      const { redpacketList } = state;
      let time = 0;
      redpacketList.forEach((item) => {
        const { expire_at } = item;
        if (time === 0 || (expire_at && expire_at < time)) {
          time = expire_at;
        }
      });
      return time;
    },

    // 下一个领取的红包（满），用于红包tips提示
    nextFullRedpacket(state) {
      const { redpacketList, bugList } = state;
      const fullList = redpacketList.filter((item) => Number(item.coins) === Number(item.max_coin));
      let id = 0;

      if (fullList.length) {
        id = fullList[0].id;
        // 最顶上的红包满且有蛀虫时跳过tips展示
        if (Number(bugList[1].status) === 1 && id === 2) {
          if (fullList.length > 1) {
            return fullList[1].id;
          } else {
            return 0;
          }
        }
      }
      return id;
    },

    // 蛀虫信息 - 新手指引下展示假数据
    displayBugList(state) {
      const { bugList, showMoneyTreeGuide } = state;
      return showMoneyTreeGuide
        ? [
            {
              create_time: 0, // 蛀虫的出生时间点
              status: 0, // 1-已激活，0-未激活
              id: 1, // ID标识
            },
            {
              create_time: 0,
              status: 1,
              id: 2,
              isFake: true,
            },
          ]
        : bugList;
    },
  },
  modules: {
    sign,
    steal,
  },
};
