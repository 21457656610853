export default {
  // 游戏库页面数据接口
  GET_GAME_LIB_PAGE_INFO_API: '/GameLib/GetPageInfo',
  // 游戏库每日登陆领奖接口
  GAME_LIB_RECEIVE_REWARD_API: '/GameLib/ReceiveReward',
  // 游戏时长宝箱奖励领取接口
  GET_GAME_TIME_REWARD_API: '/GameLib/ReceiveChestReward',
  // 通知服务端游戏跳转开始接口
  MARK_GAME_START_API: '/GameLib/MarkTaskBegin',
  // 通知服务端游戏结束接口
  MARK_GAME_END_API: '/GameLib/MarkTaskCommit',
};
