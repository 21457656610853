export default {
  // 农场首页
  GET_FARM_PAGE_INFO: '/Farm/getPageInfo',
  // 收支记录
  GET_FARM_INCOME_RECORD: '/Farm/getDetails',
  // 收取水滴
  GET_FARM_TAKE_WATER_DROP: '/Farm/takeDroplets',
  // 果树浇水
  FARM_WATERING: '/Farm/water',
  // 获取果树升级奖励
  GET_FARM_UPGRADE_REWARD: '/Farm/getUpgradeReward',
  // 浇水奖励领取
  GET_FARM_WATERING_REWARD: '/Farm/getWaterNumReward',
  // 农场主动弹窗
  GET_FARM_WINDOW_INFO: '/Farm/window',
  // 农场种树接口
  FARM_PLANT_TREES: '/Farm/plant',
  // 农场种树接口
  GET_FARM_TASK_INFO: '/Farm/getTaskInfo',
  // 种树完成，填写收货地址
  FARM_FINISH_PLANT: '/Farm/finishedPlant',
  // 重新种树页面
  GET_FARM_REPLANT_PAGE: '/Farm/replantPage',
  // 签到
  FARM_SIGN_IN: '/SignIn/SignIn',
  // 更新任务列表
  FARM_UPDATE_TASK: '/Farm/taskProgress',
  // 领取任务奖励
  FARM_CLIAM_REWARD: '/Farm/getReward',
  // 领取任务奖励
  FARM_START_INVITE_TASK: '/Farm/startInviteTask',
  // 检查是否可以领取任务列表奖励
  FARM_CHECK_REWADR: '/Farm/checkReward',
  // 签到奖励前置检查
  FARM_CHECK_SIGNIN: '/SignIn/checkSignIn',
};
