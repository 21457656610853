<template>
  <audio
    ref="audio"
    id="audio_player"
    @timeupdate="onTimeChange"
    :src="currentSong.play_song.play_address"
    loop
  ></audio>
</template>

<script>
  import { mapGetters, mapState, mapMutations } from 'vuex';
  export default {
    data() {
      return {
        currentTime: 0,
        endTime: 0,
        randomStartTime: 0,
      };
    },
    computed: {
      ...mapState('guess_music', ['currentIndex', 'playing', 'gameMode', 'circleSecond']),
      ...mapGetters('guess_music', ['currentSong']),
    },
    watch: {
      playing: {
        handler(val) {
          this.$nextTick(() => {
            let mode;
            if (this.currentIndex === 0) {
              mode = 'next';
            }
            val ? this.play(mode) : this.pause();
          });
        },
        immediate: true,
      },
      currentIndex(val) {
        this.$nextTick(() => {
          val !== 0 && this.setPlaying(true);
          this.play('next');
        });
        console.log('下一首', val);
      },
    },
    mounted() {
      // this.$refs.audio.play();
    },
    methods: {
      ...mapMutations('guess_music', ['setPlaying', 'setCanAutoPlay', 'setCurrentTime', 'setEndTime']),
      play(mode) {
        if (!this.currentSong.play_song.play_address) {
          return;
        }
        this.$refs.audio
          .play()
          .then(() => {
            if (mode === 'next') {
              this.setRandomTime();
            }
          })
          .catch((err) => {
            console.log('music_err', err);
            this.setCanAutoPlay(false);
          });
      },
      pause() {
        this.$refs.audio.pause();
      },

      onTimeChange(e) {
        if (this.gameMode === 'break_through') {
          return;
        }
        const currentTime = e.target.currentTime;
        if (currentTime >= this.randomStartTime + this.circleSecond) {
          this.$refs.audio.currentTime = this.randomStartTime;
        }
      },

      setRandomTime() {
        // if (this.gameMode === 'break_through') {
        //   return;
        // }
        // 随机时间
        const duration = Number(this.currentSong.play_song.play_time);
        const startTime = Number(this.currentSong.play_song.chorus_time.start_time);
        const maxNum = Math.max(duration - this.circleSecond, 0);
        // const randomStartTime = this.randomNum(0, maxNum);
        if (startTime) {
          this.randomStartTime = startTime;
        } else {
          this.randomStartTime = maxNum / 2;
        }
        console.log('music_name', this.currentSong.play_song.music_name);
        console.log('music_id', this.currentSong.play_song.music_id);
        console.log('maxNum', maxNum);
        console.log('startTime', startTime);
        console.log('randomStartTime', this.randomStartTime);
        this.$refs.audio.currentTime = this.randomStartTime;
      },

      // [m,n] 的随机数
      randomNum(minNum, maxNum) {
        const len = arguments.length;
        switch (len) {
          case 1:
            return parseInt(Math.random() * minNum + 1, 10);
          case 2:
            return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          default:
            return 0;
        }
      },
    },
  };
</script>
