var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SignModal"},[_c('img',{staticClass:"SignModal-light",attrs:{"src":require("@/assets/images/eatingV2/guang.png"),"alt":"光"}}),_c('div',{staticClass:"SignModal-signDay"},[_vm._v("已连续签到"+_vm._s(_vm.pageModalInfo.today_sign_in_day)+"天")]),_c('div',{staticClass:"SignModal-get"},[_vm._v("签到可领+"+_vm._s(_vm.pageModalInfo.today_reward_amount_txt))]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"SignModal-process"},[_c('img',{staticClass:"SignModal-process-right__bar",attrs:{"src":_vm.pageModalInfo.today_sign_in_day >= 7
            ? require('../../../assets/images/eatingV2/right-bar-active.png')
            : require('../../../assets/images/eatingV2/right-bar.png'),"alt":"bar"}}),_c('div',{staticClass:"SignModal-process-iconPrice"},_vm._l((_vm.firstLineProcess),function(item,k){return _c('div',{key:k,class:['SignModal-process-iconPrice-item', _vm.pageModalInfo.today_sign_in_day >= item.day ? 'active' : '']},[_c('img',{staticClass:"SignModal-process-iconPrice-item-img__icon",attrs:{"src":require("@/assets/images/eating/icon_coin.png"),"alt":"金币"}}),_c('span',{staticClass:"SignModal-process-iconPrice-item-num"},[_vm._v(_vm._s(item.reward_amount))])])}),0),_c('div',{staticClass:"SignModal-process-first-bar"},[_c('div',{staticClass:"SignModal-process-first-bar-active",style:(("width: " + (_vm.initWidth(_vm.pageModalInfo.today_sign_in_day / 6))))})]),_c('div',{staticClass:"SignModal-process-info"},_vm._l((_vm.firstLineProcess),function(item,k){return _c('div',{key:k,class:['SignModal-process-info-item', _vm.pageModalInfo.today_sign_in_day === item.day ? 'active' : '']},[_vm._v(" "+_vm._s(_vm.pageModalInfo.today_sign_in_day === item.day ? '可领' : ((item.day) + "天"))+" ")])}),0),_c('div',{staticClass:"SignModal-process-gift"},_vm._l((_vm.secondLineProcess),function(item,k){return _c('div',{key:k,class:['SignModal-process-gift-item', _vm.pageModalInfo.today_sign_in_day >= item.day ? 'active' : '']},[_c('img',{class:[
              'SignModal-process-gift-item-img__icon',
              _vm.pageModalInfo.today_sign_in_day >= item.day ? 'active' : '',
              _vm.pageModalInfo.today_sign_in_day === item.day ? 'rotateAni' : '' ],attrs:{"src":_vm.pageModalInfo.today_sign_in_day >= item.day
                ? require('../../../assets/images/eatingV2/modal-price.png')
                : require('../../../assets/images/eatingV2/modal-price-normal.png'),"alt":"金币"}}),_c('span',{staticClass:"SignModal-process-gift-item-num"},[_vm._v(_vm._s(item.reward_amount))])])}),0),_c('div',{staticClass:"SignModal-process-second-bar"},[_c('div',{class:['SignModal-process-second-bar-left', _vm.pageModalInfo.today_sign_in_day >= 30 ? 'active' : '']}),_c('div',{staticClass:"SignModal-process-second-bar-active",style:(("width:" + (_vm.initWidth('', 'second'))))})]),_c('div',{staticClass:"SignModal-process-second-info"},_vm._l((_vm.secondLineProcess),function(item,k){return _c('div',{key:k,class:[
            'SignModal-process-second-info-item',
            _vm.pageModalInfo.today_sign_in_day === item.day ? 'active' : '' ]},[_vm._v(" "+_vm._s(_vm.pageModalInfo.today_sign_in_day === item.day ? '可领' : ((item.day) + "天"))+" ")])}),0)])]),(_vm.pageModalInfo.extend_tasks.length > 0)?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"SignModal-btn__video",on:{"click":_vm.loadMediationAd}},[_c('span',[_vm._v("看视频领取"+_vm._s(_vm.pageModalInfo.extend_tasks[0].reward_amount)+"金币")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }