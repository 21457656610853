const TREE_API = {
  GET_TREE_PAGE_INFO: '/MoneyTree/GetPageInfo', // 获取任务页面信息
  // 浇水
  WATRING: '/MoneyTree/Watering',
  // 开红包
  OPEN_TREE_RED_PACKAGE: '/MoneyTree/OpenRedpackage',
  KILL_BUG: '/MoneyTree/KillBug',
  REPLANT: '/MoneyTree/Replant',

  // 施肥打卡列表
  GET_TREE_PUNCH_PAGE_INFO: '/MoneyTree/GetPunchPageInfo',
  // 施肥打卡
  TREE_PUNCH: '/MoneyTree/Punch',
  // 施肥补卡
  TREE_FILL_PUNCH: '/MoneyTree/FillPunch',

  // 搜索用户
  TREE_SEARCH: '/MoneyTree/Search',

  // 选择用户
  TREE_SELECT_USER: '/MoneyTree/SelectUser',

  // 偷金币-换一家
  TREE_RE_SELECT_USER: '/MoneyTree/reSelectUser',
  // 偷金币
  TREE_STEAL_COIN: '/MoneyTree/StealCoin',
  // 分享得水滴
  TREE_SHARE_REWARD_WATER: '/MoneyTree/MarkShareRewardWater',
};

export default TREE_API;
