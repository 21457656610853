import { GamePost, Api } from '@/http/index';
import { mockPlayList } from './mock';
import { SHOW_SAND_REST_TIME_DEFAULT, MAX_REBORN_COUNT_DEFAULT } from '@/views/GuessMusic/const';
import answer from './modules/answer';

export default {
  namespaced: true,
  state: () => {
    return {
      // 是否存活
      alive: true,

      // 播放器状态
      playing: false,
      // 游戏模式 break_through/singer
      gameMode: 'break_through',
      // 全部歌曲列表
      totalList: [],
      // 加载中列表
      preloadingList: [],
      // 播放列表
      playList: mockPlayList,
      // 当前歌曲索引
      currentIndex: 0,
      // 选择的答案
      selectedAnswer: null,
      // 歌手列表
      singerList: [],
      // 选中的歌手
      selectedSinger: null,
      // 任务key
      taskKey: '',
      // 音乐是否全部加载
      loaded: false,

      gameType: '',
      businessName: '',

      scoreConditions: [],
      // 游戏结果 - success/fail
      result: '',

      // 复活视频信息
      resurgenceVideoGroup: null,
      // 奖励视频信息
      rewardVideoGroup: null,

      // 可否自动播放
      canAutoPlay: true,

      event: null,

      // 当前播放时间
      currentTime: 0,
      // 歌曲结束时间
      endTime: 0,

      // 沙漏时间
      showSandRestTime: SHOW_SAND_REST_TIME_DEFAULT,

      // 最大复活次数
      maxRebornCount: MAX_REBORN_COUNT_DEFAULT,
      // 当前复活次数
      rebornCount: 0,

      // 是否展示hp
      showHp: false,

      // 总计时时长 s
      totalSecond: 120,
      // 每题计时 s
      songSecond: 10,
      // 复活倒计时 s
      rebornSecond: 20,
      // 歌星模式单曲循环时间
      circleSecond: 15,
    };
  },
  mutations: {
    setPlaying(state, flag) {
      state.playing = flag;
    },
    setCurrentIndex(state, index) {
      state.currentIndex = index;
    },
    setSelectedAnswer(state, item) {
      state.selectedAnswer = item;
    },
    setTaskKey(state, key) {
      state.taskKey = key;
    },

    setTotalList(state, list) {
      state.totalList = [...list];
    },

    setPreloadingList(state, list) {
      state.preloadingList = [...list];
    },

    setPlayList(state, list) {
      state.playList = list;
    },

    setLoaded(state, flag) {
      state.loaded = flag;
    },

    setScoreConditions(state, list) {
      state.scoreConditions = [...list];
    },

    setResult(state, flag) {
      state.result = flag;
    },

    setSingerList(state, list) {
      state.singerList = [...list];
    },

    setSelectedSinger(state, item) {
      state.selectedSinger = { ...item };
    },

    setBusinessName(state, str) {
      state.businessName = str;
    },

    setGameType(state, str) {
      state.gameType = str;
    },

    setAlive(state, flag) {
      state.alive = flag;
    },

    setResurgenceVideoGroup(state, obj) {
      state.resurgenceVideoGroup = { ...obj };
    },

    setRewardVideoGroup(state, obj) {
      state.rewardVideoGroup = { ...obj };
    },

    setCanAutoPlay(state, flag) {
      state.canAutoPlay = flag;
    },

    setEvent(state, obj) {
      state.event = obj;
    },

    setCurrentTime(state, time) {
      state.currentTime = time;
    },

    setEndTime(state, time) {
      state.endTime = time;
    },

    setGameMode(state, mode) {
      state.gameMode = mode;
    },

    setShowSandRestTime(state, time) {
      state.showSandRestTime = time;
    },

    setMaxRebornCount(state, count) {
      state.maxRebornCount = count;
    },
    setRebornCount(state, count) {
      state.rebornCount = count;
    },

    setShowHp(state, flag) {
      state.showHp = flag;
    },

    setTotalSecond(state, sec) {
      state.totalSecond = sec;
    },
    setSongSecond(state, sec) {
      state.songSecond = sec;
    },
    setRebornSecond(state, sec) {
      state.rebornSecond = sec;
    },

    setCircleSecond(state, sec) {
      state.circleSecond = sec;
    },
  },
  actions: {
    // 获取闯关模式数据
    async getBerakThroughInfo({ commit }) {
      try {
        const data = await GamePost(
          Api.PASS_MUSIC_LIST_API,
          {},
          {
            showLoading: false,
          }
        ).catch((e) => {});
        const {
          business_name,
          game_type,
          list,
          red_package,
          task_key,
          score_conditions,
          resurgence_video_group,
          reward_video_group,
          show_special_effects,
          resurgence_times_limit,
          pass_count_down,
          pass_song_count_down,
          resurgence_count_down,
        } = data;
        // {
        //   resurgence_video_group: {
        //     game_type: 'game_new_guess_song_resurgence',
        //     ad_group_id: 55,
        //   },
        //   reward_video_group: {
        //     game_type: 'game_new_guess_song_pass_reward',
        //     ad_group_id: 56,
        //   },
        //   resurgence_times_limit: 1000,
        //   red_package: [
        //     {
        //       combo: 3,
        //       num: 1,
        //     },
        //     {
        //       combo: 3,
        //       num: 2,
        //     },
        //     {
        //       combo: 5,
        //       num: 3,
        //     },
        //     {
        //       combo: 3,
        //       num: 4,
        //     },
        //     {
        //       combo: 3,
        //       num: 5,
        //     },
        //     {
        //       combo: 3,
        //       num: 6,
        //     },
        //   ],
        //   score_conditions: [
        //     {
        //       score: 'C',
        //       correct_num: [0, 12],
        //     },
        //     {
        //       score: 'B',
        //       correct_num: [13, 17],
        //     },
        //     {
        //       score: 'A',
        //       correct_num: [18, 24],
        //     },
        //     {
        //       score: 'S',
        //       correct_num: [25, 30],
        //     },
        //     {
        //       score: 'SS',
        //       correct_num: [31, 40],
        //     },
        //     {
        //       score: 'SSS',
        //       correct_num: [41, 60],
        //     },
        //   ],
        //   show_special_effects: 30,
        //   pass_count_down: 120,
        //   pass_song_count_down: 15,
        //   resurgence_count_down: 8,
        //   task_key: 'M2N2WEdONTVEWXZQellMcEFqa3J0T1FSd3hpVUpNNjY5d1Boc3V1THEvZU9UT3NrUEdJK3JQOG1nYitXYVFwMQ==',
        //   list: [
        //     {
        //       id: 1,
        //       play_song: {
        //         music_id: '7640492',
        //         music_name: '站在草原望北京',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza62287f4c6ad37367958840.mp3',
        //         play_time: '241',
        //         chorus_time: {
        //           start_time: '58.51',
        //           end_time: '96.6',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '12140755',
        //           music_name: '祁隆 - 等你等了那么久.p4舞曲',
        //         },
        //         {
        //           music_id: '7359501',
        //           music_name: '太想念',
        //         },
        //         {
        //           music_id: '7640492',
        //           music_name: '站在草原望北京',
        //         },
        //         {
        //           music_id: '11780326',
        //           music_name: '拉紧手跟我走 (DJ伟然版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 2,
        //       play_song: {
        //         music_id: '28096887',
        //         music_name: '郎从门前过',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e39f81dbf2bd4fe8a44b6c5edd61117a.mp3',
        //         play_time: '138',
        //         chorus_time: {
        //           start_time: '35.48',
        //           end_time: '67.62',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9912158',
        //           music_name: '潇洒走一回(电视剧《京城四少》主题曲)',
        //         },
        //         {
        //           music_id: '22710158',
        //           music_name: '走在人生的路口 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '11118978',
        //           music_name: '最真的梦',
        //         },
        //         {
        //           music_id: '28096887',
        //           music_name: '郎从门前过',
        //         },
        //       ],
        //     },
        //     {
        //       id: 3,
        //       play_song: {
        //         music_id: '24766566',
        //         music_name: '包容',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e8017df46dc644ec92a066fd95b7c6c3.mp3',
        //         play_time: '253',
        //         chorus_time: {
        //           start_time: '48.85',
        //           end_time: '105.33',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '23493379',
        //           music_name: '旧梦 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '1824226',
        //           music_name: '军中绿花',
        //         },
        //         {
        //           music_id: '7237035',
        //           music_name: '知心爱人(电视剧《雨夜惊情》片尾曲)',
        //         },
        //         {
        //           music_id: '24766566',
        //           music_name: '包容',
        //         },
        //       ],
        //     },
        //     {
        //       id: 4,
        //       play_song: {
        //         music_id: '3218052',
        //         music_name: '无结局的爱',
        //         play_address: 'https://static1.kaixinyf.cn/resource/645c5730ad084b9fb63e8b56a8602a09.mp3',
        //         play_time: '208',
        //         chorus_time: {
        //           start_time: '53.5',
        //           end_time: '76.9',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1920059',
        //           music_name: '醉千年',
        //         },
        //         {
        //           music_id: '14697616',
        //           music_name: '你若盛开蝴蝶自来DJ',
        //         },
        //         {
        //           music_id: '9502037',
        //           music_name: '不能忘记你',
        //         },
        //         {
        //           music_id: '3218052',
        //           music_name: '无结局的爱',
        //         },
        //       ],
        //     },
        //     {
        //       id: 5,
        //       play_song: {
        //         music_id: '4562214',
        //         music_name: '我不后悔',
        //         play_address: 'https://static1.kaixinyf.cn/resource/3ae850ea510647e89927746c1b6a4b11.mp3',
        //         play_time: '251',
        //         chorus_time: {
        //           start_time: '66.32',
        //           end_time: '101.05',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1862389',
        //           music_name: '红枣树 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '26148062',
        //           music_name: '多情野花不要采',
        //         },
        //         {
        //           music_id: '1849178',
        //           music_name: '最炫民族风',
        //         },
        //         {
        //           music_id: '4562214',
        //           music_name: '我不后悔',
        //         },
        //       ],
        //     },
        //     {
        //       id: 6,
        //       play_song: {
        //         music_id: '5133383',
        //         music_name: '新鸳鸯蝴蝶梦(电视剧《包青天》主题曲)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/930bafa936ad4888a1fa8c99dd0bc2e9.mp3',
        //         play_time: '264',
        //         chorus_time: {
        //           start_time: '82.85',
        //           end_time: '109.6',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9778207',
        //           music_name: '青藏高原(央视星光璀璨中国巨星演唱会)',
        //         },
        //         {
        //           music_id: '7602547',
        //           music_name: '披着羊皮的狼',
        //         },
        //         {
        //           music_id: '5133383',
        //           music_name: '新鸳鸯蝴蝶梦(电视剧《包青天》主题曲)',
        //         },
        //         {
        //           music_id: '25764333',
        //           music_name: '生活就像那一杯苦酒',
        //         },
        //       ],
        //     },
        //     {
        //       id: 7,
        //       play_song: {
        //         music_id: '7714386',
        //         music_name: '光辉岁月 (电影《摆渡人》插曲)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/f9808cb508c3461fb6ae1cd58e3aab51.mp3',
        //         play_time: '299',
        //         chorus_time: {
        //           start_time: '53.13',
        //           end_time: '91.02',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7714386',
        //           music_name: '光辉岁月 (电影《摆渡人》插曲)',
        //         },
        //         {
        //           music_id: '1794067',
        //           music_name: '天在下雨我在想你',
        //         },
        //         {
        //           music_id: '21912617',
        //           music_name: '姑娘我爱你',
        //         },
        //         {
        //           music_id: '6084744',
        //           music_name: '我的好兄弟',
        //         },
        //       ],
        //     },
        //     {
        //       id: 8,
        //       play_song: {
        //         music_id: '21556385',
        //         music_name: '大美妞 (DJ何鹏版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/5b3e7e8b0e304852bd7c3c5a0ec5a980.mp3',
        //         play_time: '243',
        //         chorus_time: {
        //           start_time: '55.54',
        //           end_time: '90.09',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '13788956',
        //           music_name: '爹娘汗水为了儿女流',
        //         },
        //         {
        //           music_id: '10639328',
        //           music_name: '潮湿的心 (DJ版)',
        //         },
        //         {
        //           music_id: '21556385',
        //           music_name: '大美妞 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '7470561',
        //           music_name: '站着等你三千年（Live）',
        //         },
        //       ],
        //     },
        //     {
        //       id: 9,
        //       play_song: {
        //         music_id: '5500816',
        //         music_name: '九妹',
        //         play_address: 'https://static1.kaixinyf.cn/resource/f15e36a03edf4faa861fa964708b1996.mp3',
        //         play_time: '229',
        //         chorus_time: {
        //           start_time: '57.59',
        //           end_time: '95.67',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1985347',
        //           music_name: '伤心的时候可以听情歌',
        //         },
        //         {
        //           music_id: '5500816',
        //           music_name: '九妹',
        //         },
        //         {
        //           music_id: '22301409',
        //           music_name: '路边的野花不要采',
        //         },
        //         {
        //           music_id: '19743025',
        //           music_name: '下定决心忘记你 (DJ 晨仔 Remix)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 10,
        //       play_song: {
        //         music_id: '2263115',
        //         music_name: '没房没车没老婆',
        //         play_address: 'https://static1.kaixinyf.cn/resource/7b311127933c4ce7b05abc9080b8e76e.mp3',
        //         play_time: '240',
        //         chorus_time: {
        //           start_time: '63.53',
        //           end_time: '100.12',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '2263115',
        //           music_name: '没房没车没老婆',
        //         },
        //         {
        //           music_id: '15097152',
        //           music_name: '有钱才有缘 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '17470603',
        //           music_name: '浪漫红尘缘',
        //         },
        //         {
        //           music_id: '2136423',
        //           music_name: '男人苦男人累',
        //         },
        //       ],
        //     },
        //     {
        //       id: 11,
        //       play_song: {
        //         music_id: '23109491',
        //         music_name: '太想念（DJCandy版）',
        //         play_address: 'https://static1.kaixinyf.cn/resource/3cfa5d7afe15476faf518dd11f24815e.mp3',
        //         play_time: '301',
        //         chorus_time: {
        //           start_time: '78.95',
        //           end_time: '109.41',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '25627095',
        //           music_name: '叹这一生 (Live)',
        //         },
        //         {
        //           music_id: '23109491',
        //           music_name: '太想念（DJCandy版）',
        //         },
        //         {
        //           music_id: '17395287',
        //           music_name: '人生不过三杯酒',
        //         },
        //         {
        //           music_id: '10160503',
        //           music_name: '故乡情 (DJ何鹏版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 12,
        //       play_song: {
        //         music_id: '13875065',
        //         music_name: '红颜知己',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza6216f58713af3723712619.mp3',
        //         play_time: '283',
        //         chorus_time: {
        //           start_time: '77.28',
        //           end_time: '127.62',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '15743062',
        //           music_name: '《三十出头》DJ版 真好听',
        //         },
        //         {
        //           music_id: '13875065',
        //           music_name: '红颜知己',
        //         },
        //         {
        //           music_id: '16908043',
        //           music_name: '求佛',
        //         },
        //         {
        //           music_id: '8934685',
        //           music_name: '吻别',
        //         },
        //       ],
        //     },
        //     {
        //       id: 13,
        //       play_song: {
        //         music_id: '18363057',
        //         music_name: '滚滚长江东逝水',
        //         play_address: 'https://static1.kaixinyf.cn/resource/8460608eaf5b4c02acd9206476e28e2e.mp3',
        //         play_time: '181',
        //         chorus_time: {
        //           start_time: '74.49',
        //           end_time: '92.88',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '5780166',
        //           music_name: '千年等一回(电视剧《新白娘子传奇》片头曲)',
        //         },
        //         {
        //           music_id: '18363057',
        //           music_name: '滚滚长江东逝水',
        //         },
        //         {
        //           music_id: '17380059',
        //           music_name: '坐上火车去拉萨',
        //         },
        //         {
        //           music_id: '11108856',
        //           music_name: '很久没人抱过我 (DJ可乐版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 14,
        //       play_song: {
        //         music_id: '2970595',
        //         music_name: '挣点小钱',
        //         play_address: 'https://static1.kaixinyf.cn/resource/032b0b0f683e4dd0b0764c21ee69c45b.mp3',
        //         play_time: '197',
        //         chorus_time: {
        //           start_time: '58.51',
        //           end_time: '91.95',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9797263',
        //           music_name: '香水有毒',
        //         },
        //         {
        //           music_id: '11211372',
        //           music_name: '情已欠费爱已停机',
        //         },
        //         {
        //           music_id: '2970595',
        //           music_name: '挣点小钱',
        //         },
        //         {
        //           music_id: '1905097',
        //           music_name: '醉相思',
        //         },
        //       ],
        //     },
        //     {
        //       id: 15,
        //       play_song: {
        //         music_id: '27436428',
        //         music_name: '无处容身 (DJ默涵版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/b89c2dba698d4dd79839a462f705b432.mp3',
        //         play_time: '246',
        //         chorus_time: {
        //           start_time: '11.52',
        //           end_time: '30.84',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '27295781',
        //           music_name: '涉爱未深-DJ版-QQ',
        //         },
        //         {
        //           music_id: '11964649',
        //           music_name: '天不下雨天不刮风天上有太阳',
        //         },
        //         {
        //           music_id: '21872414',
        //           music_name: '喝醉以后才明白 (DJ)',
        //         },
        //         {
        //           music_id: '27436428',
        //           music_name: '无处容身 (DJ默涵版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 16,
        //       play_song: {
        //         music_id: '5376045',
        //         music_name: '好姑娘',
        //         play_address: 'https://static1.kaixinyf.cn/resource/ee86c18a2731404aa8b479ab95ce9aa8.mp3',
        //         play_time: '283',
        //         chorus_time: {
        //           start_time: '51.64',
        //           end_time: '89.72',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7853189',
        //           music_name: '忘情水',
        //         },
        //         {
        //           music_id: '11636609',
        //           music_name: '妹妹你等着我（DJ何鹏版）',
        //         },
        //         {
        //           music_id: '5376045',
        //           music_name: '好姑娘',
        //         },
        //         {
        //           music_id: '9816356',
        //           music_name: '不过人间(DJ版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 17,
        //       play_song: {
        //         music_id: '18866509',
        //         music_name: '中了爱情的枪',
        //         play_address: 'https://static1.kaixinyf.cn/resource/9b6ac85e626d4eaa85422c5d2f3cb5b0.mp3',
        //         play_time: '223',
        //         chorus_time: {
        //           start_time: '71.7',
        //           end_time: '89.35',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7544146',
        //           music_name: '如果爱情也可以百度（DJ何鹏版）',
        //         },
        //         {
        //           music_id: '11817022',
        //           music_name: '开上我的大货车(DJ何鹏版)',
        //         },
        //         {
        //           music_id: '18866509',
        //           music_name: '中了爱情的枪',
        //         },
        //         {
        //           music_id: '11990357',
        //           music_name: '懂你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 18,
        //       play_song: {
        //         music_id: '19918502',
        //         music_name: '电话情思',
        //         play_address: 'https://static1.kaixinyf.cn/resource/72bcbd749ec74f5cb2fa1eb739230043.mp3',
        //         play_time: '259',
        //         chorus_time: {
        //           start_time: '28.98',
        //           end_time: '72.82',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '14590428',
        //           music_name: '红尘相爱不容易',
        //         },
        //         {
        //           music_id: '12893496',
        //           music_name: '花都开了你来不来 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '19918502',
        //           music_name: '电话情思',
        //         },
        //         {
        //           music_id: '6196695',
        //           music_name: '映山红 (广场舞版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 19,
        //       play_song: {
        //         music_id: '15712264',
        //         music_name: '农村夫妻苦命的鸳鸯',
        //         play_address: 'https://static1.kaixinyf.cn/resource/7ac377e986dd4b17aa66aa82f23fbb09.mp3',
        //         play_time: '258',
        //         chorus_time: {
        //           start_time: '75.23',
        //           end_time: '121.86',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '14408753',
        //           music_name: '彭筝_太想念 (Remix)',
        //         },
        //         {
        //           music_id: '23109491',
        //           music_name: '太想念（DJCandy版）',
        //         },
        //         {
        //           music_id: '15712264',
        //           music_name: '农村夫妻苦命的鸳鸯',
        //         },
        //         {
        //           music_id: '5284137',
        //           music_name: '谁在意我流下的泪',
        //         },
        //       ],
        //     },
        //     {
        //       id: 20,
        //       play_song: {
        //         music_id: '13040488',
        //         music_name: '下辈子不做女人 (伦巴版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/65fc74d4aad643cc92bdef10875912e8.mp3',
        //         play_time: '270',
        //         chorus_time: {
        //           start_time: '50.71',
        //           end_time: '120.19',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '15712264',
        //           music_name: '农村夫妻苦命的鸳鸯',
        //         },
        //         {
        //           music_id: '13040488',
        //           music_name: '下辈子不做女人 (伦巴版)',
        //         },
        //         {
        //           music_id: '1790825',
        //           music_name: '新贵妃醉酒',
        //         },
        //         {
        //           music_id: '1808421',
        //           music_name: '纳西情歌',
        //         },
        //       ],
        //     },
        //     {
        //       id: 21,
        //       play_song: {
        //         music_id: '26148163',
        //         music_name: '怎能不牵挂',
        //         play_address: 'https://static1.kaixinyf.cn/resource/lza630477f817688481251034.mp3',
        //         play_time: '273',
        //         chorus_time: {
        //           start_time: '90.28',
        //           end_time: '123.34',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '26317129',
        //           music_name: '真心的人没有几个',
        //         },
        //         {
        //           music_id: '7854748',
        //           music_name: '喝一杯最烈的酒（DJ何鹏版）',
        //         },
        //         {
        //           music_id: '26148163',
        //           music_name: '怎能不牵挂',
        //         },
        //         {
        //           music_id: '5376045',
        //           music_name: '好姑娘',
        //         },
        //       ],
        //     },
        //     {
        //       id: 22,
        //       play_song: {
        //         music_id: '15451232',
        //         music_name: '爱情调味汤',
        //         play_address: 'https://static1.kaixinyf.cn/resource/f69efdc82b384b3aa0abc07434b34d3e.mp3',
        //         play_time: '273',
        //         chorus_time: {
        //           start_time: '62.6',
        //           end_time: '96.41',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9450483',
        //           music_name: '醉梦在红尘',
        //         },
        //         {
        //           music_id: '17474349',
        //           music_name: '离家的孩子',
        //         },
        //         {
        //           music_id: '15451232',
        //           music_name: '爱情调味汤',
        //         },
        //         {
        //           music_id: '26408073',
        //           music_name: '最美的情歌就是你和我',
        //         },
        //       ],
        //     },
        //     {
        //       id: 23,
        //       play_song: {
        //         music_id: '9744422',
        //         music_name: '为了谁',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza6216f5ace4f7c013683731.mp3',
        //         play_time: '265',
        //         chorus_time: {
        //           start_time: '70.4',
        //           end_time: '110.16',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9744422',
        //           music_name: '为了谁',
        //         },
        //         {
        //           music_id: '10264955',
        //           music_name: '为你一生去守候',
        //         },
        //         {
        //           music_id: '9126926',
        //           music_name: '牧羊曲',
        //         },
        //         {
        //           music_id: '7868434',
        //           music_name: '天边的情哥哥 (DJ何鹏版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 24,
        //       play_song: {
        //         music_id: '7602547',
        //         music_name: '披着羊皮的狼',
        //         play_address: 'https://static1.kaixinyf.cn/resource/0582cd9b82a84aad85be69d32073664c.mp3',
        //         play_time: '244',
        //         chorus_time: {
        //           start_time: '108.67',
        //           end_time: '156.6',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '11324059',
        //           music_name: '你偷走了我的心',
        //         },
        //         {
        //           music_id: '18446833',
        //           music_name: '人生靠自己',
        //         },
        //         {
        //           music_id: '7602547',
        //           music_name: '披着羊皮的狼',
        //         },
        //         {
        //           music_id: '14272804',
        //           music_name: '想你不敢联系你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 25,
        //       play_song: {
        //         music_id: '19882418',
        //         music_name: '爱情这杯酒谁喝都得醉 (DJheap九天版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/f6dc36f0ab104b90901a687c16bbd0db.mp3',
        //         play_time: '305',
        //         chorus_time: {
        //           start_time: '86.38',
        //           end_time: '114.06',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '20615204',
        //           music_name: '谁是谁过客 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '21622386',
        //           music_name: '在变老的路上善待自己',
        //         },
        //         {
        //           music_id: '11324059',
        //           music_name: '你偷走了我的心',
        //         },
        //         {
        //           music_id: '19882418',
        //           music_name: '爱情这杯酒谁喝都得醉 (DJheap九天版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 26,
        //       play_song: {
        //         music_id: '26820241',
        //         music_name: '寂寞的时候点几根烟抽',
        //         play_address: 'https://static1.kaixinyf.cn/resource/4aab0ed0c7d24561afbb1559c68f07ce.mp3',
        //         play_time: '173',
        //         chorus_time: {
        //           start_time: '49.04',
        //           end_time: '161.98',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7756297',
        //           music_name: '铁血丹心(电视剧《射雕英雄传(黄日华版)》主题曲)',
        //         },
        //         {
        //           music_id: '10252684',
        //           music_name: '不要再来伤害我(电视剧《情已逝》主题曲)',
        //         },
        //         {
        //           music_id: '10510273',
        //           music_name: '风含情水含笑',
        //         },
        //         {
        //           music_id: '26820241',
        //           music_name: '寂寞的时候点几根烟抽',
        //         },
        //       ],
        //     },
        //     {
        //       id: 27,
        //       play_song: {
        //         music_id: '9816356',
        //         music_name: '不过人间(DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/8041c5f559114a9e94afd6e666d15433.mp3',
        //         play_time: '249',
        //         chorus_time: {
        //           start_time: '79.88',
        //           end_time: '105.51',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9816356',
        //           music_name: '不过人间(DJ版)',
        //         },
        //         {
        //           music_id: '1818264',
        //           music_name: '今生无缘来生再聚',
        //         },
        //         {
        //           music_id: '5234491',
        //           music_name: '暗里着迷',
        //         },
        //         {
        //           music_id: '8438968',
        //           music_name: '昨夜星辰',
        //         },
        //       ],
        //     },
        //     {
        //       id: 28,
        //       play_song: {
        //         music_id: '15374506',
        //         music_name: '姐就是女王',
        //         play_address: 'https://static1.kaixinyf.cn/resource/2340610d1c4640c78a28fcc207d8e36c.mp3',
        //         play_time: '217',
        //         chorus_time: {
        //           start_time: '51.83',
        //           end_time: '83.96',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1862628',
        //           music_name: '月亮惹的祸',
        //         },
        //         {
        //           music_id: '5234491',
        //           music_name: '暗里着迷',
        //         },
        //         {
        //           music_id: '15374506',
        //           music_name: '姐就是女王',
        //         },
        //         {
        //           music_id: '1820532',
        //           music_name: '永远放你在心底',
        //         },
        //       ],
        //     },
        //     {
        //       id: 29,
        //       play_song: {
        //         music_id: '7474851',
        //         music_name: '直击你神经，一曲入魂(抖音DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/6f3604d543164f0da5a7f2bde4ad525e.mp3',
        //         play_time: '233',
        //         chorus_time: {
        //           start_time: '57.4',
        //           end_time: '85.45',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '6882061',
        //           music_name: '甘心情愿',
        //         },
        //         {
        //           music_id: '7474851',
        //           music_name: '直击你神经，一曲入魂(抖音DJ版)',
        //         },
        //         {
        //           music_id: '1858165',
        //           music_name: '不爱我就别伤害我',
        //         },
        //         {
        //           music_id: '26572223',
        //           music_name: '三月里的小雨 (remix: 格格|Remix)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 30,
        //       play_song: {
        //         music_id: '26645314',
        //         music_name: '爱情这杯酒谁喝都得醉(中四三步踩)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/1b74e8276aa149ba9091a60140269003.mp3',
        //         play_time: '240',
        //         chorus_time: {
        //           start_time: '38.82',
        //           end_time: '63.9',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '26910181',
        //           music_name: '除了梦里再没见过你',
        //         },
        //         {
        //           music_id: '26645314',
        //           music_name: '爱情这杯酒谁喝都得醉(中四三步踩)',
        //         },
        //         {
        //           music_id: '1967448',
        //           music_name: '让一切随风',
        //         },
        //         {
        //           music_id: '4562214',
        //           music_name: '我不后悔',
        //         },
        //       ],
        //     },
        //     {
        //       id: 31,
        //       play_song: {
        //         music_id: '10769801',
        //         music_name: '老婆老婆我亲爱的老婆 (DJ何鹏版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/ab0c37c2a4f64551b34a16b1e3ea6ce0.mp3',
        //         play_time: '221',
        //         chorus_time: {
        //           start_time: '65.57',
        //           end_time: '95.48',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '15374506',
        //           music_name: '姐就是女王',
        //         },
        //         {
        //           music_id: '10769801',
        //           music_name: '老婆老婆我亲爱的老婆 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '19142667',
        //           music_name: '可可托海的牧羊人 (Remix)',
        //         },
        //         {
        //           music_id: '7234198',
        //           music_name: '轻轻的告诉你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 32,
        //       play_song: {
        //         music_id: '1905097',
        //         music_name: '醉相思',
        //         play_address: 'https://static1.kaixinyf.cn/resource/7d25807bfcfe4fe9b563e8454b8f801f.mp3',
        //         play_time: '284',
        //         chorus_time: {
        //           start_time: '72.26',
        //           end_time: '119.07',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '24612325',
        //           music_name: '三两三',
        //         },
        //         {
        //           music_id: '14691014',
        //           music_name: '情深似海',
        //         },
        //         {
        //           music_id: '1905097',
        //           music_name: '醉相思',
        //         },
        //         {
        //           music_id: '21592492',
        //           music_name: '记得我',
        //         },
        //       ],
        //     },
        //     {
        //       id: 33,
        //       play_song: {
        //         music_id: '11924937',
        //         music_name: '小芳',
        //         play_address: 'https://static1.kaixinyf.cn/resource/0238659031df4516b92aeb30c831bdc8.mp3',
        //         play_time: '273',
        //         chorus_time: {
        //           start_time: '50.53',
        //           end_time: '66.69',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '11561434',
        //           music_name: '一个人喝醉一个人流泪',
        //         },
        //         {
        //           music_id: '21592492',
        //           music_name: '记得我',
        //         },
        //         {
        //           music_id: '9466082',
        //           music_name: '叹这一生',
        //         },
        //         {
        //           music_id: '11924937',
        //           music_name: '小芳',
        //         },
        //       ],
        //     },
        //     {
        //       id: 34,
        //       play_song: {
        //         music_id: '25185464',
        //         music_name: '姐有姐的范儿（DJ默涵版）',
        //         play_address: 'https://static1.kaixinyf.cn/resource/5471a476426b4f349392608595a227ad.mp3',
        //         play_time: '219',
        //         chorus_time: {
        //           start_time: '11.7',
        //           end_time: '27.31',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '24766566',
        //           music_name: '包容',
        //         },
        //         {
        //           music_id: '25185464',
        //           music_name: '姐有姐的范儿（DJ默涵版）',
        //         },
        //         {
        //           music_id: '7474851',
        //           music_name: '直击你神经，一曲入魂(抖音DJ版)',
        //         },
        //         {
        //           music_id: '26922698',
        //           music_name: '我是真的好想你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 35,
        //       play_song: {
        //         music_id: '6946161',
        //         music_name: '屠杀',
        //         play_address: 'https://static1.kaixinyf.cn/resource/99f502e949334cd6a8a1765ddaf63ccb.mp3',
        //         play_time: '255',
        //         chorus_time: {
        //           start_time: '65.02',
        //           end_time: '97.15',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '6946161',
        //           music_name: '屠杀',
        //         },
        //         {
        //           music_id: '13096994',
        //           music_name: '女人的眼泪男人的酒杯',
        //         },
        //         {
        //           music_id: '1936481',
        //           music_name: '叹情缘',
        //         },
        //         {
        //           music_id: '24428064',
        //           music_name: '酒醉后的心碎 (DJ默涵版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 36,
        //       play_song: {
        //         music_id: '1862389',
        //         music_name: '红枣树 (DJ何鹏版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/cd918b5cabbf494fa65e7a26c60d2c0d.mp3',
        //         play_time: '248',
        //         chorus_time: {
        //           start_time: '61.86',
        //           end_time: '91.77',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1865132',
        //           music_name: '一晃就老了 (DJ版)',
        //         },
        //         {
        //           music_id: '11914223',
        //           music_name: '你是我的人 (DJ版)',
        //         },
        //         {
        //           music_id: '9512416',
        //           music_name: '偏偏喜欢你',
        //         },
        //         {
        //           music_id: '1862389',
        //           music_name: '红枣树 (DJ何鹏版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 37,
        //       play_song: {
        //         music_id: '4889151',
        //         music_name: '桃花运',
        //         play_address: 'https://static1.kaixinyf.cn/resource/94b6f52839634107880b6c9d62cfbc1e.mp3',
        //         play_time: '244',
        //         chorus_time: {
        //           start_time: '41.05',
        //           end_time: '72.45',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '22329675',
        //           music_name: '向天再借五百年',
        //         },
        //         {
        //           music_id: '4889151',
        //           music_name: '桃花运',
        //         },
        //         {
        //           music_id: '2263115',
        //           music_name: '没房没车没老婆',
        //         },
        //         {
        //           music_id: '11914223',
        //           music_name: '你是我的人 (DJ版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 38,
        //       play_song: {
        //         music_id: '7234198',
        //         music_name: '轻轻的告诉你',
        //         play_address: 'https://static1.kaixinyf.cn/resource/59ba2b9a436844f78342ae91be86ad51.mp3',
        //         play_time: '282',
        //         chorus_time: {
        //           start_time: '74.68',
        //           end_time: '113.13',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '2230742',
        //           music_name: '醉 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '9692106',
        //           music_name: '白头到老不分离',
        //         },
        //         {
        //           music_id: '7234198',
        //           music_name: '轻轻的告诉你',
        //         },
        //         {
        //           music_id: '10431372',
        //           music_name: '男人的苦女人不清楚',
        //         },
        //       ],
        //     },
        //     {
        //       id: 39,
        //       play_song: {
        //         music_id: '1914089',
        //         music_name: '喝着啤酒唱情歌',
        //         play_address: 'https://static1.kaixinyf.cn/resource/cda037595b6d48e7a3991fc80322809a.mp3',
        //         play_time: '217',
        //         chorus_time: {
        //           start_time: '55.91',
        //           end_time: '93.25',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '20703292',
        //           music_name: '因为有你 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '10431372',
        //           music_name: '男人的苦女人不清楚',
        //         },
        //         {
        //           music_id: '1914089',
        //           music_name: '喝着啤酒唱情歌',
        //         },
        //         {
        //           music_id: '1822949',
        //           music_name: '不是打工不想家',
        //         },
        //       ],
        //     },
        //     {
        //       id: 40,
        //       play_song: {
        //         music_id: '10047045',
        //         music_name: '没有人能够把你代替',
        //         play_address: 'https://static1.kaixinyf.cn/resource/a9323485572b4f7288ecca2cac37e835.mp3',
        //         play_time: '265',
        //         chorus_time: {
        //           start_time: '75.98',
        //           end_time: '127.8',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7805057',
        //           music_name: '老公赚钱老婆花',
        //         },
        //         {
        //           music_id: '17863184',
        //           music_name: '大海',
        //         },
        //         {
        //           music_id: '7786026',
        //           music_name: '对面的小姐姐',
        //         },
        //         {
        //           music_id: '10047045',
        //           music_name: '没有人能够把你代替',
        //         },
        //       ],
        //     },
        //     {
        //       id: 41,
        //       play_song: {
        //         music_id: '25948101',
        //         music_name: '旧梦 (深情版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/0b4247e81ff34ddbadd525b203aea338.mp3',
        //         play_time: '73',
        //         chorus_time: {
        //           start_time: '32.88',
        //           end_time: '72.63',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '8495131',
        //           music_name: '老父亲',
        //         },
        //         {
        //           music_id: '18022357',
        //           music_name: '十送红军',
        //         },
        //         {
        //           music_id: '10769801',
        //           music_name: '老婆老婆我亲爱的老婆 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '25948101',
        //           music_name: '旧梦 (深情版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 42,
        //       play_song: {
        //         music_id: '20278754',
        //         music_name: '我爱的人你别碰 (DJ默涵版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e08ddd1172544cc9817cadb58b9f4f4f.mp3',
        //         play_time: '227',
        //         chorus_time: {
        //           start_time: '1.67',
        //           end_time: '97.34',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '5062996',
        //           music_name: '藕断丝连',
        //         },
        //         {
        //           music_id: '15266458',
        //           music_name: '我是否也在你心中',
        //         },
        //         {
        //           music_id: '20278754',
        //           music_name: '我爱的人你别碰 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '2939214',
        //           music_name: '老公老公我爱你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 43,
        //       play_song: {
        //         music_id: '14867138',
        //         music_name: '今夜的你又在和谁约会',
        //         play_address: 'https://static1.kaixinyf.cn/resource/49299ed6ab8049aea0cb0ca3e4134014.mp3',
        //         play_time: '302',
        //         chorus_time: {
        //           start_time: '93.62',
        //           end_time: '160.12',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '14514501',
        //           music_name: '老虎不发威你当我病猫',
        //         },
        //         {
        //           music_id: '10491226',
        //           music_name: '爱情醉醉醉DJ',
        //         },
        //         {
        //           music_id: '27436428',
        //           music_name: '无处容身 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '14867138',
        //           music_name: '今夜的你又在和谁约会',
        //         },
        //       ],
        //     },
        //     {
        //       id: 44,
        //       play_song: {
        //         music_id: '7640443',
        //         music_name: '繁星点点 (DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/1c737610c6514f5bb949f2e9410a4421.mp3',
        //         play_time: '392',
        //         chorus_time: {
        //           start_time: '119.44',
        //           end_time: '164.77',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '10440883',
        //           music_name: '滚滚红尘',
        //         },
        //         {
        //           music_id: '7640443',
        //           music_name: '繁星点点 (DJ版)',
        //         },
        //         {
        //           music_id: '14585104',
        //           music_name: '人生好无奈 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '26924883',
        //           music_name: '妹妹的牵挂',
        //         },
        //       ],
        //     },
        //     {
        //       id: 45,
        //       play_song: {
        //         music_id: '10824608',
        //         music_name: '后海酒吧 (Remix: 唐玉德)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e18ab39068ae4a5fa6b49f9d06b1e831.mp3',
        //         play_time: '217',
        //         chorus_time: {
        //           start_time: '56.29',
        //           end_time: '93.81',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1978757',
        //           music_name: '你牛什么牛',
        //         },
        //         {
        //           music_id: '11368395',
        //           music_name: '雨花石 (DJ版)',
        //         },
        //         {
        //           music_id: '10824608',
        //           music_name: '后海酒吧 (Remix: 唐玉德)',
        //         },
        //         {
        //           music_id: '20348849',
        //           music_name: '冰冷的酒悲伤的泪（DJ默涵）',
        //         },
        //       ],
        //     },
        //     {
        //       id: 46,
        //       play_song: {
        //         music_id: '1818264',
        //         music_name: '今生无缘来生再聚',
        //         play_address: 'https://static1.kaixinyf.cn/resource/9d0be686c9494d7cbece105c45e284dc.mp3',
        //         play_time: '252',
        //         chorus_time: {
        //           start_time: '64.46',
        //           end_time: '107.18',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '3218052',
        //           music_name: '无结局的爱',
        //         },
        //         {
        //           music_id: '1818264',
        //           music_name: '今生无缘来生再聚',
        //         },
        //         {
        //           music_id: '2016276',
        //           music_name: '下辈子不做女人',
        //         },
        //         {
        //           music_id: '10448681',
        //           music_name: '特别的爱给特别的你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 47,
        //       play_song: {
        //         music_id: '1856437',
        //         music_name: '白狐(精彩高潮版)',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza62147b7b1e35d323584650.mp3',
        //         play_time: '355',
        //         chorus_time: {
        //           start_time: '105.7',
        //           end_time: '155.11',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '11942416',
        //           music_name: '受了伤的狼',
        //         },
        //         {
        //           music_id: '1856437',
        //           music_name: '白狐(精彩高潮版)',
        //         },
        //         {
        //           music_id: '1914122',
        //           music_name: '我曾用心的爱着你',
        //         },
        //         {
        //           music_id: '2016276',
        //           music_name: '下辈子不做女人',
        //         },
        //       ],
        //     },
        //     {
        //       id: 48,
        //       play_song: {
        //         music_id: '11347694',
        //         music_name: '爱情路上风雨多',
        //         play_address: 'https://static1.kaixinyf.cn/resource/50ee6f500fcd44ff8a408f1a7703f54d.mp3',
        //         play_time: '331',
        //         chorus_time: {
        //           start_time: '46.63',
        //           end_time: '76.53',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '5951725',
        //           music_name: '对面的小姐姐 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '11347694',
        //           music_name: '爱情路上风雨多',
        //         },
        //         {
        //           music_id: '27666061',
        //           music_name: '心飘飘（伊然女声版）',
        //         },
        //         {
        //           music_id: '1902571',
        //           music_name: '得到你的人却得不到你的心',
        //         },
        //       ],
        //     },
        //     {
        //       id: 49,
        //       play_song: {
        //         music_id: '9542298',
        //         music_name: '金莲的爱',
        //         play_address: 'https://static1.kaixinyf.cn/resource/d9f8bfe77f744ef9a75cb8a0d6549410.mp3',
        //         play_time: '260',
        //         chorus_time: {
        //           start_time: '67.99',
        //           end_time: '115.36',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '10999062',
        //           music_name: '拈花一笑（DJ何鹏版）',
        //         },
        //         {
        //           music_id: '19867196',
        //           music_name: '男人就是累(DJ版)',
        //         },
        //         {
        //           music_id: '10394551',
        //           music_name: '梦见妈妈',
        //         },
        //         {
        //           music_id: '9542298',
        //           music_name: '金莲的爱',
        //         },
        //       ],
        //     },
        //     {
        //       id: 50,
        //       play_song: {
        //         music_id: '1820532',
        //         music_name: '永远放你在心底',
        //         play_address: 'https://static1.kaixinyf.cn/resource/5a51a3bb0c3f4bf3816818083744c277.mp3',
        //         play_time: '238',
        //         chorus_time: {
        //           start_time: '50.16',
        //           end_time: '85.26',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9561137',
        //           music_name: '我为谁流泪,我为谁伤悲,是你的无情痛彻我心扉。#伤感#情歌',
        //         },
        //         {
        //           music_id: '5231163',
        //           music_name: '一个家一个妈 (DJ版)',
        //         },
        //         {
        //           music_id: '1840454',
        //           music_name: '在心里从此永远有个你',
        //         },
        //         {
        //           music_id: '1820532',
        //           music_name: '永远放你在心底',
        //         },
        //       ],
        //     },
        //     {
        //       id: 51,
        //       play_song: {
        //         music_id: '10209431',
        //         music_name: '你牛什么牛 (DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/80ef888d091845fe904b948243ec2cdf.mp3',
        //         play_time: '253',
        //         chorus_time: {
        //           start_time: '69.85',
        //           end_time: '97.34',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1882771',
        //           music_name: '半壶纱',
        //         },
        //         {
        //           music_id: '10209431',
        //           music_name: '你牛什么牛 (DJ版)',
        //         },
        //         {
        //           music_id: '1840454',
        //           music_name: '在心里从此永远有个你',
        //         },
        //         {
        //           music_id: '23030914',
        //           music_name: '太想念 (DJR7版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 52,
        //       play_song: {
        //         music_id: '2136423',
        //         music_name: '男人苦男人累',
        //         play_address: 'https://static1.kaixinyf.cn/resource/fd71165ce46f42d1bc4e960e688f8dff.mp3',
        //         play_time: '228',
        //         chorus_time: {
        //           start_time: '52.76',
        //           end_time: '88.05',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '2136423',
        //           music_name: '男人苦男人累',
        //         },
        //         {
        //           music_id: '21464072',
        //           music_name: '我已不是当年的我',
        //         },
        //         {
        //           music_id: '2970595',
        //           music_name: '挣点小钱',
        //         },
        //         {
        //           music_id: '18392596',
        //           music_name: '下定决心忘记你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 53,
        //       play_song: {
        //         music_id: '6084744',
        //         music_name: '我的好兄弟',
        //         play_address: 'https://static1.kaixinyf.cn/resource/dd3df6d5ad3747938e0eab75d9e8ce0a.mp3',
        //         play_time: '278',
        //         chorus_time: {
        //           start_time: '86.56',
        //           end_time: '112.76',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1930313',
        //           music_name: '你不来我不老',
        //         },
        //         {
        //           music_id: '1854665',
        //           music_name: '青青河边草(电视剧《青青河边草》主题曲)',
        //         },
        //         {
        //           music_id: '4770599',
        //           music_name: '拥抱你的离去',
        //         },
        //         {
        //           music_id: '6084744',
        //           music_name: '我的好兄弟',
        //         },
        //       ],
        //     },
        //     {
        //       id: 54,
        //       play_song: {
        //         music_id: '8518606',
        //         music_name: '幺妹住在十三寨',
        //         play_address: 'https://static1.kaixinyf.cn/resource/6379bb7566ec4f1eb16741c0856c1bf0.mp3',
        //         play_time: '274',
        //         chorus_time: {
        //           start_time: '93.25',
        //           end_time: '109.78',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '4770599',
        //           music_name: '拥抱你的离去',
        //         },
        //         {
        //           music_id: '24705809',
        //           music_name: '拥抱着你离去 (DJ版)',
        //         },
        //         {
        //           music_id: '1790827',
        //           music_name: '没有你陪伴真的好孤单',
        //         },
        //         {
        //           music_id: '8518606',
        //           music_name: '幺妹住在十三寨',
        //         },
        //       ],
        //     },
        //     {
        //       id: 55,
        //       play_song: {
        //         music_id: '1904573',
        //         music_name: '一个人挺好',
        //         play_address: 'https://static1.kaixinyf.cn/resource/1afdaae9bfd3449abc41236871579fe6.mp3',
        //         play_time: '270',
        //         chorus_time: {
        //           start_time: '74.86',
        //           end_time: '123.53',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '10774996',
        //           music_name: '我在想你你在哪里',
        //         },
        //         {
        //           music_id: '11924937',
        //           music_name: '小芳',
        //         },
        //         {
        //           music_id: '1904573',
        //           music_name: '一个人挺好',
        //         },
        //         {
        //           music_id: '14476536',
        //           music_name: '姐就这么拽',
        //         },
        //       ],
        //     },
        //     {
        //       id: 56,
        //       play_song: {
        //         music_id: '8934685',
        //         music_name: '吻别',
        //         play_address: 'https://static1.kaixinyf.cn/resource/8a448da1d0564886ae9a9d3932b2dd9a.mp3',
        //         play_time: '304',
        //         chorus_time: {
        //           start_time: '98.08',
        //           end_time: '117.21',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '19872784',
        //           music_name: '放不下的情缘 (DJ劲爆版)',
        //         },
        //         {
        //           music_id: '11966121',
        //           music_name: '杯酒敬爹娘 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '8934685',
        //           music_name: '吻别',
        //         },
        //         {
        //           music_id: '21556385',
        //           music_name: '大美妞 (DJ何鹏版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 57,
        //       play_song: {
        //         music_id: '16498031',
        //         music_name: '三十年后寡妇多',
        //         play_address: 'https://static1.kaixinyf.cn/resource/2ad4fd8f723542aeb78ae185e5efd71a.mp3',
        //         play_time: '202',
        //         chorus_time: {
        //           start_time: '32.88',
        //           end_time: '61.86',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '13875065',
        //           music_name: '红颜知己',
        //         },
        //         {
        //           music_id: '21556385',
        //           music_name: '大美妞 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '12125206',
        //           music_name: '杜十娘',
        //         },
        //         {
        //           music_id: '16498031',
        //           music_name: '三十年后寡妇多',
        //         },
        //       ],
        //     },
        //     {
        //       id: 58,
        //       play_song: {
        //         music_id: '18562304',
        //         music_name: '窗外 (重制版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/b490aa13fa834f6ebd4055630dc1d2b1.mp3',
        //         play_time: '225',
        //         chorus_time: {
        //           start_time: '65.94',
        //           end_time: '102.54',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '11012314',
        //           music_name: '妹妹的心头肉',
        //         },
        //         {
        //           music_id: '18113137',
        //           music_name: '驼铃',
        //         },
        //         {
        //           music_id: '18562304',
        //           music_name: '窗外 (重制版)',
        //         },
        //         {
        //           music_id: '19521589',
        //           music_name: '你还是从前的你吗',
        //         },
        //       ],
        //     },
        //     {
        //       id: 59,
        //       play_song: {
        //         music_id: '1790536',
        //         music_name: '清明上河图',
        //         play_address: 'https://static1.kaixinyf.cn/resource/ugc5de1d93be82ed397021688.mp3',
        //         play_time: '273',
        //         chorus_time: {
        //           start_time: '87.86',
        //           end_time: '117.21',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1790536',
        //           music_name: '清明上河图',
        //         },
        //         {
        //           music_id: '1940753',
        //           music_name: '相思的债',
        //         },
        //         {
        //           music_id: '5034661',
        //           music_name: '歌在飞【8d环绕】.m4a (DJ版)',
        //         },
        //         {
        //           music_id: '8342801',
        //           music_name: '万爱千恩',
        //         },
        //       ],
        //     },
        //     {
        //       id: 60,
        //       play_song: {
        //         music_id: '13540212',
        //         music_name: '爱情路上风雨',
        //         play_address: 'https://static1.kaixinyf.cn/resource/a3012bf3bf604e8b985e8a99bea8ffec.mp3',
        //         play_time: '331',
        //         chorus_time: {
        //           start_time: '46.63',
        //           end_time: '76.53',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '19750855',
        //           music_name: '父母的恩情还不够',
        //         },
        //         {
        //           music_id: '13540212',
        //           music_name: '爱情路上风雨',
        //         },
        //         {
        //           music_id: '24068277',
        //           music_name: '踏心而过 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '18562304',
        //           music_name: '窗外 (重制版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 61,
        //       play_song: {
        //         music_id: '10431372',
        //         music_name: '男人的苦女人不清楚',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza6221b0f93f1f0724156899.mp3',
        //         play_time: '250',
        //         chorus_time: {
        //           start_time: '60.93',
        //           end_time: '100.12',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '10431372',
        //           music_name: '男人的苦女人不清楚',
        //         },
        //         {
        //           music_id: '7941156',
        //           music_name: '九月九的酒',
        //         },
        //         {
        //           music_id: '6755729',
        //           music_name: '你的万水千山(DJ沈念版)',
        //         },
        //         {
        //           music_id: '1842162',
        //           music_name: '苏幕遮',
        //         },
        //       ],
        //     },
        //     {
        //       id: 62,
        //       play_song: {
        //         music_id: '2100775',
        //         music_name: '心雨',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e28b990a96794ddc9e264cfe1fecdfe2.mp3',
        //         play_time: '275',
        //         chorus_time: {
        //           start_time: '73.19',
        //           end_time: '122.23',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '8855579',
        //           music_name: '小花 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '2100775',
        //           music_name: '心雨',
        //         },
        //         {
        //           music_id: '1842162',
        //           music_name: '苏幕遮',
        //         },
        //         {
        //           music_id: '1914973',
        //           music_name: '两只蝴蝶',
        //         },
        //       ],
        //     },
        //     {
        //       id: 63,
        //       play_song: {
        //         music_id: '24917143',
        //         music_name: '烟雨人间 (DJ女声版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/13524d51fda74e0a98c7ac20cc348dd4.mp3',
        //         play_time: '285',
        //         chorus_time: {
        //           start_time: '2.79',
        //           end_time: '30.46',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '24917143',
        //           music_name: '烟雨人间 (DJ女声版)',
        //         },
        //         {
        //           music_id: '12696029',
        //           music_name: '醉红妆(DJ伟然版)',
        //         },
        //         {
        //           music_id: '7640492',
        //           music_name: '站在草原望北京',
        //         },
        //         {
        //           music_id: '7640443',
        //           music_name: '繁星点点 (DJ版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 64,
        //       play_song: {
        //         music_id: '7190103',
        //         music_name: '情难枕',
        //         play_address: 'https://static1.kaixinyf.cn/resource/858462885bd44e6f85623a53fafda7d2.mp3',
        //         play_time: '271',
        //         chorus_time: {
        //           start_time: '36.78',
        //           end_time: '107.18',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7190103',
        //           music_name: '情难枕',
        //         },
        //         {
        //           music_id: '21502862',
        //           music_name: '你不知道的事',
        //         },
        //         {
        //           music_id: '9744422',
        //           music_name: '为了谁',
        //         },
        //         {
        //           music_id: '23058946',
        //           music_name: '太想念 (remix: 豆包) (Remix)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 65,
        //       play_song: {
        //         music_id: '22710158',
        //         music_name: '走在人生的路口 (DJ默涵版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/d0bea073865f42cea15dff610bc7ba21.mp3',
        //         play_time: '225',
        //         chorus_time: {
        //           start_time: '66.13',
        //           end_time: '108.86',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1810908',
        //           music_name: '流泪的情人 (DJ版)',
        //         },
        //         {
        //           music_id: '22710158',
        //           music_name: '走在人生的路口 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '26580982',
        //           music_name: '做不了女王照样狂 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '8001593',
        //           music_name: '对面的小姐姐（DJ何鹏版）',
        //         },
        //       ],
        //     },
        //     {
        //       id: 66,
        //       play_song: {
        //         music_id: '7805057',
        //         music_name: '老公赚钱老婆花',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza627dcc26b9db1752921605.mp3',
        //         play_time: '223',
        //         chorus_time: {
        //           start_time: '38.27',
        //           end_time: '76.35',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7805057',
        //           music_name: '老公赚钱老婆花',
        //         },
        //         {
        //           music_id: '12114359',
        //           music_name: '久醉风雨情 (DJ晓朋版)',
        //         },
        //         {
        //           music_id: '20384286',
        //           music_name: '康定情歌',
        //         },
        //         {
        //           music_id: '24778832',
        //           music_name: '没你也一样可以',
        //         },
        //       ],
        //     },
        //     {
        //       id: 67,
        //       play_song: {
        //         music_id: '13264099',
        //         music_name: '北郊',
        //         play_address: 'https://static1.kaixinyf.cn/resource/b450075305a443bd9fa4afb247fa1b2f.mp3',
        //         play_time: '285',
        //         chorus_time: {
        //           start_time: '59.63',
        //           end_time: '88.98',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '12114359',
        //           music_name: '久醉风雨情 (DJ晓朋版)',
        //         },
        //         {
        //           music_id: '19140792',
        //           music_name: '在路上 (DJ版)',
        //         },
        //         {
        //           music_id: '13264099',
        //           music_name: '北郊',
        //         },
        //         {
        //           music_id: '11020402',
        //           music_name: '心碎的蝴蝶(DJ何鹏版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 68,
        //       play_song: {
        //         music_id: '10491226',
        //         music_name: '爱情醉醉醉DJ',
        //         play_address: 'https://static1.kaixinyf.cn/resource/40438e2326714aaf8d903c8873792fdb.mp3',
        //         play_time: '214',
        //         chorus_time: {
        //           start_time: '60.37',
        //           end_time: '101.05',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '25180353',
        //           music_name: '三生石下 (3D环绕版)',
        //         },
        //         {
        //           music_id: '16498031',
        //           music_name: '三十年后寡妇多',
        //         },
        //         {
        //           music_id: '15721487',
        //           music_name: '我好想和你在一起（DJ）',
        //         },
        //         {
        //           music_id: '10491226',
        //           music_name: '爱情醉醉醉DJ',
        //         },
        //       ],
        //     },
        //     {
        //       id: 69,
        //       play_song: {
        //         music_id: '8342801',
        //         music_name: '万爱千恩',
        //         play_address: 'https://static1.kaixinyf.cn/resource/834ad58d948c47148e3283c65311d078.mp3',
        //         play_time: '322',
        //         chorus_time: {
        //           start_time: '112.2',
        //           end_time: '142.66',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '4889151',
        //           music_name: '桃花运',
        //         },
        //         {
        //           music_id: '21251341',
        //           music_name: '殇雪 (改编版原唱: 云菲菲|DJ版)',
        //         },
        //         {
        //           music_id: '7959198',
        //           music_name: '爱到流泪谁的罪 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '8342801',
        //           music_name: '万爱千恩',
        //         },
        //       ],
        //     },
        //     {
        //       id: 70,
        //       play_song: {
        //         music_id: '16838201',
        //         music_name: '想你想断肠',
        //         play_address: 'https://static1.kaixinyf.cn/resource/b7790385fe464ef9b649a520361ad6ff.mp3',
        //         play_time: '254',
        //         chorus_time: {
        //           start_time: '65.2',
        //           end_time: '104.95',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '8280422',
        //           music_name: '情陷 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '16838201',
        //           music_name: '想你想断肠',
        //         },
        //         {
        //           music_id: '7959198',
        //           music_name: '爱到流泪谁的罪 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '10562572',
        //           music_name: '出门打工不容易',
        //         },
        //       ],
        //     },
        //     {
        //       id: 71,
        //       play_song: {
        //         music_id: '9512416',
        //         music_name: '偏偏喜欢你',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza6221b995e5f08178479899.mp3',
        //         play_time: '202',
        //         chorus_time: {
        //           start_time: '74.12',
        //           end_time: '86.19',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9512416',
        //           music_name: '偏偏喜欢你',
        //         },
        //         {
        //           music_id: '15451232',
        //           music_name: '爱情调味汤',
        //         },
        //         {
        //           music_id: '23994314',
        //           music_name: '我能走九十九步去爱你',
        //         },
        //         {
        //           music_id: '15458152',
        //           music_name: '伤心的雪花 (温柔女声版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 72,
        //       play_song: {
        //         music_id: '10440883',
        //         music_name: '滚滚红尘',
        //         play_address: 'https://static1.kaixinyf.cn/resource/372c8189bfbd4ef99057604e3cd1936a.mp3',
        //         play_time: '191',
        //         chorus_time: {
        //           start_time: '50.9',
        //           end_time: '68.55',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '10440883',
        //           music_name: '滚滚红尘',
        //         },
        //         {
        //           music_id: '16838201',
        //           music_name: '想你想断肠',
        //         },
        //         {
        //           music_id: '20278754',
        //           music_name: '我爱的人你别碰 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '20553145',
        //           music_name: '花香醉在我心间 (DJ何鹏 Remix)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 73,
        //       play_song: {
        //         music_id: '16908043',
        //         music_name: '求佛',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e19882de6232496595c817fd038a224e.mp3',
        //         play_time: '277',
        //         chorus_time: {
        //           start_time: '83.96',
        //           end_time: '116.1',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '16908043',
        //           music_name: '求佛',
        //         },
        //         {
        //           music_id: '25900608',
        //           music_name: '情难断 (DJcandy Mix广场舞版)',
        //         },
        //         {
        //           music_id: '2118832',
        //           music_name: '真的好想你 (慢四版)',
        //         },
        //         {
        //           music_id: '1904573',
        //           music_name: '一个人挺好',
        //         },
        //       ],
        //     },
        //     {
        //       id: 74,
        //       play_song: {
        //         music_id: '20553145',
        //         music_name: '花香醉在我心间 (DJ何鹏 Remix)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/a1ab39c4f29a464cb04045274eafa5e7.mp3',
        //         play_time: '260',
        //         chorus_time: {
        //           start_time: '60',
        //           end_time: '97.9',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1970229',
        //           music_name: '又见山里红',
        //         },
        //         {
        //           music_id: '18363057',
        //           music_name: '滚滚长江东逝水',
        //         },
        //         {
        //           music_id: '19388907',
        //           music_name: '纤夫的爱',
        //         },
        //         {
        //           music_id: '20553145',
        //           music_name: '花香醉在我心间 (DJ何鹏 Remix)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 75,
        //       play_song: {
        //         music_id: '21502862',
        //         music_name: '你不知道的事',
        //         play_address: 'https://static1.kaixinyf.cn/resource/010c41aaf91445b3a9055f935fa1d8a6.mp3',
        //         play_time: '274',
        //         chorus_time: {
        //           start_time: '62.42',
        //           end_time: '107.37',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '21502862',
        //           music_name: '你不知道的事',
        //         },
        //         {
        //           music_id: '18866509',
        //           music_name: '中了爱情的枪',
        //         },
        //         {
        //           music_id: '7812991',
        //           music_name: '做你的爱人',
        //         },
        //         {
        //           music_id: '1932064',
        //           music_name: '错错错',
        //         },
        //       ],
        //     },
        //     {
        //       id: 76,
        //       play_song: {
        //         music_id: '17928652',
        //         music_name: '真的好想你',
        //         play_address: 'https://static1.kaixinyf.cn/resource/9f2989e666ef4c49aff837fb0546b84d.mp3',
        //         play_time: '263',
        //         chorus_time: {
        //           start_time: '84.89',
        //           end_time: '124.27',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '5172166',
        //           music_name: '打工的妹妹',
        //         },
        //         {
        //           music_id: '7334834',
        //           music_name: '花桥流水 (合唱)',
        //         },
        //         {
        //           music_id: '18866509',
        //           music_name: '中了爱情的枪',
        //         },
        //         {
        //           music_id: '17928652',
        //           music_name: '真的好想你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 77,
        //       play_song: {
        //         music_id: '1854665',
        //         music_name: '青青河边草(电视剧《青青河边草》主题曲)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/530047bf698643a7bd8f92069515315c.mp3',
        //         play_time: '280',
        //         chorus_time: {
        //           start_time: '46.81',
        //           end_time: '93.07',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '26543707',
        //           music_name: '娶了手机嫁了寂寞',
        //         },
        //         {
        //           music_id: '10416655',
        //           music_name: '夜夜夜漫长',
        //         },
        //         {
        //           music_id: '1854665',
        //           music_name: '青青河边草(电视剧《青青河边草》主题曲)',
        //         },
        //         {
        //           music_id: '9133613',
        //           music_name: '红唇',
        //         },
        //       ],
        //     },
        //     {
        //       id: 78,
        //       play_song: {
        //         music_id: '24772829',
        //         music_name: '没有你的夜晚好凄凉 (DJ默涵版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/a288a8e1ffd1473f90b8806043df824d.mp3',
        //         play_time: '253',
        //         chorus_time: {
        //           start_time: '63.16',
        //           end_time: '102.54',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '24772829',
        //           music_name: '没有你的夜晚好凄凉 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '26543707',
        //           music_name: '娶了手机嫁了寂寞',
        //         },
        //         {
        //           music_id: '13608691',
        //           music_name: '让我一生来爱你',
        //         },
        //         {
        //           music_id: '16906657',
        //           music_name: '无情的人犯下无情的罪 (DJ默涵版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 79,
        //       play_song: {
        //         music_id: '12125206',
        //         music_name: '杜十娘',
        //         play_address: 'https://static1.kaixinyf.cn/resource/b67354d1f13a4212be5b51fc6b764edb.mp3',
        //         play_time: '308',
        //         chorus_time: {
        //           start_time: '117.77',
        //           end_time: '136.72',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '17148797',
        //           music_name: '你的眼角流着我的泪DJ',
        //         },
        //         {
        //           music_id: '12125206',
        //           music_name: '杜十娘',
        //         },
        //         {
        //           music_id: '25948101',
        //           music_name: '旧梦 (深情版)',
        //         },
        //         {
        //           music_id: '6802885',
        //           music_name: '梦中把你等候 (DJ版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 80,
        //       play_song: {
        //         music_id: '6802885',
        //         music_name: '梦中把你等候 (DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/49c5416d76a5484298a3c979d66e2ca5.mp3',
        //         play_time: '228',
        //         chorus_time: {
        //           start_time: '66.69',
        //           end_time: '98.27',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1856437',
        //           music_name: '白狐(精彩高潮版)',
        //         },
        //         {
        //           music_id: '17928652',
        //           music_name: '真的好想你',
        //         },
        //         {
        //           music_id: '11379482',
        //           music_name: '追梦人',
        //         },
        //         {
        //           music_id: '6802885',
        //           music_name: '梦中把你等候 (DJ版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 81,
        //       play_song: {
        //         music_id: '11914223',
        //         music_name: '你是我的人 (DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/0d5fb714ec9a4aa589a96d803c781dab.mp3',
        //         play_time: '197',
        //         chorus_time: {
        //           start_time: '31.21',
        //           end_time: '60.37',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '11914223',
        //           music_name: '你是我的人 (DJ版)',
        //         },
        //         {
        //           music_id: '12430524',
        //           music_name: '片片相思赋予谁',
        //         },
        //         {
        //           music_id: '5500816',
        //           music_name: '九妹',
        //         },
        //         {
        //           music_id: '1894547',
        //           music_name: '辞九门回忆',
        //         },
        //       ],
        //     },
        //     {
        //       id: 82,
        //       play_song: {
        //         music_id: '18388582',
        //         music_name: '最炫民族风 (Live)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/263a65de96ad4f22a19bda9d640f791f.mp3',
        //         play_time: '286',
        //         chorus_time: {
        //           start_time: '56.84',
        //           end_time: '98.27',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '18388582',
        //           music_name: '最炫民族风 (Live)',
        //         },
        //         {
        //           music_id: '20884737',
        //           music_name: '等你等到白了头',
        //         },
        //         {
        //           music_id: '4857406',
        //           music_name: '舞女泪',
        //         },
        //         {
        //           music_id: '9384011',
        //           music_name: '青藏高原',
        //         },
        //       ],
        //     },
        //     {
        //       id: 83,
        //       play_song: {
        //         music_id: '10252684',
        //         music_name: '不要再来伤害我(电视剧《情已逝》主题曲)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/6f18b24ead074aeaabcaae61a50ee332.mp3',
        //         play_time: '322',
        //         chorus_time: {
        //           start_time: '68.36',
        //           end_time: '103.65',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '8964923',
        //           music_name: '潇洒走一回 (Live)',
        //         },
        //         {
        //           music_id: '10252684',
        //           music_name: '不要再来伤害我(电视剧《情已逝》主题曲)',
        //         },
        //         {
        //           music_id: '1875332',
        //           music_name: '伤了心的女人怎么了',
        //         },
        //         {
        //           music_id: '10914574',
        //           music_name: '九九女儿红',
        //         },
        //       ],
        //     },
        //     {
        //       id: 84,
        //       play_song: {
        //         music_id: '20884737',
        //         music_name: '等你等到白了头',
        //         play_address: 'https://static1.kaixinyf.cn/resource/4ffb52d74daa4e1b85a0b0d9dc5e4d27.mp3',
        //         play_time: '296',
        //         chorus_time: {
        //           start_time: '76.72',
        //           end_time: '124.46',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '26468387',
        //           music_name: '心里的苦谁知道 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '26820241',
        //           music_name: '寂寞的时候点几根烟抽',
        //         },
        //         {
        //           music_id: '14092781',
        //           music_name: '苏谭谭-叹这一生 (DJ旗仔2020Remix慢四独家版)',
        //         },
        //         {
        //           music_id: '20884737',
        //           music_name: '等你等到白了头',
        //         },
        //       ],
        //     },
        //     {
        //       id: 85,
        //       play_song: {
        //         music_id: '15097152',
        //         music_name: '有钱才有缘 (DJ何鹏版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/be2043b602584f059d3958e7b31d2251.mp3',
        //         play_time: '215',
        //         chorus_time: {
        //           start_time: '47.93',
        //           end_time: '79.13',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '11347694',
        //           music_name: '爱情路上风雨多',
        //         },
        //         {
        //           music_id: '15097152',
        //           music_name: '有钱才有缘 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '13540212',
        //           music_name: '爱情路上风雨',
        //         },
        //         {
        //           music_id: '9087402',
        //           music_name: '负心的你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 86,
        //       play_song: {
        //         music_id: '11324059',
        //         music_name: '你偷走了我的心',
        //         play_address: 'https://static1.kaixinyf.cn/resource/c2bb3e344e2a4c8eb6d02d131d1b807a.mp3',
        //         play_time: '200',
        //         chorus_time: {
        //           start_time: '47.74',
        //           end_time: '81.18',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '18488962',
        //           music_name: '心相印手牵手',
        //         },
        //         {
        //           music_id: '11324059',
        //           music_name: '你偷走了我的心',
        //         },
        //         {
        //           music_id: '13040488',
        //           music_name: '下辈子不做女人 (伦巴版)',
        //         },
        //         {
        //           music_id: '10770132',
        //           music_name: '把悲伤留给自己 (经典怀旧版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 87,
        //       play_song: {
        //         music_id: '1916314',
        //         music_name: '那一夜',
        //         play_address: 'https://static1.kaixinyf.cn/resource/91e2cba9843e4248bf99de0c115f845e.mp3',
        //         play_time: '343',
        //         chorus_time: {
        //           start_time: '110.34',
        //           end_time: '148.61',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '6469679',
        //           music_name: '长得漂亮不如活得漂亮 (改编版原唱: 黑小黑|DJ版)',
        //         },
        //         {
        //           music_id: '3602007',
        //           music_name: '阿哥阿妹 (三步踩)',
        //         },
        //         {
        //           music_id: '18488962',
        //           music_name: '心相印手牵手',
        //         },
        //         {
        //           music_id: '1916314',
        //           music_name: '那一夜',
        //         },
        //       ],
        //     },
        //     {
        //       id: 88,
        //       play_song: {
        //         music_id: '2122104',
        //         music_name: '套马杆',
        //         play_address: 'https://static1.kaixinyf.cn/resource/595b38b354e74c63b6e068c485dd1924.mp3',
        //         play_time: '290',
        //         chorus_time: {
        //           start_time: '83.03',
        //           end_time: '128.73',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '2122104',
        //           music_name: '套马杆',
        //         },
        //         {
        //           music_id: '10728076',
        //           music_name: '红枣树(DJ何鹏版)',
        //         },
        //         {
        //           music_id: '15522892',
        //           music_name: '走破几双鞋',
        //         },
        //         {
        //           music_id: '19538622',
        //           music_name: '不再打扰',
        //         },
        //       ],
        //     },
        //     {
        //       id: 89,
        //       play_song: {
        //         music_id: '26408073',
        //         music_name: '最美的情歌就是你和我',
        //         play_address: 'https://static1.kaixinyf.cn/resource/461bb1152aef482a885ee1ead6a5b05a.mp3',
        //         play_time: '296',
        //         chorus_time: {
        //           start_time: '74.49',
        //           end_time: '119.44',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1914089',
        //           music_name: '喝着啤酒唱情歌',
        //         },
        //         {
        //           music_id: '26408073',
        //           music_name: '最美的情歌就是你和我',
        //         },
        //         {
        //           music_id: '11207086',
        //           music_name: '不是我的错',
        //         },
        //         {
        //           music_id: '15533114',
        //           music_name: '谁能陪我到最后',
        //         },
        //       ],
        //     },
        //     {
        //       id: 90,
        //       play_song: {
        //         music_id: '25507186',
        //         music_name: '听着情歌我流着眼泪DJ',
        //         play_address: 'https://static1.kaixinyf.cn/resource/536b768ef99f4048a82f4f6a7e38b337.mp3',
        //         play_time: '242',
        //         chorus_time: {
        //           start_time: '55.36',
        //           end_time: '90.84',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '25185464',
        //           music_name: '姐有姐的范儿（DJ默涵版）',
        //         },
        //         {
        //           music_id: '7190103',
        //           music_name: '情难枕',
        //         },
        //         {
        //           music_id: '1914089',
        //           music_name: '喝着啤酒唱情歌',
        //         },
        //         {
        //           music_id: '25507186',
        //           music_name: '听着情歌我流着眼泪DJ',
        //         },
        //       ],
        //     },
        //     {
        //       id: 91,
        //       play_song: {
        //         music_id: '1904154',
        //         music_name: '火红的萨日朗',
        //         play_address: 'https://static1.kaixinyf.cn/resource/919302b1791748cbaa113537aa305631.mp3',
        //         play_time: '224',
        //         chorus_time: {
        //           start_time: '70.4',
        //           end_time: '102.91',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1904154',
        //           music_name: '火红的萨日朗',
        //         },
        //         {
        //           music_id: '1920057',
        //           music_name: '风筝误',
        //         },
        //         {
        //           music_id: '15717172',
        //           music_name: '一辈子图个啥',
        //         },
        //         {
        //           music_id: '17612265',
        //           music_name: '爱情是什么',
        //         },
        //       ],
        //     },
        //     {
        //       id: 92,
        //       play_song: {
        //         music_id: '1822949',
        //         music_name: '不是打工不想家',
        //         play_address: 'https://static1.kaixinyf.cn/resource/ff5ed6cf38e445f0b4acb665676db8c3.mp3',
        //         play_time: '278',
        //         chorus_time: {
        //           start_time: '69.29',
        //           end_time: '114.24',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1822949',
        //           music_name: '不是打工不想家',
        //         },
        //         {
        //           music_id: '9296172',
        //           music_name: '不白活一回(电视剧《古船·女人和网》插曲)',
        //         },
        //         {
        //           music_id: '15442198',
        //           music_name: '迟来的爱 (修复版)',
        //         },
        //         {
        //           music_id: '7714386',
        //           music_name: '光辉岁月 (电影《摆渡人》插曲)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 93,
        //       play_song: {
        //         music_id: '12488571',
        //         music_name: '雨中的思念',
        //         play_address: 'https://static1.kaixinyf.cn/resource/0a1982a6c8ea480989f2cc0946807bb9.mp3',
        //         play_time: '288',
        //         chorus_time: {
        //           start_time: '64.46',
        //           end_time: '115.73',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '11192814',
        //           music_name: '那个爱情小偷 (舞曲)',
        //         },
        //         {
        //           music_id: '12488571',
        //           music_name: '雨中的思念',
        //         },
        //         {
        //           music_id: '12342135',
        //           music_name: '好听到醉《歌在飞》车载蓝调',
        //         },
        //         {
        //           music_id: '22952657',
        //           music_name: '苍天饶过谁(DJ何鹏版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 94,
        //       play_song: {
        //         music_id: '2230742',
        //         music_name: '醉 (DJ何鹏版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/c90203f314184a97a3f8ab1dddf20c99.mp3',
        //         play_time: '217',
        //         chorus_time: {
        //           start_time: '43.65',
        //           end_time: '62.6',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '2100775',
        //           music_name: '心雨',
        //         },
        //         {
        //           music_id: '23907556',
        //           music_name: '情歌虽老却动听',
        //         },
        //         {
        //           music_id: '2230742',
        //           music_name: '醉 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '26649302',
        //           music_name: '我为你掏心掏肺',
        //         },
        //       ],
        //     },
        //     {
        //       id: 95,
        //       play_song: {
        //         music_id: '5062996',
        //         music_name: '藕断丝连',
        //         play_address: 'https://static1.kaixinyf.cn/resource/3bd3c9ab75cd41f1a95eeaa47c46a564.mp3',
        //         play_time: '293',
        //         chorus_time: {
        //           start_time: '63.9',
        //           end_time: '96.22',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '19882418',
        //           music_name: '爱情这杯酒谁喝都得醉 (DJheap九天版)',
        //         },
        //         {
        //           music_id: '5062996',
        //           music_name: '藕断丝连',
        //         },
        //         {
        //           music_id: '25507186',
        //           music_name: '听着情歌我流着眼泪DJ',
        //         },
        //         {
        //           music_id: '12054148',
        //           music_name: '爱情41度9 (DJ何鹏版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 96,
        //       play_song: {
        //         music_id: '19867196',
        //         music_name: '男人就是累(DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/dbb372217abf43b599693f7e401fb945.mp3',
        //         play_time: '357',
        //         chorus_time: {
        //           start_time: '106.63',
        //           end_time: '138.39',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7868494',
        //           music_name: '女人花',
        //         },
        //         {
        //           music_id: '19867196',
        //           music_name: '男人就是累(DJ版)',
        //         },
        //         {
        //           music_id: '18392591',
        //           music_name: '没有你陪伴我真的好孤单',
        //         },
        //         {
        //           music_id: '10600713',
        //           music_name: '等风等雨不如等你',
        //         },
        //       ],
        //     },
        //     {
        //       id: 97,
        //       play_song: {
        //         music_id: '20154814',
        //         music_name: '酒醉的雨滴',
        //         play_address: 'https://static1.kaixinyf.cn/resource/fb8769370bff47de9cc2024c0398153e.mp3',
        //         play_time: '231',
        //         chorus_time: {
        //           start_time: '58.89',
        //           end_time: '95.48',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1904154',
        //           music_name: '火红的萨日朗',
        //         },
        //         {
        //           music_id: '7742647',
        //           music_name: '最美的遗憾',
        //         },
        //         {
        //           music_id: '10600713',
        //           music_name: '等风等雨不如等你',
        //         },
        //         {
        //           music_id: '20154814',
        //           music_name: '酒醉的雨滴',
        //         },
        //       ],
        //     },
        //     {
        //       id: 98,
        //       play_song: {
        //         music_id: '15717172',
        //         music_name: '一辈子图个啥',
        //         play_address: 'https://static1.kaixinyf.cn/resource/57e68ff158604bf4ab5a799a81f8347f.mp3',
        //         play_time: '191',
        //         chorus_time: {
        //           start_time: '37.15',
        //           end_time: '69.29',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '10824608',
        //           music_name: '后海酒吧 (Remix: 唐玉德)',
        //         },
        //         {
        //           music_id: '15717172',
        //           music_name: '一辈子图个啥',
        //         },
        //         {
        //           music_id: '22100341',
        //           music_name: '九十九步退一步 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '24432192',
        //           music_name: '流浪的孩子谁来疼 (DJ版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 99,
        //       play_song: {
        //         music_id: '1920059',
        //         music_name: '醉千年',
        //         play_address: 'https://static1.kaixinyf.cn/resource/8df8fb72874c4675a8b5c8c26d156ae0.mp3',
        //         play_time: '220',
        //         chorus_time: {
        //           start_time: '60.74',
        //           end_time: '88.98',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '17847263',
        //           music_name: '小薇',
        //         },
        //         {
        //           music_id: '1920059',
        //           music_name: '醉千年',
        //         },
        //         {
        //           music_id: '19918502',
        //           music_name: '电话情思',
        //         },
        //         {
        //           music_id: '24637718',
        //           music_name: '我想对你说（对唱版）',
        //         },
        //       ],
        //     },
        //     {
        //       id: 100,
        //       play_song: {
        //         music_id: '20348849',
        //         music_name: '冰冷的酒悲伤的泪（DJ默涵）',
        //         play_address: 'https://static1.kaixinyf.cn/resource/2772b7ad45b34722b2c0f59ec92fa970.mp3',
        //         play_time: '174',
        //         chorus_time: {
        //           start_time: '52.38',
        //           end_time: '92.14',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '20348849',
        //           music_name: '冰冷的酒悲伤的泪（DJ默涵）',
        //         },
        //         {
        //           music_id: '14867138',
        //           music_name: '今夜的你又在和谁约会',
        //         },
        //         {
        //           music_id: '17201782',
        //           music_name: '无奈的思绪',
        //         },
        //         {
        //           music_id: '1790536',
        //           music_name: '清明上河图',
        //         },
        //       ],
        //     },
        //     {
        //       id: 101,
        //       play_song: {
        //         music_id: '14697616',
        //         music_name: '你若盛开蝴蝶自来DJ',
        //         play_address: 'https://static1.kaixinyf.cn/resource/4f0702d82ee54a11981502e56ae3bce0.mp3',
        //         play_time: '203',
        //         chorus_time: {
        //           start_time: '63.16',
        //           end_time: '103.28',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1916314',
        //           music_name: '那一夜',
        //         },
        //         {
        //           music_id: '7629551',
        //           music_name: '一万个理由',
        //         },
        //         {
        //           music_id: '12858036',
        //           music_name: '一路的歌唱 (DJ版-Danny)',
        //         },
        //         {
        //           music_id: '14697616',
        //           music_name: '你若盛开蝴蝶自来DJ',
        //         },
        //       ],
        //     },
        //     {
        //       id: 102,
        //       play_song: {
        //         music_id: '11192814',
        //         music_name: '那个爱情小偷 (舞曲)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/49610b6a6364479e8f23d11b77333ae3.mp3',
        //         play_time: '275',
        //         chorus_time: {
        //           start_time: '69.66',
        //           end_time: '104.77',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '24484867',
        //           music_name: '伤心的雪花 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '11192814',
        //           music_name: '那个爱情小偷 (舞曲)',
        //         },
        //         {
        //           music_id: '6946161',
        //           music_name: '屠杀',
        //         },
        //         {
        //           music_id: '3024035',
        //           music_name: '梦中的妈妈',
        //         },
        //       ],
        //     },
        //     {
        //       id: 103,
        //       play_song: {
        //         music_id: '17863184',
        //         music_name: '大海',
        //         play_address: 'https://static1.kaixinyf.cn/resource/f61c62ec76f44d5e93ac91982b77641f.mp3',
        //         play_time: '280',
        //         chorus_time: {
        //           start_time: '64.64',
        //           end_time: '112.01',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '9197278',
        //           music_name: '套马杆 (Live)',
        //         },
        //         {
        //           music_id: '9542298',
        //           music_name: '金莲的爱',
        //         },
        //         {
        //           music_id: '8386685',
        //           music_name: '月亮月亮你别睡',
        //         },
        //         {
        //           music_id: '17863184',
        //           music_name: '大海',
        //         },
        //       ],
        //     },
        //     {
        //       id: 104,
        //       play_song: {
        //         music_id: '18488962',
        //         music_name: '心相印手牵手',
        //         play_address: 'https://static1.kaixinyf.cn/resource/bf0b89a8a8ce43869d039ff89bd68c0e.mp3',
        //         play_time: '275',
        //         chorus_time: {
        //           start_time: '36.04',
        //           end_time: '70.22',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '24424971',
        //           music_name: '别让人瞧不起（DJ）',
        //         },
        //         {
        //           music_id: '10862219',
        //           music_name: '亲爱的你在想我吗',
        //         },
        //         {
        //           music_id: '18488962',
        //           music_name: '心相印手牵手',
        //         },
        //         {
        //           music_id: '13264099',
        //           music_name: '北郊',
        //         },
        //       ],
        //     },
        //     {
        //       id: 105,
        //       play_song: {
        //         music_id: '21616903',
        //         music_name: '伤疤还没好就忘了疼',
        //         play_address: 'https://static1.kaixinyf.cn/resource/81c9ebe0c4be49e092d032d9e606cb4b.mp3',
        //         play_time: '118',
        //         chorus_time: {
        //           start_time: '52.94',
        //           end_time: '103.84',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '21616903',
        //           music_name: '伤疤还没好就忘了疼',
        //         },
        //         {
        //           music_id: '24424971',
        //           music_name: '别让人瞧不起（DJ）',
        //         },
        //         {
        //           music_id: '18772132',
        //           music_name: '阿哥在何方 (DJ版)',
        //         },
        //         {
        //           music_id: '20179424',
        //           music_name: '车站',
        //         },
        //       ],
        //     },
        //     {
        //       id: 106,
        //       play_song: {
        //         music_id: '11780326',
        //         music_name: '拉紧手跟我走 (DJ伟然版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e6d175ad01ad4691bca5f36e2943ac31.mp3',
        //         play_time: '203',
        //         chorus_time: {
        //           start_time: '32.69',
        //           end_time: '62.04',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '24772829',
        //           music_name: '没有你的夜晚好凄凉 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '11780326',
        //           music_name: '拉紧手跟我走 (DJ伟然版)',
        //         },
        //         {
        //           music_id: '8518606',
        //           music_name: '幺妹住在十三寨',
        //         },
        //         {
        //           music_id: '5838958',
        //           music_name: '女人天生就爱美',
        //         },
        //       ],
        //     },
        //     {
        //       id: 107,
        //       play_song: {
        //         music_id: '23088389',
        //         music_name: '你过得好吗',
        //         play_address: 'https://static1.kaixinyf.cn/resource/d820d623674d4abc8341237dd9949b47.mp3',
        //         play_time: '214',
        //         chorus_time: {
        //           start_time: '49.23',
        //           end_time: '85.45',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '8518606',
        //           music_name: '幺妹住在十三寨',
        //         },
        //         {
        //           music_id: '10209431',
        //           music_name: '你牛什么牛 (DJ版)',
        //         },
        //         {
        //           music_id: '23088389',
        //           music_name: '你过得好吗',
        //         },
        //         {
        //           music_id: '9452672',
        //           music_name: '我被青春撞了一下腰',
        //         },
        //       ],
        //     },
        //     {
        //       id: 108,
        //       play_song: {
        //         music_id: '7854748',
        //         music_name: '喝一杯最烈的酒（DJ何鹏版）',
        //         play_address: 'https://static1.kaixinyf.cn/resource/8a6189ae4c9445d1b37c24d8b351f89d.mp3',
        //         play_time: '200',
        //         chorus_time: {
        //           start_time: '46.81',
        //           end_time: '76.53',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '10044466',
        //           music_name: '十不该',
        //         },
        //         {
        //           music_id: '1780055',
        //           music_name: '恋人心',
        //         },
        //         {
        //           music_id: '7854748',
        //           music_name: '喝一杯最烈的酒（DJ何鹏版）',
        //         },
        //         {
        //           music_id: '23786238',
        //           music_name: '三生三幸',
        //         },
        //       ],
        //     },
        //     {
        //       id: 109,
        //       play_song: {
        //         music_id: '19521589',
        //         music_name: '你还是从前的你吗',
        //         play_address: 'https://static1.kaixinyf.cn/resource/f08d0f20ed0b4e0e9bebb07a5af75915.mp3',
        //         play_time: '300',
        //         chorus_time: {
        //           start_time: '59.81',
        //           end_time: '98.27',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '19521589',
        //           music_name: '你还是从前的你吗',
        //         },
        //         {
        //           music_id: '1778001',
        //           music_name: '你的答案',
        //         },
        //         {
        //           music_id: '28096887',
        //           music_name: '郎从门前过',
        //         },
        //         {
        //           music_id: '24672147',
        //           music_name: '最伤心的人喝下最伤心的酒 DJ女版',
        //         },
        //       ],
        //     },
        //     {
        //       id: 110,
        //       play_song: {
        //         music_id: '1882771',
        //         music_name: '半壶纱',
        //         play_address: 'https://static1.kaixinyf.cn/resource/d5ac38ca5f2440879830a971a001c817.mp3',
        //         play_time: '222',
        //         chorus_time: {
        //           start_time: '74.86',
        //           end_time: '98.64',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7442956',
        //           music_name: '笑纳',
        //         },
        //         {
        //           music_id: '10047045',
        //           music_name: '没有人能够把你代替',
        //         },
        //         {
        //           music_id: '19056444',
        //           music_name: '酒干倘卖无',
        //         },
        //         {
        //           music_id: '1882771',
        //           music_name: '半壶纱',
        //         },
        //       ],
        //     },
        //     {
        //       id: 111,
        //       play_song: {
        //         music_id: '7359501',
        //         music_name: '太想念',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza62a9443718bee353328881.mp3',
        //         play_time: '228',
        //         chorus_time: {
        //           start_time: '62.23',
        //           end_time: '100.87',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '7359501',
        //           music_name: '太想念',
        //         },
        //         {
        //           music_id: '20336814',
        //           music_name: '借酒浇愁 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '26722929',
        //           music_name: '杭娇 - 听心(恰恰)',
        //         },
        //         {
        //           music_id: '1956526',
        //           music_name: '遇上你是我的缘',
        //         },
        //       ],
        //     },
        //     {
        //       id: 112,
        //       play_song: {
        //         music_id: '21251341',
        //         music_name: '殇雪 (改编版原唱: 云菲菲|DJ版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/219f335261684c4592c30b74113ed065.mp3',
        //         play_time: '281',
        //         chorus_time: {
        //           start_time: '67.24',
        //           end_time: '100.68',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '20650496',
        //           music_name: '梦里水乡',
        //         },
        //         {
        //           music_id: '24917143',
        //           music_name: '烟雨人间 (DJ女声版)',
        //         },
        //         {
        //           music_id: '26148163',
        //           music_name: '怎能不牵挂',
        //         },
        //         {
        //           music_id: '21251341',
        //           music_name: '殇雪 (改编版原唱: 云菲菲|DJ版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 113,
        //       play_song: {
        //         music_id: '10510273',
        //         music_name: '风含情水含笑',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza6245452984a1f453325013.mp3',
        //         play_time: '250',
        //         chorus_time: {
        //           start_time: '85.08',
        //           end_time: '118.14',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '15208168',
        //           music_name: '谁的眼泪在飞',
        //         },
        //         {
        //           music_id: '10510273',
        //           music_name: '风含情水含笑',
        //         },
        //         {
        //           music_id: '21616903',
        //           music_name: '伤疤还没好就忘了疼',
        //         },
        //         {
        //           music_id: '8897206',
        //           music_name: '谢军-山谷里的思念 (dj想念版)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 114,
        //       play_song: {
        //         music_id: '26924883',
        //         music_name: '妹妹的牵挂',
        //         play_address: 'https://static1.kaixinyf.cn/resource/lza632016218a3d6588718877.mp3',
        //         play_time: '280',
        //         chorus_time: {
        //           start_time: '76.9',
        //           end_time: '125.95',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '12140755',
        //           music_name: '祁隆 - 等你等了那么久.p4舞曲',
        //         },
        //         {
        //           music_id: '11780326',
        //           music_name: '拉紧手跟我走 (DJ伟然版)',
        //         },
        //         {
        //           music_id: '26924883',
        //           music_name: '妹妹的牵挂',
        //         },
        //         {
        //           music_id: '11118978',
        //           music_name: '最真的梦',
        //         },
        //       ],
        //     },
        //     {
        //       id: 115,
        //       play_song: {
        //         music_id: '9197278',
        //         music_name: '套马杆 (Live)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/e3252bd6370f46d38c01f38a50eadc19.mp3',
        //         play_time: '280',
        //         chorus_time: {
        //           start_time: '81.92',
        //           end_time: '125.76',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1824226',
        //           music_name: '军中绿花',
        //         },
        //         {
        //           music_id: '9912158',
        //           music_name: '潇洒走一回(电视剧《京城四少》主题曲)',
        //         },
        //         {
        //           music_id: '22710158',
        //           music_name: '走在人生的路口 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '9197278',
        //           music_name: '套马杆 (Live)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 116,
        //       play_song: {
        //         music_id: '1842162',
        //         music_name: '苏幕遮',
        //         play_address: 'https://static1.kaixinyf.cn/resource/f55f51c9a795428d91309528e49815f6.mp3',
        //         play_time: '199',
        //         chorus_time: {
        //           start_time: '50.16',
        //           end_time: '87.86',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '14697616',
        //           music_name: '你若盛开蝴蝶自来DJ',
        //         },
        //         {
        //           music_id: '1842162',
        //           music_name: '苏幕遮',
        //         },
        //         {
        //           music_id: '23493379',
        //           music_name: '旧梦 (DJ默涵版)',
        //         },
        //         {
        //           music_id: '7237035',
        //           music_name: '知心爱人(电视剧《雨夜惊情》片尾曲)',
        //         },
        //       ],
        //     },
        //     {
        //       id: 117,
        //       play_song: {
        //         music_id: '12696029',
        //         music_name: '醉红妆(DJ伟然版)',
        //         play_address: 'https://static1.kaixinyf.cn/resource/87cd5cf728324033bb1b48ba51ed749e.mp3',
        //         play_time: '159',
        //         chorus_time: {
        //           start_time: '49.04',
        //           end_time: '86.01',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '12696029',
        //           music_name: '醉红妆(DJ伟然版)',
        //         },
        //         {
        //           music_id: '26148062',
        //           music_name: '多情野花不要采',
        //         },
        //         {
        //           music_id: '9502037',
        //           music_name: '不能忘记你',
        //         },
        //         {
        //           music_id: '1920059',
        //           music_name: '醉千年',
        //         },
        //       ],
        //     },
        //     {
        //       id: 118,
        //       play_song: {
        //         music_id: '19538622',
        //         music_name: '不再打扰',
        //         play_address: 'https://static1.kaixinyf.cn/resource/0de6175ef0514312bdfbc1f603f35839.mp3',
        //         play_time: '316',
        //         chorus_time: {
        //           start_time: '76.53',
        //           end_time: '126.5',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1849178',
        //           music_name: '最炫民族风',
        //         },
        //         {
        //           music_id: '9778207',
        //           music_name: '青藏高原(央视星光璀璨中国巨星演唱会)',
        //         },
        //         {
        //           music_id: '1862389',
        //           music_name: '红枣树 (DJ何鹏版)',
        //         },
        //         {
        //           music_id: '19538622',
        //           music_name: '不再打扰',
        //         },
        //       ],
        //     },
        //     {
        //       id: 119,
        //       play_song: {
        //         music_id: '1956526',
        //         music_name: '遇上你是我的缘',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza62287d0a06768197475718.mp3',
        //         play_time: '200',
        //         chorus_time: {
        //           start_time: '51.08',
        //           end_time: '101.42',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '1956526',
        //           music_name: '遇上你是我的缘',
        //         },
        //         {
        //           music_id: '25764333',
        //           music_name: '生活就像那一杯苦酒',
        //         },
        //         {
        //           music_id: '21912617',
        //           music_name: '姑娘我爱你',
        //         },
        //         {
        //           music_id: '7602547',
        //           music_name: '披着羊皮的狼',
        //         },
        //       ],
        //     },
        //     {
        //       id: 120,
        //       play_song: {
        //         music_id: '11942416',
        //         music_name: '受了伤的狼',
        //         play_address: 'https://static1.kaixinyf.cn/cut/lza62622264b7340358104331.mp3',
        //         play_time: '257',
        //         chorus_time: {
        //           start_time: '67.62',
        //           end_time: '104.03',
        //         },
        //       },
        //       options: [
        //         {
        //           music_id: '6084744',
        //           music_name: '我的好兄弟',
        //         },
        //         {
        //           music_id: '1794067',
        //           music_name: '天在下雨我在想你',
        //         },
        //         {
        //           music_id: '13788956',
        //           music_name: '爹娘汗水为了儿女流',
        //         },
        //         {
        //           music_id: '11942416',
        //           music_name: '受了伤的狼',
        //         },
        //       ],
        //     },
        //   ],
        // };
        commit('setTotalList', list);
        commit('setTaskKey', task_key);
        commit('setScoreConditions', score_conditions);
        commit('answer/setComboList', red_package);
        commit('setBusinessName', business_name);
        commit('setGameType', game_type);
        commit('setResurgenceVideoGroup', resurgence_video_group);
        commit('setRewardVideoGroup', reward_video_group);
        commit('setShowSandRestTime', Number(show_special_effects));

        resurgence_times_limit && commit('setMaxRebornCount', Number(resurgence_times_limit));
        pass_count_down && commit('setTotalSecond', Number(pass_count_down));
        pass_song_count_down && commit('setSongSecond', Number(pass_song_count_down));
        resurgence_count_down && commit('setRebornSecond', Number(resurgence_count_down));
      } catch (e) {
        console.log(e);
      }
    },

    // 获取歌手列表
    async getSingerList({ commit }) {
      try {
        const data = await GamePost(
          Api.GET_GUESSE_MUSIC_SINGER_LIST_API,
          {},
          {
            showLoading: false,
          }
        ).catch((e) => {});
        const { list, singer_song_count_down } = data;
        if (list) {
          commit('setSingerList', list);
          commit('setCircleSecond', Number(singer_song_count_down));
        } else {
          commit('setSingerList', data);
        }
      } catch (e) {
        console.log(e);
      }
    },

    // 获取歌手模式歌曲列表
    async getSingerModeInfo({ commit }, id) {
      try {
        const data = await GamePost(
          Api.GET_SINGER_MUSIC_LIST_API,
          { singer_id: id },
          {
            showLoading: false,
          }
        ).catch((e) => {});
        const { list, task_key, reward_video_group } = data;
        commit('setTaskKey', task_key);
        commit('setTotalList', list);
        commit('setRewardVideoGroup', reward_video_group);
      } catch (e) {
        console.log(e);
      }
    },

    // 下一首
    nextSong({ commit, state }) {
      console.log('nextSong_store');
      const { currentIndex, playList } = state;
      let index;
      if (currentIndex + 1 < playList.length) {
        index = currentIndex + 1;
      } else {
        index = 0;
      }
      commit('setCurrentIndex', index);
      commit('setSelectedAnswer', null);
    },

    // 重置游戏状态
    resetGame({ commit, dispatch }) {
      dispatch('answer/resetStatistics');
      commit('setCurrentIndex', 0);
      commit('setSelectedAnswer', null);
      commit('setTotalList', []);
      commit('setLoaded', false);
      commit('setPlayList', []);
      commit('setPreloadingList', []);
      commit('setCanAutoPlay', true);
      commit('setPlaying', false);
      commit('setAlive', true);
      commit('setRebornCount', 0);
      commit('setShowHp', false);
      const audio = document.getElementById('audio_player');
      audio && audio.pause();
    },

    // 闯关模式复活
    reborn({ commit, state }) {
      commit('setSelectedAnswer', null);
      commit('setResult', '');
      commit('answer/setCurrentHp', 1);
      commit('setAlive', true);
      commit('setRebornCount', state.rebornCount + 1);
    },
  },
  getters: {
    // 当前播放歌曲
    currentSong(state) {
      const { currentIndex, playList } = state;
      if (!playList.length || currentIndex < 0) {
        return mockPlayList[0];
      }
      return playList[currentIndex] ? playList[currentIndex] : playList[0];
    },

    // 是否可以复活
    canReborn(state) {
      const { rebornCount, maxRebornCount } = state;
      return rebornCount < maxRebornCount;
    },
  },
  modules: {
    answer,
  },
};
