import PushOff from './welfare/PushOff';
import PushOn from './welfare/PushOn';
import DrinkRuleModal from './drink/DrinkRuleModal';
import GameSignModal from './common/GameSignModal';
import SleepRule from './sleep/SleepRule';
import SleepRewardModal from './sleep/SleepRewardModal';
import SignModal from './sign/DailySignModal';
import WalkRulesModal from './new_walk/WalkRulesModal';
import WalkStealModal from './new_walk/WalkStealModal';
import WalkRewardModal from './new_walk/WalkRewardModal';
import FortuneWheel from './fortune_wheel/FortuneWheel.vue';
import FortuneReward from './fortune_wheel/FortuneWheelReward.vue';
import SleepSignModal from './new_sleep/SleepSignModal.vue';
import SleepSignReward from './new_sleep/SleepSignReward.vue';
import SleepGetupModal from './new_sleep/SleepGetupModal.vue';
import SleepRetainModal from './new_sleep/SleepRetainModal.vue';
import SleepCarveRuleModal from './new_sleep/SleepCarveRuleModal.vue';
import SleepCarveResultModal from './new_sleep/SleepCarveResultModal.vue';
import NewSleepRule from './new_sleep/NewSleepRule.vue';

import DrinkingLevelUpModal from '@/views/Drink/V2/LevelUpModal/LevelUpModal.vue';
import DrinkingLevelDownModal from '@/views/Drink/V2/LevelDownModal/LevelDownModal.vue';
import DrinkingTreasureBoxModal from '@/views/Drink/V2/TreasureBoxModal/TreasureBoxModal.vue';
import DrinkingHealthRewardModal from '@/views/Drink/V2/HealthRewardModal/HealthRewardModal.vue';
import TreeGrouthModal from '@/views/Tree/components/GrouthModal/GrouthModal.vue';
import TreeVideoWaterModal from '@/views/Tree/components/VideoWaterModal/VideoWaterModal.vue';
import TreeDeadModal from '@/views/Tree/components/DeadModal/DeadModal.vue';
import TreeRuleModal from '@/views/Tree/components/RuleModal/RuleModal.vue';
import TreeSearchChanceModal from '@/views/Tree/components/SearchChanceModal/SearchChanceModal.vue';
import TreeUpdateModal from '@/views/Tree/components/UpdateModal/UpdateModal.vue';
import TreeRetainModal from '@/views/Tree/components/RetainModal/RetainModal.vue';

import EatingSignModal from '@/components/Modal/eating/SignModal';
import EatingSignModalV2 from '@/components/Modal/eating/SignModalV2';

import DoubleSign from './welfare/DoubleSign';
/**
 * 快音农场相关弹窗
 */
import FruitSelectionModal from '@/views/Farm/components/FruitSelectionModal/FruitSelectionModal.vue';
import MatureFruitModal from '@/views/Farm/components/MatureFruitModal/MatureFruitModal.vue';
import CongratulationModal from '@/views/Farm/components/CongratulationModal/CongratulationModal.vue';
import AddressModal from '@/views/Farm/components/AddressModal/AddressModal.vue';
import IncomeRecordModal from '@/views/Farm/components/IncomeRecordModal/index.vue';
import GainWaterDropModal from '@/views/Farm/components/GainWaterDropModal/index.vue';
import RedPacketModal from '@/views/Farm/components/RedPacketModal';
import DowngradeModal from '@/views/Farm/components/DowngradeModal/DowngradeModal.vue';
import FarmRuleModal from '@/views/Farm/components/RuleModal/index.vue';
import FarmUpgradeModal from '@/views/Farm/components/UpgradeModal/index.vue';

const modalType = new Map([
  ['push-off', PushOff],
  ['push-on', PushOn],
  ['drink-rule-modal', DrinkRuleModal],
  ['game-sign-modal', GameSignModal],
  ['sleep-rule-modal', SleepRule],
  ['sleep-reward-modal', SleepRewardModal],
  ['sign-modal', SignModal],
  ['walk-rule-modal', WalkRulesModal],
  ['walk-steal-modal', WalkStealModal],
  ['walk-reward-modal', WalkRewardModal],
  ['fortune-wheel', FortuneWheel],
  ['fortune-reward', FortuneReward],
  ['sleep-sign-modal', SleepSignModal],
  ['sleep-sign-reward-modal', SleepSignReward],
  ['sleep-getup-modal', SleepGetupModal],
  ['sleep-retain-modal', SleepRetainModal],
  ['sleep-carve-rule-modal', SleepCarveRuleModal],
  ['sleep-carve-result-modal', SleepCarveResultModal],
  ['sleep-rule-new-modal', NewSleepRule],
  ['drinking-level-up-modal', DrinkingLevelUpModal],
  ['drinking-level-down-modal', DrinkingLevelDownModal],
  ['drinking-treasure-box-modal', DrinkingTreasureBoxModal],
  ['drinking-health-reward-modal', DrinkingHealthRewardModal],
  ['tree-grouth-modal', TreeGrouthModal],
  ['tree-video-water-modal', TreeVideoWaterModal],
  ['tree-dead-modal', TreeDeadModal],
  ['tree-rule-modal', TreeRuleModal],
  ['tree-search-chance-modal', TreeSearchChanceModal],
  ['tree-redpacket-update-modal', TreeUpdateModal],
  ['tree-retain-modal', TreeRetainModal],
  ['eating-sign-modal', EatingSignModal],
  ['eating-sign-modal-v2', EatingSignModalV2],
  ['double-sign', DoubleSign],
  ['fruit-selection-modal', FruitSelectionModal],
  ['mature-fruit-modal', MatureFruitModal],
  ['congratulation-modal', CongratulationModal],
  ['address-modal', AddressModal],
  ['income-record-modal', IncomeRecordModal],
  ['gain-water-drop-modal', GainWaterDropModal],
  ['red-packet-modal', RedPacketModal],
  ['downgrade-modal', DowngradeModal],
  ['farm-rule-modal', FarmRuleModal],
  ['farm-upgrade-modal', FarmUpgradeModal],
]);

export default {
  functional: true,
  name: 'Modal',
  props: {
    type: {
      type: String,
      required: true,
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    closeBtnPos: {
      top: Number,
      right: Number,
      value: String, // 有四种取值：
      // 'right' (默认)：右上角 30px 距离，大小 0.25 rem;
      // 'left'：左上角 30px 距离，大小 0.25 rem;
      // 'bottom'：底部居中，大小 0.4 rem;
      // 'top'：顶部居中， 距离，大小 0.25 rem;
    },
    childType: Number,
    closeFn: {
      type: Function,
      // required: true,
    },
    btnFn: Function,
    overlay: {
      type: Boolean,
      default: true,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  render(h, context) {
    const htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.style.overflow = 'hidden';

    const { props, data } = context;

    const closeBtnConf = {
      class: 'close-btn right',
      attrs: {
        width: 34,
      },
      on: {
        click() {
          closeFn();
        },
      },
      domProps: {
        // eslint-disable-next-line global-require
        src: require('@/assets/ky-close.png'),
      },
    };

    if (props.closeBtnPos) {
      const { top, right, bottom, value, width, height } = props.closeBtnPos;

      closeBtnConf.style = top ? (closeBtnConf.style = { top: `${top}rem` }) : {};

      right && Object.assign(closeBtnConf.style, { right: `${right}rem` });

      bottom && Object.assign(closeBtnConf.style, { bottom: `${bottom}rem` });

      if (value) {
        closeBtnConf.class = closeBtnConf.class.replace('right', value);
      }
      if (width && height) {
        closeBtnConf.style.width = `${width}rem`;
        closeBtnConf.style.height = `${height}rem`;
      }
    }

    const closeFn = () => {
      htmlElement.style.overflow = 'scroll';
      props.closeFn();
    };

    const btnFn = (...arg) => {
      htmlElement.style.overflow = 'scroll';

      if (props.btnFn) {
        props.btnFn(...arg);
      } else {
        props.closeFn();
      }
    };
    const childProp = {
      handleBtn: btnFn,
      handleClose: closeFn,
      child: props.childType,
      ...data.attrs,
    };

    return h(
      'transition',
      {
        attrs: {
          name: 'modal',
        },
      },
      [
        h(
          'div',
          {
            class: props.overlay ? 'modal-wrapper-new' : 'modal-wrapper-new transpart',
          },
          [
            h(
              'main',
              {
                class: 'modal-content-new',
              },
              [
                props.showCloseBtn && h('img', closeBtnConf),
                h(modalType.get(props.type), {
                  props: childProp,
                }),
              ]
            ),
          ]
        ),
      ]
    );
  },
};
