import { GamePost, Api } from '@/http';
import { otherGameList } from './config';

export default {
  namespaced: true,
  state: () => ({
    bubbleList: [],
    bannerList: [],
    // 连续签到信息
    keepSign: {
      sign_days: 0,
    },
    // 当日可偷取次数
    remainStealTimes: 0,
    // 当日已总步数
    stepTotal: 0,
    // 偷步数广告
    stealAds: [],
    // 气泡广告
    adInfo: {},
    isListLoading: false,
    // 聚合视频信息
    mediationAdInfo: {
      ad_group_id: 0,
      ad_platform_status: false,
    },
    rewardInfo: {
      // 分享奖励
      shareReward: 0,
      // 昨日奖励
      yesterday_reward: 0,
    },
    // 看视频得偷步数得机会
    adSteal: 0,
  }),
  mutations: {
    setBubbleList(state, list) {
      state.bubbleList = [...list];
    },
    setBanner(state, list) {
      state.bannerList = [...list];
    },
    setKeepSign(state, data) {
      state.keepSign = data;
    },
    setRemainStealTimes(state, number) {
      state.remainStealTimes = number;
    },
    setStepTotal(state, number) {
      state.stepTotal = number;
    },
    setStealAds(state, data) {
      state.stealAds = [...data];
    },
    setAdInfo(state, data) {
      state.adInfo = { ...data };
    },
    setLoading(state, payload) {
      state.isListLoading = payload;
    },
    setMediationAdInfo(state, data) {
      state.mediationAdInfo = { ...data };
    },
    setRewardInfo(state, data) {
      state.rewardInfo = { ...data };
    },
    setAdSteal(state, number) {
      state.adSteal = number;
    },
  },
  actions: {
    async getWalkPageInfo({ commit }) {
      commit('setLoading', true);
      const res = await GamePost(
        Api.GET_OLD_WALK_PAGEINFO_API,
        { app_position: 'game_walk' },
        { showLoading: false }
      ).catch((e) => {});
      commit('setLoading', false);
      const {
        daily_steps,
        list,
        steal_num,
        ad_steal,
        banner,
        normal_sign_stats,
        share_rewarded,
        ad_info,
        steal_ads,
        yesterday_reward, // 当日步数转化，为0不提示。
        ad_group_id,
        ad_platform_status,
      } = res;

      commit('setBanner', banner);
      commit('setKeepSign', normal_sign_stats);
      commit('setRemainStealTimes', steal_num);
      commit('setStepTotal', daily_steps);
      commit('setAdInfo', ad_info);
      commit('setStealAds', steal_ads);
      commit('setRewardInfo', { share_rewarded, yesterday_reward });
      commit('setAdSteal', ad_steal);

      const mediationAdInfo = { ad_group_id, ad_platform_status };
      commit('setMediationAdInfo', mediationAdInfo);

      const bubbleList = list.map((bubble) => {
        // 类型：1签到 2视频 3运动
        let { id, type, steps, video_index, max_reward } = bubble;
        type = Number(type);
        console.log(`${id};${video_index}`);
        return {
          id,
          type: type === 1 ? 'sign' : type === 2 ? 'video' : 'walk',
          name: type === 1 ? '签到奖励' : type === 2 ? '视频奖励' : '运动步数',
          reward: type === 2 ? max_reward : steps,
          show: true,
          className:
            type === 1
              ? 'bubble-sign'
              : type === 2
              ? `bubble-video bubble-video${video_index + 1}-position`
              : 'bubble-walk',
          realReward: steps, // 真实奖励
        };
      });

      commit('setBubbleList', bubbleList);
    },
  },
  getters: {
    signSevenDaysList(state) {
      const { sign_days } = state.keepSign;
      let list = [];
      for (let i = 0; i < 7; i++) {
        const dayTime = i + 1;
        const hasSign = sign_days >= dayTime ? true : false;
        list.push({ dayTime, hasSign });
      }
      return list;
    },

    // 气泡列表
    comBubbleList(state) {
      const { bubbleList, isListLoading } = state;
      return bubbleList.length === 0 && !isListLoading ? otherGameList : bubbleList;
    },

    // 当前是气泡是否卫东
    isEmptyBubble(state) {
      return state.bubbleList.length === 0 && !state.isListLoading ? true : false;
    },

    // 是否有分享机会
    hasChanceShareReward(state) {
      return state.rewardInfo.share_rewarded === 0 ? true : false;
    },

    // 是否能看视频获得偷步数次数
    hasChanceWatchAdForSteal(state) {
      return state.adSteal === 1 ? true : false;
    },

    stealVideoRecordId(state) {
      if (state.stealAds && state.stealAds.length !== 0) {
        return state.stealAds[0].id;
      } else {
        return 0;
      }
    },
  },
};
