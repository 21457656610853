<template>
  <div class="selection-modal">
    <div class="top-user">
      <img class="top-user-avatar" :src="avatar" />
      <p class="top-user-name">{{ nickname }}</p>
    </div>
    <div class="desc">选择你的果树，果子成熟包邮送到家</div>
    <div class="select-list">
      <div class="select-list-item" v-for="(item, index) in selectList" :key="index" @click="handleSelect(item)">
        <div class="select-list-item__image">
          <img :src="item.img" alt="" />
        </div>
        <div class="select-list-item__name">
          <div id="checkbox" :class="{ checkbox__seleced: item.select }">
            <img v-if="item.select" src="@/assets/images/farm/fruit-select-modal/checked.png" alt="checked" />
          </div>
          {{ item.name }}
        </div>
        <div class="sold-out-label" v-if="+item.is_plant === 0">已告罄</div>
      </div>
    </div>
    <button @click="onClick(selectItem)">开始种植{{ this.selectItem.name }}</button>
    <div class="reward">{{ selectItem.desc }}</div>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {
      avatar: {
        type: String,
        default: '',
      },
      nickname: {
        type: String,
        default: '',
      },
      list: {
        type: Array,
        default: () => [],
      },
      onClick: {
        type: Function,
        default: () => {},
      },
    },
    name: 'selection-modal',
    data() {
      return {
        selectList: [],
        selectItem: {},
      };
    },
    computed: {},
    watch: {},
    created() {
      this.selectList = [...this.list];
      this.selectList.forEach((item, index) => {
        this.$set(item, 'id', index + 1);
        this.$set(item, 'select', false);
      });
      this.handleSelect(this.selectList[0]);
    },
    mounted() {},
    methods: {
      handleSelect(item) {
        if (item.is_plant === 0) {
          this.$toast('啊哦，被抢光了，下回快一点哦');
          return;
        }
        this.selectList.forEach((item) => {
          item.select = false;
        });
        item.select = true;
        this.selectItem = item;
      },
    },
  };
</script>
<style scoped lang="less">
  .selection-modal {
    width: 3.07rem;
    height: 4.4rem;
    background: linear-gradient(180deg, #efffdf 0%, #fffffe 100%);
    border-radius: 0.12rem;
    border-top: 0.02rem solid #ffeacc;
    border-left: 0.02rem solid rgba(255, 196, 112, 1);
    border-right: 0.02rem solid rgba(255, 196, 112, 1);
    border-bottom: 0.02rem solid rgba(255, 196, 112, 1);
    .top-user {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -0.365rem;
      left: 50%;
      width: 1.18rem;
      height: 0.8rem;
      transform: translateX(-50%);
      background: url('~@/assets/images/farm/fruit-select-modal/avatar-bg.png') no-repeat center center;
      background-size: contain;
      &-avatar {
        width: 0.73rem;
        height: 0.73rem;
        border-radius: 50%;
        background-color: #007aff;
      }
      &-name {
        margin-top: -0.1rem;
        height: 0.15rem;
        font-size: 0.15rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #5a963e;
        line-height: 0.15rem;
        // text-stroke: 0.5px #ffffff;
        -webkit-text-stroke: 0.5px #ffffff;
      }
    }
    .desc {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 0 0.17rem;
      margin: 0.6rem auto 0;
      width: 2.25rem;
      height: 0.21rem;
      background: #6bb54c;
      border-radius: 0.13rem;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .select-list {
      overflow-y: clip;
      overflow-x: visible;
      margin: 0.1rem auto 0;
      width: 2.2rem;
      height: 2.22rem;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &-item {
        position: relative;
        margin-bottom: 0.16rem;
        width: 0.88rem;
        height: 0.98rem;
        .sold-out-label {
          position: absolute;
          right: -0.11rem;
          top: 0;
          padding: 0.01rem 0.06rem;
          background: #ff8324;
          border-radius: 0.07rem 0rem 0.07rem 0rem;
          font-size: 0.12rem;
          font-weight: 600;
          color: #ffffff;
        }
        &__image {
          img {
            width: 0.88rem;
            height: 0.88rem;
          }
        }
        &__name {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.12rem;
          color: #666666;
          #checkbox {
            margin-right: 0.04rem;
            width: 0.15rem;
            height: 0.15rem;
            background: #ffffff;
            border-radius: 0.04rem !important;
            border: 0.01rem solid #ff8f53;
          }
          .checkbox__seleced {
            border: 0 !important;
            background-color: #6bb54c !important;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 0.15rem;
              height: 0.15rem;
              border-radius: 0.01rem;
            }
          }
        }
      }
    }
    button {
      margin-top: 0.2rem;
      margin-left: 50%;
      transform: translateX(-50%);
      width: 2.67rem;
      height: 0.47rem;
      background: url('~@/assets/images/farm/fruit-select-modal/button_bg.png') no-repeat center center;
      background-size: contain;
      font-size: 0.16rem;
      font-weight: 600;
      color: #9d4f07;
    }
    .reward {
      margin: 0.15rem auto 0;
      margin-left: 50%;
      transform: translateX(-50%);
      height: 0.12rem;
      font-size: 0.12rem;
      color: #666666;
    }
  }
</style>
