export const otherGameList = [
  {
    id: '1',
    type: 'otherGame',
    gameType: 'gameDrinking',
    reward: 0,
    name: '喝水赚钱',
    className: 'bubble-video bubble-video1-position',
    show: true,
    path: window.location.origin + '/#/game/drinking',
    pageType: 'normal',
    origin: 'local',
  },
  {
    id: '5',
    type: 'otherGame',
    gameType: 'gameInvite',
    reward: 0,
    name: '万元现金',
    className: 'bubble-walk',
    show: true,
    path: process.env.VUE_APP_DOMAIN + '/invite_friend',
    pageType: 'normal',
    origin: 'task',
  },
];
