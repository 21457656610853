<template>
  <div>
    <div class="model_box">
      <img class="bg" :src="stepBg" alt="" />
      <div class="content">
        <h2 class="title">恭喜获得</h2>
        <p class="detail">
          <span class="num">{{ step }}</span>
          <span class="unit">步</span>
        </p>
        <div v-if="double" class="double_btn" @click="handleClickDouble">
          <div class="double_btn_content">
            <span class="double_btn_text">加倍领取</span>
            <img class="video_icon" :src="videoIcon" alt="" />
          </div>
          <div class="double_btn_bottom"></div>
        </div>
      </div>
    </div>
    <img class="light" :src="lightBg" alt="光" />
  </div>
</template>

<script>
  // http://static1.kaixinyf.cn/img/lza5fd72626bf4c4904245142.png 步数背景
  const stepBg = require('../../../assets/images/walk/stepBg.png');
  const lightBg = require('../../../assets/images/walk/lightBg.png');
  const videoIcon = require('../../../assets/images/walk/videoIcon.png');
  export default {
    props: {
      // 步数
      step: {
        type: Number,
        default: 0,
      },
      // 是否翻倍
      double: {
        type: Boolean,
        default: true,
      },
      handleDouble: {
        type: Function,
      },
      handleClose: {
        type: Function,
      },
    },
    data() {
      return {
        stepBg: stepBg,
        lightBg: lightBg,
        videoIcon: videoIcon,
      };
    },
    methods: {
      handleClickDouble() {
        this.handleDouble();
        this.handleClose();
      },
    },
  };
</script>

<style lang="less" scoped>
  .light {
    position: absolute;
    z-index: -1;
    top: -0.72rem;
    width: 2.63rem;
    height: 2.61rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .model_box {
    width: 3rem;
    background: linear-gradient(180deg, #fffaeb 0%, #fff1cc 100%);
    border-radius: 0.155rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    box-sizing: border-box;
    position: relative;
    z-index: 101;
    display: flex;
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.2rem;
    .bg {
      position: absolute;
      z-index: 200;
      top: -0.68rem;
      width: 2.13rem;
      height: 1.27rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: PingFangSC-Semibold, PingFang SC;
      .title {
        font-size: 0.2rem;
        font-weight: bold;
        color: #fd7008;
        line-height: 0.2rem;
        text-align: center;
        margin-bottom: 0.08rem;
      }
      .detail {
        position: relative;
        .num {
          height: 0.34rem;
          font-size: 0.34rem;
          font-weight: bold;
          color: #fd0808;
          line-height: 0.34rem;
        }
        .unit {
          height: 0.16rem;
          font-size: 0.16rem;
          font-weight: bold;
          color: #fd7008;
          line-height: 0.16rem;
          position: relative;
          top: -0.02rem;
        }
      }
      .double_btn {
        margin-top: 0.16rem;
        width: 2.3rem;
        height: 0.46rem;
        position: relative;
        .double_btn_content {
          position: relative;
          z-index: 200;
          width: 2.3rem;
          height: 0.41rem;
          background: #ff5959;
          border-radius: 0.21rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .double_btn_text {
            font-size: 0.21rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #ffffff;
          }
          .video_icon {
            width: 0.18rem;
            height: 0.18rem;
            margin-left: 0.08rem;
          }
        }
        .double_btn_bottom {
          z-index: 199;
          position: absolute;
          top: 0.04rem;
          width: 2.3rem;
          height: 0.42rem;
          background: #e31919;
          border-radius: 0.21rem;
        }
      }
    }
  }
</style>
