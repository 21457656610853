<template>
  <div class="address">
    <h3>领取奖励去浇水</h3>
    <div class="address-item">
      <label for="name">收货人</label>
      <input type="text" id="name" v-model.trim="name" placeholder="名字" />
    </div>
    <div class="address-item">
      <label for="phone">手机号码</label>
      <input type="tel" id="phone" v-model.trim="phone" placeholder="收货人手机号码" />
    </div>
    <div class="address-item" @click="show = true">
      <label for="area">所在地区</label>
      <input type="text" id="area" v-model="area" readonly placeholder="省、市、区、街道" />
    </div>

    <div class="address-item">
      <label for="addressDetail">详细地址</label>
      <input type="textarea" id="addressDetail" v-model.trim="addressDetail" placeholder="小区楼栋/乡村名称" />
    </div>

    <button @click="onSubmit({ name, phone, area, addressDetail })">提交信息</button>

    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>
<script>
  import { useCascaderAreaData } from '@vant/area-data';

  export default {
    components: {},
    props: {
      onSubmit: {
        type: Function,
        default: () => {},
      },
    },
    name: 'AddressModal',
    data() {
      return {
        show: false,
        name: '',
        phone: '',
        area: '',
        addressDetail: '',
        cascaderValue: '',
        options: useCascaderAreaData(),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // 全部选项选择完毕后，会触发 finish 事件
      onFinish({ selectedOptions }) {
        this.show = false;
        this.area = selectedOptions.map((option) => option.text).join('/');
      },
    },
  };
</script>
<style scoped lang="less">
  .address {
    padding: 0.2rem 0.15rem;
    width: 2.7rem;
    height: 3.71rem;
    background: #ffffff;
    border-radius: 0.12rem;
    h3 {
      margin-bottom: 0.2rem;
      height: 0.25rem;
      font-size: 0.18rem;
      font-weight: 600;
      color: #333333;
      line-height: 0.25rem;
      text-align: center;
    }
    &-item {
      margin-top: 0.1rem;
    }
    label {
      vertical-align: middle;
      margin-right: 0.16rem;
      display: inline-block;
      width: 0.56rem;
      font-size: 0.14rem;
      color: #333333;
      line-height: 0.14rem;
    }
    input {
      padding: 0 0.2rem;
      width: 1.58rem;
      height: 0.4rem;
      background: #f7f8fa;
      border-radius: 0.06rem;
    }
    input#addressDetail {
      height: 1.11rem;
      line-height: normal;
    }
    button {
      margin-top: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.6rem;
      height: 0.4rem;
      background: url('~@/assets/images/farm/congratulation-modal/button_bg.png') no-repeat center center;
      background-size: contain;
      font-size: 0.16rem;
      font-weight: 600;
      color: #9d4f07;
    }
  }
</style>
