import Axios from 'axios';
import Qs from 'qs';
import SDK from '@/utils/sdk';
import loading from '@/components/Loading/Loading';
import { Toast } from 'vant';
import { trackClick } from '@/utils/track';

const axiosInstance = Axios.create({
  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' },
  timeout: 15000,
  withCredentials: true,
  paramsSerializer(params) {
    return Qs.stringify(Object.assign({}, params));
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    loading.stop();
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    const { config } = response;
    loading.stop();
    return handleOKHTTP(response, config);
  },
  (error) => {
    loading.stop();
    return handleErrorHTTP(error);
  }
);

/**
 * @description: http 响应成功拦截处理
 * @param response
 * @param parseData
 */

const handleOKHTTP = function (response, { parseData }) {
  const { data } = response;

  if (Number(data.code) === 0) {
    if (parseData) {
      return data.data;
    } else {
      return data;
    }
  }
  return handleException(response);
};

/**
 * @description: 业务错误处理
 * @param {object} response - 返回数据
 */

const handleException = function (response) {
  const {
    data: { code, action, message },
    config: { url, data },
  } = response;
  const info = SDK.appCall(SDK.callMethod.GET_USER_INFO);
  let client = {};
  if (info) {
    client = JSON.parse(info);
  }
  trackClick({
    page_title: 'request_error',
    element_name: 'exception',
    remarks: JSON.stringify({
      data,
      path: url,
      code,
      message,
      client: {
        uid: client.uid,
        'client-v': client['client-v'] || '',
        'app-v': client['app-v'] || '',
        'device-id': client['device-id'] || '',
        platform: client.platform || '',
        'platform-brand': client['platform-brand'] || '',
        'platform-model': client['platform-model'] || '',
        'utm-source': client['utm-source'] || '',
        // accessToken: client.accessToken || '',
      },
    }),
  });
  switch (code) {
    case '10001': // 未登录
      login();
      break;
    case '10003': // 参数不正确
      return Toast(`参数不正确`);
    case '10005': // access_token 已过期
      if (info) {
        trackClick({
          page_title: 'token_expired',
          element_name: '10005',
          remarks: `uid: ${client.uid};acToken: ${client.accessToken};tourToken:${client.tourist_token}`,
        });
      }
      SDK.appCall(SDK.callMethod.REFRESH_TOKEN);
      break;
    case '10035':
      if (info) {
        trackClick({ page_title: 'token_expired', element_name: '10035', remarks: info });
      }
      SDK.appCall(SDK.callMethod.REFRESH_TOKEN);
      break;
    case '10037':
      if (info) {
        trackClick({ page_title: 'token_expired', element_name: '10037', remarks: info });
      }
      SDK.appCall(SDK.callMethod.REFRESH_TOKEN);
      break;
    case '40001': // 签名 token 过期, 需要重新获取token
      if (info) {
        trackClick({ page_title: 'token_expired', element_name: '40001', remarks: info });
      }
      SDK.appCall(SDK.callMethod.REFRESH_TOKEN);
      break;
    case '20000': // 需要登录
      login();
      break;
    default:
      // console.log('toast')
      if (String(action) === 'toast') {
        if (message) {
          Toast(message);
          // return false;
        }
      }
  }

  return Promise.reject(response.data);
};

/**
 * @description: http 响应错误拦截处理
 * @param error
 * @return: {Promise}
 */

const handleErrorHTTP = function (error) {
  const {
    response: { status },
  } = error;
  if (error.response) {
    switch (status) {
      case 404:
        throw Error('未找到请求资源！');
      case 500:
        throw Error('服务器异常');
    }
  } else {
    console.log('No Response Error', error);
  }
  return Promise.reject(error);
};

/**
 * @description: 打开原生登录页
 */

const login = function () {
  try {
    const LOGIN_SCHEME = 'kuaiyin://login';
    const data = JSON.stringify({ url: LOGIN_SCHEME });

    SDK.appCall(SDK.callMethod.OPEN_PAGE_WITH_JSON, data);
  } catch (e) {
    console.log(e);
  }
};

export default axiosInstance;
