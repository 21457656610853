export default [
  {
    path: '/game/farm',
    name: 'Farm',
    component: () => import('@/views/Farm/index.vue'),
  },
  {
    path: '/game/farm/result',
    name: 'FarmResult',
    component: () => import('@/views/Farm/Result/index.vue'),
  },
];
