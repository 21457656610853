<template>
  <div class="modal-warpper">
    <div class="modal-container">
      <!-- 模态框顶部开始 -->
      <div class="modal-top-position">
        <!-- 模态框标题开始 -->
        <div class="modal-title">{{ title }}</div>
        <!-- 模态框标题结束 -->
        <!-- 关闭按钮开始 -->
        <div class="modal-close" @click="handleClose"></div>
        <!-- 关闭按钮结束 -->
      </div>
      <!-- 模态框顶部结束 -->
      <!-- 具体内容开始 -->
      <div class="modal-content-warpper">
        <!-- 新走路规则 -->
        <div v-if="isNew" class="modal-content">
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem; margin-bottom: 0.14rem">
            1） 运动步数每个小时更新1次，记得经常打开快音-步数活动领取哦~；
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem; margin-bottom: 0.14rem">
            2） 获得一定数量步数后直接领取金币奖励，金币可以兑换为现金红包哦！
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem; margin-bottom: 0.14rem">
            3） 完整观看精彩视频可获得更多的步数奖励，快来轻松完成今日的步数目标吧
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem; margin-bottom: 0.14rem">
            4） 每天可以免费偷步数，偷完后可观看精彩视频获得额外机会
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem; margin-bottom: 0.14rem">
            5） 赚钱页还有很好好玩赚金币的小游戏和任务，多多去玩吧！
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem; margin-bottom: 0.14rem">
            6） 您有任何问题咨询，欢迎点击加入官方讨论QQ群：849403767；
          </p>
        </div>
        <!-- 新走路规则结束 -->

        <!-- 老走路规则 -->
        <div v-else class="modal-content">
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem; margin-bottom: 0.14rem">
            1）运动步数通过运动产生，每天最多统计20000步，运动步数每个小时更新1次，记得经常打开快音领取哦；
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem">
            2）每天进入页面签到可以获得3000步签到奖励，签到后再分享给好友可得额外的分享奖励步数；
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem">
            3）完整观看广告视频可得最多10000步奖励，每天可以观看3次；完整看完视频后再分享给好友可得额外分享奖励步数；看完3个视频后在赚钱页还可以点击看视频赚钱继续赚金币哦；
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem">
            4）每天可以免费偷3次步数，偷完后可观看视频再获得3次机会，偷到步数后分享给好友可得额外的分享奖励步数；
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem">
            5）每天获取的步数会在次日0:00后自动转为金币，转换可能存在延迟，转换汇率会随当天平台收益浮动，越多人参与收益越高，快去向周围的朋友推荐快音吧！
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem">
            6）赚钱页还有很好好玩赚金币的小游戏和任务，多多去玩吧！
          </p>
          <p style="color: #ffffff; font-size: 0.14rem; line-height: 0.17rem">
            7）您有任何问题咨询，欢迎点击加入官方讨论QQ群：728704491；
          </p>
        </div>
        <!-- 老走路规则结束 -->
      </div>
      <!-- 具体内容结束 -->
      <!-- 确认按钮开始 -->
      <div class="modal-content-ok_button">
        <div class="ok-button" @click="handleClose">好的</div>
      </div>
      <!-- 确认按钮结束 -->
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      isNew: {
        type: Boolean,
        default: true,
      },
      handleClose: {
        type: Function,
      },
    },
    methods: {
      close() {},
    },
  };
</script>

<style lang="less" scoped>
  .modal-shade {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-top-position {
    margin-bottom: 0.1rem;
    display: flex;
    justify-content: space-between;
  }

  .modal-container {
    width: 2.9rem;
    height: auto;
    background: linear-gradient(180deg, rgba(134, 180, 255, 1) 0%, rgba(88, 150, 250, 1) 100%);
    border-radius: 0.12rem;
    border: 0.005rem solid rgba(151, 151, 151, 1);
    padding: 0.15rem;
  }

  .modal-content-warpper {
    box-sizing: border-box;
    padding: 0.15rem;
    background: rgba(82, 144, 255, 1);
    box-shadow: 0 0.02rem 0.03rem 0 rgba(50, 109, 215, 1);
    border-radius: 0.06rem;
    color: #fff;
    text-align: left;
  }

  .modal-title {
    font-size: 0.16rem;
    font-weight: bold;
    color: #fff;
  }

  .modal-close {
    height: 0.2rem;
    width: 0.2rem;
    background-image: url(../../../assets/images/walkTask/close.png);
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
  }

  .modal-content-ok_button {
    margin-top: 0.37rem;
    width: 100%;
  }

  .ok-button {
    width: 100%;
    background: linear-gradient(180deg, rgba(246, 251, 193, 1) 0%, rgba(255, 204, 0, 1) 100%);
    box-shadow: 0 0.06rem 0.02rem 0 rgba(15, 115, 255, 0.5);
    border-radius: 0.2rem;
    text-align: center;
    font-size: 0.16rem;
    font-weight: bold;
    color: #855600;
    padding: 0.095rem 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: all 0.4s ease;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    transform: scale(0.2);
  }
  .modal-shade-fade-enter-active,
  .modal-shade-fade-leave-active {
    transition: all 0.4s ease;
  }

  .modal-shade-fade-enter,
  .modal-shade-fade-leave-to {
    opacity: 0;
  }
</style>
