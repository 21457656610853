import { GamePost, Api } from '@/http/index';

export default {
  namespaced: true,
  state: () => {
    return {
      data: {
        punch_list: [],
        gold_package_progress: {
          task_value: 4,
          target_value: 0,
        },
        bottom_feed_ad_id: 946733737,
        business_name_punch: 'gameMoneyTreePunch',
        business_name_fill_punch: 'gameMoneyTreeFillPunch',
        business_name_punch_double: 'gameMoneyTreePunchDouble',
        punch_double_video_group: {
          ad_group_id: null,
          game_type: 'game_money_tree_punch_double',
        },
        gold_package_video_group: {
          ad_group_id: null,
          game_type: 'game_money_tree_gold_package',
        },
        configs: {
          gold_package_max_coin: 80, // 单个金袋最大容量
          gold_package_expire_hours: 48, // 金袋有效时间，小时
          gold_package_punch_time: 4, // 升级金袋需要的整点打卡次数
        },
      },
    };
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
  },
  actions: {
    async getSignPageInfo({ commit }) {
      const data = await GamePost(
        Api.GET_TREE_PUNCH_PAGE_INFO,
        {},
        {
          showLoading: false,
        }
      ).catch((e) => {});

      commit('setData', data);
    },
  },
  getters: {
    list(state) {
      return state.data.punch_list;
    },
    progress(state) {
      const { data } = state;
      return {
        ...data.gold_package_progress,
        is_full: data.gold_package_progress.target_value >= state.data.gold_package_progress.task_value,
      };
    },
    goldVideoGroup(state) {
      return state.data.gold_package_video_group;
    },
    punchDoubleVideoGroup(state) {
      return state.data.punch_double_video_group;
    },
    businessNameMap(state) {
      const { business_name_punch, business_name_fill_punch, business_name_punch_double } = state.data;
      return {
        business_name_punch,
        business_name_fill_punch,
        business_name_punch_double,
      };
    },

    feedAdId(state) {
      return state.data.bottom_feed_ad_id;
    },

    bagMaxCoin(state) {
      return state.data.configs.gold_package_max_coin;
    },
  },
};
