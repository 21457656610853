import { GamePost } from '@/http/index';
import { getUrlParams } from '@/utils/common/index';

// 0未打卡，1可打卡，2已打卡，3已过期 4可翻倍
export default {
  namespaced: true,
  state: () => {
    // 为喝水列表设定初始值
    const drinkingCardList = Array(8)
      .fill()
      .map((_, i) => i + 1)
      .map((item, index) => {
        return {
          id: index,
          status: 0,
          icon: 0,
          min_hour: '00:00:00',
          fill_punch_ttl: 30,
        };
      });
    return {
      drinkingCardList, // 当前八杯水列表
      keepSign: {}, // 连续打卡
      fullSign: {}, // 全勤打卡
      adInfo: {}, // 广告信息
      nextInitTime: 0, // 下次打卡时差/ms
      dailyCoins: '-',
      bannerList: [],
      mediationAdInfo: {
        ad_group_id: 0,
        ad_platform_status: false,
      },
      businessName: '',
      drinkCardTitle: '喝水赚金币，健康好生活',
      businessNamePunch: '',
      businessNamePunchNumReward: '',
      businessNamePunchDoubleReward: '',

      punchRewardList: [
        {
          num: 2,
          reward_amount: '0',
          ad_reward_amount: 1,
        },
        {
          num: 4,
          reward_amount: '0',
          ad_reward_amount: 2,
        },
        {
          num: 6,
          reward_amount: '0',
          ad_reward_amount: 0,
        },
        {
          num: 8,
          reward_amount: '0',
          ad_reward_amount: 0,
        },
      ],

      cupInfo: {
        // 水杯信息
        vol: 0, // 容量
        level: 1, // 等级
        reduce_cup: false,
        time: 1626665308,
      },

      healthPoint: 0, // 健康值
      healthPointDoubleVideoGroup: {
        // 健康值翻倍广告
        ad_group_id: 1,
        game_type: 'game_drinking_health_point_double',
      },
      punchDoubleVideoGroup: {
        // 打卡翻倍广告
        ad_group_id: 1,
        game_type: 'game_drinking_punch_double',
      },
      incrWaterCupVideoGroup: {
        // 升杯广告
        ad_group_id: 1,
        game_type: 'game_drinking_incr_water_cup',
      },
      punchNumRewardVideoGroup: {
        // 打卡次数红包奖励广告
        ad_group_id: 1,
        game_type: 'game_drinking_punch_num_reward',
      },

      continuousSignVideoGroup: {
        // 连续打卡次数奖励广告
        ad_group_id: 1,
        game_type: 'game_drinking_continuous_sign',
      },

      punchUiAb: 'a',

      allPunchCount: 0,
    };
  },
  mutations: {
    setDrinkingCardList(state, list) {
      state.drinkingCardList = [...list];
    },
    setKeepSign(state, data) {
      state.keepSign = { ...data };
    },
    setFullSign(state, data) {
      state.fullSign = { ...data };
    },
    setAdInfo(state, data) {
      state.adInfo = { ...data };
    },
    setNextInitTime(state, time) {
      state.nextInitTime = time;
    },
    setDailyCoins(state, coins) {
      state.dailyCoins = coins;
    },
    setBannerList(state, list) {
      state.bannerList = [...list];
    },
    setMediationAdInfo(state, data) {
      state.mediationAdInfo = { ...data };
    },
    setBusinessName(state, name) {
      state.businessName = name;
    },
    setDrinkCardTitle(state, title) {
      state.drinkCardTitle = title;
    },

    setBusinessNamePunch(state, payload) {
      state.businessNamePunch = payload;
    },
    setBusinessNamePunchNumReward(state, payload) {
      state.businessNamePunchNumReward = payload;
    },

    setBusinessNamePunchDoubleReward(state, payload) {
      state.businessNamePunchDoubleReward = payload;
    },

    setPunchRewardList(state, list) {
      state.punchRewardList = [...list];
    },

    setCupInfo(state, data) {
      state.cupInfo = { ...data };
    },

    setHealthPoint(state, payload) {
      state.healthPoint = Number(payload);
    },

    setHealthPointDoubleVideoGroup(state, data) {
      state.healthPointDoubleVideoGroup = data;
    },
    setPunchDoubleVideoGroup(state, data) {
      state.punchDoubleVideoGroup = data;
    },
    setIncrWaterCupVideoGroup(state, data) {
      state.incrWaterCupVideoGroup = data;
    },
    setPunchNumRewardVideoGroup(state, data) {
      state.punchNumRewardVideoGroup = data;
    },
    setContinuousSignVideoGroup(state, data) {
      state.continuousSignVideoGroup = data;
    },

    setPunchUiAb(state, payload) {
      state.punchUiAb = payload;
    },

    setAllPunchCount(state, payload) {
      state.allPunchCount = payload;
    },
  },
  actions: {
    async getDrinkingPageInfo({ commit }) {
      const url = '/drinking/detail_v3c';
      const position = getUrlParams('position');
      const data = await GamePost(
        url,
        { position: position || '' },
        {
          showLoading: false,
        }
      ).catch((e) => {});
      let {
        list, // 当前八杯水列表
        normal_sign_stats, // 连续打卡
        full_sign_stats, // 全勤打卡
        ad_info, // 广告信息
        daily_coins, // 当天总收益
        server_ts, // 服务器当前时间
        next_ts, // 下一次打卡时间
        banner, // 广告轮播信息
        // 聚合广告信息
        ad_group_id,
        ad_platform_status,
        reissue_drink_ad_group_id,
        title,
        // 新增属性
        punch_num_reward_list,
        cup_info,
        health_point,
        // 广告信息
        health_point_double_video_group,
        punch_double_video_group,
        incr_water_cup_video_group,
        punch_num_reward_video_group,
        continuous_sign_video_group,
        punch_ui_ab,
        all_punch_count,

        business_name_punch_reward,
        business_name_fill_punch_reward,
        business_name_punch_num_reward,
        business_name_punch_double_reward,
      } = data;

      commit('setDrinkingCardList', list);
      commit('setKeepSign', normal_sign_stats);
      commit('setFullSign', full_sign_stats);
      commit('setAdInfo', ad_info);
      commit('setDailyCoins', daily_coins);
      commit('setBannerList', banner);
      commit('setMediationAdInfo', { ad_group_id, ad_platform_status, reissue_drink_ad_group_id });
      const nextInitTime = (next_ts - server_ts) * 1000; // 下次打卡时差
      commit('setNextInitTime', nextInitTime);
      if (title) {
        commit('setDrinkCardTitle', title);
      }
      // 恭喜获得布局
      commit('setBusinessName', business_name_fill_punch_reward);
      commit('setBusinessNamePunch', business_name_punch_reward);
      commit('setBusinessNamePunchNumReward', business_name_punch_num_reward);
      commit('setBusinessNamePunchDoubleReward', business_name_punch_double_reward);

      commit('setPunchRewardList', punch_num_reward_list);
      // 总打卡次数
      commit('setAllPunchCount', all_punch_count);
      // 水杯信息
      commit('setCupInfo', cup_info);
      // 健康值
      commit('setHealthPoint', health_point);

      commit('setHealthPointDoubleVideoGroup', health_point_double_video_group);
      commit('setPunchDoubleVideoGroup', punch_double_video_group);
      commit('setIncrWaterCupVideoGroup', incr_water_cup_video_group);
      commit('setPunchNumRewardVideoGroup', punch_num_reward_video_group);
      commit('setContinuousSignVideoGroup', continuous_sign_video_group);
      commit('setPunchUiAb', punch_ui_ab);
    },
  },
  getters: {
    // 七日签到列表
    signSevenDaysList(state) {
      const list = [
        {
          dayTime: 1,
          hasSign: false,
        },
        {
          dayTime: 2,
          hasSign: false,
        },
        {
          dayTime: 3,
          hasSign: false,
        },
        {
          dayTime: 4,
          hasSign: false,
        },
        {
          dayTime: 5,
          hasSign: false,
        },
        {
          dayTime: 6,
          hasSign: false,
        },
        {
          dayTime: 7,
          hasSign: false,
        },
      ];
      const { sign_days } = state.keepSign;
      return list.map((day) => {
        day.hasSign = sign_days >= day.dayTime;
        day.isRewardDay = day.dayTime === 7;
        return day;
      });
    },

    // 是否满足连续签到领奖条件
    canReceiveKeepSignReward(state) {
      const { keepSign } = state;
      if (keepSign) {
        const { sign_days, sign_days_rule } = keepSign;
        return +sign_days === +sign_days_rule;
      } else {
        return false;
      }
    },

    // 打卡次数
    signedTimes(state) {
      const { allPunchCount } = state;
      return allPunchCount;
    },

    // 杯子是否有水
    isCupFull(state) {
      const { drinkingCardList } = state;
      return drinkingCardList.some((item) => Number(item.status) === 1);
    },

    drinkingTimeTable(state) {
      const { drinkingCardList } = state;
      return drinkingCardList.map((item) => {
        return item.min_hour.split(':')[0].replace(/\b(0+)/gi, '');
      });
    },
  },
  modules: {},
};
