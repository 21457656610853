<template>
  <div class="modal-container" :style="{ backgroundImage: `url(${bgImg})` }">
    <h3 class="modal-title" :class="signModalType">{{ tips }}</h3>
    <div class="modal-days">
      <div
        class="modal-days-item"
        :class="[index == 8 ? 'big-item no-margin' : '', index == 4 ? 'no-margin' : '']"
        v-for="(item, index) in dayList"
        :key="index"
      >
        <div class="modal-days-item-content">
          <div class="modal-days-item-content__coin">{{ item.reward_amount }}</div>
          <div class="modal-days-item-content__icon"></div>
        </div>
        <div class="modal-days-item__label" v-if="item.tomorrowlabel">
          <span>明日可领</span>
        </div>
        <div class="modal-days-item__text" :class="signModalType">第{{ item.day }}天</div>
        <div class="modal-days-item__cover" v-if="item.sign">
          <div class="modal-days-item__cover-icon" :class="signModalType"></div>
        </div>
      </div>
    </div>

    <div class="modal-button top" :class="signModalType" @click="topButton.onClick" v-if="topButton.isShow">
      {{ topButton.label }}
    </div>
    <div class="modal-button bottom" :class="signModalType" @click="bottomButton.onClick">{{ bottomButton.label }}</div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      tips: {
        type: String,
        default: '',
      },
      dayList: {
        type: Array,
        default: () => [],
      },
      // 弹窗类型sleep / drink
      signModalType: {
        type: String,
        default: 'sleep',
      },
      topButton: {
        type: Object,
        default: () => ({
          label: '看视频再领266金币',
          onClick: () => {},
        }),
      },
      bottomButton: {
        type: Object,
        default: () => ({
          label: '邀请好友赚现金',
          onClick: () => {},
        }),
      },
    },
    data() {
      return {
        sleepBg: 'http://static1.kaixinyf.cn/img/lza5f98dd9122122376630261.png',
        drinkBg: 'http://static1.kaixinyf.cn/img/lza5f98dd73e8d3f250305975.png',
      };
    },
    computed: {
      bgImg() {
        return this.signModalType === 'sleep' ? this.sleepBg : this.drinkBg;
      },
      signIcon() {
        return this.signModalType === 'sleep' ? this.sleepBg : this.drinkBg;
      },
    },
    watch: {},
    created() {},
    mounted() {
      console.log(this.closeFn);
    },
    methods: {},
  };
</script>

<style lang="less" scoped>
  .modal {
    font-family: PingFangSC-Regular, PingFang SC;
    &-container {
      position: relative;
      width: 3rem;
      height: 4.52rem;
      border-radius: 0.12rem;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    &-close {
      position: absolute;
      right: 0rem;
      top: -0.2rem;
      z-index: 999;
      img {
        width: 0.24rem;
        height: 0.24rem;
      }
    }
    &-title {
      position: absolute;
      top: 1.4rem;
      width: 100%;
      height: 0.13rem;
      line-height: 0.13rem;
      font-size: 0.13rem;
      height: 0.13rem;
      text-align: center;
      &.sleep {
        color: #ffffff;
      }
      &.drink {
        color: #3c77fb;
      }
    }

    &-days {
      position: absolute;
      top: 1.81rem;
      padding: 0 0.225rem;
      display: flex;
      flex-wrap: wrap;
      &-item {
        position: relative;
        margin-right: 0.1rem;
        margin-bottom: 0.2rem;
        width: 0.43rem;
        height: 0.5rem;
        background: #c0e5ff;
        border-radius: 0.04rem;
        font-size: 0.1rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.032rem;
        &-content {
          width: 100%;
          height: 0.42rem;
          background: #ffffff;
          border-radius: 0.04rem;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0.02rem 0;
          box-sizing: border-box;
          &__coin {
            width: 100%;
            height: 0.12rem;
            font-size: 0.12rem;
            font-weight: 400;
            color: #ee5962;
            line-height: 0.12rem;
            text-align: center;
            margin-bottom: 0.01rem;
          }
          &__icon {
            position: absolute;
            top: 0.15rem;
            width: 0.16rem;
            height: 0.16rem;
            background: url('http://static1.kaixinyf.cn/img/lza5ed5c047c1792652681349.png') no-repeat center center;
            background-size: contain;
          }
        }
        &__text {
          position: absolute;
          bottom: 0;
          left: 0rem;
          width: 100%;
          height: 0.13rem;
          font-size: 0.1rem;
          padding: 0.015rem 0;
          background: #1f63ff;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-bottom-left-radius: 0.04rem;
          border-bottom-right-radius: 0.04rem;
          box-sizing: border-box;
          font-family: PingFangSC-Light, PingFang SC;
          &.sleep {
            background: #1f63ff;
          }
          &.drink {
            background: #7cdcff;
          }
        }

        &__label {
          position: absolute;
          top: -0.19rem;
          left: -0.015rem;
          width: 0.46rem;
          height: 0.13rem;
          background: #ffc644;
          border-radius: 0.045rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding: 0.02rem 0;
          line-height: 0.13rem;
          span {
            position: absolute;
            font-size: 0.09rem;
            height: 0.09rem;
            font-weight: bold;
            color: #ffffff;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0.13rem;
            width: 0;
            height: 0;
            border: 0.03rem solid;
            left: 0.2rem;
            border-color: transparent transparent #ffc644;
            transform: rotateX(180deg);
          }
        }

        &__cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.04rem;
          background: rgba(255, 255, 255, 0.6);
          z-index: 1;
          &-icon {
            position: absolute;
            left: 50%;
            top: 0.19rem;
            margin-left: -0.08rem;
            width: 0.16rem;
            height: 0.16rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            &.sleep {
              background-image: url('http://static1.kaixinyf.cn/img/lza5f98e8f2db2f0102900317.png');
            }
            &.drink {
              background-image: url('http://static1.kaixinyf.cn/img/lza5f98e8fb56ddf433842638.png');
            }
          }
        }
      }
    }
    &-button {
      width: 2.35rem;
      height: 0.38rem;
      border-radius: 0.25rem;
      text-align: center;
      font-size: 0.15rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.42rem;
      letter-spacing: 1px;
      &.sleep {
        background: #1f63ff;
      }
      &.drink {
        background: #5f90ff;
      }
    }
  }
  .top {
    position: absolute;
    left: 0.325rem;
    bottom: 0.86rem;
  }
  .bottom {
    position: absolute;
    left: 0.325rem;
    bottom: 0.39rem;
  }
  .no-margin {
    margin: 0;
  }
  .big-item {
    width: 0.96rem;
    height: 0.5rem;
    & .modal-days-item-content {
      width: 0.9rem;
      height: 0.42rem;
    }
  }
</style>
