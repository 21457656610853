export default [
  {
    path: '/game/tree',
    name: 'Tree',
    component: () => import('@/views/Tree/index.vue'),
  },
  {
    path: '/game/tree/steal',
    name: 'TreeSteal',
    component: () => import('@/views/Tree/Steal/StealTree.vue'),
  },
  {
    path: '/game/tree/sign',
    name: 'TreeSign',
    component: () => import('@/views/Tree/Sign/index.vue'),
  },
];
