import Vue from 'vue';
import Vuex from 'vuex';
import sdk from '@/utils/sdk';
import task from './modules/task';
import drink from './modules/drink/index';
import video from './modules/video';
import sleep from './modules/sleep';
import new_walk from './modules/new_walk';
import walk from './modules/walk';
import fortune_wheel from './modules/fortune-wheel';
import new_sleep from './modules/new_sleep';
import chat_bubble from './modules/chat_bubble';
import guess_music from './modules/guess_music';
import new_drink from './modules/new_drink';
import tree from './modules/tree';
import eating from './modules/eating';
import eatingV2 from './modules/eating/eatingV2';
import farm from './modules/farm/index';

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  state: {
    client: {}, // 用户信息
    modalList: [],
    isCompliance: false,
  },
  mutations: {
    setClient(state, data) {
      state.client = { ...data };
    },
    setModalList(state, list) {
      state.modalList = [...list];
    },
    setIsCompliance(state, data) {
      state.isCompliance = data;
    },
  },
  actions: {
    /**
     * @description: 获取客户端信息
     * @param {Function} commit
     * @return {void}
     */
    getClient({ commit }) {
      const { appCall } = sdk;
      const info = appCall(sdk.callMethod.GET_USER_INFO);

      if (info) {
        const client = JSON.parse(info);
        commit('setClient', client || {});
      }
    },

    /**
     * @description: 打开弹窗
     * @param {Function} commit
     * @param {Function} dispatch
     * @param {object} state
     * @param {object} data
     * @return {void}
     */
    openModal({ commit, dispatch, state }, data) {
      let { closeFn, show, modalType } = data;
      const originList = state.modalList.map((item) => {
        return { ...item, overlay: false };
      });
      const modalList = [...originList];

      // 默认打开弹框
      if (!show) show = !show;
      // 默认按钮点击事件为关闭弹窗
      if (!closeFn) {
        closeFn = () => {
          dispatch('closeModal', { modalType });
        };
      }
      data = { ...data, closeFn, show, overlay: true };
      const list = [...modalList, data];
      commit('setModalList', list);
    },

    /**
     * @description: 关闭弹窗
     * @param {Function} commit
     * @param {object} state
     * @param {object} data
     * @return {void}
     */
    closeModal({ commit, state }, data = {}) {
      const { modalType } = data;
      const list = [...state.modalList];
      const index = state.modalList.findIndex((item) => item.modalType === modalType);
      if (index > -1) {
        list[index] = {
          ...list[index],
          show: false,
        };
        list.splice(index, 1);
      } else {
        // 默认关闭最后一个
        list.pop();
      }
      if (list.length) {
        list[list.length - 1].overlay = true;
      }
      commit('setModalList', list);
    },
  },
  getters: {
    client: (state) => {
      return state.client;
    },
    modalList(state) {
      return state.modalList;
    },
    isCompliance(state) {
      return state.isCompliance;
    },
  },
  modules: {
    task,
    drink,
    video,
    sleep,
    new_walk,
    walk,
    fortune_wheel,
    new_sleep,
    chat_bubble,
    guess_music,
    new_drink,
    tree,
    eating,
    eatingV2,
    farm,
  },
});

export default store;
