<template>
  <div class="modal-container">
    <div class="modal-top"></div>
    <p class="modal-tip">阅读快音的消息推送就有金币送哦～</p>
    <div class="modal-button" @click="handleBtn">我知道了</div>
  </div>
</template>

<script>
  export default {
    props: {
      handleBtn: Function,
    },
  };
</script>

<style lang="less" scoped>
  .modal-container {
    position: relative;
    width: 2.79rem;
    height: auto;
    border-radius: 0.12rem;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0.12rem 0 0.24rem;
  }
  .modal-top {
    width: 2.54rem;
    height: 2.3rem;
    background: url('http://static1.kuaiyin123.cn/img/lza5f3ba2ad1cf80715617784.png') no-repeat center center;
    background-size: contain;
    margin: 0 auto;
  }
  .modal-tip {
    margin: 0.18rem auto 0;
    font-size: 0.14rem;
    text-align: center;
    color: #333333;
  }
  .modal-button {
    margin: 0.24rem auto 0;
    width: 1.62rem;
    height: 0.38rem;
    line-height: 0.38rem;
    font-size: 0.14rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    background: rgba(250, 65, 35, 1);
    box-shadow: 0 0.06rem 0.1rem 0 rgba(255, 36, 0, 0.3), inset 0 0.06rem 0.06rem 0 rgba(255, 149, 0, 0.3),
      inset 0 -0.06rem 0.06rem 0 rgba(255, 0, 0, 0.6);
    border-radius: 0.22rem;
    text-align: center;
  }
</style>
