<template>
  <div class="modal-container">
    <img class="top-img" src="http://static3.kuaiyin123.cn/img/lza5f0ef73262e49290230492.png" />
    <div class="content">
      <h3>打开快音消息推送</h3>
      <h3>每天至少领200金币</h3>
      <div class="content-reward">打开消息通知</div>
      <div class="modal-button" @click="handleBtn">去打开</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      handleBtn: Function,
    },
  };
</script>

<style lang="less" scoped>
  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 0.25rem;
    width: 2.6rem;
    height: auto;
    border-radius: 0.12rem;
    background-color: #fff;
  }
  .top-img {
    display: block;
    width: 2.1rem;
    margin: 0 auto;
  }
  .content {
    h3 {
      color: #333333;
      font-size: 0.16rem;
      font-weight: bold;
      text-align: center;
    }
    &-reward {
      padding: 0.15rem 0;
      color: #bbbbbb;
      font-size: 0.12rem;
      text-align: center;
    }
  }
  .modal-button {
    width: 2rem;
    height: 0.38rem;
    line-height: 0.38rem;
    font-size: 0.14rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    background: rgba(250, 65, 35, 1);
    box-shadow: 0 0.06rem 0.1rem 0 rgba(255, 36, 0, 0.3), inset 0 0.06rem 0.06rem 0 rgba(255, 149, 0, 0.3),
      inset 0px -0.06rem 0.06rem 0 rgba(255, 0, 0, 0.6);
    border-radius: 0.22rem;
    text-align: center;
  }
</style>
