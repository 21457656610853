const TASK_API = {
  GET_TASK_PAGE_INFO: '/task/getTaskPageInfo', // 获取任务页面信息
  // 获取弹窗信息
  GET_WINDOW_INFO: '/window/windows',
  // 手动签到
  GET_HANDLE_SIGN_INFO: '/task/get_sign_in_list',
  // 获取分享信息
  GET_SHARE_INFO: '/invite/get_share_info',
  // 上报错误
  EXCEPTION_LOG_API: '/config/exception_log',
  // 获取插屏页广告信息
  GET_AD_INTERSTITIAL_INFO: '/task/getAdInterstitialInfo',
};

export default TASK_API;
