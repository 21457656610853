<template>
  <div class="modal_wrapper">
    <img class="levelup_title" src="@/assets/images/drinkingTask/levelup_title.png" alt="title" />
    <p class="label">单次可饮用的水量增加至</p>
    <p class="water_value">{{ waterValue }}ml</p>
    <img class="levelup_cup" src="@/assets/images/drinkingTask/levelup_cup.png" alt="cup" />
    <p class="tips">每日可领取的更多健康值金币奖励</p>
    <div class="button" @click="onClose">我知道了</div>
  </div>
</template>

<script>
  export default {
    props: {
      onClose: {
        type: Function,
        default: () => {},
      },
      waterValue: {
        type: Number,
        default: 0,
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -0.6rem;
    .levelup_title {
      width: 1.31rem;
      height: 0.31rem;
    }
    .label {
      height: 0.14rem;
      font-size: 0.14rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #aedaff;
      line-height: 0.14rem;
      margin-top: 0.28rem;
    }
    .water_value {
      font-size: 0.24rem;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #aedaff;
      line-height: 0.24rem;
      margin-top: 0.09rem;
    }
    .levelup_cup {
      width: 2.28rem;
      height: 1.22rem;
      margin-top: 0.2rem;
    }
    .tips {
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.12rem;
      margin-top: 0.14rem;
    }
    .button {
      width: 2.6rem;
      height: 0.4rem;
      background: #62b7ff;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      margin-top: 0.44rem;
    }
  }
</style>
