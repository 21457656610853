<template>
  <div id="app">
    <router-view></router-view>
    <div v-for="(modal, index) in modalList" :key="index">
      <Modal
        v-if="modal.show"
        v-bind="modal.props"
        :isLast="modalList.length && index === modalList.length - 1"
        :type="modal.modalType"
        :closeFn="modal.closeFn"
        :btnFn="modal.btnFn"
        :closeBtnPos="modal.pos"
        :overlay="modal.overlay"
        :showCloseBtn="modal.showCloseBtn"
      />
    </div>
    <AudioPlayer></AudioPlayer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Modal from '@/components/Modal/index';
  import { getRmKReferrerHref, getUrlParams } from '@/utils/common/index';
  import platform from '@/utils/platform/index';
  import { TaskPost, Api } from '@/http';
  import AudioPlayer from '@/views/GuessMusic/components/AudioPlayer/AudioPlayer';

  export default {
    name: 'App',
    components: {
      Modal,
      AudioPlayer,
    },
    props: {},
    computed: {
      ...mapGetters({
        modalList: 'modalList',
      }),
    },
    watch: {
      path: function () {
        this.saRegisterPage();
        const sa = window.sa;
        if (sa && sa.track) {
          sa.quick('autoTrack');
        }
      },
    },
    async created() {
      await this.$store.dispatch('getClient');
      this.sensorsAnalytics();
      // this.getIsCompliance();
    },
    methods: {
      async getIsCompliance() {
        try {
          const { is_compliance } = await TaskPost(Api.GET_IS_COMPLIANCE);
          console.log(`is_compliance`, is_compliance);
          this.$store.commit('setIsCompliance', is_compliance);
        } catch (e) {
          console.log(e);
        }
      },
      sensorsAnalytics() {
        // 神策初始化
        (async () => {
          const sa = window.sa;
          if (sa && sa.track) {
            let userInfo = this.$store.getters.client;
            let info = {
              platform: userInfo.platform,
              client_v: userInfo['app-v'] || userInfo['client-v'],
              sa_device_id: userInfo.sa_device_id,
              device_id: userInfo['device-id'],
            };
            if (info.sa_device_id) {
              sa.login(info.sa_device_id);
            }
            sa.registerPage({
              uid: userInfo.uid || '',
              platform: info.platform || platform.platform,
              app_version: info.client_v || '',
              device_id: info.sa_device_id,
            });
            this.saRegisterPage();
            sa.quick('autoTrack');
          }
        })();
        // 神策初始化 end
      },
      saRegisterPage() {
        let sa = window.sa;
        if (sa && sa.track) {
          // 手动实现referrer， 结合SDK的openpage来使用
          let kReferrer = getUrlParams('k_referrer');
          let href = getRmKReferrerHref();
          if (kReferrer) {
            if (!/^http/.test(kReferrer)) kReferrer = window.location.origin + kReferrer;
          }
          let rg = {
            current_url: decodeURI(href),
            referrer: document.referrer ? decodeURI(document.referrer) : kReferrer || '',
            time: new Date().getTime(),
          };
          rg.source = getUrlParams('source') || '';
          if (this.$route.query.invite_code) {
            rg.invite_code = this.$route.query.invite_code;
          }
          if (this.$route.query.from_type) {
            rg.from_type = decodeURI(this.$route.query.from_type);
          }
          sa.registerPage(rg);
        }
      },
    },
  };
</script>

<style>
  #app {
    font-family: 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
