<template>
  <div class="modal_wrapper">
    <div class="modal_box">
      <img @click="onClose" class="close_btn" src="@/assets/images/newSleep/close.png" alt="关闭" />

      <img class="top_img" src="@/assets/images/newSleep/coin_top.png" alt="金币装饰" />

      <p v-if="!isSigned" class="content" v-html="tips"></p>
      <p v-else class="signed_content" v-html="tips">{{ tips }}</p>

      <div v-if="isSigned && pageType == 'video'" @click="onExit" class="sign_button">
        <span>{{ exitText }}</span>
      </div>

      <div v-if="pageType == 'share'" @click="onShare" class="sign_button">
        <span>{{ exitText }}</span>
      </div>

      <div v-else @click="handleClickButton" class="sign_button">
        <span>立即领取</span>
      </div>

      <p v-if="!isSigned || pageType == 'share'" @click="onExit" class="no_need">我要离开</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // 是否签到
      isSigned: {
        type: Boolean,
        default: true,
      },
      // 下次签到礼物
      nextSignReward: {
        type: Object,
        required: true,
      },
      handleClickButton: {
        type: Function,
      },
      onClose: {
        type: Function,
      },
      onExit: {
        type: Function,
      },
      onShare: {
        type: Function,
      },
      exitText: {
        type: String,
        default: '我知道了',
      },
      pageType: {
        type: String,
        default: 'video',
      },
    },
    computed: {
      tips() {
        const { reward_prop, reward_coin } = this.nextSignReward;
        console.log(reward_prop);
        if (reward_prop) {
          const { title } = reward_prop;
          // 虚拟道具
          return this.isSigned
            ? `明日签到可领取虚拟道具 ${title},<br/>穿戴即可领取更多金币`
            : `今日可领取虚拟道具 ${title},<br/>立即穿戴增加1点状态值`;
        } else {
          // 金币
          return this.isSigned
            ? `明日再来签到可领${reward_coin}金币<br/>明天一定要回来哦～`
            : `今日可再领取${reward_coin}金币`;
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_box {
    position: relative;
    width: 2.68rem;
    height: auto;
    background: #e1efff;
    border-radius: 0.12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.2rem;
    box-sizing: border-box;
    font-family: PingFangSC-Regular, PingFang SC;
    .close_btn {
      position: absolute;
      top: 0.14rem;
      right: 0.12rem;
      width: 0.18rem;
      height: 0.18rem;
    }
    .top_img {
      position: absolute;
      top: -0.38rem;
      left: 0.7rem;
      width: 1.28rem;
      height: 0.83rem;
    }

    .content {
      height: 0.25rem;
      font-size: 0.15rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2538c0;
      line-height: 0.25rem;
      margin-bottom: 0.24rem;
      margin-top: 0.66rem;
    }

    .signed_content {
      width: 2.2rem;
      font-size: 0.15rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2538c0;
      line-height: 0.25rem;
      margin-top: 0.53rem;
      margin-bottom: 0.12rem;
      text-align: center;
    }

    .sign_button {
      width: 1.88rem;
      height: 0.5rem;
      background-image: url(../../../assets/images/newSleep/check-button.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bolder;
        color: #8c3213;
      }
    }
    .no_need {
      width: 0.62rem;
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: rgba(37, 56, 192, 0.3);
      line-height: 0.12rem;
      margin-top: 0.12rem;
    }
  }
</style>
