<template>
  <div class="modal_wrapper">
    <div class="modal_box">
      <img @click="onClose" class="close_btn" src="@/assets/images/newSleep/close.png" alt="关闭" />

      <!-- 明日时间 -->
      <p v-if="success" class="tips">您已成功参与<br />{{ settleDate }}早起瓜分打卡</p>
      <p v-else class="tips">很遗憾当前金币余额不足<br />快去赚取金币吧～</p>

      <img class="coin_img" src="@/assets/images/newSleep/coin_top.png" alt="金币装饰" />

      <div class="coin_info">
        <p class="coin_title">剩余金币</p>
        <p class="coin_num">{{ coin }}</p>
      </div>

      <div class="button_wrapper">
        <div class="button main_button" @click="onJumpLink">
          <span class="button_text">{{ title }}</span>
        </div>
        <div class="button" @click="onClose">
          <span class="button_text">返回</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    props: {
      success: {
        type: Boolean,
        default: false,
      },
      coin: {
        default: '0',
      },
      title: {
        type: String,
        default: '',
      },
      onClose: {
        type: Function,
      },
      onJumpLink: {
        type: Function,
      },
    },
    computed: {
      ...mapGetters('new_sleep', ['settleDate']),
    },
  };
</script>

<style lang="less" scoped>
  .modal_box {
    position: relative;
    width: 2.68rem;
    height: 3.03rem;
    background: #e1efff;
    border-radius: 0.12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0 0.2rem;
    box-sizing: border-box;
    font-family: PingFangSC-Regular, PingFang SC;
    .close_btn {
      position: absolute;
      top: 0.14rem;
      right: 0.12rem;
      width: 0.18rem;
      height: 0.18rem;
    }

    .coin_img {
      width: 1.28rem;
      height: 0.83rem;
    }

    .coin_info {
      text-align: center;
      color: #2538c0;
      .coin_title {
        height: 0.18rem;
        font-size: 0.18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 0.18rem;
        margin-bottom: 0.06rem;
      }
      .coin_num {
        height: 0.34rem;
        font-size: 0.34rem;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        line-height: 0.34rem;
      }
    }

    .tips {
      width: 100%;
      height: 0.44rem;
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2538c0;
      line-height: 0.22rem;
      text-align: center;
    }

    .button_wrapper {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      z-index: 10;
      padding: 0 0.2rem;
      box-sizing: border-box;

      .button {
        background-position: center, center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 1.11rem;
        height: 0.47rem;
        background-image: url(../../../assets/images/newSleep/check-button-small.png);
        .button_text {
          font-size: 0.12rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bolder;
          color: #8c3213;
        }
      }
    }
  }
</style>
