import { TaskPost } from '@/http/index';

export default {
  namespaced: true,
  state: () => ({
    rewardAmount: 0,
    adInfo: {},
  }),
  mutations: {
    setRewardAmount(state, reward) {
      state.rewardAmount = reward;
    },
    setAdInfo(state, data) {
      state.adInfo = { ...data };
    },
  },
  actions: {
    async getAdInfo({ commit }) {
      try {
        const res = await TaskPost('/ad/impress_for_video');
        const adInfo = {
          master: res.master,
          fill: res.fill,
        };
        commit('setAdInfo', adInfo);
        commit('setRewardAmount', res.ad_reward);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {},
  modules: {},
};
