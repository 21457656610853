<template>
  <div class="gain-water-drop">
    <div class="title">{{ title }}</div>
    <img class="water-drop-icon" src="@/assets/images/farm/water-drop-with-bg-blue.png" alt="water-drop" />
    <div v-if="!useUp" class="amount">水滴x{{ gainAmount }}g</div>
    <div class="award-btn" @click="handleStartWater">{{ awardText }}</div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { closeModal } from '@/utils/modal';
  import { trackClick } from '@/utils/track';
  export default {
    components: {},
    props: {
      // 是否水滴已经领完
      useUp: {
        type: Boolean,
        default: false,
      },
      // 去浇水
      startWatering: {
        type: Function,
        default: () => {},
      },
      loadPage: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        gainAmount: 0,
      };
    },
    computed: {
      title() {
        return this.useUp ? '每日都可免费领取水滴哦' : '恭喜获得';
      },
      awardText() {
        return this.useUp ? '去浇水' : '领取奖励去浇水';
      },
    },
    watch: {},
    created() {},
    async mounted() {
      this.gainAmount = await this.gainWater();
      await this.loadPage();
    },
    methods: {
      ...mapActions('farm', ['gainWater']),
      // 开始浇水
      handleStartWater() {
        trackClick({ page_title: '快音农场', element_name: this.useUp ? '去浇水' : '领取奖励浇水' });
        this.startWatering();
        closeModal('gain-water-drop-modal');
      },
    },
  };
</script>

<style lang="less" scoped>
  .gain-water-drop {
    box-sizing: border-box;
    padding: 0.2rem 0.2rem 0.24rem;
    width: 3rem;
    background: #ffffff;
    border-radius: 0.12rem;
    .title {
      width: 100%;
      height: 0.25rem;
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.25rem;
      margin-bottom: 0.12rem;
      text-align: center;
    }
    .water-drop-icon {
      display: block;
      width: 0.82rem;
      height: 0.82rem;
      margin: 0 auto;
    }
    .amount {
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.12rem;
      text-align: center;
      margin-top: 0.07rem;
    }
    .award-btn {
      width: 2.6rem;
      height: 0.4rem;
      text-align: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #9d4f07;
      line-height: 0.4rem;
      background: url('~@/assets/images/farm/gain-award-btn-modal.png') no-repeat;
      background-size: 100% 100%;
      margin-top: 0.19rem;
    }
  }
</style>
