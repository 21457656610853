import { GamePost, Api } from '@/http/index';

export default {
  namespaced: true,
  state: () => ({
    task_id: 0,
    status: 0,
    system_time: '',
    game_type: 'game_sleep',
    watch_ad_times: 0,
    reward_coins: 0,
    watch_ad_reward: 0,
    reward_coins_max: 0,
    distance_start_time: 0,
    task_start_ts: 0,
    task_end_ts: 0,
    task_end_time: '',
    task_start_time: '',
    settle_end_time: '',
    settle_start_time: '',
    ad_info: {},
    mediationAdInfo: {
      ad_group_id: 0,
      ad_platform_status: false,
    },
    // 气泡信息
    bubbles: [],
    // 虚拟道具信息
    itemProps: [],
    // 活力值
    energy: 1,
    // 上一个任务的id
    pre_task_id: 0,
    // 是否展示引流入口
    is_show_task_entrance: 0,
    // 签到信息
    signInfo: {
      signed_num: 0,
      sign_rule_days: 7,
      pop_date: 0,
      reward_info: [],
    },

    // 气泡视频信息
    bubble_video_group: {
      ad_group_id: 0,
      game_type: 'game_sleep_bubble',
    },

    // 瓜分视频信息
    carve_video_group: {
      ad_group_id: 0,
      game_type: 'game_sleep_carve',
    },

    // 业务名--恭喜获得模块用
    business_name: '',

    watch_num_for_bubble: 0,
    bubble_energy: 0,

    // 自动弹窗信息
    windowInfo: {
      valid: 0,
      data: [],
    },

    // 视频来源 -- 回调用
    videoFrom: '',

    // 上次挽留的日期
    lastRetainTime: window.localStorage.getItem('lastRetainTime') || '',

    settle_end: 0,

    // 下个任务瓜分支付
    carve_pay: 100,
    // 上个任务瓜分支付
    pre_carve_paid: 100,

    drainage_info: {
      is_show_task_entrance: 0,
      title: '',
      img: '',
      url: '',
    },

    // 分享相关
    shareAction: {
      from: '',
      time: 0,
    },

    // 分享信息
    share_info: [],

    // 挽留、瓜分等步骤的流程类型 video - 看激励视频/ share - 分享
    pageType: 'video',

    // mock宝箱信息
    timeRewardInfo: {
      is_valid: true,
      reward_status: 0,
      reward_times: 0, //已获奖次数
      progress: 0, //游戏进度，0一次都没得过，1已领过没领完，2已领完
      next_reward_time: 0, //下次领奖还需秒数
      last_reward: 0, //不可领奖时点击，展示这个金币数,即上次领奖金币数
      business_name: 'default', //不可领奖时点击，使用这个自定义布局
    },
  }),
  mutations: {
    // 更新pageInfo
    setTaskPageInfo(state, data) {
      Object.keys(state).forEach((key) => {
        if (data[key] !== undefined) {
          state[key] = data[key];
        }
      });
    },

    // 设置签到信息
    setSignInfo(state, data) {
      state.signInfo = { ...data };
    },
    // 设置自动弹窗信息
    setWindowInfo(state, data) {
      state.windowInfo = { ...data };
    },
    // 设置视频来源
    setVideoFrom(state, data) {
      state.videoFrom = data;
    },

    // 更新挽留时间
    updateLastRetainTime(state) {
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth();
      const d = date.getDate();
      const dateStr = '' + y + m + d;
      window.localStorage.setItem('lastRetainTime', dateStr);
      state.lastRetainTime = dateStr;
    },

    // 设置分享信息
    setShareAction(state, from) {
      state.shareAction = {
        from,
        time: Date.now(),
      };
    },

    setPageType(state, type) {
      state.pageType = type;
    },

    setTimeRewardInfo(state, data) {
      state.timeRewardInfo = { ...data };
    },
  },
  actions: {
    // 从服务端获取页面信息
    async getSleepPageInfo({ commit }, pageType = 'video') {
      try {
        const data = await GamePost(Api.GET_SLEEP_PAGE_INFO_API, {}, { showLoading: false });
        let { ad_group_id, props, bubbles, time_reward_info, ...rest } = data;
        const mediationAdInfo = { ad_group_id };
        const itemProps = props;
        // 设置真实气泡
        const positionBubbles = setBubblePosition(bubbles, pageType);

        commit('setPageType', pageType);

        commit('setTaskPageInfo', {
          ...rest,
          mediationAdInfo,
          itemProps,
          bubbles: positionBubbles,
        });

        commit('setTimeRewardInfo', time_reward_info);
      } catch (e) {
        console.log(e);
      }
    },

    // 从服务端获取签到信息
    async getSleepSignInfo({ commit }) {
      try {
        const data = await GamePost(Api.GET_SLEEP_SIGN_INFO, { type: 'sleep_game_sign_in' });
        commit('setSignInfo', data);
      } catch (e) {
        console.log(e);
      }
    },

    // 从服务端获取弹窗信息
    async getSleepWindow({ commit }) {
      try {
        const data = await GamePost(Api.GET_SLEEP_WINDOW, {}, { showLoading: false });
        commit('setWindowInfo', data);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    /**
     * @description: 当前时间节点
     * @param {object} state
     * @return {string} day | night | sleeping
     */
    nowTime(state) {
      const { system_time, status } = state;
      const hour = new Date(system_time * 1000).getHours();
      let dayStatus = '';
      if (hour >= 19 || hour <= 7) {
        dayStatus = 'night';
      } else {
        dayStatus = 'day';
      }

      if (status == 3) {
        return 'sleeping';
      } else {
        return dayStatus;
      }
    },

    /**
     * @description: 活力值状态
     * @param {object} state
     * @return {object}
     */
    energyStatus(state) {
      const { energy } = state;
      // const energy = 1
      const text = energy === 2 ? '普通' : energy === 3 ? '精力饱满' : energy === 4 ? '活力四射' : '一般';
      return {
        value: energy,
        text,
      };
    },

    /**
     * @description: 是否挽留
     * @param {object} state
     * @return {boolean}
     */
    shouldRetain(state) {
      const { lastRetainTime } = state;
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth();
      const d = date.getDate();
      const dateStr = '' + y + m + d;
      return dateStr !== lastRetainTime;
    },

    settleDate(state) {
      const { settle_end } = state;
      const date = new Date(parseInt(settle_end) * 1000);

      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();

      return `${y}年${m}月${d}日`;
    },

    // 分享信息
    shareInfo(state) {
      const { share_info } = state;
      let ret = {};
      share_info.forEach((item) => {
        ret[item.type] = item;
      });
      return ret;
    },
  },
};

/**
 * @description: 设置气泡位置
 * @param {object[]} list
 * @return {object[]}
 */
function setBubblePosition(list, pageType) {
  let ret = list.map((item) => {
    switch (item.num) {
      case 1:
        return {
          ...item,
          top: '0rem',
          left: '0.4rem',
        };
      case 2:
        return {
          ...item,
          top: '0.16rem',
          left: '1.6rem',
        };
      case 3:
        return {
          ...item,
          top: '1.86rem',
          left: '0.9rem',
        };
      case 4:
        return {
          ...item,
          top: '1.86rem',
          left: '1.9rem',
        };

      case 5:
        return {
          ...item,
          top: '0.8rem',
          left: '2rem',
        };
      case 6:
        return {
          ...item,
          top: '0.96rem',
          left: '-0.2rem',
        };
      default:
        return {
          ...item,
        };
    }
  });
  if (pageType !== 'share') {
    ret = ret.filter((item) => item.bubble_type !== 'share_bubbles');
  }

  return ret;
}
