import TASK_API from '@/api/modules/task';
import NEW_WALK_API from './modules/walk';
import SLEEP_API from './modules/sleep';
import DRINK_API from './modules/drink';
import DRAW_API from './modules/draw';
import NEW_SLEEP_API from './modules/newSleep';
import PASS_MUSIC_LIST_API from './modules/guessMusic';
import TREE_API from './modules/tree';
import EATING_API from './modules/eating';
import GAME_LIBRARY from './modules/gameLibrary';
import COMMON from './modules/common';
import FARM from './modules/farm';

const api = {
  ...TASK_API,
  ...NEW_WALK_API,
  ...SLEEP_API,
  ...DRINK_API,
  ...DRAW_API,
  ...NEW_SLEEP_API,
  ...PASS_MUSIC_LIST_API,
  ...TREE_API,
  ...EATING_API,
  ...GAME_LIBRARY,
  ...COMMON,
  ...FARM,
};

export default api;
