<template>
  <div class="double-sign-rule">
    <h3 class="title">活动规则</h3>
    <ul class="rule-list">
      <li class="rule-item" v-for="(item, i) in rules" :key="i">{{ i + 1 }}.{{ item }}</li>
    </ul>
    <button class="btn" @click="handleClose">我知道了</button>
  </div>
</template>

<script>
  export default {
    props: {
      rules: {
        type: Array,
        default: () => ['', ''],
      },
      handleClose: Function,
    },
  };
</script>

<style lang="less" scoped>
  .double-sign-rule {
    box-sizing: border-box;
    width: 3rem;
    padding: 0.2rem 0.2rem 0.24rem 0.2rem;
    background: #ffffff;
    border-radius: 0.12rem;
  }
  .title {
    margin-bottom: 0.12rem;
    font-size: 0.18rem;
    color: #333333;
    line-height: 0.25rem;
    text-align: center;
  }
  .rule-list {
    margin-bottom: 0.25rem;
  }
  .rule-item {
    margin-bottom: 0.15rem;
    font-size: 0.15rem;
    color: #666666;
    line-height: 0.21rem;
  }
  .btn {
    display: block;
    margin: 0 auto;
    width: 2.6rem;
    height: 0.4rem;
    background: linear-gradient(308deg, #683cfe 0%, #5300c9 100%);
    border-radius: 0.2rem;
    font-size: 0.16rem;
    color: #ffffff;
  }
  .title,
  .btn {
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
  }
</style>
