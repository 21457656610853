/**
 * @description: 分配气泡的位置-5个以上
 * @param {number} num 气泡的位置序号
 * @return {object} 气泡位置
 */
export function setBubblePositionLong(num) {
  let position;
  switch (+num) {
    case 1:
      position = {
        left: 1.675,
        top: 0,
      };
      break;
    case 2:
      position = {
        left: 1,
        top: 0.45,
      };
      break;
    case 3:
      position = {
        left: 2.35,
        top: 0.45,
      };
      break;
    case 4:
      position = {
        left: 0.35,
        top: 0.15,
      };
      break;
    case 5:
      position = {
        left: 3,
        top: 0.15,
      };
      break;
    case 6:
      position = {
        left: 0.2,
        top: 0.9,
      };
      break;
    case 7:
      position = {
        left: 3.15,
        top: 0.9,
      };
      break;
    default:
      position = {
        left: 0,
        top: 0,
      };
  }
  return position;
}

/**
 * @description: 分配气泡的位置--5个以下
 * @param {number} num 气泡的位置序号
 * @return {object} 气泡位置
 */
export function setBubblePositionShort(num) {
  let position;
  switch (+num) {
    case 1:
      position = {
        left: 1.645,
        top: 0.35,
      };
      break;
    case 2:
      position = {
        left: 0.76,
        top: 0.06,
      };
      break;
    case 3:
      position = {
        left: 2.53,
        top: 0.06,
      };
      break;
    case 4:
      position = {
        left: 0.2,
        top: 0.58,
      };
      break;
    case 5:
      position = {
        left: 3.09,
        top: 0.58,
      };
      break;
    default:
      position = {
        left: 0,
        top: 0,
      };
  }
  return position;
}

/**
 * @description: 找出arr1中存在，arr2中不存在的元素
 * @param {array} arr1
 * @param {array} arr2
 * @return {array} 新增元素组成的新数组
 */
export function findAddElement(arr1, arr2) {
  let newArr = arr1.filter((item) => {
    return arr2.every((item1) => {
      return item.bubble_id != item1.bubble_id;
    });
  });
  return newArr;
}
