<template>
  <div class="mature">
    <div class="mature-title"></div>
    <div class="mature-tree">
      <div class="mature-tree-star"></div>
      <div class="mature-tree-main"></div>
      <div class="mature-tree-circle1"></div>
      <div class="mature-tree-circle2"></div>
      <div class="mature-tree-desc"></div>
    </div>
    <div class="mature-button" @click="onClick">点击收获果实，填写收货地址</div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      onClick: {
        type: Function,
        default: () => {},
      },
    },
    name: 'mature',
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>
<style scoped lang="less">
  .mature {
    width: 3.51rem;
    height: 5.83rem;
    &-title {
      margin: 0 auto;
      width: 1.82rem;
      height: 0.73rem;
      background: url('~@/assets/images/farm/mature-fruit-modal/title.png') no-repeat center center;
      background-size: contain;
    }
    &-tree {
      margin: 0 auto;
      position: relative;
      width: 100%;
      height: 4.5rem;
      &-star {
        margin: 0 auto;
        width: 3.43rem;
        height: 3.43rem;
        background: url('~@/assets/images/farm/mature-fruit-modal/stars.png') no-repeat center center;
        background-size: contain;
      }
      &-main {
        position: absolute;
        top: 0.52rem;
        width: 3.51rem;
        height: 3.51rem;
        background: url('~@/assets/images/farm/mature-fruit-modal/tree.png') no-repeat center center;
        background-size: contain;
      }
      &-circle1 {
        position: absolute;
        top: 0.28rem;
        width: 3.37rem;
        height: 3.37rem;
        background: url('~@/assets/images/farm/mature-fruit-modal/circle1.png') no-repeat center center;
        background-size: contain;
      }
      &-circle2 {
        position: absolute;
        top: 0.68rem;
        left: 0.39rem;
        width: 2.59rem;
        height: 2.59rem;
        background: url('~@/assets/images/farm/mature-fruit-modal/circle2.png') no-repeat center center;
        background-size: contain;
      }
      &-desc {
        position: absolute;
        top: 3.02rem;
        left: 0.68rem;
        width: 2.21rem;
        height: 1.05rem;
        background: url('~@/assets/images/farm/mature-fruit-modal/desc.png') no-repeat center center;
        background-size: contain;
      }
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 2.67rem;
      height: 0.47rem;
      background: url('~@/assets/images/farm/mature-fruit-modal/button_bg.png') no-repeat center center;
      background-size: contain;
      font-size: 0.16rem;
      font-weight: 600;
      color: #9d4f07;
    }
  }
</style>
