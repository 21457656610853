<template>
  <div class="dialog">
    <div class="dialog_con video_task_dialog--new" @click.stop>
      <div class="video_wrapper" :style="{ height: '1.835rem' }">
        <img class="coin_stack" src="../../../assets/images/task/coin_stack_gold.png" alt="" srcset="" />
        <p style="font-size: 0.14rem; color: #111111; font-weight: 700">获得起床打卡奖励</p>
        <div>
          <p class="reward-area">
            <i class="icon-coin--big"></i><span>{{ Number(reward_coins) }}</span>
          </p>
        </div>

        <button class="video_task_btn" @click="handleViewVideo">
          看视频领取{{ Number(adRewardAmount) + reward_coins }}金币<img
            style="width: 0.19rem; height: 0.16rem; margin-left: 0.05rem; margin-top: -0.02rem"
            src="../../../assets/images/task/video2.png"
          />
        </button>
        <p style="font-size: 12px; color: #f03d5b; margin-top: 0.08rem" @click="handleDialogClose">
          直接收下{{ Number(reward_coins) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      reward_coins: {
        type: Number,
        default: 0,
      },
      adRewardAmount: {
        type: Number,
        default: 0,
      },
      handleViewVideo: {
        type: Function,
      },
      handleDialogClose: {
        type: Function,
      },
    },
  };
</script>

<style lang="less" scoped>
  .video_task_dialog--new {
    position: relative;
    top: 45%;
    width: 3rem;
    height: 2.485rem;
    box-sizing: border-box;
    text-align: center;
  }
  .video_task_dialog--offline {
    width: 2.785rem;
    height: 2.1rem;
    box-sizing: border-box;
    text-align: center;
    background-color: #fff;
    border-radius: 0.06rem;
  }
  .video_task_dialog--offline img {
    width: 0.88rem;
    height: 0.88rem;
  }
  .video_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.7rem 0 0.05rem;
    width: 3rem;
    background-color: #fff;
    border-radius: 0.08rem;
  }
  .video_task_dialog--new .coin_stack {
    position: absolute;
    top: -0.5rem;
    left: 50%;
    margin-left: -0.6rem;
    width: 1.2rem;
    height: 1.2rem;
  }
  .video_task_btn {
    margin-top: 0.32rem;
    width: 2.3rem;
    height: 0.44rem;
    text-align: center;
    background: rgba(240, 61, 91, 1);
    border-radius: 0.22rem;
    font-size: 0.16rem;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video_task_btn img {
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    /* background-color: #fff; */
  }
  .reward-area {
    display: inline-flex;
    align-items: center;
    width: auto;
    padding: 6px 0.1rem;
    margin-top: 0.08rem;
    color: #222222;
    font-size: 0.26rem;
    font-weight: bold;
    vertical-align: middle;
    background: rgba(238, 238, 238, 1);
    border-radius: 0.06rem;
    text-align: center;
  }
  .icon-coin--big {
    display: inline-block;
    width: 0.3rem;
    height: 0.3rem;
    background-image: url(../../../assets/images/task/coin.png);
    background-size: contain;
    vertical-align: middle;
    margin-top: -0.02rem;
    margin-right: 0.04rem;
  }
</style>
