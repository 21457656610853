import IOS_LISTEN_METHOD from './interface';
import IOS_BRIDGE_METHOD from './method';
const dsbridge = require('dsbridge');

// const USER_INFO = require('../../user-info.json');

/**
 * @description: IOS 桥接对象注册事件回调方法
 * @param {string} event - 时间名称
 * @param {object} callback - 回调函数
 * @return: void
 */

const IOS_REGISTER = (event, callback) => {
  try {
    if (dsbridge) {
      dsbridge.register(event, callback);
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @description: js 调用 window.dsbridge 原生方法
 * @param {string} functionName - 方法名
 * @param {string} params - 参数
 * @return: *
 */

const IOS_APP_CALL = (functionName, ...params) => {
  try {
    if (dsbridge) {
      if (functionName === IOS_BRIDGE_METHOD.SHOW_REWARD_VIDEO_AD_WITH_JSON) {
        const [masterVideoJSONstr, fillVideoJSONstr] = params;
        // 对激励视频方法特殊处理
        let JSONObj = {};
        if (fillVideoJSONstr) {
          JSONObj = {
            master: JSON.parse(masterVideoJSONstr),
            fill: JSON.parse(fillVideoJSONstr),
          };
        } else {
          JSONObj = {
            master: JSON.parse(masterVideoJSONstr),
          };
        }
        return dsbridge.call('showRewardVideoAdWithJSON', JSONObj);
      }

      if (functionName === IOS_BRIDGE_METHOD.SHARE_TO) {
        return dsbridge.call(functionName, JSON.parse(...params));
      }
      return dsbridge.call(functionName, ...params);
    } else {
      return handleNoBridge(functionName, ...params);
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @description: 处理本地开发，没有 bridge 的情况
 * @param {string} functionName - 方法名
 * @param {any} params - 参数
 */

const handleNoBridge = (functionName, params) => {
  console.log('noBridge');
  switch (functionName) {
    case 'openPageWithJSON':
      window.location.href = JSON.parse(params).url;
      break;
    case 'getUserInfo':
      // if (process.env.NODE_ENV === 'development') {
      //   return JSON.stringify(USER_INFO);
      // }
      break;
    case 'signContent':
      break;
    default:
    // throw Error('bridge is not defined!');
  }
};

export { IOS_REGISTER, IOS_APP_CALL, IOS_LISTEN_METHOD, IOS_BRIDGE_METHOD };
