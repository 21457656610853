export default {
  // 猜歌游戏闯关歌曲信息
  PASS_MUSIC_LIST_API: '/NewGuessSong/PassMusicList',
  // 猜歌游戏闯关主动结算
  PASS_REWARD_API: '/NewGuessSong/PassReward',
  // 歌星歌手列表
  GET_GUESSE_MUSIC_SINGER_LIST_API: '/NewGuessSong/SingerList',
  // 歌星歌曲列表信息
  GET_SINGER_MUSIC_LIST_API: '/NewGuessSong/SingerMusicList',
  // 歌星奖励结算
  GET_SINGER_TASK_REWARD_API: '/NewGuessSong/SingerTaskReward',
};
