<template>
  <div class="modal_wrapper">
    <img class="rule_top_img" src="@/assets/images/newSleep/carve_rule_top.png" alt="顶部" />
    <div class="modal_header">
      <!-- src="@/assets/images/newSleep/carve_rule_title.png" -->
      <div class="rule_title">
        <h2>活动规则</h2>
      </div>
    </div>
    <div class="modal_box">
      <img @click="onClose" class="close_btn" src="@/assets/images/newSleep/close.png" alt="关闭" />
      <div class="padding">
        <div class="modal_content">
          <div class="step_item step1">
            <img class="step_img" src="@/assets/images/newSleep/carve_step1.png" alt="" />
            <p class="step_text">
              活动参与：<br />20:00-02:00内进入奖池瓜分弹窗并成功支付{{ carve_pay }}金币放逐瓜分奖池
            </p>
          </div>
          <img class="step_icon_1" src="@/assets/images/newSleep/step_icon_1.png" alt="step" />

          <div class="step_item step2">
            <img class="step_img" src="@/assets/images/newSleep/carve_step2.png" alt="" />
            <p class="step_text text_center">睡眠中</p>
          </div>
          <img class="step_icon_2" src="@/assets/images/newSleep/step_icon_2.png" alt="step" />

          <div class="step_item step3">
            <img class="step_img" src="@/assets/images/newSleep/carve_step3.png" alt="" />
            <p class="step_text">活动参与：<br />06:00-11:00内点击[我睡醒了-瓜分打卡]立即领取瓜分金币</p>
          </div>
          <img class="step_icon_3" src="@/assets/images/newSleep/step_icon_1.png" alt="step" />

          <div class="step_item step4">
            <img class="step_img" src="@/assets/images/newSleep/carve_step4.png" alt="" />
            <p class="step_text">Oh,no~打卡失败，立即看视频拿回{{ carve_pay }}金币</p>
          </div>
          <img class="step_icon_4" src="@/assets/images/newSleep/step_icon_4.png" alt="step" />

          <div class="step_item step5">
            <img class="step_img" src="@/assets/images/newSleep/carve_step5.png" alt="" />
            <p class="step_text">叮～咚～金币到账啦 奖池金币+官方补贴金币</p>
          </div>
        </div>
      </div>

      <div class="button_wrapper">
        <div @click="handleClickJoin" class="button main_button">
          <span class="join_text">支付{{ carve_pay }}金币<br />投入奖池参与</span>
        </div>
        <div @click="onClose" class="button">
          <span class="close_text">我再想想</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      carve_pay: {
        type: Number,
        default: 100,
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      handleClickJoin: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_wrapper {
    position: relative;
  }
  .text_center {
    text-align: center;
  }
  .padding {
    padding: 0.24rem 0.21rem 0;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    position: relative;
  }
  .rule_top_img {
    position: absolute;
    z-index: 49;
    top: -0.46rem;
    left: 0.39rem;
    width: 2.47rem;
    height: 0.73rem;
  }
  .modal_header {
    position: absolute;
    z-index: 51;
    width: 2.47rem;
    height: 0.73rem;
    top: -0.46rem;
    .rule_title {
      position: absolute;
      z-index: 99;
      left: 0.78rem;
      top: 0.24rem;
      width: 1.7rem;
      height: 0.5rem;
      background-image: url(../../../assets/images/newSleep/carve_rule_title.png);
      background-position: center;
      background-size: 100% 100%;
      padding: 0.06rem 0 0.19rem;
      box-sizing: border-box;
      line-height: normal;
      display: table;
      h2 {
        display: table-cell;
        text-align: center;
        height: 0.25rem;
        font-size: 0.18rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bolder;
        color: #ffffff;
        line-height: 0.25rem;
      }
    }
  }
  .modal_box {
    position: relative;
    z-index: 50;
    width: 3.25rem;
    // height: 5.23rem;
    height: 4rem;
    // overflow: hidden;
    background: #e1efff;
    border-radius: 0.12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC-Regular, PingFang SC;

    .close_btn {
      position: absolute;
      z-index: 30;
      top: 0.14rem;
      right: 0.12rem;
      width: 0.18rem;
      height: 0.18rem;
    }

    .modal_content {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      .step_item {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 1.04rem;
        .step_img {
          width: 1rem;
          height: 1rem;
        }
        .step_text {
          width: 1.1rem;
          font-size: 0.1rem;
          font-family: PingFang-SC-Regular, PingFang-SC;
          color: #2538c0;
          line-height: 0.15rem;
        }
      }
      .step1 {
        top: 0;
        left: 0;
      }
      .step2 {
        top: 0.16rem;
        right: 0;
      }
      .step3 {
        top: 1.82rem;
        left: 0;
      }
      .step4 {
        top: 1.42rem;
        right: 0.02rem;
      }
      .step5 {
        top: 2.79rem;
        right: 0.47rem;
      }

      .step_icon_1 {
        position: absolute;
        top: 0.28rem;
        left: 1.05rem;
        width: 0.73rem;
        height: 0.52rem;
      }
      .step_icon_2 {
        position: absolute;
        top: 1.18rem;
        left: 1.11rem;
        width: 0.61rem;
        height: 0.63rem;
      }
      .step_icon_3 {
        position: absolute;
        top: 2.2rem;
        left: 1rem;
        width: 0.8rem;
        height: 0.58rem;
        transform: scaleY(-1);
      }
      .step_icon_4 {
        position: absolute;
        top: 3.4rem;
        left: 0.48rem;
        width: 0.73rem;
        height: 0.73rem;
      }
    }
    .modal_content::-webkit-scrollbar {
      display: none;
    }

    .button_wrapper {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      z-index: 10;
      padding: 0 0.11rem 0.2rem;
      box-sizing: border-box;

      .button {
        background-position: center, center;
        background-size: contain;
        background-repeat: no-repeat;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 1.5rem;
        height: 0.52rem;
        background-image: url(../../../assets/images/newSleep/check-button-small.png);
        .close_text {
          font-size: 0.12rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bolder;
          color: #8c3213;
        }
        .join_text {
          font-size: 0.12rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bolder;
          color: #8c3213;
          line-height: 0.14rem;
        }
      }
    }
  }
</style>
