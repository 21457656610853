<template>
  <div class="modal_wrapper">
    <div class="modal_box">
      <div class="modal_content">
        <h1>每日签到</h1>
        <h2 class="title">登陆7日，可领取大量奖励</h2>
        <ul class="sign_list">
          <li
            class="sign_item"
            v-for="item in signInfo.reward_info"
            :key="item.day"
            :class="{ signed: signInfo.signed_num >= item.day }"
          >
            <div class="sign_item_content" v-if="signInfo.signed_num >= item.day">
              <img class="sign_item_light" :src="lightBg" alt="光" />
              <div class="signed_item">
                <span>已签到</span>
              </div>
            </div>
            <div v-else class="sign_item_content">
              <img v-if="item.reward_prop" class="sign_item_img" :src="item.reward_prop.img" alt="内容" />
              <Coin v-else :count="item.reward_coin"></Coin>
              <img class="sign_item_light" :src="lightBg" alt="光" />
            </div>
            <div class="sign_item_label">第{{ item.day }}天</div>
          </li>
        </ul>
      </div>
      <div v-if="+signInfo.is_signed === 1" class="sign_button signed">
        <span>已签到</span>
      </div>
      <div @click="handleClickSign" v-else class="sign_button">
        <span>立即签到</span>
      </div>
    </div>
  </div>
</template>

<script>
  // http://static1.kaixinyf.cn/img/lza5fd72626bf4c4904245142.png 步数背景
  const lightBg = require('../../../assets/images/walk/lightBg.png');
  import { mapActions, mapState } from 'vuex';
  import { GamePost, Api } from '@/http';
  import Coin from '@/components/Coin/index';
  export default {
    components: {
      Coin,
    },
    props: {
      onSuccess: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        lightBg: lightBg,
      };
    },
    computed: {
      ...mapState('new_sleep', ['signInfo', 'task_id']),
    },
    methods: {
      ...mapActions('new_sleep', ['getSleepSignInfo']),
      async handleClickSign() {
        try {
          const data = await GamePost(Api.SIGN_IN_SLEEP, {
            type: 'sleep_game_sign_in',
            day: Number(this.signInfo.signed_num) + 1,
          });
          if (data) {
            this.$toast('签到成功');
            await this.getSleepSignInfo();
            this.onSuccess(data);
          }
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_box {
    position: relative;
    top: -0.4rem;
    width: 3.2rem;
    height: 4.93rem;
    background-image: url(../../../assets/images/newSleep/sign_modal.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 1.425rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modal_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        position: absolute;
        top: 0.88rem;
        width: 0.72rem;
        height: 0.25rem;
        font-size: 0.18rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.25rem;
      }
      .title {
        font-size: 0.14rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #4a81ff;
      }
      .sign_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.24rem 0.25rem 0 0.25rem;
        box-sizing: border-box;
        .sign_item {
          width: 0.63rem;
          height: 0.94rem;
          background: #3774ff;
          box-shadow: 0rem 0.01rem 0.04rem 0.01rem rgba(122, 180, 255, 0.4);
          border-radius: 0.06rem;
          margin-bottom: 0.2rem;
          &:last-of-type {
            width: 1.32rem;
          }
          .sign_item_content {
            width: 100%;
            height: 0.7rem;
            background: #ffffff;
            box-shadow: 0rem 0.03rem 0.02rem 0rem rgba(62, 140, 255, 0.1);
            border-radius: 0.06rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .sign_item_img {
              position: relative;
              z-index: 50;
              width: 0.52rem;
              height: 0.52rem;
            }
            .sign_item_coin {
              position: relative;
              z-index: 50;
              width: 0.34rem;
              height: 0.34rem;
            }
            .sign_item_light {
              position: absolute;
              z-index: 49;
              width: 0.63rem;
              height: 0.63rem;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .signed_item {
              position: relative;
              z-index: 51;
              width: 0.4rem;
              height: 0.4rem;
              background: #6d9aff;
              border-radius: 100%;

              display: table;
              text-align: center;
              box-sizing: border-box;
              font-size: 0.1rem;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: bolder;
              color: #ffffff;
              span {
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
          .sign_item_label {
            height: 0.24rem;
            text-align: center;
            line-height: normal;
            font-size: 12px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #ffffff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &.signed {
            background: #95c7ff;
          }
        }
      }
    }
    .sign_button {
      width: 2.22rem;
      height: 0.59rem;
      background-image: url(../../../assets/images/newSleep/check-button.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0.26rem;
      span {
        font-size: 0.18rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bolder;
        color: #8c3213;
      }
      &.signed {
        background-image: url(../../../assets/images/newSleep/check-button-icon--disabled.png);
        span {
          color: #666666;
          position: relative;
          top: -0.02rem;
        }
      }
    }
  }
</style>
