<template>
  <CommonModal :buttonText="buttonText" @click="onClick" @close="onClose" height="1.86rem">
    <img class="water" src="@/assets/images/tree/water.png" alt="water" />
    <p class="text">{{ text }}<br />×{{ amount }}</p>
  </CommonModal>
</template>

<script>
  import CommonModal from '../CommonModal/CommonModal';
  export default {
    components: {
      CommonModal,
    },
    props: {
      status: {
        type: Number,
        default: 0,
      },
      onClick: {
        type: Function,
        default: () => {},
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      text: {
        type: String,
        default: '免费获得水滴',
      },
      buttonText: {
        type: String,
        default: '看视频领水滴',
      },
      amount: {
        type: Number,
        default: 30,
      },
    },
  };
</script>

<style lang="less" scoped>
  .water {
    width: 1.7rem;
    height: 1.7rem;
    position: absolute;
    top: -0.75rem;
  }
  .text {
    height: 0.5rem;
    font-size: 0.15rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2538c0;
    line-height: 0.25rem;
    text-align: center;
    position: absolute;
    top: 0.6rem;
  }
</style>
