import { comboList, TOTAL_HP } from '../config';
export default {
  namespaced: true,
  state: () => {
    return {
      comboInfo: {
        // 总连击数 错误时reset
        totalCombo: 0,
        // 当前连击 错误和凑够targetCombo时reset
        currentCombo: 0,
        // 最大连击数
        maxCombo: 0,
      },

      // 红包数
      redpacketNumber: 0,
      // 单轮游戏红包数
      redpacketNumberRound: 0,
      // 正确数
      totalCorrectCount: 0,
      // 错误数
      wrongCount: 0,
      // 快速正确数
      quickAnswerCount: 0,

      // 总生命
      totalHp: TOTAL_HP,
      // 当前生命
      currentHp: TOTAL_HP,

      // 是否展示combo信息
      showCombo: false,

      // 答案列表
      answerList: [],
      // 单轮答案列表
      answerListRound: [],

      // 每个红包所需的combo数
      comboList: comboList,
    };
  },
  mutations: {
    setCombo(state, data) {
      state.comboInfo = { ...state.comboInfo, ...data };
    },

    // 设置总正确数
    addTotalCorrectCount(state) {
      state.totalCorrectCount += 1;
    },
    resetTotalCorrectCount(state) {
      state.totalCorrectCount = 0;
    },

    // 设置总错误数
    addWrongCount(state) {
      state.wrongCount += 1;
    },
    resetWrongCount(state) {
      state.wrongCount = 0;
    },

    addQuickAnswerCount(state) {
      state.quickAnswerCount += 1;
    },
    resetQuickAnswerCount(state) {
      state.quickAnswerCount = 0;
    },

    // 设置红包数
    addRedpacketNumber(state) {
      state.redpacketNumber += 1;
      state.redpacketNumberRound += 1;
    },
    resetRedpacketNumber(state) {
      state.redpacketNumber = 0;
      state.redpacketNumberRound = 0;
    },

    // 重置单论红包数
    resetRedpacketNumberRound(state) {
      state.redpacketNumberRound = 0;
    },

    // 设置生命值 传负数为减少
    reduceCurrentHp(state, payload) {
      state.currentHp += payload;
    },
    setCurrentHp(state, num) {
      state.currentHp = num;
    },

    // 设置combo信息的展示
    setShowCombo(state, flag) {
      state.showCombo = flag;
    },

    pushAnswerList(state, obj) {
      state.answerList = [...state.answerList, obj];
      state.answerListRound = [...state.answerListRound, obj];
    },
    setAnswerList(state, list) {
      state.answerList = [...list];
      state.answerListRound = [...list];
    },
    resetAnswerListRound(state) {
      state.answerListRound = [];
    },

    setComboList(state, list) {
      console.log(list);
      state.comboList = [...list];
    },
  },
  actions: {
    // 回答正确
    correct({ commit, state, getters }, useSecond) {
      let { totalCombo, currentCombo, maxCombo } = state.comboInfo;
      const { targetCombo } = getters;
      // 设置最大连击
      maxCombo = totalCombo + 1 > maxCombo ? totalCombo + 1 : maxCombo;

      // 设置当前连击
      if (currentCombo + 1 >= targetCombo) {
        currentCombo = 0;
        // 更新红包数
        commit('addRedpacketNumber');
      } else {
        currentCombo += 1;
      }

      totalCombo += 1;

      if (useSecond < 3) {
        commit('addQuickAnswerCount');
      }

      // 展示combo信息
      commit('setShowCombo', true);
      setTimeout(() => {
        commit('setShowCombo', false);
      }, 800);

      commit('setCombo', {
        totalCombo,
        currentCombo,
        maxCombo,
      });
      commit('addTotalCorrectCount');
    },

    // 回答错误
    wrong({ commit }, mode = 'break_through') {
      // reset总连击数和当前连击数
      commit('setCombo', {
        totalCombo: 0,
        currentCombo: 0,
      });

      // 累计错误数
      commit('addWrongCount');

      if (mode === 'break_through') {
        // 扣血
        commit('reduceCurrentHp', -1);
      }
    },

    /**
     * @description: 重置统计信息
     * @param {function} commit
     * @return {void}
     */
    resetStatistics({ commit }) {
      commit('resetTotalCorrectCount');
      commit('setCombo', {
        totalCombo: 0,
        currentCombo: 0,
        maxCombo: 0,
      });
      commit('resetWrongCount');
      commit('resetRedpacketNumber');
      commit('setCurrentHp', TOTAL_HP);
      commit('resetQuickAnswerCount');
      commit('setAnswerList', []);
    },
  },
  getters: {
    targetCombo(state) {
      const { redpacketNumber, comboList } = state;
      const target =
        redpacketNumber > comboList.length - 1 ? comboList[comboList.length - 1] : comboList[redpacketNumber];
      return target.combo;
    },

    // 打败了%多少人
    defeatedPercent(state) {
      // 正确题数/已答题总数 * 已答题总数/100 * 100%
      const { totalCorrectCount, answerList } = state;
      let alreadyCount = answerList.length;
      if (!alreadyCount) {
        return 0;
      }
      const ret = (totalCorrectCount / alreadyCount) * (alreadyCount / 100);
      return Math.ceil(ret * 100);
    },
  },
};
