<template>
  <div>
    <div class="container" :style="containerStyle">
      <div class="rule-modal">
        <img class="content" src="@/assets/images/farm/rule-content.png" />
        <img class="rule-detail-title" src="@/assets/images/farm/rule-detail-title.png" />
        <div class="title">一、参与条件</div>
        <div class="rule">活动参与用户必须为快音app注册用户。</div>
        <div class="rule">
          <b
            >本轮活动将在2023年10月30日结束，之前种植成功的用户将在次月第三个工作日获得水果。
            感谢大家对快音助农活动的支持，我们会继续加油，后续有同类型活动上线会通知大家，敬请期待哦！（2023年10月30日活动结束后种植成功的水果，不会发货哦）</b
          >
        </div>
        <div class="title">二、活动玩法</div>
        <div class="title">(一)玩法形式:</div>
        <div class="rule">
          用户选择一种种子种下果树，通过浇水使果树结出水果，在水果成熟后，用户即可收获一份实物水果。活动期间每个用户只能同时种一棵果树，水果成熟并完成兑换后，可以继续。种新果树。
        </div>
        <div class="title">(二) 具体说明:</div>
        <div class="title">1、选择水果</div>
        <div class="rule">
          1)、用户进入活动主页面后，可从展示的几种水果中自行选择其中一种作为种子种下一棵果树，具体水果类型以实际展示的为准。果树结出的水果与之前所选种子一致。
        </div>
        <div class="rule">
          2)、由于水果有一定保鲜期限制，导致部分地区无法配送，为保障用户实物水果权益的实现，农场将根据用户的收货地址派发，若后期因距离无法送达，会有快音小助手<span
            class="account"
            @click="handleCopyCustomerService"
            >kuaiyin12345666</span
          >联系您更改地址，或者更换水果。
        </div>
        <div class="rule">
          3）若是有水果损坏，或内附纸条，请谅解，可能是供应商的小失误（供应商是大批下岗工人销售滞销水果，平均年龄65岁，农场属于助农公益），若由于天气原因损坏少量水果，请您谅解，若水果大批量损坏，可以联系小助手反馈
        </div>
        <div class="rule">
          受水果保鲜期限制，水果可发货地区为北京市，天津市，上海市，重庆市，河北省，山西省，江苏省，浙江省，安徽省，福建省，江西省，山东省，河南省，湖北省，湖南省，广东省，四川省，贵州省，云南省，陕西省，广西壮族自治区。若用户填写收货地址不在上述地区，可联系小助手<span
            class="account"
            @click="handleCopyCustomerService"
            >kuaiyin12345666</span
          >修改地址，用户可更换收货地址，否则将视为放弃权益，无法获得水果，对此平台有权不予补偿。
        </div>
        <div class="title">2、种植方式:用户可单独种一棵果树。</div>
        <div class="title">3、领水滴</div>
        <div class="rule">
          1)、用户可通过“领水滴”按钮完成任务获取水滴，获取的水滴可用于给果树浇灌。每种任务对同一用户每天获得的水滴以及获得的次数存在限制，具体以页面说明和互动结果为准。
        </div>
        <div class="rule">
          2)、用户也可通过邀请好友让好友助力集水滴，分享者和被分享者均有机会获得一定量的水滴，同一分享者和被分享者之间每天只可以互相助力集水滴一次，并且每天每个用户可为多名好友助力集水滴，具体数量请以页面提示为准。
        </div>
        <div class="rule">
          3)、用户还可以通过页面上随机触发的活动玩法获得水滴，具体获得的水滴数量以当次触发时的说明为准。
          <span class="tips">（正在开发中…）</span>
        </div>
        <div class="title">4、浇灌果树</div>
        <div class="rule">
          果树从最初发芽到最终成熟共经历九个阶段当前所处阶段可参见主界面进度条。用户选择完水果种下果树后，需要主动在互动主界面点击“水壶”浇水使果树成长。
        </div>
        <div class="rule">
          2)果树由一个阶段成长至下一个阶段会有不同的水滴值要求，具体要求以页面展示为准。用户浇淋水滴使果树完成当前阶段的成长进度要求后，果树会自动成长至下一阶段。
        </div>
        <div class="title">5.继续种新水果</div>
        <div class="rule">活动期间每个用户只能同时种一棵水果，水果成熟并完成兑换后，可以继续种新果树。</div>
        <div class="title">三、农场奖品发放及使用规则</div>
        <div class="rule">
          1.提现券：用户领取后，实时发放至账户，农场提现券有效期为领取后168(7X24)小时，逾期未用自动失效。
        </div>
        <div class="rule">2.水果实物：用户领取后次月3个工作日发出，若遇节假日则顺延。</div>
        <div class="title">四、水滴等仅是本活动玩法形式，无实际现金或折扣优惠价值，不具备互动外的任何功能。</div>
      </div>
      <img class="main-title" :style="titleStyle" src="@/assets/images/farm/rule-title-main.png" />
      <img class="close" src="@/assets/ky-close.png" @click="handleCloseModal" />
    </div>
  </div>
</template>

<script>
  import { closeModal } from '@/utils/modal';
  import platform from '@/utils/platform';
  import sdk from '@/utils/sdk';

  export default {
    name: 'RuleModal',
    components: {},
    props: {},
    data() {
      return {
        containerStyle: {
          paddingTop: '1.43rem',
        },
        titleStyle: {
          top: '0.88rem',
        },
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      if (platform.iOS) {
        this.containerStyle.paddingTop = '0.99rem';
        this.titleStyle.top = '0.44rem';
      }
    },
    methods: {
      handleCloseModal() {
        closeModal('farm-rule-modal');
      },
      handleCopyCustomerService() {
        sdk.appCall(sdk.callMethod.COPY, 'kuaiyin12345666');
        this.$toast('内容已复制');
      },
    },
  };
</script>

<style lang="less" scoped>
  .container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 3.35rem;
    overflow: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none;
    }
    .rule-modal {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      min-height: 100%;
      background: linear-gradient(180deg, #efffdf 0%, #fffffe 100%);
      // box-shadow: 0rem 0.03rem 0.06rem 0rem rgba(91, 111, 22, 0.5),
      //   inset 0rem 0.01rem 0.03rem 0.07rem rgba(167, 108, 53, 0.5);
      border-radius: 0.12rem;
      border: 0.07rem solid #d26c00;
      text-align: center;
      padding-top: 0.48rem;
      padding-bottom: 0.1rem;
      margin-bottom: 0.6rem;
      .content {
        width: 2.98rem;
        min-height: 100%;
      }
      .rule-detail-title {
        width: 2.23rem;
        height: 0.76rem;
        margin: 0.3rem 0 0.1rem -0.11rem;
      }
      .title {
        padding: 0 0.11rem;
        font-size: 0.12rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 0.22rem;
        text-align: start;
        margin-bottom: 0.05rem;
      }
      .rule {
        padding: 0 0.11rem;
        font-size: 0.12rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 0.22rem;
        text-align: start;
        margin-bottom: 0.05rem;
        .account {
          color: #fe0000;
          text-decoration: underline;
        }
        .tips {
          color: #666666;
        }
      }
    }
    .main-title {
      position: absolute;
      width: 3.07rem;
      height: 1rem;
      top: 0.88rem;
      left: 0.14rem;
    }
    .close {
      position: absolute;
      width: 0.25rem;
      height: 0.25rem;
      top: 0.88rem;
      right: 0rem;
    }
  }
</style>
