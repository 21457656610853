export default [
  // 猜歌游戏
  {
    path: '/game/guess_music/index',
    name: 'gameGuessMusicIndex',
    component: () => import('@/views/GuessMusic/index.vue'),
  },
  {
    path: '/game/guess_music/break_through',
    name: 'gameGuessMusicThrough',
    component: () => import('@/views/GuessMusic/BreakThroughMode/index.vue'),
  },
  {
    path: '/game/guess_music/select_singer',
    name: 'gameGuessMusicSinger',
    component: () => import('@/views/GuessMusic/SelectSinger/index.vue'),
  },
  {
    path: '/game/guess_music/singer_mode',
    name: 'gameGuessMusicMode',
    component: () => import('@/views/GuessMusic/SingerMode/index.vue'),
  },
];
