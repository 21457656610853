/**
 * @description: 猜歌游戏的常量
 */

// 第一次预加载歌曲的数量
export const PRELOAD_NUM = 25;
// 闯关模式剩几秒时背景改变
export const CHANGE_COLOR_REST_TIME = 0;
// 默认闯关模式剩几秒显示沙漏,取到服务端数据会被替换
export const SHOW_SAND_REST_TIME_DEFAULT = 30;

// 需求为100首 歌星模式总答题数
export const SINGER_MODE_TOTAL = 100;
// 默认最大复活次数，取到服务端数据会被替换
export const MAX_REBORN_COUNT_DEFAULT = 999;
// 首页bgm
export const INDEX_BGM = require('@/assets/mp3/bg.mp3');
// 按钮点击音效
export const BUTTON_SOUND = require('@/assets/mp3/button.mp3');
// 金币音效
export const COINS_SOUND = require('@/assets/mp3/coins.mp3');
// 计时音效
export const COUNT_DOWN_SOUND = require('@/assets/mp3/countdown.mp3');
// 初始化生命值音效
export const INIT_HP_SOUND = require('@/assets/mp3/init_hp.mp3');
// 开始游戏音效
export const START_GAME_SOUND = require('@/assets/mp3/start_game.mp3');

// combo数对应的评价
export const getComboMessage = (combo) => {
  if (combo === 2) {
    return 'Good';
  } else if (combo === 3) {
    return 'Great';
  } else if (combo > 3) {
    return 'Perfect';
  } else {
    return 'Good';
  }
};
