<template>
  <CommonModal @click="onClick" @close="onClose" height="2.2rem" dead>
    <img class="bug" src="@/assets/images/tree/bug_title.png" alt="water" />
    <p class="text">由于长时间未清理蛀虫<br />您的摇钱树已死亡</p>
    <template v-slot:button>
      <div class="button_text">
        收取<span>{{ coins }}</span
        >金币重新种树
      </div>
    </template>
  </CommonModal>
</template>

<script>
  import CommonModal from '../CommonModal/CommonModal';
  export default {
    components: {
      CommonModal,
    },
    props: {
      onClick: {
        type: Function,
        default: () => {},
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      coins: {
        type: Number,
        default: 0,
      },
    },
    computed: {},
  };
</script>

<style lang="less" scoped>
  .bug {
    width: 1.87rem;
    height: 1.87rem;
    position: absolute;
    top: -0.66rem;
  }
  .text {
    height: 0.5rem;
    font-size: 0.15rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2538c0;
    line-height: 0.25rem;
    text-align: center;
    position: absolute;
    top: 0.85rem;
  }
  .button_text {
    position: relative;
    top: 0.18rem !important;

    height: 100%;
    font-size: 0.12rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #8c3213;
    display: flex;
    align-items: flex-end;
    span {
      font-size: 0.22rem;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #fa3123;
      line-height: 0.22rem;
      margin: 0 0.02rem;
    }
  }
</style>
