export default {
  state: () => ({
    turntable: {
      prizes: [], // 转盘奖品
      accessNum: 0, // 免费抽奖次数
      useNum: 0, // 已抽次数
      limitNum: 0, // 每天抽奖次数限制
      doubleCard: 0, // 翻倍卡数量
      againCard: 0, // 再来一次卡数量
      timeLimit: 0, // 抽奖时间间隔
      videoCan: 0, // 是否还能看视频 （不能看视频则无法抽奖）
      chestStatus: 0, // 宝箱状态：0：未开启 1：可开启 2：已开完
      chestProgress: 0, // 宝箱进度
      chestSum: 0, // 宝箱可开启阀值
    },
    chest: {
      chestStatus: 0, // 宝箱状态：0：未开启 1：可开启 2：已开完
      chestProgress: 0,
      chestSum: 1,
    },
    // 测试信息
    // reward: {
    //   reward_type:'sleep_game_prop',
    //   reward_text: '睡帽',
    //   reward_num: 1,
    //   reward_pic: 'http://static1.kaixinyf.cn/img/lza6033564b49a6a580615906.png'
    // }, // 转盘获奖奖品
    reward: {},
    chestReward: {}, // 宝箱奖品
    rewardVideo: {}, // 抽奖获得视频配置/宝箱获得模块视频配置
    video: {}, // 抽奖观看视频配置
    videoPos: '', // 调用激励视频位置
    hasVirtualItem: false, // 是否抽中过虚拟道具
  }),
  mutations: {
    setTurnTable(state, data) {
      console.log(data);
      const {
        config: prizes,
        now_can: accessNum,
        done: useNum,
        daily_limit: limitNum,
        double_card: doubleCard,
        times_one_card: againCard,
        time_limit: timeLimit,
        video_can: videoCan,
        box: { status: chestStatus, progress: chestProgress, threshold: chestSum },
        video_model: video,
      } = data;

      state.turntable = Object.assign(state.turntable, {
        prizes,
        accessNum: +accessNum,
        useNum: +useNum,
        limitNum: +limitNum,
        doubleCard: +doubleCard,
        againCard: +againCard,
        timeLimit,
        videoCan,
        chestStatus,
        chestProgress,
        chestSum,
      });
      state.video = Object.assign(state.video, video);
    },
    setChest(state, data) {
      state.chest = Object.assign(state.chest, data);
    },
    setReward(state, data) {
      const {
        id,
        reward_num,
        reward_type,
        reward_pic,
        reward_text,
        double_status,
        handle_status,
        double_card: doubleCard,
        times_one_card: againCard,
        time_limit: timeLimit,
        video_model,
        userInfo,
        video_can: videoCan,
        now_can: accessNum,
        done: useNum,
        daily_limit: limitNum,
        box: { status: chestStatus, progress: chestProgress, threshold: chestSum },
        sleep_game_prop_info,
      } = data;

      state.reward = Object.assign(state.reward, {
        id,
        reward_num,
        reward_type,
        reward_pic,
        reward_text,
        userInfo,
        double_status,
        handle_status,
        accessNum: +accessNum,
        useNum: +useNum,
        limitNum: +limitNum,
        doubleCard: +doubleCard,
        againCard: +againCard,
        videoCan,
        timeLimit,
        sleep_game_prop_info,
      });

      // 只更新 chest 状态
      state.turntable = Object.assign(state.turntable, {
        chestStatus,
        chestProgress,
        chestSum,
      });

      state.rewardVideo = { ...video_model };
    },
    setChestReward(state, data) {
      const {
        reward_num,
        reward_type,
        reward_pic,
        reward_text,
        video_model,
        box: { status: chestStatus, progress: chestProgress, threshold: chestSum },
      } = data;
      state.chestReward = Object.assign(state.chestReward, { reward_num, reward_type, reward_pic, reward_text });
      // 只更新 chest 状态
      state.turntable = Object.assign(state.turntable, { chestStatus, chestProgress, chestSum });
      state.rewardVideo = { ...video_model };
    },
    updateTurnTable(state, data) {
      const { accessNum, useNum, limitNum, doubleCard, againCard, videoCan, timeLimit } = data;

      state.turntable = Object.assign(state.turntable, {
        accessNum: +accessNum,
        useNum: +useNum,
        limitNum: +limitNum,
        doubleCard: +doubleCard,
        againCard: +againCard,
        videoCan,
        timeLimit,
      });
    },
    updateTimeLimit(state, data) {
      const { timeLimit } = data;
      state.turntable = Object.assign(state.turntable, { timeLimit });
    },
    setVideoPos(state, data) {
      state.videoPos = data;
    },
    setHasVirtualItem(state, payload) {
      state.hasVirtualItem = payload;
    },
  },
  actions: {},
  getters: {
    progress(state) {
      return state.chest.chestProgress / state.chest.chestSum;
    },
    reward(state) {
      return state.reward;
    },
    chestReward(state) {
      return state.chestReward;
    },
    turntable(state) {
      return state.turntable;
    },
    video(state) {
      return state.video;
    },
    rewardVideo(state) {
      return state.rewardVideo;
    },
    chest(state) {
      return state.chest;
    },
    videoPos(state) {
      return state.videoPos;
    },
  },
};
