<template>
  <div class="drinking_treasure_modal">
    <div class="header">
      <h2>连续7天打开宝箱</h2>
      <p>已打卡{{ keepSign.sign_days }}天</p>
    </div>

    <div class="content">
      <div v-for="item in positionList" :key="item.id" class="point" :style="{ left: item.left, top: item.top }">
        <div v-if="item.id === 7" class="box_wrapper">
          <img
            class="box"
            @click="onClick"
            :class="{ shake: canReceiveKeepSignReward && !hasReceivedKeepSign }"
            src="@/assets/images/drinkingTask/box.png"
            alt="box"
          />
          <div v-if="hasReceivedKeepSign" class="flag">
            <div class="blur"></div>
            <span>已领</span>
          </div>
        </div>

        <div v-else-if="item.id <= keepSignDays" class="inner"></div>
        <p class="text">{{ item.text }}</p>
      </div>
    </div>

    <!-- <div class="button" @click="onClick">领取翻倍金币</div> -->
    <p class="tips">*当日任意时间点打卡即算作本日已打卡</p>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  export default {
    props: {
      onClick: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        positionList: [
          {
            id: 1,
            text: '第一天',
            left: '-0.12rem',
            top: '-0.17rem',
          },
          {
            id: 2,
            text: '第二天',
            left: '0.46rem',
            top: '-0.17rem',
          },
          {
            id: 3,
            text: '第三天',
            left: '1.07rem',
            top: '-0.17rem',
          },
          {
            id: 4,
            text: '第四天',
            left: '1.7rem',
            top: '-0.17rem',
          },
          {
            id: 5,
            text: '第五天',
            left: '1.7rem',
            top: '0.66rem',
          },
          {
            id: 6,
            text: '第六天',
            left: '0.73rem',
            top: '0.66rem',
          },
          {
            id: 7,
            text: '第七天',
            left: '-0.12rem',
            top: '0.66rem',
          },
        ],
      };
    },
    computed: {
      ...mapState('new_drink', ['keepSign']),
      ...mapGetters('new_drink', ['canReceiveKeepSignReward', 'signSevenDaysList']),

      // 持续签到的天数
      keepSignDays() {
        return this.signSevenDaysList.filter((day) => day.hasSign).length;
      },
      // 是否已领取连续奖励
      hasReceivedKeepSign() {
        return +this.keepSign.received_status === 2;
      },
    },
  };
</script>

<style lang="less" scoped>
  .drinking_treasure_modal {
    width: 3rem;
    height: 2.5rem;
    background: #ffffff;
    border-radius: 0.12rem;
    padding: 0.2rem 0.2rem 0.24rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .header {
      width: 100%;
      height: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.01rem solid #f7f7f7;
      box-sizing: border-box;
      padding: 0rem 0 0.1rem;
      h2 {
        height: 0.15rem;
        font-size: 0.15rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #000000;
        line-height: 0.15rem;
      }
      p {
        height: 0.12rem;
        font-size: 0.12rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #62b7ff;
        line-height: 0.12rem;
      }
    }
    .content {
      width: 2.28rem;
      height: 0.72rem;
      border-top: 0.1rem solid #f3f3f3;
      border-right: 0.1rem solid #f3f3f3;
      border-bottom: 0.1rem solid #f3f3f3;
      border-radius: 0 1rem 1rem 0;
      margin-top: 0.23rem;
      position: relative;
      left: 0.26rem;
      .point {
        position: absolute;
        width: 23px;
        height: 23px;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        .text {
          position: absolute;
          top: 0.34rem;
          width: 0.4rem;
          height: 0.1rem;
          font-size: 0.1rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
          line-height: 0.1rem;
        }
        .inner {
          width: 15px;
          height: 15px;
          background: linear-gradient(180deg, #a9d5ff 0%, #62afff 100%);
          box-shadow: 0rem 0.01rem 0.02rem 0rem rgba(0, 168, 255, 0.5);
          border-radius: 100%;
        }
        .box_wrapper {
          width: 0.33rem;
          height: 0.33rem;
          img {
            width: 0.33rem;
            height: 0.33rem;
          }
          .flag {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              position: relative;
              z-index: 99;
              height: 0.12rem;
              font-size: 0.12rem;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #62b7ff;
              line-height: 0.12rem;
            }

            .blur {
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(243, 243, 243, 0.9);
              filter: blur(1px);
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .button {
      margin-top: 0.38rem;
      width: 2.6rem;
      height: 0.4rem;
      background: #62b7ff;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
    }
    .tips {
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 0.12rem;
      margin-top: 0.5rem;
      text-align: center;
    }
  }
  .shake {
    animation: shake 0.5s infinite;
  }

  @keyframes shake {
    0% {
      transform: rotate(5deg);
    }

    50% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(5deg);
    }
  }
</style>
