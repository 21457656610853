import { GamePost, Api } from '@/http/index';

export default {
  namespaced: true,
  state: () => ({
    stageInfo: {},
    stageMap: new Map([
      [
        'breakfast-forbid',
        {
          id: 1,
          title: require('@/assets/images/eating/breakfast_title--forbid.png'),
          background: require('@/assets/images/eating/breakfast_forbid.jpg'),
        },
      ],
      [
        'breakfast-available',
        {
          id: 1,
          title: require('@/assets/images/eating/breakfast_title--available.png'),
          background: require('@/assets/images/eating/breakfast_available.jpg'),
          nextStage: 'breakfast-success',
        },
      ],
      [
        'breakfast-success',
        {
          id: 2,
          title: require('@/assets/images/eating/breakfast_title--success.png'),
          background: require('@/assets/images/eating/lunch_forbid.jpg'),
          titleTop: '0',
        },
      ],
      [
        'lunch-forbid',
        {
          id: 2,
          title: require('@/assets/images/eating/lunch_title--forbid.png'),
          background: require('@/assets/images/eating/lunch_forbid.jpg'),
        },
      ],
      [
        'lunch-available',
        {
          id: 2,
          title: require('@/assets/images/eating/lunch_title--available.png'),
          background: require('@/assets/images/eating/lunch_available.jpg'),
          nextStage: 'lunch-success',
        },
      ],
      [
        'lunch-success',
        {
          id: 3,
          title: require('@/assets/images/eating/lunch_title--success.png'),
          background: require('@/assets/images/eating/dinner_forbid.jpg'),
          titleTop: '0',
        },
      ],
      [
        'dinner-forbid',
        {
          id: 3,
          title: require('@/assets/images/eating/dinner_title--forbid.png'),
          background: require('@/assets/images/eating/dinner_forbid.jpg'),
        },
      ],
      [
        'dinner-available',
        {
          id: 3,
          title: require('@/assets/images/eating/dinner_title--available.png'),
          background: require('@/assets/images/eating/dinner_available.jpg'),
          nextStage: 'dinner-success',
        },
      ],
      [
        'dinner-success',
        {
          id: 4,
          title: require('@/assets/images/eating/dinner_title--success.png'),
          background: require('@/assets/images/eating/nightingale_forbid.jpg'),
          titleTop: '0',
        },
      ],
      [
        'nightingale-forbid',
        {
          id: 4,
          title: require('@/assets/images/eating/nightingale_title--forbid.png'),
          background: require('@/assets/images/eating/nightingale_forbid.jpg'),
        },
      ],
      [
        'nightingale-available',
        {
          id: 4,
          title: require('@/assets/images/eating/nightingale_title--available.png'),
          background: require('@/assets/images/eating/nightingale_available.jpg'),
          nextStage: 'nightingale-success',
        },
      ],
      [
        'nightingale-success',
        {
          id: 1,
          title: require('@/assets/images/eating/nightingale_title--success.png'),
          background: require('@/assets/images/eating/breakfast_forbid.jpg'),
          titleTop: '0',
        },
      ],
    ]),
    taskList: [],
    bussinessName: null,
    currentTask: null,
    serverTime: Date.now(),
  }),
  mutations: {
    setStageInfo(state, stage) {
      state.stageInfo = state.stageMap.get(stage);
    },
    setBusinessName(state, data) {
      state.bussinessName = data;
    },
    setTaskList(state, data) {
      state.taskList = data;
    },
    setCurrentTask(state, current_task) {
      state.currentTask = current_task;
    },
    setServerTime(state, server_time) {
      state.serverTime = server_time;
    },
  },
  actions: {
    // 从服务端获取页面信息
    async getEatingPageInfo({ commit }) {
      try {
        const data = await GamePost(Api.GET_EATING_PAGE_INFO, {});
        const { server_timestamp, business_name, task_list, current_task } = data;

        const { id, status } = current_task;
        let stage;

        if (id === 1) {
          if (status === 0) {
            stage = 'breakfast-forbid';
          } else {
            stage = 'breakfast-available';
          }
        } else if (id === 2) {
          if (status === 0) {
            stage = 'lunch-forbid';
          } else {
            stage = 'lunch-available';
          }
        } else if (id === 3) {
          if (status === 0) {
            stage = 'dinner-forbid';
          } else {
            stage = 'dinner-available';
          }
        } else if (id === 4) {
          if (status === 0) {
            stage = 'nightingale-forbid';
          } else {
            stage = 'nightingale-available';
          }
        }

        commit('setStageInfo', stage);
        commit('setServerTime', server_timestamp);
        commit('setCurrentTask', current_task);
        commit('setBusinessName', business_name);
        commit('setTaskList', task_list);
      } catch (error) {}
    },
  },
  getters: {
    StageInfo(state) {
      return state.stageInfo;
    },
    TaskList(state) {
      return state.taskList;
    },
    BussinessName(state) {
      return state.bussinessName;
    },
    CurrentTask(state) {
      return state.currentTask;
    },
    ServerTime(state) {
      return state.serverTime;
    },
  },
};
