export default [
  // 新走路赚钱任务
  {
    path: '/game/new_walk',
    name: 'newWalk',
    component: () => import('@/views/Walk/V2'),
  },
  // 旧走路赚钱任务
  {
    path: '/game/walk',
    name: 'gameWalk',
    component: () => import('@/views/Walk/V1'),
  },
  // 旧走路详情页
  {
    path: '/game/walk_detail',
    name: 'gameWalkDetail',
    component: () => import('@/views/Walk/V1/WalkDetail/index.vue'),
  },
];
