<template>
  <CommonModal buttonText="知道了" @click="onClick" @close="onClose" height="1.76rem">
    <img v-if="action === 'update'" class="bag" src="@/assets/images/tree/sign/bag_small.png" alt="bag" />
    <img v-else class="bag" src="@/assets/images/tree/sign/redpacket_group.png" alt="bag" />
    <p v-if="action === 'update'" class="text">恭喜您红包已升级为钱袋</p>
    <p v-else class="text">您的钱袋降级为红包</p>
  </CommonModal>
</template>

<script>
  import CommonModal from '../CommonModal/CommonModal';
  export default {
    components: { CommonModal },
    props: {
      action: {
        type: String,
        default: 'update',
      },
      onClick: {
        type: Function,
        default: () => {},
      },
      onClose: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>

<style lang="less" scoped>
  .bag {
    width: 1.7rem;
    height: 1.7rem;
    position: absolute;
    top: -0.75rem;
  }
  .text {
    height: 0.24rem;
    font-size: 0.15rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2538c0;
    line-height: 0.24rem;
    text-align: center;
    position: absolute;
    top: 0.6rem;
  }
</style>
