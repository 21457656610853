import { GamePost, Api } from '@/http/index';
import Soil from '../../../assets/images/farm/soil.png';
// 静态图
import Tree1 from '../../../assets/images/farm/tree-1.png';
import Tree2 from '../../../assets/images/farm/tree-2.png';
import Tree3 from '../../../assets/images/farm/tree-3.png';
import Tree4 from '../../../assets/images/farm/tree-4.png';
import Tree5 from '../../../assets/images/farm/tree-5.png';
import Tree6 from '../../../assets/images/farm/tree-6.png';
import Tree7 from '../../../assets/images/farm/tree-7.png';
import Tree8 from '../../../assets/images/farm/tree-8.png';
import Tree9 from '../../../assets/images/farm/tree-9.png';
import Tree9Orange from '../../../assets/images/farm/tree-9-orange.png';
// lottie json
import Tree1_Json from '../../../assets/lottie/farm/tree-1.json';
import Tree2_Json from '../../../assets/lottie/farm/tree-2.json';
import Tree3_Json from '../../../assets/lottie/farm/tree-3.json';
import Tree4_Json from '../../../assets/lottie/farm/tree-4.json';
import Tree5_Json from '../../../assets/lottie/farm/tree-5.json';
import Tree6_Json from '../../../assets/lottie/farm/tree-6.json';
import Tree8_Json from '../../../assets/lottie/farm/tree-8.json';
import Tree9_Json from '../../../assets/lottie/farm/tree-9.json';
import Tree9_Apple_Json from '../../../assets/lottie/farm/tree-9-apple.json';
import Tree9_Orange_Json from '../../../assets/lottie/farm/tree-9-orange.json';
import ProgressEnd from '../../../assets/images/farm/progress-end.png';
import ProgressEndOrange from '../../../assets/images/farm/progress-end-orange.png';
import { showModal } from '@/utils/modal';

export default {
  namespaced: true,
  state: () => ({
    curLevel: 1, // 当前果树等级,
    maxLevel: 9, // 最大等级
    status: '', // processing-进行中 finished-已完成
    newUser: false, // 是否新用户
    waterDrop: 0, // 水滴量
    waterDropMax: 360, // 水滴量上限
    waterText: '明日可领',
    isGainCompleteWater: false, // 今日水滴是否收获完成
    waterTimeStart: 5000, // 水滴倒计时开始
    kettleCapacity: 0, // 水壶容量
    kettleCapacityMax: 2000, // 水壶容量上限
    expendWater: 0, // 单次浇水量
    curWaterAmount: 0, // 当前浇水量
    needWaterAmount: 0, // 升级需要浇水量
    progressEndImg: ProgressEnd,
    progressBottomText: '再浇水32次，苹果树就长出来啦',
    progressTopText: '再浇水32次可得',
    type: 'apple', // 果树类型
    boardText: '苹果一箱', // 木板文案
    isRedPacketModal: false,
    hasTask: false, // 是否还有任务可做
    isDowngradeModal: false,
    isUpgradeModal: false,
    modalList: [],
    // 降级详情
    downDetail: {
      from: 5,
      to: 4,
      tips: '',
    },
    isStartTeaching: false, // 是否开始教学
    showTree: true,
    list: [
      {
        level: 1,
        soilUI: Soil,
        treeUI: Tree1,
        json: Tree1_Json,
        lock: false,
        info: '胚芽萌发',
      },
      {
        level: 2,
        soilUI: Soil,
        treeUI: Tree2,
        json: Tree2_Json,
        lock: true,
        info: '嫩芽吐绿',
      },
      {
        level: 3,
        soilUI: Soil,
        treeUI: Tree3,
        json: Tree3_Json,
        lock: true,
        info: '青青葱葱',
      },
      {
        level: 4,
        soilUI: Soil,
        treeUI: Tree4,
        json: Tree4_Json,
        lock: true,
        info: '枝繁叶茂',
      },
      {
        level: 5,
        soilUI: Soil,
        treeUI: Tree5,
        json: Tree5_Json,
        lock: true,
        info: '含苞待放',
      },
      {
        level: 6,
        soilUI: Soil,
        treeUI: Tree6,
        json: Tree6_Json,
        lock: true,
        info: '花开朵朵',
      },
      {
        level: 7,
        soilUI: Soil,
        treeUI: Tree7,
        json: Tree6_Json,
        lock: true,
        info: '招蜂引蝶',
      },
      {
        level: 8,
        soilUI: Soil,
        treeUI: Tree8,
        json: Tree8_Json,
        lock: true,
        info: '结出小果',
      },
      {
        level: 9,
        soilUI: Soil,
        treeUI: Tree9,
        json: Tree9_Json,
        lock: true,
        fruitJson: Tree9_Apple_Json,
        info: '瓜熟蒂落',
      },
    ],
    toastList: [],
    marquee: [], // broadcast文案
    user_tree: {}, // 用户种树信息
    signInfo: {},
    taskList: [],
    leavePageTime: 0,
    leaveTimer: null,
    leaveRewardTime: 15000,
  }),
  mutations: {
    // 修改水滴下方文案
    changeWaterCountTime(state, { time, isComplete }) {
      if (isComplete) {
        state.waterText = '明日可领';
        return;
      }
      const m = Math.floor(time / 60);
      const s = time % 60;
      state.waterText = `${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    },
    // 控制是否开始或结束教学
    controlTeaching(state, payload) {
      state.isStartTeaching = payload;
    },
    // 修改页面基础数据
    changePageInfo(state, payload) {
      Object.assign(state, payload);
      state.list = state.list.map((tree) => {
        if (tree.level <= state.curLevel) tree.lock = false;
        else tree.lock = true;
        if (tree.level === state.maxLevel) {
          const animAsset = {
            pingguo: Tree9_Apple_Json,
            juzi: Tree9_Orange_Json,
          };
          const treeAsset = {
            pingguo: Tree9,
            juzi: Tree9Orange,
          };
          tree.fruitJson = animAsset[state.type] || Tree9_Apple_Json;
          tree.treeUI = treeAsset[state.type] || Tree9;
        }
        return tree;
      });
      const progressEndAsset = {
        pingguo: ProgressEnd,
        juzi: ProgressEndOrange,
      };
      state.progressEndImg = progressEndAsset[state.type] || ProgressEnd;
    },
    // 升级弹窗
    changeUpgradeModal(state, payload) {
      state.isUpgradeModal = payload;
    },
    // 红包弹窗
    changeRedPacketModal(state, payload) {
      state.isRedPacketModal = payload;
    },
    // 降级弹窗
    changeDowngradeModal(state, payload) {
      state.isDowngradeModal = payload;
    },
    addModal(state, payload) {
      state.modalList.push.apply(state.modalList, payload);
      this.commit('farm/openModal');
    },
    openModal(state) {
      const modalHandler = state.modalList.shift();
      if (modalHandler) {
        modalHandler.call();
      }
    },
    // 修改降级详情
    changeDownDetail(state, { from, to, tips }) {
      state.downDetail.from = +from;
      state.downDetail.to = +to;
      state.downDetail.tips = tips;
    },
    setLeaveTime(state, data) {
      state.leavePageTime = data;
    },
    setLeaveTimer(state, data) {
      state.leaveTimer = data;
    },
    setLeaveRewardTime(state, data) {
      state.leaveRewardTime = data;
    },
    setShowTree(state, data) {
      state.showTree = data;
    },
  },
  actions: {
    async getPageInfo({ commit }, payload) {
      const showLoading = payload === undefined ? true : !!payload;
      const res = await GamePost(Api.GET_FARM_PAGE_INFO, {}, { parseData: false, showLoading }).catch((err) =>
        console.error(err)
      );
      if (res && +res.code === 0) {
        const data = res.data || {};
        const user_kettle = data.user_kettle || {};
        const user_droplets = data.user_droplets || {};
        const user_tree = data.user_tree || {};
        commit('changePageInfo', {
          marquee: data.marquee,
          kettleCapacity: user_kettle.user_capacity,
          kettleCapacityMax: user_kettle.max_capacity || 2000,
          waterDrop: user_droplets.user_capacity,
          waterDropMax: user_droplets.max_capacity || 360,
          waterTimeStart: user_droplets.countdown,
          user_tree,
          curLevel: +user_tree.level || 1,
          maxLevel: +user_tree.max_level,
          status: user_tree.status,
          newUser: !!+user_tree.is_new_user,
          expendWater: +user_tree.expend_water_value,
          curWaterAmount: user_tree.water_value,
          needWaterAmount: user_tree.up_water_value,
          progressBottomText: user_tree.tips,
          progressTopText: user_tree.reward_tips,
          type: user_tree.tree,
          boardText: user_tree.reward_title,
          toastList: user_tree.click_tips || [],
        });
      }
    },
    async gainWater() {
      let amount = 0;
      const res = await GamePost(Api.GET_FARM_TAKE_WATER_DROP, {}, { parseData: false }).catch((err) =>
        console.error(err)
      );
      if (res && +res.code === 0) {
        const data = res.data || {};
        amount = +(data.take_capacity || 0);
      }
      return amount;
    },
    // 浇水
    async watering({ commit }) {
      const res = await GamePost(Api.FARM_WATERING, {}, { parseData: false, showLoading: false }).catch((err) =>
        console.error(err)
      );
      if (res && +res.code === 0) {
        const data = res.data || {};
        const { is_up, is_water_reward } = data;
        const modalList = [];
        if (is_up) {
          const up = (data.window || {}).up || {};
          const handler = {
            name: 'farm-upgrade-modal',
            call: () => {
              commit('changeUpgradeModal', true);
              showModal('farm-upgrade-modal', {
                showCloseBtn: false,
                props: { awayFromReceiving: up.need_level || 9, amount: up.reward_amount },
              });
            },
          };
          modalList.push(handler);
        }
        if (is_water_reward) {
          const water_reward = (data.window || {}).water_reward || {};
          const handler = {
            name: 'red-packet-modal',
            call: () => {
              commit('changeRedPacketModal', true);
              showModal('red-packet-modal', {
                props: { amount: water_reward.reward_amount },
                pos: { top: 3.32, right: 1.3, width: 0.4, height: 0.4 },
                closeFn() {
                  commit('changeRedPacketModal', false);
                },
              });
            },
          };
          modalList.push(handler);
        }
        is_up &&
          modalList.push({
            name: 'upgrade-animation',
            call: () => {
              commit('setShowTree', true);
            },
          });
        commit('addModal', modalList);
      }
    },
    // 获取农场主动弹窗
    async getPopup() {
      try {
        const res = await GamePost(Api.GET_FARM_WINDOW_INFO, {}, { parseData: false });
        if (res && +res.code === 0) {
          const data = res.data || {};
          return data;
        }
      } catch (error) {
        console.log(`error`, error);
      }
    },
    // 获取签到和任务列表
    async getTaskInfo({ state }) {
      try {
        const res = await GamePost(Api.GET_FARM_TASK_INFO, {}, { parseData: false });
        if (res && +res.code === 0) {
          const data = res.data || {};
          console.log(`data`, data);
          const { sign_info, task_list } = data;
          state.signInfo = sign_info;
          state.taskList = task_list;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    // 水壶容量
    kettleCapacityNormalize(state) {
      return `${state.kettleCapacity}g`;
    },
    waterDropNormalize(state) {
      return `${state.waterDrop}g`;
    },
    // 树苗点击模型
    touchArea: () => {
      return (level) => {
        let area = {
          1: {
            bottom: '0.4rem',
            width: '0.62rem',
            height: '0.8rem',
          },
          2: {
            bottom: '0.4rem',
            width: '0.62rem',
            height: '1.2rem',
          },
          3: {
            bottom: '0.4rem',
            width: '1.12rem',
            height: '1.9rem',
          },
          4: {
            bottom: '0.4rem',
            width: '2.02rem',
            height: '2.2rem',
          },
          5: {
            bottom: '0.4rem',
            width: '2.02rem',
            height: '2.5rem',
          },
          6: {
            bottom: '0.4rem',
            width: '2.72rem',
            height: '3rem',
          },
        };
        return area[level] || { bottom: '0.4rem', width: '2.72rem', height: '3rem' };
      };
    },
    downTreeDetail(state) {
      let down = {};
      down.from = state.list.find((tree) => {
        return tree.level === state.downDetail.from;
      });
      down.to = state.list.find((tree) => {
        return tree.level === state.downDetail.to;
      });
      down.tips = state.downDetail.tips;
      return down;
    },
  },
};
