<template>
  <div class="modal_wrapper">
    <div class="modal_box" :style="{ height: height }">
      <img @click="$emit('close')" class="close_btn" src="@/assets/ky-close.png" alt="关闭" />

      <slot name="default"></slot>

      <div @click="$emit('click')" class="button main_button">
        <slot name="button"
          ><span class="button_text">{{ buttonText }}</span></slot
        >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      buttonText: {
        type: String,
        default: '',
      },
      height: {
        type: String,
        default: '2.51rem',
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_box {
    position: relative;
    top: -0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0 0.2rem;
    box-sizing: border-box;
    font-family: PingFangSC-Regular, PingFang SC;

    width: 2.68rem;
    min-height: 1rem;
    background: #fffbe6;
    border-radius: 0.12rem;
    .close_btn {
      position: absolute;
      right: 0rem;
      top: -0.5rem;
      width: 0.34rem;
      height: 0.34rem;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 100%;
    }

    .button {
      position: absolute;
      bottom: 0.23rem;
      background-position: center, center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 2rem;
      height: 0.5rem;
      background-image: url(../../../../assets/images/tree/modal_button.png);
      .button_text {
        position: absolute;
        top: 0.15rem;
        height: 0.15rem;
        font-size: 0.12rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #8c3213;
      }
    }
  }
</style>
