import { GamePost, Api } from '@/http';
import { trackClick } from '@/utils/track';
import { findAddElement, setBubblePositionShort, setBubblePositionLong } from './bubble.js';
import { getUrlParams } from '@/utils/common/index';

export default {
  namespaced: true,
  state: () => ({
    // 账号信息
    amount_info: {
      uid: 0,
      coin_amount: 0, //金币数
      balance_amount: 0, //余额
    },
    // 步数信息
    step_info: {
      maxSteps: 0, //配置的最大展示步数
      currentSteps: 0, //当前步数
    },
    // 签到信息
    sign_status: {
      sign_days: 0,
      received_status: 0,
      sign_days_rule: 0,
      sign_num_rule: 10000,
    },
    // 气泡信息
    air_bubbles: [],
    // 偷步数信息
    steal_info: {
      steal_num: 0,
      ad_steal: 0,
      // 看视频可获取偷步数次数
      reward_times: 0,
      // 已用偷取次数
      steal_used_num: 0,
      // 是否展示偷步数按钮
      is_show_steal: 0,
    },
    // 偷步数广告
    steal_ads: {},
    // 气泡广告
    ad_info: {},
    // 偷取的步数
    steal_step: 0,
    // 最大气泡步数
    max_bubbles_num: 0,
    // 自定义布局参数
    businessName: {
      free: '',
      video: '',
    },
  }),
  mutations: {
    setAmountInfo(state, data) {
      state.amount_info = data;
    },
    setStepInfo(state, data) {
      state.step_info = data;
    },
    setSignStatus(state, data) {
      state.sign_status = data;
    },
    setAirBubbles(state, data) {
      state.air_bubbles = data;
    },
    setStealInfo(state, data) {
      state.steal_info = data;
    },
    setStealAds(state, data) {
      state.steal_ads = data;
    },
    setAdInfo(state, data) {
      state.ad_info = data;
    },
    setStealStep(state, step) {
      state.steal_step = step;
    },
    setMaxBubblesNum(state, num) {
      state.max_bubbles_num = num;
    },
    setBusinessName(state, name) {
      state.businessName = name;
    },
  },
  actions: {
    /**
     * @description: 获取走路页信息
     * @param {object} store
     * @return {void}
     */
    async getWalkPageInfo({ commit, state }) {
      const position = getUrlParams('position');
      const res = await GamePost(
        Api.GET_NEW_WALK_PAGEINFO_API,
        { app_position: 'game_walk', position },
        { showLoading: false }
      ).catch((e) => {});
      const {
        amount_info,
        step_info,
        air_bubbles,
        steal_info,
        steal_ads,
        sign_status,
        ad_info,
        max_bubbles_num,
        business_name_son,
      } = res;
      commit('setAmountInfo', amount_info);
      commit('setStepInfo', step_info);
      commit('setSignStatus', sign_status);
      commit('setMaxBubblesNum', max_bubbles_num);
      commit('setBusinessName', business_name_son);
      // 找出新增的气泡 为其埋点
      const addArr = findAddElement(air_bubbles, state.air_bubbles);
      addArr.forEach((item) => {
        trackClick({
          page_title: '走路赚钱',
          element_name: '气泡曝光',
          music_user_id: `${item.bubble_id}-${item.bubble_type}`,
          remarks: `${item.bubble_id}_${item.bubble_type}`,
        });
      });

      commit('setAirBubbles', air_bubbles);
      commit('setStealInfo', steal_info);
      commit('setStealAds', steal_ads);
      commit('setAdInfo', ad_info);
    },
  },
  getters: {
    /**
     * @description: 资产信息
     * @param {state}
     * @return {obeject}
     */
    amount_info(state) {
      const { uid = 0, coin_amount = 0, balance_amount = 0 } = state.amount_info;
      return {
        uid,
        coin_amount: Number(coin_amount),
        balance_amount: Number(balance_amount),
      };
    },

    /**
     * @description: 步数信息
     * @param {state}
     * @return {obeject}
     */
    step_info(state) {
      return state.step_info;
    },

    /**
     * @description: 气泡列表
     * @param {state}
     * @return {obeject} 带位置信息的气泡列表
     */
    airBubblesList(state) {
      const { air_bubbles, max_bubbles_num } = state;
      // 处理气泡列表的位置信息
      const airBubblesList = air_bubbles.map((item) => {
        let positon;
        if (+max_bubbles_num > 5) {
          positon = setBubblePositionLong(item.num);
        } else {
          positon = setBubblePositionShort(item.num);
        }
        return {
          ...item,
          ...positon,
        };
      });

      return airBubblesList;
    },

    /**
     * @description: 偷步数信息
     * @param {state}
     * @return {obeject}
     */
    steal_info(state) {
      return state.steal_info;
    },

    /**
     * @description: 签到信息
     * @param {state}
     * @return {obeject}
     */
    sign_status(state) {
      return state.sign_status;
    },

    /**
     * @description: 广告信息
     * @param {state}
     * @return {obeject}
     */
    ad_info(state) {
      return state.ad_info;
    },

    // 偷步数广告信息
    steal_ads(state) {
      return state.steal_ads;
    },

    // 偷取的步数
    steal_step(state) {
      return state.steal_step;
    },

    // 最大气泡数
    max_bubbles_num(state) {
      return state.max_bubbles_num;
    },

    businessName(state) {
      return state.businessName;
    },
  },
};
