<template>
  <CommonModal buttonText="知道了" @click="onClick" @close="onClose" height="1.96rem">
    <img v-if="+status === 1" class="title_img" src="@/assets/images/tree/water.png" alt="water" />
    <img v-else class="title_img" src="@/assets/images/tree/title_coin.png" alt="water" />
    <p class="text">离线时间摇钱树也会持续产生收益,<br />记得按时来收取哦</p>
  </CommonModal>
</template>

<script>
  import CommonModal from '../CommonModal/CommonModal';
  export default {
    components: {
      CommonModal,
    },
    props: {
      status: {
        type: Number,
        default: 0,
      },
      onClick: {
        type: Function,
        default: () => {},
      },
      onClose: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>

<style lang="less" scoped>
  .title_img {
    width: 1.7rem;
    height: 1.7rem;
    position: absolute;
    top: -0.75rem;
  }
  .text {
    width: 2.31rem;
    height: 0.48rem;
    font-size: 0.15rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2538c0;
    line-height: 0.24rem;

    text-align: center;
    position: absolute;
    top: 0.6rem;
  }
</style>
