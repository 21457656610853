<template>
  <div class="income-record">
    <div class="title">收支记录</div>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="onLoad"
      >
        <div class="record" v-for="(item, idx) in list" :key="idx">
          <div class="content">
            <div class="left">
              <div>{{ item.title }}</div>
              <div>{{ item.reward_time }}</div>
            </div>
            <div class="right">{{ item.reward_info }}</div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="tips">仅展示最近3天明细，最多展示50条记录</div>
  </div>
</template>

<script>
  import { GamePost, Api } from '@/http/index';
  export default {
    name: 'IncomeRecordModal',
    components: {},
    props: {},
    data() {
      return {
        loading: false,
        finished: false,
        page: 1,
        list: [],
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getIncomeRecord();
    },
    methods: {
      onLoad() {
        console.log('onLoad');
        this.getIncomeRecord();
      },
      // 得到收支记录
      async getIncomeRecord() {
        const res = await GamePost(Api.GET_FARM_INCOME_RECORD, { page: this.page }, { parseData: false }).catch((err) =>
          console.error(err)
        );
        if (res && +res.code === 0) {
          const data = res.data || {};
          const rows = data.rows || [];
          if (!rows.length) {
            this.finished = true;
          }
          this.list = [...this.list, ...rows];
          this.loading = false;
          this.page += 1;
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .income-record {
    box-sizing: border-box;
    padding: 0.2rem 0.2rem;
    width: 3rem;
    height: 2.92rem;
    background: #ffffff;
    border-radius: 0.12rem;
    .title {
      width: 2.6rem;
      height: 0.25rem;
      text-align: center;
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.25rem;
    }
    .list {
      overflow: scroll;
      height: 1.8rem;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      margin-top: 0.15rem;
      .record {
        box-sizing: border-box;
        width: 100%;
        height: 0.6rem;
        padding-top: 0.14rem;
        .content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .left {
            div:first-child {
              height: 0.14rem;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 0.14rem;
              margin-bottom: 0.05rem;
            }
            div:last-child {
              height: 0.15rem;
              font-size: 0.12rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 0.15rem;
            }
          }
          .right {
            height: 0.14rem;
            font-size: 0.14rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4acc99;
            line-height: 0.14rem;
          }
        }
      }
    }
    .tips {
      margin-top: 0.2rem;
      text-align: center;
      width: 100%;
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #a6a6a6;
      line-height: 0.12rem;
    }
  }
</style>
