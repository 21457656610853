import store from '@/store/index';

export const showModal = async (type, options) => {
  await store.dispatch('openModal', {
    modalType: type, // 弹窗类型（必传）
    show: true, // 弹窗显示控制
    ...options,
  });
};

export const closeModal = async (type) => {
  await store.dispatch('closeModal', {
    modalType: type,
  });
};
