<template>
  <div class="modal_wrapper">
    <p class="top_tips">由于长期未登录<br />您已丢失升杯的特权</p>
    <img class="level_down_cup" src="@/assets/images/drinkingTask/leveldown_cup.png" alt="cup" />
    <p class="bottom_tips">
      预计每日少得<span>{{ coins }}</span
      >金币
    </p>
    <div class="button" @click="onClick">立即恢复升杯特权</div>
  </div>
</template>

<script>
  export default {
    props: {
      onClick: {
        type: Function,
        default: () => {},
      },
      coins: {
        type: Number,
        default: 0,
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -0.4rem;
    .top_tips {
      text-align: center;
      height: 0.46rem;
      font-size: 0.14rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #aedaff;
      line-height: 0.23rem;
    }
    .level_down_cup {
      width: 1.45rem;
      height: 1.45rem;
    }
    .bottom_tips {
      height: 0.23rem;
      font-size: 0.14rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #aedaff;
      line-height: 0.23rem;
      display: flex;
      align-items: flex-end;
      span {
        height: 0.29rem;
        font-size: 0.29rem;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #aedaff;
        line-height: 0.29rem;
      }
    }
    .button {
      width: 2.6rem;
      height: 0.4rem;
      background: #62b7ff;
      border-radius: 0.25rem;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      margin-top: 0.4rem;
    }
  }
</style>
