// 红包进度状态
export const ProgressPointStatus = {
  // 无红包
  NO_REDPACKET: 0,
  // 领取过的红包
  EMPTY: 1,
  // 未领取的红包
  FULL: 2,
  // 激活状态的红包(有光)
  ACTIVE: 3,
};

// 树状态 0-树苗 1-小树 2-大树 3-死树
export const TreeStatus = {
  // 树苗
  SAPLING: 0,
  // 小树
  SMALL_TREE: 1,
  // 摇钱树
  BIG_TREE: 2,
  // 死树
  DEAD: 3,
};

// 树苗阶段领取红包的水滴节点
export const saplingRedpacketPointList = [20];
// 小树阶段领取红包的水滴节点
export const smallTreeRedpacketPointList = [30, 60];

export function getNextRedpacketWaterTimes(pointlist, now) {
  const next = pointlist.find((item) => item > now);

  if (next) {
    return (next - now) / 10;
  } else {
    return '';
  }
}

export function getRedpacketStyle(item) {
  let { id, type } = item;
  switch (+id) {
    case 1:
      return {
        ...item,
        left: '0.57rem',
        top: '2.66rem',
        type: 'middle',
        zIndex: 52,
        img_type: type,
      };
    case 2:
      if (type == 1) {
        return {
          ...item,
          left: '1.25rem',
          top: '0.82rem',
          type: 'right',
          zIndex: 52,
          img_type: type,
        };
      } else {
        return {
          ...item,
          left: '1.25rem',
          top: '0.92rem',
          type: 'right',
          zIndex: 52,
          img_type: type,
        };
      }

    case 3:
      return {
        ...item,
        left: '1.81rem',
        top: '2.07rem',
        type: 'middle',
        zIndex: 52,
        img_type: type,
      };
    case 4:
      if (type == 1) {
        return {
          ...item,
          left: '2.29rem',
          top: '1.58rem',
          type: 'left',
          zIndex: 54,
          img_type: type,
        };
      } else {
        return {
          ...item,
          left: '2.29rem',
          top: '1.48rem',
          type: 'left',
          zIndex: 54,
          img_type: type,
        };
      }
    case 5:
      return {
        ...item,
        left: '2.86rem',
        top: '2.42rem',
        type: 'middle',
        zIndex: 52,
        img_type: type,
      };
    default:
      return {
        ...item,
        left: '2.86rem',
        top: '2.42rem',
        type: 'middle',
        zIndex: 52,
        img_type: type,
      };
  }
}
