/*
 * @description: 定义安卓客户端调用的接口
 */

const ANDROID_LISTEN_METHOD = {
  ON_SHOW: 'onShow',
  ON_HIDDEN: 'onHidden',
  ON_REWARD_VIDEO_PLAYEND: 'onRewardVideoPlayEnd',
  ON_MUSIC_ACTION: 'onMuscicAction',
  ON_BIND_WE_CHAT_ACCOUNT: 'onBindWeChatAccount',
  ON_CALENDAR_CALLBACK: 'onCalendarCallBack',
  ON_REWARD_VIDEO_PLAY_FAILED: 'onRewardVideoPlayFailed',
  ON_REWARD_VIDEO_JUMP: 'onRewardVideoJump',
  ON_UPLOAD_SUCCESS: 'onUploadSuccess',
  /**
   * @description: 监听恭喜获得模块相关事件
   * @param {string} event - onCongratulationsPopWindow回调的参数  'show'--弹窗打开 'dismiss'--弹窗关闭 'playVideo'--开始看视频 'playVideoComplete'--看完视频 'playVideoCompleteSkip'--跳过视频
   * @return {void}
   */
  ON_CONGRATULATIONS_POPWINDOW: 'onCongratulationsPopWindow',
  // 5.2.0的插屏广告失败回调
  ON_AD_EXPOSURE_FAILURE: 'onAdExposureFailure',
  // 5.3.0以上的插屏广告失败回调
  ON_AD_RENDER_ERROR: 'onAdRenderError',
  // 插屏广告曝光
  ON_AD_EXPOSURE: 'onAdExposure',
  // 插屏广告点击
  ON_AD_CLICK: 'onAdClick',
};

export default ANDROID_LISTEN_METHOD;
