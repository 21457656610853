<template>
  <div class="coin_wrapper" :style="{ width: size, height: size }">
    <span>{{ count }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      count: {
        type: Number,
        default: 0,
      },
      size: {
        type: String,
        default: '0.34rem',
      },
    },
  };
</script>

<style lang="less" scoped>
  .coin_wrapper {
    position: relative;
    z-index: 52;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/images/newSleep/coin.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    span {
      position: relative;
      text-align: center;
      top: 0;
      font-size: 12px;
      font-family: DINAlternate-Bold, DINAlternate, sans-serif;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0 0.1rem 0.12rem #fcab1f;
      background-image: linear-gradient(180deg, #ffffff 0%, #fddc76 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
</style>
