import { GamePost } from '@/http/index';
import { getUrlParams } from '@/utils/common/index';

export default {
  namespaced: true,
  state: () => {
    // 为喝水列表设定初始值
    const drinkingCardList = Array(8)
      .fill()
      .map((_, i) => i + 1)
      .map((item, index) => {
        return {
          id: index,
          status: 0,
          icon: 0,
          min_hour: '00:00:00',
          fill_punch_ttl: 30,
        };
      });
    return {
      drinkingCardList, // 当前八杯水列表
      keepSign: {}, // 连续打卡
      fullSign: {}, // 全勤打卡
      adInfo: {}, // 广告信息
      nextInitTime: 0, // 下次打卡时差/ms
      dailyCoins: '-',
      bannerList: [],
      mediationAdInfo: {
        ad_group_id: 0,
        ad_platform_status: false,
      },
      businessName: '',
      drinkCardTitle: '喝水赚金币，健康好生活',
      businessNamePunch: '',
    };
  },
  mutations: {
    setDrinkingCardList(state, list) {
      state.drinkingCardList = [...list];
    },
    setKeepSign(state, data) {
      state.keepSign = { ...data };
    },
    setFullSign(state, data) {
      state.fullSign = { ...data };
    },
    setAdInfo(state, data) {
      state.adInfo = { ...data };
    },
    setNextInitTime(state, time) {
      state.nextInitTime = time;
    },
    setDailyCoins(state, coins) {
      state.dailyCoins = coins;
    },
    setBannerList(state, list) {
      state.bannerList = [...list];
    },
    setMediationAdInfo(state, data) {
      state.mediationAdInfo = { ...data };
    },
    setBusinessName(state, name) {
      state.businessName = name;
    },
    setDrinkCardTitle(state, title) {
      state.drinkCardTitle = title;
    },

    setBusinessNamePunch(state, payload) {
      state.businessNamePunch = payload;
    },
  },
  actions: {
    async getDrinkingCardList({ commit }, abType) {
      // '/drinking/detail_v2'
      try {
        const url =
          abType == 'b' ? '/drinking/detail_v3b' : abType == 'c' ? '/drinking/detail_v3c' : '/drinking/detail_v2';
        const position = getUrlParams('position');
        const data = await GamePost(
          url,
          { position: position || '' },
          {
            showLoading: false,
          }
        );

        const {
          list, // 当前八杯水列表
          normal_sign_stats, // 连续打卡
          full_sign_stats, // 全勤打卡
          ad_info, // 广告信息
          daily_coins, // 当天总收益
          server_ts, // 服务器当前时间
          next_ts, // 下一次打卡时间
          banner, // 广告轮播信息
          // 聚合广告信息
          ad_group_id,
          ad_platform_status,
          reissue_drink_ad_group_id,
          title,
          business_name,
          business_name_punch,
        } = data;
        commit('setDrinkingCardList', list);
        commit('setKeepSign', normal_sign_stats);
        commit('setFullSign', full_sign_stats);
        commit('setAdInfo', ad_info);
        commit('setDailyCoins', daily_coins);
        commit('setBannerList', banner);
        commit('setMediationAdInfo', { ad_group_id, ad_platform_status, reissue_drink_ad_group_id });
        const nextInitTime = (next_ts - server_ts) * 1000; // 下次打卡时差
        commit('setNextInitTime', nextInitTime);
        commit('setBusinessName', business_name);
        if (title) {
          commit('setDrinkCardTitle', title);
        }
        if (business_name_punch) {
          commit('setBusinessNamePunch', business_name_punch);
        }
      } catch (e) {
        console.log(`getDrinkingCardList e => ${e}`);
      }
    },
  },
  getters: {
    // 七日签到列表
    signSevenDaysList(state) {
      const list = [
        {
          dayTime: 1,
          hasSign: false,
        },
        {
          dayTime: 2,
          hasSign: false,
        },
        {
          dayTime: 3,
          hasSign: false,
        },
        {
          dayTime: 4,
          hasSign: false,
        },
        {
          dayTime: 5,
          hasSign: false,
        },
        {
          dayTime: 6,
          hasSign: false,
        },
        {
          dayTime: 7,
          hasSign: false,
        },
      ];
      const { sign_days } = state.keepSign;
      return list.map((day) => {
        day.hasSign = sign_days >= day.dayTime;
        day.isRewardDay = day.dayTime === 7;
        return day;
      });
    },

    // 是否满足连续签到领奖条件
    canReceiveKeepSignReward(state) {
      const { keepSign } = state;
      if (keepSign) {
        const { sign_days, sign_days_rule } = keepSign;
        return +sign_days === +sign_days_rule;
      } else {
        return false;
      }
    },

    // 是否满足全勤领奖条件
    canReceiveFullSignReward(state) {
      const { fullSign } = state;
      if (fullSign) {
        const { sign_times, full_times_rule } = fullSign;
        return +sign_times === +full_times_rule;
      } else {
        return false;
      }
    },
  },
  modules: {},
};
