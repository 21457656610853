<template>
  <div class="congratulation">
    <h3>恭喜获得</h3>
    <img class="congratulation-icon" :src="iconUrl" alt="icon" />
    <p>{{ text }}</p>
    <button v-if="showVideoBtn && seconds" @click="onClickAd">
      <img src="@/assets/images/farm/congratulation-modal/video.png" alt="video" srcset="" />
      翻倍领取<span v-if="showCountdown">（{{ seconds }}s）</span>
    </button>
    <h5 @click="onClick">直接领取</h5>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {
      rewardType: {
        type: String,
        default: '',
      },
      amount: {
        type: Number,
        default: 10,
      },
      onClickAd: {
        type: Function,
        default: () => {},
      },
      onClick: {
        type: Function,
        default: () => {},
      },
      showCountdown: {
        type: Boolean,
        default: false,
      },
      showVideoBtn: {
        type: Boolean,
        default: true,
      },
    },
    name: 'congratulation',
    data() {
      return {
        timer: null,
        seconds: 5,
      };
    },
    computed: {
      iconUrl() {
        return this.rewardType === 'droplets'
          ? require('@/assets/images/farm/congratulation-modal/water.png')
          : require('@/assets/images/farm/congratulation-modal/coin.png');
      },
      text() {
        return this.rewardType === 'coin' ? `金币x${this.amount}` : `水滴x${this.amount}g`;
      },
    },
    watch: {},
    created() {},
    mounted() {
      if (this.showCountdown) {
        this.countdown();
      }
    },
    methods: {
      countdown() {
        if (this.timer || this.seconds <= 0) {
          this.timer = null;
          clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
          this.seconds--;
          if (this.seconds < 0) {
            clearTimeout(this.timer);
            this.timer = null;
            this.seconds = 0;
          } else {
            this.countdown();
          }
        }, 1000);
      },
    },
  };
</script>
<style scoped lang="less">
  .congratulation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.2rem;
    width: 2.6rem;
    // height: 2.27rem;
    background: #ffffff;
    border-radius: 0.12rem;
    h3 {
      height: 0.25rem;
      line-height: 0.25rem;
      font-size: 0.18rem;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    &-icon {
      margin-top: 0.12rem;
      width: 0.82rem;
      height: 0.82rem;
      border-radius: 50%;
    }
    p {
      margin-top: 0.06rem;
      font-size: 0.12rem;
      color: #333333;
    }
    button {
      margin-top: 0.19rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.6rem;
      height: 0.4rem;
      background: url('~@/assets/images/farm/congratulation-modal/button_bg.png') no-repeat center center;
      background-size: contain;
      font-size: 0.16rem;
      font-weight: 600;
      color: #9d4f07;
      img {
        margin-right: 0.05rem;
        width: 0.16rem;
        height: 0.16rem;
      }
    }
    h5 {
      margin-top: 0.08rem;
      width: 100%;
      text-align: center;
      font-size: 0.16rem;
      font-weight: 600;
      color: #a6a6a6;
    }
  }
</style>
