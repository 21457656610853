<template>
  <div class="modal_wrapper">
    <div class="modal_box">
      <img @click="onClose" class="close_btn" src="@/assets/images/newSleep/close.png" alt="关闭" />
      <div class="tips">限时{{ Math.floor(+res.ttl / 3600) }}小时</div>
      <h2 class="title">恭喜获得限时道具</h2>
      <h1 class="name">{{ name }}</h1>
      <div class="img_wrapper">
        <img v-if="+rewardType === 3" class="bed" src="@/assets/images/newSleep/reward_bed.png" alt="道具" />
        <img v-else-if="+rewardType === 2" class="mask" src="@/assets/images/newSleep/mask.png" alt="道具" />
        <img v-else-if="+rewardType === 1" class="hat" src="@/assets/images/newSleep/hat.png" alt="道具" />
      </div>
      <p class="desc">
        <span>{{ name }}</span> 可以提升1点状态值<br />获得更多气泡金币哦
      </p>

      <div @click="handleClickUse" class="sign_button">
        <span>立即使用</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { trackClick } from '@/utils/track';
  export default {
    props: {
      refresh: {
        type: Function,
        default: () => {},
      },
      rewardType: {
        type: Number,
        default: 1,
      },
      res: {
        required: true,
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      closeFn: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      // ...mapState('new_sleep', ['signInfo', 'task_id']),
      name() {
        const rewardType = this.rewardType;
        return rewardType === 1 ? '睡帽' : rewardType === 2 ? '眼罩' : rewardType === 3 ? '睡床' : '';
      },
    },
    methods: {
      handleClickUse() {
        trackClick({ page_title: '签到道具使用弹窗', element_name: '立即使用' });
        this.closeFn();
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_wrapper {
    .modal_box {
      position: relative;
      width: 2.68rem;
      height: 3.86rem;
      background: #e1efff;
      border-radius: 0.12rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0.4rem;
      box-sizing: border-box;
      .close_btn {
        position: absolute;
        top: 0.14rem;
        right: 0.12rem;
        width: 0.18rem;
        height: 0.18rem;
      }
      .tips {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.8rem;
        height: 0.3rem;
        background: #5493ff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.12rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        transform: rotate(-45deg) translateX(-0.5rem) translateY(-0.24rem);
      }
      .title {
        width: 1.2rem;
        height: 0.15rem;
        font-size: 0.15rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2538c0;
        line-height: 0.15rem;
        margin-bottom: 0.1rem;
      }
      .name {
        height: 0.22rem;
        font-size: 0.22rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #2538c0;
        line-height: 0.22rem;
      }
      .img_wrapper {
        position: relative;
        top: -0.27rem;
        width: 2.07rem;
        height: 2.07rem;
        background-image: url(../../../assets/images/newSleep/light.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .bed {
          width: 1.74rem;
          height: 0.94rem;
        }
        .mask {
          width: 1.6rem;
          height: 1.25rem;
        }
        .hat {
          width: 1.73rem;
          height: 1.73rem;
        }
      }
      .desc {
        position: absolute;

        bottom: 0.88rem;
        // width: 1.29rem;
        width: 100%;
        height: 0.36rem;
        font-size: 0.14rem;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #2538c0;
        line-height: 0.18rem;
        text-align: center;
        span {
          font-weight: bolder;
        }
      }
      .sign_button {
        width: 2.22rem;
        height: 0.56rem;
        background-image: url(../../../assets/images/newSleep/check-button.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0.3rem;
        left: 50%;
        transform: translateX(-50%);
        span {
          font-size: 0.12rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bolder;
          color: #8c3213;
        }
      }
    }
  }
</style>
