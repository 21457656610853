<template>
  <div class="fortune-reward-box">
    <img class="light" src="@/assets/images/fortune-wheel/light.png" />
    <img class="head" src="@/assets/images/fortune-wheel/reward-head.png" />
    <main class="reward-content">
      <p class="type sleep_game_prop_type" v-if="reward.reward_type === 'sleep_game_prop'">
        恭喜获得限时<span class="time">{{ Math.floor(+reward.sleep_game_prop_info.ttl / 3600) }}小时</span>道具<br />
        <span>{{ reward.reward_text }}</span>
      </p>
      <p class="type" v-else>{{ reward.reward_text }}</p>
      <p class="num" v-if="reward.reward_type !== 'sleep_game_prop'">+{{ reward.reward_num }}</p>
      <img class="coin" :src="reward.reward_pic" />

      <p v-if="reward.reward_type === 'sleep_game_prop'" class="tips">
        <span>{{ reward.reward_text }}</span> 可以提升1点状态值 <br />获得更多气泡金币哦
      </p>

      <button class="btn" v-if="reward.reward_type === 'sleep_game_prop'" @click="handleClose">
        <span>立即领取</span>
      </button>
      <button class="btn" v-else-if="child === 1" @click="handleAgainBtn">{{ btnText }}</button>
      <button class="btn" v-else-if="child === 3" @click="handleClose">我知道了</button>
      <button class="btn" v-else-if="child === 2 && reward.reward_type !== 'sleep_game_prop'" @click="handleBtn">
        <span>翻倍领取</span>
        <img class="video-icon" src="@/assets/images/fortune-wheel/video.png" />
      </button>
    </main>
  </div>
</template>

<script>
  export default {
    name: 'fortuneReward',
    props: {
      child: Number,
      handleBtn: Function,
      handleClose: Function,
      origin: String,
      handleAgainBtn: Function,
    },
    computed: {
      reward() {
        if ('lottery' === this.origin) {
          return this.$store.getters.reward;
        } else {
          return this.$store.getters.chestReward;
        }
      },
      btnText() {
        const { doubleCard, againCard } = this.reward;

        if (doubleCard || againCard) {
          return '立即使用';
        }

        return '再抽一次';
      },
    },
  };
</script>

<style lang="less" scoped>
  .fortune-reward-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reward-content {
    position: relative;
    top: -2rem;
    z-index: 6;
    width: 2.1rem;
    padding: 0.3rem 0 0.1rem 0;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.21);
    border-radius: 0.12rem;
    background-color: #fff;
    text-align: center;
  }
  .light,
  .head,
  .coin {
    vertical-align: middle;
  }
  .light {
    position: relative;
    top: 1.55rem;
    width: 3.3rem;
  }
  .head {
    position: relative;
    top: -1.5rem;
    z-index: 7;
    width: 2.88rem;
  }
  .coin {
    width: 1.2rem;
    margin-bottom: 0.02rem;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 1.98rem;
    height: 0.4rem;
    background: #ae00ff;
    border-radius: 0.21rem;
    font-size: 0.16rem;
    font-weight: 600;
    color: #fff;
  }
  .type {
    font-size: 0.14rem;
    font-weight: 400;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #080707;
  }
  .sleep_game_prop_type {
    font-size: 0.14rem;
    color: #080707;
    font-weight: normal;
    span {
      font-weight: bold;
      font-size: 0.22rem;
      color: #9611db;
      margin-top: 0.08rem;
    }
    .time {
      font-size: 0.14rem;
    }
  }
  .tips {
    font-size: 0.12rem;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #080707;
    span {
      font-weight: bold;
      font-size: 0.12rem;
      color: #ae00ff;
    }
    margin-bottom: 0.14rem;
  }
  .num {
    margin: 0.06rem 0 0.02rem;
    font-size: 0.24rem;
    font-weight: bold;
    font-family: DINAlternate-Bold, DINAlternate;
    color: #ae00ff;
  }
  .video-icon {
    width: 0.14rem;
    margin-left: 0.08rem;
  }
</style>
