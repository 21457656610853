<template>
  <div class="upgrade">
    <div class="title">果树升级</div>
    <div class="title-2">
      恭喜升级，还有
      <span>{{ awayFromReceiving }}</span>
      级就可以领水果啦
    </div>
    <img class="icon" src="@/assets/images/farm/coin-modal.png" />
    <div class="text">金币x{{ amount }}</div>
    <div class="award-btn" @click="handleReward">领取奖励</div>
  </div>
</template>

<script>
  import { GamePost, Api } from '@/http/index';
  import { mapMutations } from 'vuex';
  import { trackClick } from '@/utils/track';
  export default {
    components: {},
    props: {
      // 距离领取水果还差多少等级
      awayFromReceiving: {
        type: Number,
        default: 0,
      },
      // 奖励
      amount: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      ...mapMutations('farm', ['changeUpgradeModal']),
      async handleReward() {
        trackClick({ page_title: '快音农场', element_name: '领取升级奖励' });
        const res = await GamePost(Api.GET_FARM_UPGRADE_REWARD, {}, { parseData: false }).catch((err) =>
          console.error(err)
        );
        if (res && +res.code === 0) {
          this.changeUpgradeModal(false);
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .upgrade {
    box-sizing: border-box;
    padding: 0.2rem 0.2rem 0.24rem 0.2rem;
    width: 3rem;
    height: 3rem;
    background: #ffffff;
    border-radius: 0.12rem;
    .title {
      text-align: center;
      height: 0.25rem;
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.25rem;
    }
    .title-2 {
      margin: 0.12rem auto 0.15rem;
      text-align: center;
      width: 1.9rem;
      height: 0.44rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a6a6a6;
      line-height: 0.22rem;
      span {
        color: red;
      }
    }
    .icon {
      display: block;
      margin: 0 auto;
      width: 0.82rem;
      height: 0.82rem;
    }
    .text {
      margin: 0.07rem auto 0.19rem auto;
      text-align: center;
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.12rem;
    }
    .award-btn {
      text-align: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #9d4f07;
      line-height: 0.4rem;
      width: 2.6rem;
      height: 0.4rem;
      background: url('~@/assets/images/farm/gain-award-btn-modal.png') no-repeat;
      background-size: 100% 100%;
    }
  }
</style>
