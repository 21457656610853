import sdk from '@/utils/sdk/index';

export function track(event, data) {
  if (window.sa && window.sa.track) {
    data.time = new Date().getTime();
    const userInfo = sdk.appCall(sdk.callMethod.GET_USER_INFO);
    if (userInfo && userInfo.oaid) {
      data.oaid = userInfo.oaid;
    } else {
      data.oaid = '';
    }
    window.sa.track(event, data);
  }
}
export function trackClick(data, event) {
  data = Object.assign({}, data);
  if (data.remarks) data.remarks = String(data.remarks);
  track(event || 'element_click', {
    page_title: data.page_title || '',
    element_type: data.element_type || 'button',
    element_name: data.element_name || '',
    music_user_id: data.music_user_id || '',
    music_id: data.music_id || '',
    music_code: data.music_code || '',
    remarks: data.remarks || '',
  });
}
export function trackAdVideo(data) {
  data = Object.assign({}, data);
  track('ad_funnel', {
    ad_type: data.ad_type || '',
    is_master: data.is_master || '',
    ad_platform: videoTypeMap[data.ad_platform] || '',
    ad_source: data.ad_source || '',
    ad_position: data.ad_position || '',
    app_position: data.app_position || '',
    page_type: 'H5',
    ad_stage: data.ad_stage || '',
    is_success: Number(data.is_success),
    music_content_id: data.music_content_id || '',
    ad_hash: data.ad_hash || '',
    error: data.error || '',
  });
}

const videoTypeMap = {
  gdt: '广点通',
  ocean_engine: '穿山甲',
  hytt: '东方头条',
  kuaiyin: '快音',
  admobile: '艾狄墨搏',
};
