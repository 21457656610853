export default {
  DRINK_PUNCH_API: '/drinking/punch_v2',
  GET_DRINK_SIGN_REWARD_API: '/drinking/receive_sign_reward',
  GET_DRINK_REPUNCH: '/drinking/do_fill_punch_v3',
  GET_DRINK_REPUNCH_V4: '/drinking/do_fill_punch_v4',
  GET_DRINK_PUNCH: '/drinking/punch_v3',
  MARK_PUNCH_DOUBLE: '/drinking/mark_punch_double_reward',
  MARK_RECEIVE_PUNCH: '/drinking/mark_receive_punch_num_reward',
  GET_DRINK_WINDOWS_INFO: '/drinking/windows',
};
