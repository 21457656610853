<template>
  <div class="modal_wrapper">
    <div class="modal_box">
      <p class="row">· 点击水壶为摇钱树浇水，每次消耗10滴水，每10分钟可获得1滴水，水壶最多只能装100滴水。</p>
      <p class="row">· 3次浇水后树苗可长成为小树，13次浇水后小树可长成为摇钱树。</p>
      <p class="row">· 摇钱树上共有5个红包，每个红包最多可装50金币，5个红包都装满后不再产生收益。</p>
      <p class="row">· 每天12:00、20:00摇钱树会产生蛀虫，蛀虫会使您的金币产生变慢,请您记得及时上线清理。</p>
      <p class="row">
        · 从第二条蛀虫产生开始，连续48小时内如果您未清理，您的摇钱树则会死亡，此时您需重新种树即可重玩。
      </p>
      <p class="row">
        · 摇钱树每天可为您持续产生金币，每天最高<span class="strong">18888</span>金币收益，上线即可领取。
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      buttonText: {
        type: String,
        default: '',
      },
      height: {
        type: String,
        default: '2.51rem',
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal_box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC-Regular, PingFang SC;
    width: 2.8rem;
    height: 4.67rem;
    background-image: url(../../../../assets/images/tree/rule_box.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0.6rem 0.26rem 0.47rem;
    box-sizing: border-box;
    .row {
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2538c0;
      line-height: 0.17rem;
      margin-top: 0.15rem;
      .strong {
        font-size: 0.12rem;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #fa3123;
        line-height: 0.17rem;
      }
    }
  }
</style>
