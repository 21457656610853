import fetch from '../fetch';
import Store from '@/store';
import { signature } from '../sign';
import loading from '@/components/Loading/Loading';

const BASE_PARAMS = () => {
  const {
    getters: { client },
  } = Store;

  return {
    access_token: client.accessToken,
    visitor_token: client.visitor_token,
    tourist_token: client.tourist_token || '',
    platform: client.platform,
    platform_brand: client['platform-brand'] || '',
    client_v: client['client-v'],
    app_v: client['app-v'],
    device_id: client['sa_device_id'],
    utm_source: client['utm-source'],
    request_source: 'h5',
    platform_model: client['platform-model'],
  };
};

/**
 * @description:
 * @param {string} url - 接口地址
 * @param {data} params - 请求参数
 * @param {object} config - 请求设置
 * @param {boolean} config.showLoading - 是否显示loading
 * @return {Promise}
 */
const GamePost = (url, params, config = {}) => {
  const BaseURL = process.env['VUE_APP_GAME_BASE_URL'] + url;
  const baseParams = BASE_PARAMS();
  let data = { ...baseParams, ...params };
  const { showLoading = true, parseData = true } = config;
  if (showLoading) loading.show();
  let networkType = '';

  try {
    const {
      navigator: {
        connection: { type, effectiveType },
      },
    } = window;

    networkType = effectiveType || type;
  } catch (e) {
    console.log(e);
  }
  return fetch.post(BaseURL, signature(data), {
    parseData,
    headers: {
      'client-v': baseParams.client_v,
      'device-id': baseParams.device_id,
      platform: baseParams.platform,
      'utm-source': baseParams.utm_source,
      'platform-brand': baseParams.platform_brand,
      'platform-model': baseParams.platform_model,
      'network-type': networkType,
    },
  });
};

export default GamePost;
