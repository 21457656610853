import Modal from './Modal';
import CoinTag from './CoinTag';

const CommonComponents = {
  install(Vue) {
    Vue.component('Modal', Modal);
    Vue.component('CoinTag', CoinTag);
  },
};
export default CommonComponents;
