export default {
  // 初始化转盘接口
  INIT_TURNTABLE_API: '/turntable/get_turntable_info',
  // 打开幸运转盘宝箱接口
  OPEN_FORTUNE_CHEST_API: '/turntable/open_box',
  // 转盘抽奖接口
  GET_TURNTABLE_REWARD_API: '/turntable/getTurntableReward',
  // 转盘记录拉取视频接口
  MARK_PULL_VIDEO_API: '/turntable/markDoVideo',
};
