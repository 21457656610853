import ANDROID_LISTEN_METHOD from './interface';
import ANDROID_BRIDGE_METHOD from './method';

const USER_INFO = require('../../user-info.json');
// const { bridge } = window;

/**
 * @description: 初始化安卓 SDK
 */

const ANDROID_SDK_INIT = () => {
  if (window.bridge) {
    if (window.bridge.setMethodList) {
      const methodList = Object.values(ANDROID_LISTEN_METHOD);
      // 初始化methodList
      methodList.forEach((str) => (window.bridge[str] = () => {}));
      const METHOD_LIST_STRING = JSON.stringify(methodList);
      window.bridge.setMethodList(METHOD_LIST_STRING);
    }
  } else {
    console.log('Bridge does not exist!');
  }
};

/**
 * @description: 安卓桥接对象注册事件回调方法
 * @param {string} event - 时间名称
 * @param {object} callback - 回调函数
 * @return: void
 */

const ANDROID_REGISTER = (event, callback) => {
  try {
    if (window.bridge) {
      window.bridge[event] = callback;
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @description: js 调用 bridge 原生方法
 * @param {string} functionName - 方法名
 * @param {string} params - 参数
 * @return: *
 */

const ANDROID_APP_CALL = (functionName, ...params) => {
  try {
    if (window.bridge) {
      return window.bridge[functionName](...params);
    } else {
      return handleNoBridge(functionName, ...params);
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @description: 处理本地开发，没有 bridge 的情况
 * @param {string} functionName - 方法名
 * @param {any} params - 参数
 */

const handleNoBridge = (functionName, params) => {
  switch (functionName) {
    case 'openPageWithJSON':
      window.location.href = JSON.parse(params).url;
      break;
    case 'getUserInfo':
      if (process.env.NODE_ENV === 'development') {
        return JSON.stringify(USER_INFO);
      }
      break;
    case 'signContent':
      break;
    case 'setTitle':
      document.title = params;
      break;
    default:
    // throw Error('bridge is not defined!');
  }
};

export { ANDROID_SDK_INIT, ANDROID_REGISTER, ANDROID_APP_CALL, ANDROID_LISTEN_METHOD, ANDROID_BRIDGE_METHOD };
