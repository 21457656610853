import { GamePost, Api } from '@/http/index';

export default {
  namespaced: true,
  state: () => ({
    task_id: 0,
    status: 0,
    system_time: '',
    game_type: 'game_sleep',
    watch_ad_times: 0,
    reward_coins: 0,
    watch_ad_reward: 0,
    reward_coins_max: 0,
    distance_start_time: 0,
    task_start_ts: 0,
    task_end_ts: 0,
    task_end_time: '',
    task_start_time: '',
    settle_end_time: '',
    settle_start_time: '',
    ad_info: {},
    mediationAdInfo: {
      ad_group_id: 0,
      ad_platform_status: false,
    },
  }),
  mutations: {
    // 更新pageInfo
    setTaskPageInfo(state, data) {
      Object.keys(state).forEach((key) => {
        if (data[key]) {
          state[key] = data[key];
        }
      });
    },
  },
  actions: {
    // 从服务端获取页面信息
    async getSleepPageInfo({ commit }) {
      const data = await GamePost(Api.GET_SLEEP_PAGE_INFO).catch((e) => {});
      const { ad_group_id, ad_platform_status, ...rest } = data;
      const mediationAdInfo = { ad_group_id, ad_platform_status };
      commit('setTaskPageInfo', { ...rest, mediationAdInfo });
    },
  },
  getters: {
    /**
     * @description: 当前时间节点
     * @param {object} state
     * @return {string} day | night | sleeping
     */
    nowTime(state) {
      const { system_time, status } = state;
      const hour = new Date(system_time * 1000).getHours();
      let dayStatus = '';
      if (hour >= 19 || hour <= 7) {
        dayStatus = 'night';
      } else {
        dayStatus = 'day';
      }

      if (status == 3) {
        return 'sleeping';
      } else {
        return dayStatus;
      }
    },
  },
};
