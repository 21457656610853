/*
 * @Author: Kuaiyin FE
 * @Date: 2020-10-14 19:12:27
 * @Description: 生成请求签名模块
 * @FilePath: /vision-h5/src/service/sign.js
 */

import Qs from 'qs';
import { appCall, callMethod } from '@/utils/sdk';
import { trackClick } from '@/utils/track';
import platform from '@/utils/platform';

// 定义签名序列
const proj = [107, 118, 99, 108, 125, 110, 116, 102, 112, 62];

/**
 * @description: 排序方法：根据首字母的 Unicode 编码大小进行比对排列，首字母相同时进行递归操作
 * @param {string} k1 - 第一个参数
 * @param {string} k2 - 第二个参数
 */

const $_keySort = (k1, k2) => {
  const c1 = k1 && k1.charAt(0) ? k1.charAt(0).charCodeAt() : 0;
  const c2 = k2 && k2.charAt(0) ? k2.charAt(0).charCodeAt() : 0;
  if (!c1 && !c2) {
    return 0;
  }
  if (c1 === c2) {
    return $_keySort(k1.substring(1), k2.substring(1));
  }
  return c1 - c2;
};

/**
 * @description: 对数组进行 key => value 排序
 * @param {object} params - 请求参数
 * @return: {array} 排序后的数组
 */

const $_sortKeyValue = (params) => {
  const keys = Object.keys(params);

  keys.sort($_keySort);
  return keys;
};

/**
 * @description: 签名并字符串化数据
 * @param {object} params - 请求参数
 * @return: {string} 签名后的字符串
 */

const signature = (params) => {
  // 声明签名结果对象
  let res = {};
  // 生成排序后的参数键值对字符串数组
  let md5s = [];
  let data = { ...params };
  data.t = Math.floor(new Date().getTime() / 1000);

  // 配置签名参数
  let k = 0;
  data.proj = proj.reduce((r, i) => {
    return `${typeof r == 'string' ? r : String.fromCharCode(r - k++)}${String.fromCharCode(i - k++)}`;
  });

  // 请求参数以key值排序
  const keys = $_sortKeyValue(data);

  keys.map((k) => {
    if (data[k] !== null && data[k] !== undefined) {
      res[k] = data[k];
      let v = res[k];

      if (typeof v == 'object') {
        v = JSON.stringify(v);
      }

      v = encodeURIComponent(v);
      md5s.push(`${k}=${v}`);
    }
  });

  res.s = appCall(callMethod.SIGN_CONTENT, decodeURIComponent(md5s.join('&')));

  if (!res.s) {
    let haveSignMethod = '';
    if (platform.platform === 'iOS') {
      haveSignMethod = window.dsBridge && window.dsBridge.hasNativeMethod('signContent');
    } else if (platform.platform === 'Android') {
      haveSignMethod = window.bridge && !!window.bridge.signContent;
    }
    trackClick({
      page_title: 'request_error',
      element_name: 'empty_sign',
      remarks: JSON.stringify({
        sign_str: md5s.join('&'),
        sign: appCall(callMethod.SIGN_CONTENT, decodeURIComponent(md5s.join('&'))),
        haveSignMethod,
      }),
    });
  }

  return Qs.stringify(res);
};

export { signature };
