<template>
  <div class="modal-warpper">
    <div class="modal-container">
      <div class="modal-content">
        <!-- 顶部金币堆图片开始 -->
        <div class="top-icons-img"></div>
        <!-- 顶部金币堆图片结束 -->
        <div class="desc-i">
          <div class="desc-row">{{ successTip }}</div>
        </div>
        <!-- 金币数额开始 -->
        <div class="current-income">
          <span class="income-num">{{ rewardIconNum }}</span>
        </div>
        <!-- 金币数额结束 -->
        <div class="button-area">
          <div class="video-button" @click="watchButton.click">{{ watchButton.label }}</div>
          <div class="give-up-button" @click="giveUpButton.click">{{ giveUpButton.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'drinking-rule-modal',
    props: {
      // 看视频按钮
      watchButton: {
        type: Object,
        required: true,
        default: () => ({
          label: '',
          click: () => {},
        }),
      },
      // 放弃按钮
      giveUpButton: {
        type: Object,
        required: true,
        default: () => ({
          label: '',
          click: () => {},
        }),
      },
      // 看视频奖励金币
      rewardIconNum: {
        type: Number,
        default: 0,
      },
      // 当前已收入
      currentIncome: {
        type: Number,
        default: 0,
      },
      successTip: {
        type: String,
        default: '恭喜获得奖励',
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .modal-container {
    position: relative;
    width: 3rem;
    height: auto;
    border-radius: 0.12rem;
    box-sizing: border-box;
    padding: 0.15rem;
    background-color: #ffffff;
  }

  .modal-content {
    text-align: center;
  }
  .desc-i {
    margin-top: 0.55rem;
    font-size: 0.16rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: rgba(57, 57, 57, 1);
    line-height: 0.16rem;
    letter-spacing: 1px;
  }
  .current-income {
    background: rgba(238, 238, 238, 1);
    border-radius: 0.115rem;
    width: 1.25rem;
    padding: 0.1rem 0.12rem;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .income-num {
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: rgba(222, 185, 118, 1);
    line-height: 0.32rem;
    letter-spacing: 2px;
  }
  .income-num::before {
    content: '';
    background-image: url(../../../assets/images/task/coin.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.265rem;
    height: 0.265rem;
    display: inline-block;
    position: relative;
    top: 0.02rem;
    right: 0.03rem;
    /* vertical-align: middle; */
  }
  .desc-ii {
    margin-top: 0.15rem;
    font-size: 0.16rem;
    font-weight: bold;
    color: rgba(57, 57, 57, 1);
    line-height: 0.16rem;
    letter-spacing: 1px;
  }
  .desc-ii-num {
    color: #f23457;
  }
  .button-area {
    margin-top: 0.2rem;
  }
  .video-button {
    width: 100%;
    height: 0.5rem;
    color: #ffffff;
    text-align: center;
    font-size: 0.16rem;
    font-weight: bold;
    line-height: 0.5rem;
    letter-spacing: 1px;
    background: linear-gradient(90deg, rgba(255, 0, 67, 1) 0%, rgba(240, 61, 91, 1) 100%);
    border-radius: 0.255rem;
  }
  .video-button::after {
    content: '';
    width: 0.17rem;
    height: 0.14rem;
    background-image: url(../../../assets/images/task/video2.png);
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left: 0.05rem;
    bottom: 0.01rem;
  }
  .give-up-button {
    margin-top: 0.15rem;
    font-size: 0.12rem;
    font-weight: 400;
    color: rgba(242, 55, 89, 1);
    line-height: 0.12rem;
  }
  .dialog_tuia {
    margin: 0.1rem auto 0;
    border-radius: 0.08rem;
    width: 2.79rem;
    height: 1.46rem;
    background-color: #fff;
  }
  .dialog_tuia img {
    border-radius: 0.08rem;
    width: 2.79rem;
    height: 1.46rem;
  }

  .top-icons-img {
    position: absolute;
    right: 0;
    left: 0;
    width: 1.4rem;
    height: 1.4rem;
    background-image: url(../../../assets/images/task/coin_stack_gold.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    top: -0.6rem;
  }
</style>
