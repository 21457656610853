export default [
  // 喝水对照组
  {
    path: '/game/drinking',
    name: 'gameDrinking',
    component: () => import('@/views/Drink/V1'),
  },
  // 喝水新打卡 + 老补卡
  {
    path: '/game/drinking_b',
    name: 'newGameDrinkingB',
    component: () => import('@/views/Drink/V1/index_b.vue'),
  },
  // 喝水新打卡 + 新补卡
  {
    path: '/game/drinking_c',
    name: 'newGameDrinkingC',
    // component: (resolve) => require(['@/views/Drink/V1/index_c.vue'], resolve),
    component: () => import('@/views/Drink/V2'),
  },
  // 新喝水
  {
    path: '/game/new_drinking',
    name: 'newDrinking',
    component: () => import('@/views/Drink/V2'),
  },
];
