import Vue from 'vue';
import VueRouter from 'vue-router';
import DrinkRoutes from './modules/drink';
import EatRoutes from './modules/eat';
import GuessMusicRoutes from './modules/guess-music';
import SleepRoutes from './modules/sleep';
import TreeRoutes from './modules/tree';
import WalkRoutes from './modules/walk';
import FarmRoutes from './modules/farm';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes: [
    ...DrinkRoutes,
    ...EatRoutes,
    ...GuessMusicRoutes,
    ...SleepRoutes,
    ...TreeRoutes,
    ...WalkRoutes,
    ...FarmRoutes,
    // 游戏库页面路由
    {
      path: '/game/game_library',
      name: 'GameLibrary',
      component: () => import('@/views/GameLibrary/index.vue'),
    },
    {
      path: '/*',
      name: '404',
      component: () => import('@/views/Common/404.vue'),
    },
  ],
});

export default router;
