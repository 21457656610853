export default [
  // 旧睡觉赚钱任务
  {
    path: '/game/sleep',
    name: 'gameSleep',
    component: () => import('@/views/Sleep/V1'),
  },
  // 新睡觉赚钱任务
  {
    path: '/game/new_sleep',
    name: 'gameNewSleep',
    component: () => import('@/views/Sleep/V2'),
  },
  // 新睡觉赚钱任务- 分享
  {
    path: '/game/new_sleep_share',
    name: 'gameSleepShare',
    component: () => import('@/views/Sleep/V2/index_share.vue'),
  },
];
