<template>
  <div class="downgrade">
    <div class="tree-change">
      <div class="from">
        <img class="tree" :src="downTreeDetail.from.treeUI" />
        <img class="soil" :src="downTreeDetail.from.soilUI" />
        <div class="phase">{{ downTreeDetail.from.info }}</div>
      </div>
      <div class="to">
        <img class="tree" :src="downTreeDetail.to.treeUI" />
        <img class="soil" :src="downTreeDetail.to.soilUI" />
        <div class="phase">{{ downTreeDetail.to.info }}</div>
      </div>
      <img class="arrow" src="@/assets/images/farm/arrow-right-downgrade.png" />
    </div>
    <div class="text">{{ downTreeDetail.tips }}</div>
    <div class="btn" @click="handleWatering">我不服气，立即浇水</div>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { closeModal } from '@/utils/modal';
  import { trackClick } from '@/utils/track';

  export default {
    components: {},
    props: {
      // 浇水
      watering: {
        type: Function,
        default: () => {},
      },
    },
    name: 'downgrade',
    data() {
      return {};
    },
    computed: {
      ...mapGetters('farm', ['downTreeDetail']),
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
      ...mapMutations('farm', ['changeDowngradeModal']),
      handleWatering() {
        closeModal('downgrade-modal');
        this.changeDowngradeModal(false);
        this.watering();
        trackClick({ page_title: '快音农场', element_name: '不服气立即浇水' });
      },
    },
  };
</script>
<style scoped lang="less">
  .downgrade {
    .tree-change {
      display: flex;
      flex-direction: row;
      position: relative;
      .from {
        display: flex;
        flex-direction: column;
        position: relative;
        .tree {
          width: 1.79rem;
          height: 2.04rem;
        }
        .soil {
          width: 1.13rem;
          height: 0.53rem;
          position: absolute;
          bottom: 0.27rem;
          left: 0.32rem;
          z-index: -1;
        }
        .phase {
          box-sizing: border-box;
          text-align: center;
          margin-top: 0.1rem;
          height: 0.14rem;
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.14rem;
        }
      }
      .to {
        .from();
      }
      .arrow {
        position: absolute;
        bottom: 0.75rem;
        left: 1.68rem;
        width: 0.25rem;
        height: 0.25rem;
      }
    }
    .text {
      text-align: center;
      width: 2.75rem;
      height: 0.56rem;
      font-size: 0.15rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.28rem;
      margin: 0.33rem auto;
    }
    .btn {
      margin: 0.57rem auto;
      text-align: center;
      width: 2.67rem;
      height: 0.47rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #9d4f07;
      line-height: 0.47rem;
      background: url('~@/assets/images/farm/gain-award-btn-modal.png') no-repeat;
      background-size: 100% 100%;
    }
  }
</style>
