import { GamePost, Api } from '@/http/index';
import { getRedpacketStyle } from '../constance';

export default {
  namespaced: true,
  state: () => {
    return {
      // 搜索到的可偷用户列表
      users: [],
      // 具体用户信息
      user: {},
      // 搜索/看视频剩余次数
      chance: {
        remain_search_times: 0,
        remain_ad_times: 0,
      },
      // 底部信息流
      bottomFeedAdId: 946733737,
    };
  },
  mutations: {
    setUsers(state, list) {
      state.users = [...list];
    },
    setUser(state, data) {
      state.user = { ...data };
    },
    setChance(state, data) {
      state.chance = { ...data };
    },
    setBottomFeedAdId(state, payload) {
      state.bottomFeedAdId = payload;
    },
  },
  actions: {
    // 获取偷金币页面信息
    async searchUsers({ commit }) {
      try {
        const data = await GamePost(Api.TREE_SEARCH, {}, { showLoading: false });
        const { users, remain_search_times, remain_ad_times, bottom_feed_ad_id } = data;
        commit('setUsers', users);
        commit('setChance', { remain_search_times, remain_ad_times });
        commit('setBottomFeedAdId', bottom_feed_ad_id);
      } catch (e) {
        console.log(e);
      }
    },

    // 选择用户
    async selectUser({ commit }, ruid) {
      try {
        const postData = ruid ? { ruid } : {};
        const data = await GamePost(Api.TREE_SELECT_USER, postData, { showLoading: false });
        const { steal_info } = data;
        if (!steal_info.uid) {
          commit('setUser', {
            avatar: '',
            nickname: '没有金币可偷了',
            redpackages: [
              { id: 1, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 2, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 3, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 4, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 5, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
            ],
            status: 1,
            uid: 0,
          });
        } else {
          commit('setUser', steal_info);
        }
      } catch (e) {
        console.log(e);
      }
    },

    // 换一个用户
    async reSelectUser({ commit }) {
      try {
        const data = await GamePost(
          Api.TREE_RE_SELECT_USER,
          {},
          {
            showLoading: true,
          }
        );
        const { steal_info, remain_search_times, remain_ad_times } = data;
        commit('setChance', { remain_search_times, remain_ad_times });
        if (!steal_info.uid) {
          commit('setUser', {
            avatar: '',
            nickname: '没有金币可偷了',
            redpackages: [
              { id: 1, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 2, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 3, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 4, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
              { id: 5, coins: 0, max_coin: 20, type: 1, expire_at: 0 },
            ],
            status: 1,
            uid: 0,
          });
        } else {
          commit('setUser', steal_info);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    // 带位置信息的红包列表
    displayRedpacketList(state) {
      const { user } = state;
      if (!user.redpackages || !user.redpackages.length) {
        return user.redpackages;
      }
      const list = user.redpackages.map((item) => {
        return getRedpacketStyle(item);
      });
      return list;
    },

    // 可偷的金币总和
    getableCoins(state) {
      const { user } = state;
      if (!user.redpackages || !user.redpackages.length) {
        return 0;
      } else {
        return user.redpackages.reduce((total, current) => total + Number(current.coins), 0);
      }
    },
  },
};
