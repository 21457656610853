<template>
  <div class="red-packet-award">
    <div class="title">恭喜获得福利礼包</div>
    <div class="title-2">恭喜获得福利，继续浇水赢更多奖励</div>
    <img class="icon" src="@/assets/images/farm/coin-modal.png" />
    <div class="content">奖励金币×{{ amount }}</div>
    <div class="award-btn" @click="handleReward">领取奖励</div>
  </div>
</template>

<script>
  import { GamePost, Api } from '@/http/index';
  import { mapMutations } from 'vuex';
  import { trackClick } from '@/utils/track';
  export default {
    name: 'RedPacketModal',
    components: {},
    props: {
      // 奖励
      amount: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      trackClick({ page_title: '快音农场', element_name: '福利弹窗_曝光' });
    },
    methods: {
      ...mapMutations('farm', ['changeRedPacketModal']),
      async handleReward() {
        trackClick({ page_title: '快音农场', element_name: '福利弹窗_领取' });
        const res = await GamePost(Api.GET_FARM_WATERING_REWARD, {}, { parseData: false }).catch((err) =>
          console.error(err)
        );
        if (res && +res.code === 0) {
          this.changeRedPacketModal(false);
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .red-packet-award {
    box-sizing: border-box;
    padding: 0.2rem 0.2rem 0.24rem;
    width: 3rem;
    height: 3rem;
    background: #ffffff;
    border-radius: 0.12rem;
    .title {
      text-align: center;
      height: 0.25rem;
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.25rem;
      &-2 {
        width: 1.9rem;
        height: 0.44rem;
        text-align: center;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a6a6a6;
        line-height: 0.22rem;
        margin: 0.12rem auto 0.15rem;
      }
    }
    .icon {
      display: block;
      width: 0.82rem;
      height: 0.82rem;
      margin: 0 auto 0.07rem;
    }
    .content {
      text-align: center;
      height: 0.12rem;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.12rem;
    }
    .award-btn {
      text-align: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #9d4f07;
      line-height: 0.4rem;
      width: 2.6rem;
      height: 0.4rem;
      background: url('~@/assets/images/farm/gain-award-btn-modal.png') no-repeat;
      background-size: 100% 100%;
      margin-top: 0.19rem;
    }
  }
</style>
