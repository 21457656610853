export function AddZero(num) {
  if (typeof num != 'number') {
    throw new Error('必须传入 Number 类型数值');
  }
  if (num < 10) {
    return '0' + num;
  } else {
    return '' + num;
  }
}

/**
 * 防抖函数，返回函数连续调用时，空闲时间必须大于或等于 wait，func 才会执行
 *
 * @param  {function} func        回调函数
 * @param  {number}   wait        表示时间窗口的间隔
 * @param  {boolean}  immediate   设置为 true 时，是否立即调用函数
 * @return {function}             返回客户调用函数
 */
export function debounce(func, wait, immediate) {
  let timer, context, args;
  // 延迟执行函数
  const later = () =>
    setTimeout(() => {
      // 延迟函数执行完毕，清空缓存的定时器序号
      timer = null;
      // 延迟执行的情况下，函数会在延迟函数中执行
      // 使用到之前缓存的参数和上下文
      if (!immediate) {
        func.apply(context, args);
        context = args = null;
      }
    }, wait);

  // 这里返回的函数是每次实际调用的函数
  return function (...params) {
    // 如果没有创建延迟执行函数（later），就创建一个
    if (!timer) {
      timer = later();
      // 如果是立即执行，调用函数
      // 否则缓存参数和调用上下文
      if (immediate) {
        func.apply(this, params);
      } else {
        context = this;
        args = params;
      }
      // 如果已有延迟执行函数（later），调用的时候清除原来的并重新设定一个
      // 这样做延迟函数会重新计时
    } else {
      clearTimeout(timer);
      timer = later();
    }
  };
}

export function throttle(func, delay) {
  let prev = Date.now();
  return function () {
    const context = this;
    const args = arguments;
    const now = Date.now();
    if (now - prev >= delay) {
      func.apply(context, args);
      prev = Date.now();
    }
  };
}

export function countDownFormat2(countTime, millisecond) {
  // const t = countTime * 1000; // ms
  const t = +countTime; // m
  let h = Math.floor(t / 3600);
  let m = Math.floor((t - h * 3600) / 60);
  let s = Math.floor(t - h * 3600 - m * 60);
  h = h > 9 ? h : `0${h}`;
  m = m > 9 ? m : `0${m}`;
  s = s > 9 ? s : `0${s}`;
  if (millisecond) {
    const ts = countTime - h * 3600 - m * 60 - s;
    const ms = Math.floor(ts / 100);
    return `${h}:${m}:${s}.${ms}`;
  }
  return `${h}:${m}:${s}`;
}

// 获取url参数
// http://wangwang.hhbgrd.com/pay/shopping?shop_id=0a772610-9450-445e-b5a8-bdd9ac358da8
export const getUrlParams = (name, url) => {
  if (!url) url = decodeURIComponent(window.location.href);
  name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getRmKReferrerHref = (href) => {
  let kReferrer = getUrlParams('k_referrer');
  href = href ? href : location.href;
  if (kReferrer) {
    href = href.replace(/k_referrer=.+&/, '');
    href = href.replace(/k_referrer=.+$/, '');
    href = href.replace(/[&|?]$/, '');
  }
  return href;
};

/**
 * @description: 获取a～b的随机数
 * @param {number} min - 下限
 * @param {number} max - 上限
 * @param {boolean} int - 是否取整数
 * @return {number}
 */
export function getRandomNum(min, max, int = true) {
  const range = max - min;
  const rand = Math.random();
  return int ? min + Math.round(rand * range) : min + rand * range;
}

/*
 * @description: 格式化金币/现金
 * @param {Number} n - 数目
 * @param {Number} digits - 保留小数位数，默认为两位
 * @return {String} 格式化过的字符串
 */

export function formatCoinNumber(n, digits = 2) {
  n = +n;
  if (n < 10000) {
    return n;
  } else if (n < 100000000) {
    return (Math.floor((n / 10000) * 100) / 100).toFixed(digits) + '万';
  } else {
    return (Math.floor((n / 100000000) * 100) / 100).toFixed(digits) + '亿';
  }
}

export function getElementTop(element, offest = 'offsetTop') {
  let actualTop = element[offest];
  let current = element.offsetParent;

  while (current !== null) {
    actualTop += current[offest];
    current = current.offsetParent;
  }
  return actualTop;
}

/**
 * 感知点击目标元素外部
 * @param {string} 目标元素选择器
 */
export const sensorClickOutside = (target, callback) => {
  const handler = (e) => {
    let isClickOutside = false;
    let domList = document.querySelectorAll(target);
    domList = Array.prototype.slice.call(domList);
    isClickOutside = domList.every((targetDom) => {
      return e.target !== targetDom && !e.composedPath().includes(targetDom);
    });
    isClickOutside && callback();
  };
  return {
    listenClickOutside: () => document.body.addEventListener('click', handler),
    rmListenClickOutside: () => document.body.removeEventListener('click', handler),
  };
};
