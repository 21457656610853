/*
 * @description: 安卓客户端，可供 H5 调用方法定义
 */

const ANDROID_BRIDGE_METHOD = {
  BIND_WECHAT_ACCOUNT: 'bindWeChatAccount',
  IS_CALENDAR_ENABLED: 'isCalendarEnabled',
  OPEN_PAGE_WITH_JSON: 'openPageWithJSON',
  OPEN_TIP_AD_GAME: 'openTipAdGame',
  TOAST: 'toast',
  COPY: 'copy',
  SHARE_TO: 'shareTo',
  SET_TITLE: 'setTitle',
  CLOSE_WEBVIEW: 'closeWebView',
  GET_USER_INFO: 'getUserInfo',
  PRELOAD_ADS: 'preLoadAds',
  SHOW_REWARD_VIDEO_AD_WITH_JSON: 'showRewardVideoAdWithJSON',
  INIT_MUSIC_PLAYER_LIST: 'initMusicPlayerList',
  APPEND_MUSIC_PLAYER_LIST: 'appendMusicPlayerList',
  HANDLE_MUSIC_BY_ACTION: 'handleMusicByAction',
  UPLOAD_OPUS: 'uploadOpus',
  OPEN_BXM_GAME_DIALOG: 'openBxmGameDialog',
  IS_NOTIFICATION_ENABLED: 'isNotificationEnabled',
  OPEN_NOTIFICATION: 'openNotification',
  IS_MUSIC_PLAYING: 'isMusicPlaying',
  PAUSE_MUSIC: 'pauseMusic',
  PLAY_MUSIC: 'playMusic',
  SIGN_CONTENT: 'signContent',
  OPEN_NATIVE_PAGE: 'openNativePage',
  LAUNCH_APP_DETAILS_SETTINGS: 'launchAppDetailsSettings',
  U_TRACK: 'UTrack',
  OPEN_NOVEL: 'openNovel',
  REFRESH_TOKEN: 'refreshToken',
  LOAD_INTERACTION_AD: 'loadInteractionAd',
  /**
   * @description: 聚合平台广告调用方法
   * @param {number} groupId - 广告组ID
   * @param {string} extras - 额外参数
   * @return void
   */

  LOAD_MEDIATION_AD: 'loadMediationAd',
  /**
   * @description: 充值完成回调
   * @param: {boolean} status - 充值结果
   */

  RECHARGE_STATUS: 'rechargeStatus',

  // 信息流广告
  LOAD_TT_FEED: 'loadTTFeed',
  CLOSE_TT_FEED: 'closeTTFeed',
  LOAD_INTERSTITIAL_AD: 'loadInterstitialAd',
};

export default ANDROID_BRIDGE_METHOD;
