export default {
  namespaced: true,
  state: () => ({
    showChat: false,
    isCountDown: false,
    content: {
      name: '',
      time: 0,
    },
  }),
  mutations: {
    setShowChat(state, payload) {
      state.showChat = payload;
    },
    setIsCountDown(state, payload) {
      state.isCountDown = payload;
    },
    setContent(state, data) {
      state.content = data;
    },
  },
  actions: {},
  getters: {},
};
