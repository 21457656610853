<template>
  <CommonModal buttonText="知道了" @click="onClick" @close="onClose" height="2.62rem">
    <img v-if="isSmallTree" class="tree" src="@/assets/images/tree/small_tree_light.png" alt="tree" />
    <img v-else class="big_tree" src="@/assets/images/tree/big_tree_light.png" alt="tree" />
    <p class="text" v-html="modalInfo.text"></p>
  </CommonModal>
</template>

<script>
  import CommonModal from '../CommonModal/CommonModal';
  import { TreeStatus } from '@/store/modules/tree/constance';
  export default {
    components: {
      CommonModal,
    },
    props: {
      status: {
        type: Number,
        default: 0,
      },
      onClick: {
        type: Function,
        default: () => {},
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      coinCreateRate: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      isSmallTree() {
        return this.status === TreeStatus.SMALL_TREE;
      },
      modalInfo() {
        return this.status === TreeStatus.SMALL_TREE
          ? {
              text: `您的树苗长成小树啦!<br />离摇钱树只差一级了,继续加油~`,
              buttonText: '知道了',
            }
          : {
              text: `您的小树长成摇钱树啦!<br />每秒可为您产生${(1 / this.coinCreateRate).toFixed(
                1
              )}金币收益，<br/>可随时上线收取`,
              buttonText: '查看收益',
            };
      },
    },
  };
</script>

<style lang="less" scoped>
  .tree {
    position: absolute;
    top: -0.83rem;
    width: 1.7rem;
    height: 2.11rem;
  }
  .big_tree {
    position: absolute;
    top: -0.75rem;
    width: 1.87rem;
    height: 1.87rem;
  }
  .text {
    height: 0.5rem;
    font-size: 0.15rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2538c0;
    line-height: 0.25rem;
    text-align: center;
    position: absolute;
    top: 1.15rem;
  }
</style>
