/**
 * @description: 睡觉API接口管理文件
 */
export default {
  // 获取新走路页的页面信息
  GET_NEW_WALK_PAGEINFO_API: '/NewWalking/Home',
  // 领取步数奖励
  GET_STEP_REWARD_API: '/NewWalking/StepReward',
  // 领取签到奖励
  GET_SIGN_REWARD_API: '/NewWalking/SignReward',
  // 偷步数
  STEAL_STEP_API: '/NewWalking/Steal',
  // 领取气泡
  TAKE_BUBBLE_API: '/NewWalking/TakeBubble',
  // 检查气泡
  CHECK_BUBBLE: '/NewWalking/Check',
  // 免费气泡翻倍领取
  DOUBLE_STEPS_REWARD: '/NewWalking/DoubleStepsReward',

  // 获得旧走路页信息
  GET_OLD_WALK_PAGEINFO_API: '/walking/home',
  // 旧走路详情
  GET_OLD_WALK_DETAIL_API: '/walking/detail',
  // 旧签到奖励
  GET_OLD_SIGN_REWARD_API: '/walking/receive_sign_reward',
  // 旧走路偷步数
  STEAL_STEP_OLD_API: '/walking/steal',
  // 领步数气泡
  GET_OLD_WALK_REWARD: '/walking/reward',
};
