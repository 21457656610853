<template>
  <div class="modal-container">
    <div class="modal-close" @click="handleClose"></div>
    <!-- 具体内容开始 -->
    <div class="modal-content-warpper">
      <div class="modal-content">
        <h5 style="font-size: 0.12rem">{{ txt }}</h5>
        <div style="font-size: 0.18rem; color: #fff">
          <span
            style="
              font-size: 0.12rem;
              color: #ffeb83 !important;
              font-weight: 500;
              margin-top: 0.045rem;
              font-size: 0.3rem;
            "
            >{{ count }}</span
          >
          {{ unit }}
        </div>
      </div>
    </div>
    <!-- 具体内容结束 -->
    <!-- 确认按钮开始 -->
    <div class="modal-content-ok_button" v-if="buttonList.length !== 0">
      <div class="ok-button" v-for="(button, index) in buttonList" :key="index" @click="button.click">
        {{ button.label }}
      </div>
    </div>
    <!-- 确认按钮结束 -->
  </div>
</template>

<script>
  export default {
    name: 'WalkModal',
    props: {
      handleClose: {
        type: Function,
      },
      //按钮列表
      buttonList: {
        type: Array,
        default: () => [],
      },
      // 单位
      unit: {
        type: String,
        default: '',
      },
      // 次数
      count: {
        type: Number,
        default: 0,
      },
      // 文案
      txt: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        content: '', //纯字符串内容
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style scoped>
  .modal-container {
    position: relative;
    width: 2.9rem;
    height: auto;
    background: linear-gradient(180deg, rgba(134, 180, 255, 1) 0%, rgba(88, 150, 250, 1) 100%);
    border-radius: 0.12rem;
    border: 0.005rem solid rgba(151, 151, 151, 1);
    padding: 0.15rem;
  }

  .modal-content-warpper {
    box-sizing: border-box;
    padding: 0.15rem;
    background: rgba(82, 144, 255, 1);
    box-shadow: 0 0.02rem 0.03rem 0 rgba(50, 109, 215, 1);
    border-radius: 0.06rem;
    color: #fff;
    text-align: left;
  }

  .modal-title {
    font-size: 0.16rem;
    font-weight: bold;
    color: #fff;
  }

  .modal-content {
    /* background: red; */
    text-align: center;
  }

  .modal-close {
    position: absolute;
    right: 0;
    top: -0.4rem;
    height: 0.26rem;
    width: 0.26rem;
    background-image: url(../../../assets/images/walkTask/close.png);
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    z-index: 100;
  }

  .modal-content-ok_button {
    margin-top: 0.37rem;
    width: 100%;
  }

  .ok-button {
    width: 100%;
    background: linear-gradient(180deg, rgba(246, 251, 193, 1) 0%, rgba(255, 204, 0, 1) 100%);
    box-shadow: 0 0.06rem 0.02rem 0 rgba(15, 115, 255, 0.5);
    border-radius: 0.2rem;
    text-align: center;
    font-size: 0.16rem;
    font-weight: bold;
    color: #855600;
    padding: 0.095rem 0;
  }
  .ok-button:not(:first-child) {
    margin-top: 0.2rem;
  }
</style>
