export const mockSingerList = [
  {
    id: 1,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 2,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 3,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 4,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 5,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 6,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 7,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 8,
    name: '林俊杰',
    hot: 28390,
  },
  {
    id: 9,
    name: '林俊杰',
    hot: 28390,
  },
];

export const mockBreakThroughInfo = {
  red_package: [
    {
      num: 1,
      combo: 3,
    },
    {
      num: 2,
      combo: 5,
    },
    {
      num: 3,
      combo: 3,
    },
    {
      num: 4,
      combo: 5,
    },
    {
      num: 5,
      combo: 3,
    },
    {
      num: 6,
      combo: 5,
    },
  ],
  score_conditions: [
    {
      correct_num: [0, 12],
      score: 'C',
    },
    {
      correct_num: [13, 17],
      score: 'B',
    },
    {
      correct_num: [18, 24],
      score: 'A',
    },
    {
      correct_num: [25, 30],
      score: 'S',
    },
    {
      correct_num: [31, 40],
      score: 'SS',
    },
    {
      correct_num: [41, 60],
      score: 'SSS',
    },
  ],
  task_key: 'M2N2WEdONTVEWXZQellMcEFqa3J0TXJxVGMwRTByK0RQajNXMGlzbVA1WlNjaFVsbWp6YUlNMTZORDRJbVpoQw==',
  list: [
    {
      id: 1,
      play_song: {
        music_id: '2611058',
        music_name: '不潮不用花钱(蒙面唱将猜猜猜)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/3b117994b28968a32b3c4d8a2312fdf0.mp3',
      },
      options: [
        {
          music_id: '2611058',
          music_name: '不潮不用花钱(蒙面唱将猜猜猜)',
        },
        {
          music_id: '2611259',
          music_name: '青青河边草(电视剧《青青河边草》主题曲)',
        },
        {
          music_id: '2611442',
          music_name: '我不想说(天天向上)',
        },
        {
          music_id: '2611344',
          music_name: '刀剑如梦(央视开门大吉)',
        },
      ],
    },
    {
      id: 2,
      play_song: {
        music_id: '2614143',
        music_name: '暧昧',
        play_address: '/resource/c6beee6d40954ed7922d7079225a50e7.mp3',
      },
      options: [
        {
          music_id: '2614412',
          music_name: 'reset 学校2015',
        },
        {
          music_id: '2614293',
          music_name: 'We Are The Brave (荣耀手机全球主题曲1)',
        },
        {
          music_id: '2614143',
          music_name: '暧昧',
        },
        {
          music_id: '2614251',
          music_name: '欧若拉 (Live)',
        },
      ],
    },
    {
      id: 3,
      play_song: {
        music_id: '2611077',
        music_name: '断桥残雪',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/0a25e5667006a3b4adbd879b6c59a900.mp3',
      },
      options: [
        {
          music_id: '2614083',
          music_name: '一生回味一面',
        },
        {
          music_id: '2611077',
          music_name: '断桥残雪',
        },
        {
          music_id: '2614068',
          music_name: '要死就一定要死在你手里(中国好歌曲主打版)',
        },
        {
          music_id: '2611566',
          music_name: '你的柔情我永远不懂',
        },
      ],
    },
    {
      id: 4,
      play_song: {
        music_id: '2611459',
        music_name: '依然爱你',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/7e6ca9195d4ce63379886258712f2c87.mp3',
      },
      options: [
        {
          music_id: '2611459',
          music_name: '依然爱你',
        },
        {
          music_id: '2614298',
          music_name: '钱不重要，重要的是我喜欢丑的_剪辑后',
        },
        {
          music_id: '2614042',
          music_name: '半壶纱 (电视剧《小女花不弃》插曲)',
        },
        {
          music_id: '2614291',
          music_name: '斗战神',
        },
      ],
    },
    {
      id: 5,
      play_song: {
        music_id: '2611197',
        music_name: '不想让你哭',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/2e2f984376834af0d8cfcbb026e6538f.mp3',
      },
      options: [
        {
          music_id: '2611216',
          music_name: '爱你等于爱自己(电视剧《16个夏天》插曲)',
        },
        {
          music_id: '2614057',
          music_name: '万里长城永不倒(电视剧《大侠霍元甲》主题曲)',
        },
        {
          music_id: '2611197',
          music_name: '不想让你哭',
        },
        {
          music_id: '2611384',
          music_name: '左右为难',
        },
      ],
    },
    {
      id: 6,
      play_song: {
        music_id: '2614230',
        music_name: '官方回答     #官方回答',
        play_address: '/resource/26db95a4820f4b64b9618ae93642bd24.mp3',
      },
      options: [
        {
          music_id: '2611292',
          music_name: '趁早',
        },
        {
          music_id: '2614230',
          music_name: '官方回答     #官方回答',
        },
        {
          music_id: '2611480',
          music_name: '暗香',
        },
        {
          music_id: '2614138',
          music_name: '皑皑的雪山🏔️ 跟唱 世界那么大还是遇见你🥰🤪',
        },
      ],
    },
    {
      id: 7,
      play_song: {
        music_id: '2614184',
        music_name: '千古阿呆3',
        play_address: '/resource/4ed5ea0778b448a99de5e033d5f24185.aac',
      },
      options: [
        {
          music_id: '2614343',
          music_name: '王菲～天空',
        },
        {
          music_id: '2611117',
          music_name: '天黑黑',
        },
        {
          music_id: '2614184',
          music_name: '千古阿呆3',
        },
        {
          music_id: '2614092',
          music_name: '桥边姑娘',
        },
      ],
    },
    {
      id: 8,
      play_song: {
        music_id: '2614072',
        music_name: '刚刚好 (网络剧《我与你的光年距离》主题曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/0fa13ce73126189313522193a88218e0.mp3',
      },
      options: [
        {
          music_id: '2614072',
          music_name: '刚刚好 (网络剧《我与你的光年距离》主题曲)',
        },
        {
          music_id: '2614180',
          music_name: '小酒窝你的几号',
        },
        {
          music_id: '2611567',
          music_name: '余情未了(抖音版)',
        },
        {
          music_id: '2614404',
          music_name: '最美的伤口',
        },
      ],
    },
    {
      id: 9,
      play_song: {
        music_id: '2611460',
        music_name: '有个爱你的人不容易(中国新歌声国庆特辑)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/3b117994b28968a31623074a9b08d210.mp3',
      },
      options: [
        {
          music_id: '2611424',
          music_name: '不再犹豫 (电影《Beyond日记之莫欺少年穷》主题曲)',
        },
        {
          music_id: '2611116',
          music_name: '迷宫(电视剧《美味关系》插曲)',
        },
        {
          music_id: '2614344',
          music_name: '王菲～天空  跳跳糖',
        },
        {
          music_id: '2611460',
          music_name: '有个爱你的人不容易(中国新歌声国庆特辑)',
        },
      ],
    },
    {
      id: 10,
      play_song: {
        music_id: '2614220',
        music_name: '#周杰伦 #青花瓷 最最最喜欢的一首周董的歌，歌词和mv都美如画，也见证',
        play_address: '/resource/4270514aee79406ebb28d8ece0f4fadf.mp3',
      },
      options: [
        {
          music_id: '2614403',
          music_name: '咳咳',
        },
        {
          music_id: '2614220',
          music_name: '#周杰伦 #青花瓷 最最最喜欢的一首周董的歌，歌词和mv都美如画，也见证',
        },
        {
          music_id: '2614294',
          music_name: '胡歌 -     忘记时间',
        },
        {
          music_id: '2611164',
          music_name: '牛仔很忙',
        },
      ],
    },
    {
      id: 11,
      play_song: {
        music_id: '2611014',
        music_name: '绅士 (电视剧《是！尚先生》插曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/0a25e5667006a3b4e78b85e72f77e5be.mp3',
      },
      options: [
        {
          music_id: '2614051',
          music_name: '再度重相逢(Live)',
        },
        {
          music_id: '2611324',
          music_name: '一剪梅',
        },
        {
          music_id: '2611071',
          music_name: '酸酸甜甜就是我',
        },
        {
          music_id: '2611014',
          music_name: '绅士 (电视剧《是！尚先生》插曲)',
        },
      ],
    },
    {
      id: 12,
      play_song: {
        music_id: '2614155',
        music_name: '官方回答     #官方回答歌词歌词',
        play_address: '/resource/1bfc301f9a564c78b0da1d7746183212.mp3',
      },
      options: [
        {
          music_id: '2614355',
          music_name: '#美国 已有1500所大学出现新冠疫情',
        },
        {
          music_id: '2614155',
          music_name: '官方回答     #官方回答歌词歌词',
        },
        {
          music_id: '2614267',
          music_name: '舞女泪',
        },
        {
          music_id: '2611350',
          music_name: '海阔天空',
        },
      ],
    },
    {
      id: 13,
      play_song: {
        music_id: '2614314',
        music_name: '不要说话',
        play_address: '/resource/1c86008ca6ab44dd8d1776e184c7947e.mp3',
      },
      options: [
        {
          music_id: '2611044',
          music_name: '阿么',
        },
        {
          music_id: '2614314',
          music_name: '不要说话',
        },
        {
          music_id: '2611452',
          music_name: '我的名字',
        },
        {
          music_id: '2614049',
          music_name: '天使的翅膀',
        },
      ],
    },
    {
      id: 14,
      play_song: {
        music_id: '2611041',
        music_name: '演员',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/0a25e5667006a3b4d8cfcbb026e6538f.mp3',
      },
      options: [
        {
          music_id: '2614383',
          music_name: '初审test2',
        },
        {
          music_id: '2614102',
          music_name: '兰兰兰',
        },
        {
          music_id: '2611041',
          music_name: '演员',
        },
        {
          music_id: '2611553',
          music_name: '下雨天',
        },
      ],
    },
    {
      id: 15,
      play_song: {
        music_id: '2611404',
        music_name: '蓝莲花 (电影《从你的全世界路过》插曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/5f2e050bde471f04a4a6d668ce035f71.mp3',
      },
      options: [
        {
          music_id: '2611404',
          music_name: '蓝莲花 (电影《从你的全世界路过》插曲)',
        },
        {
          music_id: '2611343',
          music_name: '每天爱你多一些',
        },
        {
          music_id: '2611052',
          music_name: '再见只是陌生人',
        },
        {
          music_id: '2614078',
          music_name: '天青色在等烟雨',
        },
      ],
    },
    {
      id: 16,
      play_song: {
        music_id: '2611569',
        music_name: '老人与海',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/d400e29a82704171638e89cb926d2fd9.mp3',
      },
      options: [
        {
          music_id: '2611450',
          music_name: '在我生命中的每一天',
        },
        {
          music_id: '2611569',
          music_name: '老人与海',
        },
        {
          music_id: '2611294',
          music_name: '彩虹',
        },
        {
          music_id: '2614270',
          music_name: 'Beijing Bass2',
        },
      ],
    },
    {
      id: 17,
      play_song: {
        music_id: '2611445',
        music_name: '等待(2016央视歌从漓江来端午晚会)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/8c9f1cb3e6a7ec7538d9d75f7db57b52.mp3',
      },
      options: [
        {
          music_id: '2614107',
          music_name: '周杰伦',
        },
        {
          music_id: '2611554',
          music_name: '爱一点',
        },
        {
          music_id: '2611227',
          music_name: '飘摇',
        },
        {
          music_id: '2611445',
          music_name: '等待(2016央视歌从漓江来端午晚会)',
        },
      ],
    },
    {
      id: 18,
      play_song: {
        music_id: '2611565',
        music_name: 'Always Online',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/f6b0e4e46a60fdde7a8245f6b0699b40.mp3',
      },
      options: [
        {
          music_id: '2614060',
          music_name: '阳光下的星星',
        },
        {
          music_id: '2611558',
          music_name: '爱的主打歌',
        },
        {
          music_id: '2611565',
          music_name: 'Always Online',
        },
        {
          music_id: '2611253',
          music_name: '我要的幸福',
        },
      ],
    },
    {
      id: 19,
      play_song: {
        music_id: '2611050',
        music_name: '如果没有你',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/7e6ca9195d4ce633abf2b1539fc842a1.mp3',
      },
      options: [
        {
          music_id: '2614313',
          music_name: '#周杰伦 #青花瓷 最最最喜欢的一首周董的歌，歌词和mv都美如画，也见证',
        },
        {
          music_id: '2610987',
          music_name: '情人(电影《醉生梦死之湾仔之虎》插曲)',
        },
        {
          music_id: '2611422',
          music_name: '路边的野花不要采',
        },
        {
          music_id: '2611050',
          music_name: '如果没有你',
        },
      ],
    },
    {
      id: 20,
      play_song: {
        music_id: '2611563',
        music_name: '对你爱不完',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/650825eff1b6d5548a593d052e3ee049.mp3',
      },
      options: [
        {
          music_id: '2614387',
          music_name: 'My Baby',
        },
        {
          music_id: '2614090',
          music_name: '天青色在等烟雨',
        },
        {
          music_id: '2614137',
          music_name: '归去来兮（古风歌曲）',
        },
        {
          music_id: '2611563',
          music_name: '对你爱不完',
        },
      ],
    },
    {
      id: 21,
      play_song: {
        music_id: '2611025',
        music_name: '懂你',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/787d0f86d7ead5a274a6039efd1be354.mp3',
      },
      options: [
        {
          music_id: '2614196',
          music_name: '爸爸妈妈一张',
        },
        {
          music_id: '2611025',
          music_name: '懂你',
        },
        {
          music_id: '2611153',
          music_name: '无地自容(央视开门大吉)',
        },
        {
          music_id: '2611387',
          music_name: '姐姐妹妹站起来',
        },
      ],
    },
    {
      id: 22,
      play_song: {
        music_id: '2611549',
        music_name: '世间始终你好(电视剧《射雕英雄传之华山论剑》主题曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/ca7376cefa9e29b9913c14c2ec7d64ee.mp3',
      },
      options: [
        {
          music_id: '2614168',
          music_name: '小酒窝',
        },
        {
          music_id: '2611549',
          music_name: '世间始终你好(电视剧《射雕英雄传之华山论剑》主题曲)',
        },
        {
          music_id: '2611106',
          music_name: '明天是否爱我',
        },
        {
          music_id: '2611033',
          music_name: '蝴蝶泉边(全曲风行版)',
        },
      ],
    },
    {
      id: 23,
      play_song: {
        music_id: '2611461',
        music_name: '男人KTV',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/8eec8ad24ae9684ad76e0f23261c2e83.mp3',
      },
      options: [
        {
          music_id: '2614296',
          music_name: '乱七八糟',
        },
        {
          music_id: '2611307',
          music_name: '一个人失忆',
        },
        {
          music_id: '2611399',
          music_name: '单身情歌',
        },
        {
          music_id: '2611461',
          music_name: '男人KTV',
        },
      ],
    },
    {
      id: 24,
      play_song: {
        music_id: '2611535',
        music_name: '想把我唱给你听',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/13519e3ec3845e81fb4d7422cc31dec8.mp3',
      },
      options: [
        {
          music_id: '2614197',
          music_name: '心如两张',
        },
        {
          music_id: '2611535',
          music_name: '想把我唱给你听',
        },
        {
          music_id: '2611049',
          music_name: '残酷月光',
        },
        {
          music_id: '2614012',
          music_name: '男人女人',
        },
      ],
    },
    {
      id: 25,
      play_song: {
        music_id: '2611063',
        music_name: '粉红色的回忆',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/61664d394612e8457c6f6c79e22647ba.mp3',
      },
      options: [
        {
          music_id: '2611355',
          music_name: '原来你什么都不想要(最美和声第三季)',
        },
        {
          music_id: '2611063',
          music_name: '粉红色的回忆',
        },
        {
          music_id: '2611031',
          music_name: '城里的月光',
        },
        {
          music_id: '2614238',
          music_name: 'Last Stop5',
        },
      ],
    },
    {
      id: 26,
      play_song: {
        music_id: '2614195',
        music_name: '天空拍照图集 拍照软件#一甜相机  #p图教程 #申请热门 #爱官方爱热门 #作品推广',
        play_address: '/resource/600ece6214ad4b539d9fcb637b163dcc.aac',
      },
      options: [
        {
          music_id: '2614063',
          music_name: '大中国(央视激情广场马鞍山篇)',
        },
        {
          music_id: '2611167',
          music_name: '活着(恒大音乐节)',
        },
        {
          music_id: '2614086',
          music_name: '公子请留步_1596608780950',
        },
        {
          music_id: '2614195',
          music_name: '天空拍照图集 拍照软件#一甜相机  #p图教程 #申请热门 #爱官方爱热门 #作品推广',
        },
      ],
    },
    {
      id: 27,
      play_song: {
        music_id: '2611019',
        music_name: '我依然爱你',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/20ce3631579c97523e236f697d87b59a.mp3',
      },
      options: [
        {
          music_id: '2611019',
          music_name: '我依然爱你',
        },
        {
          music_id: '2611570',
          music_name: '亚洲雄风',
        },
        {
          music_id: '2611074',
          music_name: '如果这就是爱情',
        },
        {
          music_id: '2614015',
          music_name: '雪人',
        },
      ],
    },
    {
      id: 28,
      play_song: {
        music_id: '2614227',
        music_name: '海底-海底哦哦哦',
        play_address: '/resource/70598ed58a0c4ad4a9ce9e938761502e.mp3',
      },
      options: [
        {
          music_id: '2611255',
          music_name: '该死的温柔',
        },
        {
          music_id: '2614048',
          music_name: '奔跑',
        },
        {
          music_id: '2614227',
          music_name: '海底-海底哦哦哦',
        },
        {
          music_id: '2611075',
          music_name: '红尘来去一场梦',
        },
      ],
    },
    {
      id: 29,
      play_song: {
        music_id: '2611378',
        music_name: '说谎(央视2014全球中文音乐榜上榜)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/d465117af199dab2010ce1ea85755953.mp3',
      },
      options: [
        {
          music_id: '2611378',
          music_name: '说谎(央视2014全球中文音乐榜上榜)',
        },
        {
          music_id: '2611084',
          music_name: '随它吧(央视2014全球中文音乐榜上榜)',
        },
        {
          music_id: '2614301',
          music_name: '钱不重要，重要的是我喜欢丑的_剪辑后',
        },
        {
          music_id: '2614040',
          music_name: '挪威的森林',
        },
      ],
    },
    {
      id: 30,
      play_song: {
        music_id: '2611285',
        music_name: '大约在冬季',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/c1f1a06916d63431c23cb95430a57614.mp3',
      },
      options: [
        {
          music_id: '2614310',
          music_name: '千年之恋',
        },
        {
          music_id: '2614328',
          music_name: '遥远的她',
        },
        {
          music_id: '2611285',
          music_name: '大约在冬季',
        },
        {
          music_id: '2611182',
          music_name: '一生有你(央视2014全球中文音乐榜上榜)',
        },
      ],
    },
    {
      id: 31,
      play_song: {
        music_id: '2611546',
        music_name: '你看你看月亮的脸',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/085e22bb6d2a69a234dde5caff17562c.mp3',
      },
      options: [
        {
          music_id: '2611426',
          music_name: '伤不起(娱乐大歌厅)',
        },
        {
          music_id: '2614284',
          music_name: '张艺兴 - 梦想起飞',
        },
        {
          music_id: '2614215',
          music_name: '转码声音有杂质',
        },
        {
          music_id: '2611546',
          music_name: '你看你看月亮的脸',
        },
      ],
    },
    {
      id: 32,
      play_song: {
        music_id: '2611066',
        music_name: '千年之恋 (Unplug版)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/650825eff1b6d5541b6b32d3e6922405.mp3',
      },
      options: [
        {
          music_id: '2614245',
          music_name: '8447b636045c45b7a909e0aaf5fc9f07',
        },
        {
          music_id: '2614320',
          music_name: '里',
        },
        {
          music_id: '2614308',
          music_name: '❝我被爱判处终生孤寂❞屠龙刀',
        },
        {
          music_id: '2611066',
          music_name: '千年之恋 (Unplug版)',
        },
      ],
    },
    {
      id: 33,
      play_song: {
        music_id: '2614373',
        music_name: '小白',
        play_address: '/resource/fd1084cd18a84943a7fabff89b99820f.mp3',
      },
      options: [
        {
          music_id: '2614032',
          music_name: '爱我久久(电视剧《白发魔女》片尾曲)',
        },
        {
          music_id: '2611337',
          music_name: '我们的歌',
        },
        {
          music_id: '2614373',
          music_name: '小白',
        },
        {
          music_id: '2611287',
          music_name: '爱情码头',
        },
      ],
    },
    {
      id: 34,
      play_song: {
        music_id: '2611156',
        music_name: '不怕不怕 (Sandsto Club Remix)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/650825eff1b6d554ef067623934a547f.mp3',
      },
      options: [
        {
          music_id: '2611385',
          music_name: '难以抗拒你容颜',
        },
        {
          music_id: '2611122',
          music_name: '洋葱(恒大音乐节)',
        },
        {
          music_id: '2614239',
          music_name: 'Last Stop6',
        },
        {
          music_id: '2611156',
          music_name: '不怕不怕 (Sandsto Club Remix)',
        },
      ],
    },
    {
      id: 35,
      play_song: {
        music_id: '2611373',
        music_name: 'Melody',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/1808fcc73574dff0ba440d6c656efd3d.mp3',
      },
      options: [
        {
          music_id: '2611373',
          music_name: 'Melody',
        },
        {
          music_id: '2614375',
          music_name: '小小小白',
        },
        {
          music_id: '2611380',
          music_name: '等你下课(with 杨瑞代)',
        },
        {
          music_id: '2611220',
          music_name: '烟火里的尘埃',
        },
      ],
    },
    {
      id: 36,
      play_song: {
        music_id: '2611552',
        music_name: '灰姑娘',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/650825eff1b6d5545ba3d9e1a454c729.mp3',
      },
      options: [
        {
          music_id: '2611018',
          music_name: '蝶恋花',
        },
        {
          music_id: '2611552',
          music_name: '灰姑娘',
        },
        {
          music_id: '2611550',
          music_name: '绿光',
        },
        {
          music_id: '2611094',
          music_name: '非你莫属',
        },
      ],
    },
    {
      id: 37,
      play_song: {
        music_id: '2614085',
        music_name: '公子请留步_1596608780950',
        play_address: '/resource/f5b5c1434daf4229bdf69aa9ef0e0a50.aac',
      },
      options: [
        {
          music_id: '2614273',
          music_name: 'Beijing Bass4',
        },
        {
          music_id: '2611402',
          music_name: '无情的雨无情的你',
        },
        {
          music_id: '2611178',
          music_name: '爱我的人和我爱的人',
        },
        {
          music_id: '2614085',
          music_name: '公子请留步_1596608780950',
        },
      ],
    },
    {
      id: 38,
      play_song: {
        music_id: '2611072',
        music_name: '他一定很爱你',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/9e9efd4bc679f5bfeafd3fe80fd0c8a5.mp3',
      },
      options: [
        {
          music_id: '2610979',
          music_name: '爱转角(央视开门大吉)',
        },
        {
          music_id: '2611072',
          music_name: '他一定很爱你',
        },
        {
          music_id: '2611317',
          music_name: '在雨中 (Live)',
        },
        {
          music_id: '2614327',
          music_name: '遥远的她',
        },
      ],
    },
    {
      id: 39,
      play_song: {
        music_id: '2614384',
        music_name: '33333333',
        play_address: '/resource/958235cf651d42a481d0ad6a8fcbc752.flac',
      },
      options: [
        {
          music_id: '2614384',
          music_name: '33333333',
        },
        {
          music_id: '2611284',
          music_name: '爱的故事上集 (Live)',
        },
        {
          music_id: '2614065',
          music_name: '突然想到理想这个词',
        },
        {
          music_id: '2611371',
          music_name: '知心爱人(央视激情广场涟水篇)',
        },
      ],
    },
    {
      id: 40,
      play_song: {
        music_id: '2614169',
        music_name: '忽然之间',
        play_address: '/resource/a665a93358fb4231a22efb1d7dddc6f7.mp3',
      },
      options: [
        {
          music_id: '2611000',
          music_name: '青春纪念册',
        },
        {
          music_id: '2611126',
          music_name: '没有什么不同',
        },
        {
          music_id: '2614169',
          music_name: '忽然之间',
        },
        {
          music_id: '2611361',
          music_name: '走马',
        },
      ],
    },
    {
      id: 41,
      play_song: {
        music_id: '2611144',
        music_name: '天使与海豚',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/ba673b828be51a2f7b5c1b75b3ad5dc4.mp3',
      },
      options: [
        {
          music_id: '2611321',
          music_name: '心中的日月',
        },
        {
          music_id: '2611144',
          music_name: '天使与海豚',
        },
        {
          music_id: '2611470',
          music_name: '欧若拉 (2020北京卫视跨年演唱会)',
        },
        {
          music_id: '2611175',
          music_name: '漂洋过海来看你(蒙面唱将猜猜猜)',
        },
      ],
    },
    {
      id: 42,
      play_song: {
        music_id: '2611441',
        music_name: '两只蝴蝶(电视剧《281封信》插曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/692abe5995d71a7831bf9b3e3a8dd3ff.mp3',
      },
      options: [
        {
          music_id: '2611377',
          music_name: '一个人的精彩',
        },
        {
          music_id: '2614209',
          music_name: '童话',
        },
        {
          music_id: '2614360',
          music_name: '0ed3fb9c-5336-4b9b-9e7e-9b0c70717d35',
        },
        {
          music_id: '2611441',
          music_name: '两只蝴蝶(电视剧《281封信》插曲)',
        },
      ],
    },
    {
      id: 43,
      play_song: {
        music_id: '2614208',
        music_name: '#不知所措 #姚晓棠 苦涩。',
        play_address: '/resource/a998a5c7daad4bca8a28e5f9e8ef800a.aac',
      },
      options: [
        {
          music_id: '2611283',
          music_name: '暧昧',
        },
        {
          music_id: '2614208',
          music_name: '#不知所措 #姚晓棠 苦涩。',
        },
        {
          music_id: '2611180',
          music_name: '十五的月亮',
        },
        {
          music_id: '2611188',
          music_name: '谁明浪子心(电视剧《还我本色》主题曲)',
        },
      ],
    },
    {
      id: 44,
      play_song: {
        music_id: '2611398',
        music_name: '一生爱你千百回',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/a7246919c0a26905fb7f8165a02f3454.mp3',
      },
      options: [
        {
          music_id: '2614382',
          music_name: '初审test',
        },
        {
          music_id: '2611320',
          music_name: '爱就一个字(电影《宝莲灯(1999)》片尾曲)',
        },
        {
          music_id: '2611398',
          music_name: '一生爱你千百回',
        },
        {
          music_id: '2611157',
          music_name: '月牙湾',
        },
      ],
    },
    {
      id: 45,
      play_song: {
        music_id: '2611360',
        music_name: '倩女幽魂 (电影《倩女幽魂》主题曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/2079fb4cd3d5bec7e8738806537c698d.mp3',
      },
      options: [
        {
          music_id: '2611513',
          music_name: '兰亭序',
        },
        {
          music_id: '2614401',
          music_name: '我们不一样',
        },
        {
          music_id: '2611360',
          music_name: '倩女幽魂 (电影《倩女幽魂》主题曲)',
        },
        {
          music_id: '2611449',
          music_name: '我爱洗澡(百变大咖秀)',
        },
      ],
    },
    {
      id: 46,
      play_song: {
        music_id: '2611204',
        music_name: '栀子花开(电影《栀子花开》主题曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/b7d97fe6d29a68628e6d71f4ed204ea3.mp3',
      },
      options: [
        {
          music_id: '2611527',
          music_name: '天涯',
        },
        {
          music_id: '2611204',
          music_name: '栀子花开(电影《栀子花开》主题曲)',
        },
        {
          music_id: '2611440',
          music_name: '下一站天后',
        },
        {
          music_id: '2614353',
          music_name: '王菲～天空   很美丽',
        },
      ],
    },
    {
      id: 47,
      play_song: {
        music_id: '2611514',
        music_name: '祝你一路顺风 (电影《逃学外传》插曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/1e1864381872367a2a3b59f873e1698d.mp3',
      },
      options: [
        {
          music_id: '2614269',
          music_name: 'Beijing Bass',
        },
        {
          music_id: '2611096',
          music_name: '铁血丹心(电视剧《射雕英雄传(黄日华版)》主题曲)',
        },
        {
          music_id: '2611514',
          music_name: '祝你一路顺风 (电影《逃学外传》插曲)',
        },
        {
          music_id: '2614377',
          music_name: 'Plastic n Ivory',
        },
      ],
    },
    {
      id: 48,
      play_song: {
        music_id: '2611388',
        music_name: '我的天空(电影《青春派》主题曲)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/9893b471266593d206f1f08a7c59eeaf.mp3',
      },
      options: [
        {
          music_id: '2611121',
          music_name: '情非得已(电视剧《流星花园》主题曲)',
        },
        {
          music_id: '2611388',
          music_name: '我的天空(电影《青春派》主题曲)',
        },
        {
          music_id: '2614289',
          music_name: 'VID_20200901刚刚好哈哈',
        },
        {
          music_id: '2611465',
          music_name: '一个像夏天一个像秋天',
        },
      ],
    },
    {
      id: 49,
      play_song: {
        music_id: '2611279',
        music_name: '舍不得',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/ee7dd5b44def91d6ca69e8ed2b167b96.mp3',
      },
      options: [
        {
          music_id: '2611279',
          music_name: '舍不得',
        },
        {
          music_id: '2611531',
          music_name: '雪候鸟(电视剧《新乱世佳人》片头曲)',
        },
        {
          music_id: '2614008',
          music_name: '水手',
        },
        {
          music_id: '2611405',
          music_name: '盛夏的果实(央视开门大吉)',
        },
      ],
    },
    {
      id: 50,
      play_song: {
        music_id: '2611433',
        music_name: '阿刁 (歌手2018)',
        play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/0b7b5541ed259aabba89d98dcb3d138d.mp3',
      },
      options: [
        {
          music_id: '2614165',
          music_name: '忽然之间',
        },
        {
          music_id: '2611243',
          music_name: '鬼迷心窍',
        },
        {
          music_id: '2611319',
          music_name: '越长大越孤单',
        },
        {
          music_id: '2611433',
          music_name: '阿刁 (歌手2018)',
        },
      ],
    },
  ],
};

export const mockPlayList = [
  {
    id: 1,
    play_song: {
      music_id: 8,
      music_name: '天青色在等烟雨',
      play_address: '',
    },
    options: [
      {
        music_id: 8,
        music_name: '爱在西元前',
      },
      {
        music_id: 1,
        music_name: '曹操',
      },
      {
        music_id: 2,
        music_name: '不为谁而作的歌',
      },
      {
        music_id: 3,
        music_name: '江南',
      },
    ],
  },

  {
    id: 2,
    play_song: {
      music_id: 2,
      music_name: '天青色在等烟雨',
      play_address: 'https://esapp.oss-cn-shenzhen.aliyuncs.com/migu/e361a570cdbca7b5146b122c770e7303.mp3',
    },
    options: [
      {
        music_id: 1,
        music_name: '曹操',
      },
      {
        music_id: 2,
        music_name: '不为谁而作的歌',
      },
      {
        music_id: 3,
        music_name: '江南',
      },
      {
        music_id: 4,
        music_name: '稻香',
      },
    ],
  },
];
