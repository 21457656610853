<template>
  <div class="modal-warpper">
    <div class="modal-container">
      <div class="modal-content">
        <!-- 顶部开始 -->
        <div class="modal-content-top">
          <p class="modal-content-top-title">活动规则</p>
          <img @click="handleClose" src="../../../assets/images/common-component/close.png" alt="关闭" />
        </div>
        <!-- 顶部结束 -->
        <!-- 规则内容开始 -->
        <div class="modal-content-detail">
          <p>
            1、睡觉就能赚金币，每晚{{ this.taskStartTime }}至次日{{ this.taskEndTime }}，点击【睡觉
            瓜分奖池】，并支付100金币即可参与第二日的早起打卡瓜分奖池的活动。您可以在次日{{ this.settleStartTime }}至{{
              this.settleEndTime
            }}记得回来这里点击【我睡醒了，领金币】瓜分打卡icon即可获得早起瓜分的金币奖励，领取瓜分奖励后记得点击【早起金币】领取当日早起金币奖励；
          </p>
          <p>
            2、每晚{{ this.taskStartTime }}至次日{{ this.taskEndTime }}您可点击【我要睡了】进入睡眠，您可以在次日{{
              this.settleStartTime
            }}至{{ this.settleEndTime }}回来这里点击【我睡醒了，领金币】领取每日早起金币奖励；
          </p>
          <p>
            3、金币气泡：每晚进入睡眠状态前，系统会根据您当前的状态值进行结算，并在第二天您点击早起打卡后发放不同数量、额度的金币气泡，您可以通过点击这些气泡获得更多金币奖励。一定要记得在第二天早上过来打卡哦；
          </p>
          <p>
            4、幸运大转盘：在睡觉打卡的时间外，您可以点击页面左上方的转盘图标进入“幸运大转盘”，您可以在这里获得数量不等的金币奖励和限时虚拟道具奖励哦；
          </p>
          <p>
            5、每日签到：每日登陆可获得限时虚拟道具及数量不等的金币奖励哦～连续签到的天数越多，奖励也就越丰厚，所以请坚持每日签到哦～
          </p>
          <p>
            6、虚拟道具：您穿戴的虚拟道具跟您每日早起打卡后获得的金币气泡的数量及金额有关，您可以通过参与每日签到、幸运大转盘的方式获得虚拟道具；
          </p>
          <p>7、欢迎您点击页面左上方的【分享】把我们的app及活动分享给更多的亲友，邀请他们一起来玩；</p>
          <p>
            8、如果您有其他问题，请点击加入咨询QQ群<a
              @click="copy(728704491)"
              style="color: #007aff; text-decoration: underline"
              >728704491</a
            >。
          </p>
        </div>
        <!-- 规则内容结束 -->
        <!-- 关闭开始 -->
        <div @click="handleClose" class="modal-content-ok_button">
          <div class="ok-button">好的</div>
        </div>
        <!-- 关闭结束 -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      handleClose: {
        type: Function,
      },
      taskStartTime: {
        required: true,
      },
      taskEndTime: {
        required: true,
      },
      settleStartTime: {
        required: true,
      },
      settleEndTime: {
        required: true,
      },
      copy: {
        type: Function,
        required: true,
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal-container {
    width: 2.6rem;
    height: 2.85rem;
    background: #ffffff;
    box-shadow: 0px 1rem 0.3rem 0px rgba(0, 0, 0, 0.28);
    border-radius: 0.12rem;
  }
  .modal-content {
    box-sizing: border-box;
    padding: 0.15rem;
    width: 100%;
    height: 100%;
  }
  .modal-content-top {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 0.1rem;
  }
  .modal-content-top img {
    width: 0.16rem;
    height: 0.16rem;
  }
  .modal-content-top-title {
    height: 0.16rem;
    font-size: 0.16rem;
    font-weight: bold;
    color: rgba(34, 34, 34, 1);
    line-height: 0.16rem;
    letter-spacing: 0.005rem;
  }
  .modal-content-detail {
    box-sizing: border-box;
    width: 100%;
    height: 1.77rem;
    text-align: left;
    padding: 0.1rem;
    border-radius: 0.06rem;
    border: 0.005rem solid rgba(151, 151, 151, 0.75);
    color: rgba(0, 0, 0, 0.3);
    letter-spacing: 0.005rem;
    overflow-y: scroll;
  }
  .modal-content-ok_button {
    margin-top: 0.15rem;
    width: 100%;
  }
  .ok-button {
    width: 2.03rem;
    height: 0.37rem;
    background: rgba(240, 61, 91, 1);
    border-radius: 0.22rem;
    text-align: center;
    line-height: 0.37rem;
    font-size: 0.14rem;
    color: #fff;
    font-weight: bold;
    margin: 0 auto;
  }
</style>
