<template>
  <div class="SignModal">
    <img class="SignModal-light" src="@/assets/images/eatingV2/guang.png" alt="光" />
    <div class="SignModal-signDay">已连续签到{{ pageModalInfo.today_sign_in_day }}天</div>
    <div class="SignModal-get">签到可领+{{ pageModalInfo.today_reward_amount_txt }}</div>
    <div style="display: flex; justify-content: center">
      <div class="SignModal-process">
        <img
          class="SignModal-process-right__bar"
          :src="
            pageModalInfo.today_sign_in_day >= 7
              ? require('../../../assets/images/eatingV2/right-bar-active.png')
              : require('../../../assets/images/eatingV2/right-bar.png')
          "
          alt="bar"
        />
        <div class="SignModal-process-iconPrice">
          <div
            :class="['SignModal-process-iconPrice-item', pageModalInfo.today_sign_in_day >= item.day ? 'active' : '']"
            v-for="(item, k) in firstLineProcess"
            :key="k"
          >
            <img
              class="SignModal-process-iconPrice-item-img__icon"
              src="@/assets/images/eating/icon_coin.png"
              alt="金币"
            />
            <span class="SignModal-process-iconPrice-item-num">{{ item.reward_amount }}</span>
          </div>
        </div>
        <div class="SignModal-process-first-bar">
          <div
            class="SignModal-process-first-bar-active"
            :style="`width: ${initWidth(pageModalInfo.today_sign_in_day / 6)}`"
          ></div>
        </div>
        <div class="SignModal-process-info">
          <div
            v-for="(item, k) in firstLineProcess"
            :key="k"
            :class="['SignModal-process-info-item', pageModalInfo.today_sign_in_day === item.day ? 'active' : '']"
          >
            {{ pageModalInfo.today_sign_in_day === item.day ? '可领' : `${item.day}天` }}
          </div>
        </div>
        <div class="SignModal-process-gift">
          <div
            :class="['SignModal-process-gift-item', pageModalInfo.today_sign_in_day >= item.day ? 'active' : '']"
            v-for="(item, k) in secondLineProcess"
            :key="k"
          >
            <img
              :class="[
                'SignModal-process-gift-item-img__icon',
                pageModalInfo.today_sign_in_day >= item.day ? 'active' : '',
                pageModalInfo.today_sign_in_day === item.day ? 'rotateAni' : '',
              ]"
              :src="
                pageModalInfo.today_sign_in_day >= item.day
                  ? require('../../../assets/images/eatingV2/modal-price.png')
                  : require('../../../assets/images/eatingV2/modal-price-normal.png')
              "
              alt="金币"
            />
            <span class="SignModal-process-gift-item-num">{{ item.reward_amount }}</span>
          </div>
        </div>
        <div class="SignModal-process-second-bar">
          <div
            :class="['SignModal-process-second-bar-left', pageModalInfo.today_sign_in_day >= 30 ? 'active' : '']"
          ></div>
          <div class="SignModal-process-second-bar-active" :style="`width:${initWidth('', 'second')}`"></div>
        </div>
        <div class="SignModal-process-second-info">
          <div
            v-for="(item, k) in secondLineProcess"
            :key="k"
            :class="[
              'SignModal-process-second-info-item',
              pageModalInfo.today_sign_in_day === item.day ? 'active' : '',
            ]"
          >
            {{ pageModalInfo.today_sign_in_day === item.day ? '可领' : `${item.day}天` }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="pageModalInfo.extend_tasks.length > 0" style="display: flex; justify-content: center">
      <div @click="loadMediationAd" class="SignModal-btn__video">
        <span>看视频领取{{ pageModalInfo.extend_tasks[0].reward_amount }}金币</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import sdk from '@/utils/sdk';
  import { trackClick } from '@/utils/track';
  import { closeModal } from '@/utils/modal';
  export default {
    name: 'SignModal',
    computed: {
      ...mapGetters('eatingV2', ['pageModalInfo', 'BusinessName']),
      firstLineProcess() {
        return this.pageModalInfo.data_list.slice(0, 6);
      },
      secondLineProcess() {
        return this.pageModalInfo.data_list.slice(6);
      },
    },
    methods: {
      ...mapActions('eatingV2', ['getEatingPageInfo', 'getSignListInfo']),
      ...mapMutations('eatingV2', ['setVideoOrigin']),
      initWidth(point, second) {
        if (second) {
          if (
            this.pageModalInfo.today_sign_in_day >= this.secondLineProcess[0].day &&
            this.pageModalInfo.today_sign_in_day < this.secondLineProcess[2].day
          ) {
            // 前三项的计算规则为 总长度的2/3，乘以签到天数所占比例
            point =
              ((2 / 3) * (this.pageModalInfo.today_sign_in_day - this.secondLineProcess[0].day)) /
              (this.secondLineProcess[2].day - this.secondLineProcess[0].day);
          } else if (this.pageModalInfo.today_sign_in_day >= this.secondLineProcess[2].day) {
            point =
              (this.pageModalInfo.today_sign_in_day - this.secondLineProcess[0].day) /
              (this.secondLineProcess[3].day - this.secondLineProcess[0].day);
          }
        } else {
          if (point === 0 || point < 0) {
            return `0%`;
          } else if (point >= 1) {
            return `100%`;
          }
        }
        let str = Number(point * 100).toFixed();
        str += '%';
        return str;
      },
      /**
       * @description: 播放聚合广告
       * @return {void}
       */

      loadMediationAd() {
        closeModal('eating-sign-modal');
        this.setVideoOrigin('modal');
        trackClick({ page_title: '吃饭补贴', element_name: '每日签到', remarks: '看视频领取' });
        try {
          let { task_id, task_type, position, ad_group_id: groupId } = this.pageModalInfo.extend_tasks[0];
          const params = {
            uid: this.$store.getters.client.uid,
            tid: task_id,
            task_type: task_type,
            app_position: position,
          };
          if (!params.app_position) {
            trackClick({
              page_title: 'loadMediationAd',
              element_name: 'app_position',
              remarks: JSON.stringify(params),
            });
          }
          sdk.appCall(sdk.callMethod.LOAD_MEDIATION_AD, groupId, JSON.stringify(params));
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>

<style scoped lang="less">
  .SignModal {
    background: url('../../../assets/images/eatingV2/signmodalbg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    padding: 0.2rem 0;
    width: 3.8rem;
    height: 4rem;
    position: relative;

    &-signDay {
      font-size: 0.12rem;
      color: #d08437;
      margin-top: 0.72rem;
      font-weight: bold;
      text-align: center;
    }

    &-get {
      font-size: 0.18rem;
      font-weight: bold;
      color: #cc6313;
      margin-top: 0.3rem;
      text-align: center;
    }

    &-light {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -9;
      animation: light 15s linear infinite;
    }

    &-process {
      width: 60%;
      margin-top: 0.2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-iconPrice {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 100%;

        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          &-img__icon {
            width: 0.16rem;
            height: 0.16rem;
          }

          &-num {
            font-size: 0.11rem;
            color: #c3c3c3;

            &.active {
              color: #d4721d;
            }
          }

          &.active::after {
            content: '';
            position: absolute;
            bottom: -0.14rem;
            z-index: 9;
            display: inline-block;
            height: 0.12rem;
            width: 0.12rem;
            background-color: #ffa200;
            border-radius: 50%;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -0.11rem;
            z-index: 9;
            display: inline-block;
            width: 0.06rem;
            height: 0.06rem;
            border-radius: 50%;
            background: #fff;
          }
        }
      }

      &-first-bar {
        margin-top: 0.04rem;
        height: 0.08rem;
        border-radius: 0.04rem;
        background-color: #fee2b1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
        right: 0.15rem;
        width: 100%;
        z-index: 8;

        &-active {
          position: absolute;
          top: 0;
          left: 0;

          height: 100%;
          border-radius: 0.04rem;
          background-color: #ffa200;
          transition: width 2s;
        }
      }
      &-second-bar {
        margin-top: 0.04rem;
        height: 0.08rem;
        border-radius: 0.04rem;
        background-color: #fee2b1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
        right: 0;
        width: 82%;
        z-index: 8;

        &-active {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          border-radius: 0.04rem;
          background-color: #ffa200;
          transition: width 2s;
        }
        &-left {
          position: absolute;
          top: 0;
          left: -0.3rem;
          width: 0.5rem;
          height: 100%;
          border-radius: 0.04rem;
          background-color: #fee2b1;
          &.active {
            background-color: #ffa200;
          }
        }
      }

      &-gift {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-direction: row-reverse;
        width: 105%;
        height: 0.74rem;
        &-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          align-self: flex-end;
          &-img__icon {
            width: 0.3rem;
            height: 0.25rem;
            //margin-top: 0.36rem;
            margin-bottom: 0.07rem;
            &.active {
              width: 0.55rem;
              height: 0.54rem;
              margin: 0;
            }
            &.rotateAni {
              animation: rotateAni 2.5s 0.15s linear infinite;
            }
          }

          &-num {
            font-size: 0.11rem;
            color: #c3c3c3;

            &.active {
              color: #d4721d;
            }
          }

          &.active::after {
            content: '';
            position: absolute;
            bottom: -0.14rem;
            z-index: 9;
            display: inline-block;
            height: 0.12rem;
            width: 0.12rem;
            background-color: #ffa200;
            border-radius: 50%;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -0.11rem;
            z-index: 9;
            display: inline-block;
            width: 0.06rem;
            height: 0.06rem;
            border-radius: 50%;
            background: #fff;
          }
        }
      }

      &-info {
        display: flex;
        justify-content: space-between;
        margin-top: 0.06rem;
        width: 100%;

        &-item {
          font-size: 0.11rem;
          color: #c3c3c3;
          flex: 1;
          text-align: center;

          &.active {
            color: #d4721d;
            font-size: 0.1rem;
            font-weight: bold;
          }
        }
      }

      &-second-info {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 0.06rem;
        width: 100%;

        &-item {
          flex: 1;
          font-size: 0.11rem;
          color: #c3c3c3;
          text-align: center;

          &.active {
            color: #d4721d;
            font-size: 0.1rem;
            font-weight: bold;
          }
        }
      }

      &-right__bar {
        position: absolute;
        right: -0.06rem;
        top: 0.34rem;
        height: 67.2%;
      }
    }

    &-btn__video {
      margin-top: 0.6rem;
      width: 1.89rem;
      height: 0.5rem;
      background: url('../../../assets/images/eatingV2/signmodalBtn.png') no-repeat no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.17rem;
      font-weight: bold;
      color: #3e3a13;
      //box-shadow: 0 0.07rem 0 0 #ecc11f;
      position: relative;
    }
  }

  @keyframes light {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotateAni {
    10% {
      transform: rotate(15deg);
    }
    20% {
      transform: rotate(-10deg);
    }
    30% {
      transform: rotate(5deg);
    }
    40% {
      transform: rotate(-5deg);
    }
    50%,
    100% {
      transform: rotate(0deg);
    }
  }
</style>
