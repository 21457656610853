import 'babel-polyfill';
import Es6Promise from 'es6-promise';
require('es6-promise').polyfill();
Es6Promise.polyfill();
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/main/install';

Vue.config.productionTip = false;
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
const sentryConfig = require('../sentry.config');

// 集成 Sentry 监控
process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    Vue,
    dsn: 'https://6077e21f9c85451087388484fac9bbbd@sentry.kaixinyf.cn/7',
    environment: `${sentryConfig.ENVIRONMENT}`,
    integrations: [new Integrations.BrowserTracing({ tracingOrigins: ['h5.kaixinyf.cn', 'h5.rd.kaixinyf.cn'] })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    release: `${sentryConfig.PROJECT_NAME}-${sentryConfig.ENVIRONMENT}-${sentryConfig.RELEASE_VERSION}`,
    logErrors: true,
  });
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
