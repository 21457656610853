<template>
  <div class="health_reward_modal" :class="{ double: isDouble }">
    <img class="health_title" src="@/assets/images/drinkingTask/health_title.png" alt="title" />
    <h2 v-if="!isDouble">
      昨日健康值为<span class="number">{{ healthValue }}</span>
    </h2>
    <h2 v-else>恭喜您获得翻倍领取</h2>
    <p v-if="!isDouble" class="text">
      恭喜您获得<span class="number">{{ coins }}</span
      >金币
    </p>
    <p v-else class="text">
      获得<span class="number">{{ coins }}</span
      >金币
    </p>

    <div v-if="!isDouble" class="button" @click="onClick">立即翻倍</div>
    <div v-else class="button" @click="onClose">关闭</div>

    <p v-if="!isDouble" class="tips">今日也要坚持整点打卡哦</p>
  </div>
</template>

<script>
  export default {
    props: {
      isDouble: {
        type: Boolean,
        default: true,
      },
      onClose: {
        type: Function,
        default: () => {},
      },
      healthValue: {
        type: Number,
        default: 0,
      },
      coins: {
        type: Number,
        default: 0,
      },
      onClick: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>

<style lang="less" scoped>
  .health_reward_modal {
    width: 3rem;
    height: 2.28rem;
    background: #ffffff;
    border-radius: 0.12rem;
    padding: 0.62rem 0.2rem 0.2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.double {
      height: 2.4rem;
    }
    .health_title {
      position: absolute;
      left: 1.05rem;
      top: -0.45rem;
      width: 0.9rem;
      height: 0.9rem;
    }
    h2 {
      height: 0.25rem;
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.25rem;
    }
    .text {
      height: 0.16rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 0.16rem;
      margin-top: 0.14rem;
    }
    .number {
      height: 0.23rem;
      font-size: 0.23rem;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #62b7ff;
      line-height: 0.23rem;
      position: relative;
      top: 0.02rem;
    }
    .button {
      margin-top: 0.25rem;
      width: 2.6rem;
      height: 0.4rem;
      background: #62b7ff;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
    }
    .tips {
      margin-top: 0.16rem;
      height: 0.14rem;
      font-size: 0.14rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #62b7ff;
      line-height: 0.14rem;
    }
  }
</style>
